import styled from 'styled-components/macro';

export const EventWrapper = styled.div<{
    color: string;
    isStart: boolean;
    isEnd: boolean;
    index: number;
    name: string;
    yearView?: boolean;
}>`
    position: absolute;
    cursor: pointer;
    border-radius: ${(p) =>
        p.isEnd && p.isStart ? '10px' : p.isStart ? '10px 0px 0px 10px' : p.isEnd ? '0px 10px 10px 0px' : '0px'};
    top: ${(p) => (p.yearView ? `${p.index * 8 + 6}px` : `${p.index * 30}px`)};
    left: -1px;
    width: ${(p) =>
        p.isEnd && p.isStart
            ? 'calc(100% - 19px)'
            : p.isEnd
            ? 'calc(100% - 10px)'
            : p.isStart
            ? 'calc(100% - 9px)'
            : 'calc(100% + 1px)'};
    margin-left: ${(p) => (p.isStart ? '10px' : '0px')};
    margin-right: ${(p) => (p.isEnd ? '10px' : '0px')};
    z-index: 10;
    height: ${(p) => (p.yearView ? '5px' : '20px')};
    background-color: ${(p) => p.color};
    overflow: hidden;
`;

export const EventName = styled.div`
    position: absolute;
    top: 0;
    left: 10px;
    white-space: nowrap;
`;

export const EventBody = styled.div`
    height: 100%;
    width: 100%;
`;
