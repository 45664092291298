import { CrossVisitsMapsSettingsIds, ICrossVisitsSelectOption } from '../../../constants/crossVisitsMapsSettings';
import { handleServerResponse, TServerResponse } from '../../../../../../tools/API/handleServerResponse';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import requestAverageIntersectionsHelper from '../tools/requestAverageIntersectionsHelper';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { generalReducerValues } from '../../../../../../General.reducer';
import { mlFromLocation } from '../../../../../../tools/mlFromLocation';
import { ResponseStatus } from '../../../../../../tools/API/constants';
import { CROSS_VISITS_MAPS_SETTINGS } from '../../../constants/constants';
import { Maps_CrossVisits_Module_Reducer_Values } from '../../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import {
    storeRawComparisonAverageIntersectionsData,
    storeRawAverageIntersectionsData,
    Maps_CrossVisits_Widget_Reducer_Values,
    storeRawLayersData,
    storeRawPlansData,
} from '../reducer';
import requestLayers from '../../../../../../tools/API/requestLayers';
import requestPlans from '../../../../../../tools/API/requestPlans';
import usePrepareData from './usePrepareData';

/**
 * Кастомный хук для получения сырых данных
 */
export const useGetRawData = () => {
    const { mainReportingObjectId, contraReportingObjectsIds } = useSelector(Maps_CrossVisits_Widget_Reducer_Values);
    const { selectedVersionId, moduleName } = useSelector(Maps_CrossVisits_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { token, locations, urlsByServices, selectedLocationId } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    usePrepareData();

    const [plansUrl, layersUrl] = useMemo(() => {
        if (urlsByServices?.['core/map-service-back']) {
            return [
                urlsByServices['core/map-service-back'].PLANS_URL,
                urlsByServices['core/map-service-back'].LAYERS_URL,
            ];
        }
        return [];
    }, [urlsByServices]);

    /** Запрос и сохранение сырых данных по слоям */
    useEffect(() => {
        if (selectedVersionId && localCurrentOptions?.selectedLocationId && token && layersUrl) {
            const location = locations?.find((item) => item.id === localCurrentOptions.selectedLocationId);
            const mlId = mlFromLocation(location);
            dispatch(
                storeRawLayersData({ status: ResponseStatus.Loading, message: `${t(ResponseStatus.Loading)}...` }),
            );
            mlId &&
                requestLayers({
                    mlId,
                    token,
                    versionId: String(selectedVersionId),
                    url: `${layersUrl}?location_id=${mlId}&version_id=${selectedVersionId}`,
                }).then((res: TServerResponse) => {
                    handleServerResponse({
                        success: storeRawLayersData,
                        error: storeRawLayersData,
                        responseAnalyzer,
                        dispatch,
                        res,
                    });
                });
        }
    }, [selectedVersionId, token, layersUrl]);

    /** Запрос и сохранение сырых данных для планов*/
    useEffect(() => {
        if (!selectedLocationId || !token || !locations.length) return;
        const location = locations?.find((item) => item.id === selectedLocationId);
        const mlId = mlFromLocation(location);
        dispatch(storeRawPlansData({ status: ResponseStatus.Loading, message: `${t(ResponseStatus.Loading)}...` }));
        if (mlId && plansUrl) {
            requestPlans({ token, url: `${plansUrl}?location_id=${mlId}` }).then((res: TServerResponse) => {
                handleServerResponse({
                    success: storeRawPlansData,
                    error: storeRawPlansData,
                    responseAnalyzer,
                    dispatch,
                    res,
                });
            });
        }
    }, [selectedLocationId, token, locations, plansUrl]);

    /** Заапрос и сохранение сырых данных по среднедневным пересечениям */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if (localCurrentOptions?.mainPeriod && mainReportingObjectId && contraReportingObjectsIds && urlsByServices) {
            requestAverageIntersectionsHelper({
                dateRanges: localCurrentOptions.mainDateRanges,
                action: storeRawAverageIntersectionsData,
                period: localCurrentOptions.mainPeriod,
                contraReportingObjectsIds,
                mainReportingObjectId,
                dispatch,
                signal,
                token,
                url: `${urlsByServices['core/structure-service'].AVERAGE_INTERSECTIONS_REQUEST_URL}`,
            });
        }

        return () => controller.abort();
    }, [
        JSON.stringify(localCurrentOptions?.mainPeriod),
        mainReportingObjectId,
        contraReportingObjectsIds,
        urlsByServices,
    ]);

    /** Получение сырых данных для периода сранвнения */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if (
            !!localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]?.find(
                (element: ICrossVisitsSelectOption) => element.id === CrossVisitsMapsSettingsIds.Comparison,
            ) &&
            localCurrentOptions?.comparePeriods &&
            mainReportingObjectId &&
            contraReportingObjectsIds &&
            urlsByServices
        ) {
            requestAverageIntersectionsHelper({
                action: storeRawComparisonAverageIntersectionsData,
                dateRanges: localCurrentOptions.compareDateRanges,
                period: localCurrentOptions.comparePeriods[0],
                contraReportingObjectsIds,
                mainReportingObjectId,
                dispatch,
                signal,
                token,
                url: `${urlsByServices['core/structure-service'].AVERAGE_INTERSECTIONS_REQUEST_URL}`,
            });
        } else {
            dispatch(storeRawComparisonAverageIntersectionsData(null));
        }

        return () => controller.abort();
    }, [
        JSON.stringify(localCurrentOptions?.compareDateRanges?.map((element) => element.period)),
        JSON.stringify(localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]),
        JSON.stringify(localCurrentOptions?.comparePeriods),
        contraReportingObjectsIds,
        mainReportingObjectId,
        urlsByServices,
    ]);
};
