import React from 'react';
import { IPanelProps } from './interfaces';
import { PanelWrapper, Title } from './styles';

const Panel: React.FC<IPanelProps> = ({ title, children }) => {
    return (
        <PanelWrapper>
            <Title>{title}</Title>
            {children}
        </PanelWrapper>
    );
};

export default Panel;
