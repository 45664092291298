import { cloneDeep } from 'lodash';

import { IReportingObject } from '../../../../../../../../General.interfaces';
import { IEventType } from '../../../../../../../Events/EventsMap/widgets/EventsMapWidget/interfaces';
import { IFiltersByCellKey, ITableCellData } from '../../../interfaces';
import getCellStringValue from '../../../tools/getCellStringValue';

interface IArgs {
    tableData: ITableCellData[][];
    filters?: IFiltersByCellKey | null;
    reportingObjectsById?: { [id: string]: IReportingObject };
    eventTypesById?: { [id: string]: IEventType };
}

/**
 * Функция для фильтрации строк в таблице
 * @param tableData данные таблицы
 * @param filters все фильтры
 */
const filterTableData = (args: IArgs) => {
    const { tableData, filters, eventTypesById, reportingObjectsById } = args;
    if (filters) {
        let result = cloneDeep(tableData);
        Object.keys(filters).forEach((key) => {
            const filterData = filters[key];
            switch (filterData.mode) {
                case 'Any value':
                    result = result.filter((row) => {
                        if (row[0].type === 'header') return true;
                        const currentCell = row.find((cell) => cell.key === key);
                        if (currentCell) {
                            return currentCell.value !== '';
                        }
                        return true;
                    });
                    break;
                case 'No value':
                    result = result.filter((row) => {
                        if (row[0].type === 'header') return true;
                        const currentCell = row.find((cell) => cell.key === key);
                        if (currentCell) {
                            return currentCell.value === '';
                        }
                        return true;
                    });
                    break;

                default:
                    result = result.filter((row) => {
                        if (row[0].type === 'header') return true;

                        const currentCell = row.find((cell) => cell.key === key);

                        if (currentCell) {
                            const value = getCellStringValue(currentCell, reportingObjectsById, eventTypesById);

                            return JSON.stringify(value).toLowerCase().includes(filterData.filter.toLowerCase());
                        }
                        return true;
                    });
                    break;
            }
        });
        return result;
    }

    return tableData;
};

export default filterTableData;
