import { emptyArray } from 'src/tools';
import { TFunction, TTranslationNS } from 'src/hooks/useTranslation';

import { PointsOfSalesResponse, PointOfSale, ReportingObjectsById } from '../interfaces';

import { posDataByTenantId } from './helpers';

export function allPosEntitiesByTenantAdapter({
    allTimeResponse,
    reportingObjectsById,
    t,
}: {
    response: PointsOfSalesResponse;
    allTimeResponse: PointsOfSalesResponse;
    reportingObjectsById: ReportingObjectsById;
    t: TFunction<TTranslationNS>;
}) {
    const { results = emptyArray as PointOfSale[] } = allTimeResponse?.data;

    const possByTenant = posDataByTenantId(results, reportingObjectsById, t);

    return possByTenant;
}
