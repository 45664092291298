import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { toString } from 'src/tools'
import { AppThunk, RootState } from '../../../../../store';

import { IContextMenu, IReducerState } from './interfaces';

const initialState: IReducerState = {
    chapter: 'table',
    contextMenu: { show: false },
    filters: {},
    showLoader: false,
};

export const Configuration_Categories_Widget_Reducer = createSlice({
    name: 'Configuration_Categories_Widget_Reducer',
    initialState,

    reducers: {
        storeChapter: (state, action: PayloadAction<string>) => {
            state.chapter = action.payload;
        },

        toggleContextMenu: (state, action: PayloadAction<IContextMenu>) => {
            state.contextMenu = action.payload;
        },

        resetFilters: (state, action: PayloadAction) => {
            state.filters = {};
        },

        toggleLoader: (state, action: PayloadAction<boolean>) => {
            state.showLoader = action.payload;
        },

        changeFilters: (state, action: PayloadAction<{ value: string; colIndex: number; mode?: string }>) => {
            const { filters } = cloneDeep(state);
            const { colIndex, value, mode } = action.payload;
            if (!value || value === '') {
                delete filters[colIndex];
                state.filters = filters;
            } else {
                if (mode) {
                    state.filters = { ...filters, [toString(colIndex)]: { filter: value, mode } };
                } else {
                    state.filters = { ...filters, [toString(colIndex)]: { filter: value } };
                }
            }
        },
    },
});

export const { storeChapter, toggleContextMenu, toggleLoader, changeFilters, resetFilters } =
    Configuration_Categories_Widget_Reducer.actions;

export const Configuration_Categories_Widget_Reducer_Values = (state: RootState) =>
    state.Configuration_Categories_Widget_Reducer;

export default Configuration_Categories_Widget_Reducer.reducer;
