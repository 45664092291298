import styled from 'styled-components/macro';

export const Title = styled.div`
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: var(--text-lg);
    div {
        span {
            margin: 0 var(--space-xs);
            font-size: var(--text-xs);
            color: var(--color-secondary-darker);
        }
    }
    column-gap: 10px;

    @media (max-width: ${(p) => p.theme.breakpoints.md}) {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 10px;
    }
`;

export const LoadingWrapper = styled.div`
    height: 100%;
`;

export const ChartWrapper = styled.div`
    height: 100%;
`;


export const BeforeWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
`;

export const EventTooltipBodyItemWrapper = styled.div`
    display: flex;
    align-items: center;
    span {
        font-weight: 500;
        display: inline-block;
    }
`;

export const EventTypeCircle = styled.div<{ color: string }>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(p) => p.color};
`;
