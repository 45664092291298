import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IReducerState, ITableOrderData, ITableSetting } from './interfaces';
import { TabListItemIds } from '../../../enums';
import { TABLE_SETTINGS } from './constants/constants';

const initialState: IReducerState = {
    tableOrderData: null,
    tableItemsToShow: 'all',
    selectedTab: TabListItemIds.PaymentType,
    selectedTableSetting: TABLE_SETTINGS[0],
};

const Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer = createSlice({
    name: 'Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение выбранного таба
         */
        storeSelectedTab: (state, action: PayloadAction<TabListItemIds>) => {
            state.selectedTab = action.payload;
        },
        /**
         * Сохранение данных для сортировки таблицы
         */
        storeTableOrderData: (state, action: PayloadAction<null | ITableOrderData>) => {
            state.tableOrderData = action.payload;
        },

        /**
         * Изменение отображаемых элементов таблицы
         */
        storeTableItemsToShow: (state, action: PayloadAction<number | 'all'>) => {
            state.tableItemsToShow = action.payload;
        },

        /**
         * Сохранение настройки таблицы
         */
        storeTableSetting: (state, action: PayloadAction<ITableSetting>) => {
            state.selectedTableSetting = action.payload;
        },
    },
});

export const { storeTableItemsToShow, storeTableOrderData, storeSelectedTab, storeTableSetting } =
    Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer.actions;

export const Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values = (state: RootState) =>
    state.Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer;

export default Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer.reducer;
