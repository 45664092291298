import { DateTime } from 'luxon';
import { TFunction } from 'i18next';
import {
    TMetricResponse,
    IReportingObject,
    IMetricResponseItem,
    IResponseItem,
    ISheetCell,
    IMetric,
} from '../../../../../../General.interfaces';
import getDifferenceBetweenNumbers from '../../../../../../tools/getDifferenceBetweenNumbers';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';

export const generateGrid = (
    response: TMetricResponse[],
    reportingObjects: IReportingObject[],
    allMetrics: IMetric[],
    t: TFunction,
    selectedAddCols: string[] = [],
) => {
    const timeFreq = response[0][0].context.time_freq;

    //Собираем предварительную структуру
    const dataArray: Array<any>[] = [];

    response.forEach((resp: TMetricResponse) => {
        resp.forEach((item: IMetricResponseItem) => {
            const metricId = item.context.metric;
            const timeZone = item.context.data_objects[0].timezone;
            const timeRange = item.context.time_range;
            const isMainPeriod = item.context.alias === 'main';
            const isMainMetric = isMainPeriod;
            const units = allMetrics?.find((m) => m.id === item.context.metric)?.units || '';
            const objId = item.context.data_objects[0].id;
            const objName = reportingObjects?.find((item) => item.id === objId)?.name || '';
            item.items.forEach((data: IResponseItem, timeIndex) => {
                const row: any[] = [{ key: t('Object'), value: objName }];

                if (selectedAddCols?.includes('addId')) {
                    row.unshift({ key: 'Id', value: String(objId) });
                }

                let innerId = ``;
                if (timeFreq === null) {
                    const value = `${timeRange[0]} – ${timeRange[1]}`;
                    row.push({ key: t('Period'), value, isMainPeriod, timeIndex });
                    innerId = `${objId}:${value}`;
                } else {
                    const value =
                        timeFreq !== 'H'
                            ? DateTime.fromISO(data.time).toISODate()
                            : DateTime.fromISO(data.time).toFormat('yyyy-MM-dd HH:mm');
                    const key = timeFreq !== 'H' ? t('Date') : t('DateTime');
                    const weekDay = DateTime.fromISO(data.time).weekdayShort;
                    row.push({ key, value, isMainPeriod, timeIndex, weekDay });
                    innerId = `${objId}:${value}`;
                }

                if (selectedAddCols?.includes('addTimeZone')) {
                    row.push({ key: t('Time zone'), value: timeZone });
                }

                row.push({
                    metric: metricId,
                    value: data.value,
                    // value: valueFormatter({ value: data.value, units, showUnits: false, numericOutput: true }),
                    isMainMetric,
                });
                row.push({ key: 'innerId', value: innerId });
                dataArray.push(row);
            });
        });
    });

    //Собираем все метрики основного периода
    const mainRows: { [x: string]: Array<any> } = {};

    dataArray.forEach((item) => {
        if (item.some((cell) => cell.isMainPeriod)) {
            const id = item?.find((cell) => cell.key === 'innerId')?.value || ('Y' as string);
            if (mainRows.hasOwnProperty(id)) {
                const metricCell = item?.find((cell) => cell.metric);
                if (metricCell) {
                    mainRows[id] = [...mainRows[id], metricCell];
                }
            } else {
                mainRows[id] = item;
                // mainRows[id] = item?.filter((cell) => cell.key !== 'innerId');
            }
        }
    });

    //Добавляем все метрики периода сравнения
    const grid = Object.values(mainRows)
        .map((value) => value)
        .map((item) => {
            const mainId = item?.find((cell) => cell.key === 'innerId').value.split(':')[0];
            const mainTimeIndex = item?.find((cell) => cell.timeIndex)?.timeIndex;
            const row = [...item?.filter((cell) => cell.key !== 'innerId')];
            dataArray.forEach((r) => {
                const id = r?.find((cell) => cell.key === 'innerId').value.split(':')[0];
                const timeIndex = r?.find((cell) => cell.timeIndex)?.timeIndex;
                const isMain = r.some((cell) => cell.isMainPeriod);
                if (mainId === id && !isMain && mainTimeIndex === timeIndex) {
                    const compareDate = r?.find((cell) => cell.timeIndex !== undefined);
                    if (compareDate && !row.some((item) => item.value === compareDate.value)) {
                        row.push({ ...compareDate, key: t('Compare date') });
                    }
                    const metricCell = r?.find((cell) => cell.metric);
                    if (metricCell) {
                        row.push(metricCell);
                    }
                }
            });
            return row;
        })
        //Добавляем ячейки с дельтой и процентами, если надо
        .map((row) => {
            const newRow: ISheetCell[] = [];
            row.forEach((cell) => {
                if (cell.isMainMetric === false) {
                    const mainValue = row?.find((item) => item.metric === cell.metric && item.isMainMetric)?.value;
                    const compareValue = Number(cell.value);

                    const metricName = allMetrics?.find((m) => m.id === cell.metric)?.text || '';
                    newRow.push(cell);
                    if (selectedAddCols?.includes('addDelta')) {
                        const units = allMetrics?.find((m) => m.id === cell.metric)?.units || '';
                        const delta = Number(mainValue) - Number(compareValue);

                        newRow.push({
                            value: valueFormatter({ value: delta, units, numericOutput: true, showUnits: false }),
                            key: `Δ ${t(metricName, { ns: 'metrics' })}`,
                        });
                    }
                    if (selectedAddCols?.includes('addPercent')) {
                        const percent = valueFormatter({
                            value: getDifferenceBetweenNumbers(Number(mainValue), Number(compareValue))
                                .percentDifference,
                            units: '%',
                            numericOutput: true,
                            showUnits: false,
                        });
                        newRow.push({ value: `${percent}`, key: `% ${t(metricName, { ns: 'metrics' })}` });
                    }
                } else if (cell.weekDay) {
                    newRow.push(cell);
                    if (selectedAddCols?.includes('addWeekDay')) {
                        if (cell.isMainPeriod) {
                            newRow.push({ key: t('Week day'), value: t(cell.weekDay) });
                        } else {
                            newRow.push({ key: t('Compare week day'), value: t(cell.weekDay) });
                        }
                    }
                } else {
                    newRow.push(cell);
                }
            });
            return newRow;
        })
        .map((row) => {
            return row.map((cell) => {
                if (cell.metric) {
                    const units = allMetrics?.find((m) => m.id === cell.metric)?.units || '';
                    const precision = allMetrics?.find((m) => m.id === cell.metric)?.round_decimal_places || 2;
                    return {
                        ...cell,
                        value: valueFormatter({
                            value: cell.value,
                            units,
                            precision,
                            numericOutput: true,
                            showUnits: false,
                        }),
                    };
                } else {
                    return cell;
                }
            });
        })
        .filter((row) => {
            const dataCells = row.filter((cell) => cell.metric);
            let flag = true;

            if (!selectedAddCols?.includes('showEmpty')) {
                flag = dataCells.some((metric) => {
                    return metric.value !== '–';
                });
            }

            return flag;
        });

    try {
        //Добавляем первую строку таблицы
        const firstRow = grid[0].map((item) => {
            const metricName = allMetrics?.find((m) => m.id === item.metric)?.text || '';
            const units = allMetrics?.find((m) => m.id === item.metric)?.units || '';
            return { value: item.key || `${t(metricName, { ns: 'metrics' })}, ${t(units)}` || '', readOnly: true };
        });
        grid.unshift(firstRow);
        return grid;
    } catch (error) {
        return [];
    }
};
