import Button from '../../../../../../../components/Button/Button';
import useTranslation from '../../../../../../../hooks/useTranslation/useTranslation';
import useCreateBgReport from './hooks/useCreateBgReport';
import { AddCircle24Regular } from '@fluentui/react-icons';
import { Wrapper } from './styles';

const CreateBgReportButton = ({
    onClick,
    billsCount,
}: {
    billsCount: undefined | null | number;
    onClick: () => void;
}) => {
    const { isPending } = useCreateBgReport();

    const { t } = useTranslation();

    return (
        <Wrapper>
            <Button
                appearance="primary"
                onClick={onClick}
                text={
                    isPending || billsCount === undefined
                        ? t('Loading...')
                        : !billsCount
                        ? t('No bills')
                        : t('Order a new report')
                }
                disabled={isPending || !billsCount}
                icon={<AddCircle24Regular />}
            />
        </Wrapper>
    );
};

export default CreateBgReportButton;
