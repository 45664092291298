import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
    padding: var(--space-xxs) 0;
    /* max-height: 700px; */
    overflow: auto;
    table {
        width: 100%;
        /* border-collapse: collapse;
        border-spacing: 0; */
    }
    tr {
        /* border: 1px solid var(--default-border-color); */
    }
    td {
        /* padding: var(--space-xxxxs) var(--space-xxs);
        height: var(--space-lg); */
    }
    thead {
        /* font-weight: 700; */
    }
`;

export const CustomHeader = styled.th<{ types: string[] }>`
    vertical-align: middle !important;
    padding: 8px 5px !important;
    span {
        font-size: 14px;
        color: var(--color-contrast-higher);
        font-weight: 700;
        display: flex !important;
        /* overflow: auto; */
        align-items: center;
        justify-content: flex-start;
        text-align: left !important;
    }
`;
export const CustomCell = styled.td<{ types: string[] }>`
    padding: 8px 5px !important;
    background-color: white !important;
    vertical-align: middle !important;
    white-space: ${(p) => (p.types.includes('date') ? 'nowrap !important' : 'normal')};
    max-width: ${(p) => (p.types.includes('object') ? '200px !important' : 'none')};
    span {
        font-size: 12px;
        color: var(--color-contrast-higher);
        display: flex !important;
        /* overflow: auto; */
        overflow: hidden;
        align-items: center;
        justify-content: flex-start;
        text-align: left !important;
    }
`;

export const FilterValue = styled.span`
    font-size: 12px !important;
    color: ${(p) => p.theme.colors.secondaryDarker + ' !important'};
`;
