import React, { forwardRef, Children, ReactNode, useEffect, useCallback, useState } from 'react';

import { useGeneralSelector } from 'src/hooks';
import { DS } from 'src/constants';
import { arrayBeforeMatch } from 'src/tools';
import { ArrayElement } from 'src/types';

import {
    getChildrenByDisplayName,
    changeChildPropsByDisplayName,
    getSubBranchForDisplayNameAndExtendsProps,
    retrievePropertyFromChildren,
} from './utils';
import { WidgetWrapperStyled } from './styles';
import { WidgetWrapperContext } from './WidgetWrapperContext';

interface Props {
    children?: ReactNode;
    styles?: React.CSSProperties;
}

export type Ref = HTMLDivElement;

const WidgetWrapper = forwardRef<Ref, Props>((props, ref) => {

    const { isRtlLanguage, currentModuleID } = useGeneralSelector();

    const [isTitleCollapsed, setIsTitleCollapsed] = useState(true)
    const widgetName = retrievePropertyFromChildren('widgetName', props.children);

    const localStorageKey = `${currentModuleID}${DS}${widgetName}`;


    useEffect(() => {
        const collapsedWidgets = JSON.parse(localStorage.getItem('collapsedWidgets') || '{}');
        const isCurrentWidgetCollapsed = collapsedWidgets[localStorageKey] ?? false;
        setIsTitleCollapsed(isCurrentWidgetCollapsed);
    }, [localStorageKey]);

    const handleTitleToggle = useCallback(
        () => setIsTitleCollapsed((prewState) => {
            const collapsedWidgets = JSON.parse(localStorage.getItem('collapsedWidgets') || '{}');

            localStorage.setItem(
                'collapsedWidgets',
                JSON.stringify({ ...collapsedWidgets, [localStorageKey]: !prewState }),
            );
            return !prewState;
        }),
        [setIsTitleCollapsed, localStorageKey],
    );

    const childrenExpanded = getChildrenByDisplayName('WidgetTitle', props.children)
        ? changeChildPropsByDisplayName('WidgetTitle', props.children, { onTitleCollapseChange: handleTitleToggle })
        : props.children;

    const children = isTitleCollapsed && !!widgetName
        ? getNodesSeparatedByDisplayName('WidgetTitle', props.children)
        : childrenExpanded;

    return (
        <WidgetWrapperStyled ref={ref} isRtlLanguage={isRtlLanguage} style={{ ...props.styles }}>
            <WidgetWrapperContext.Provider value={{ isTitleCollapsed, onTitleCollapseChange: handleTitleToggle }}>
                { children }
            </WidgetWrapperContext.Provider>
        </WidgetWrapperStyled>
    );
});

WidgetWrapper.displayName = 'WidgetWrapper';
export default WidgetWrapper;

const getNodesSeparatedByDisplayName = (expectedDisplayName: string, children?: React.ReactNode, props?: object) => {
    const expectedChild = getSubBranchForDisplayNameAndExtendsProps(expectedDisplayName, children, props);

    if (!expectedChild) {
        return children;
    }

    const childrenItems = Children.toArray(children);
    type ChildType = ArrayElement<typeof childrenItems>;

    return [
        ...arrayBeforeMatch<ChildType>(childrenItems, (item) => getSubBranchForDisplayNameAndExtendsProps(expectedDisplayName, item) !== null),
        expectedChild,
    ];
};
