import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { ITabListOptionsItem } from 'src/components/TabList/interfaces';

import { TabListItemIds } from '../../../enums';

import { DynamicsPosReducerState } from './interfaces';

const availableChartTypes: ITabListOptionsItem[] = [
    { text: 'Periods', id: 'periods' },
    { text: 'Metrics', id: 'metrics' },
    { text: 'Objects', id: 'objects' },
];

const initialState: DynamicsPosReducerState = {
    selectedChartType: availableChartTypes[0],
    availableChartTypes,
    selectedTab: TabListItemIds.PaymentType,
};

const Sales_PosAnalysis_Dynamics_Pos_Widget_Reducer = createSlice({
    name: 'Sales_PosAnalysis_Dynamics_Pos_Widget_Reducer',
    initialState,
    reducers: {
        storeSelectedChartType: (state, action: PayloadAction<string>) => {
            const currentChartType = availableChartTypes?.find((element) => element.id === action.payload);
            state.selectedChartType = currentChartType || availableChartTypes[0];
        },

        storeSelectedTab: (state, action: PayloadAction<TabListItemIds>) => {
            state.selectedTab = action.payload;
        },
    },
});

export const { storeSelectedChartType } = Sales_PosAnalysis_Dynamics_Pos_Widget_Reducer.actions;

export const Sales_PosAnalysis_Dynamics_Pos_Widget_Reducer_Values = (state: RootState) =>
    state.Sales_PosAnalysis_Dynamics_Pos_Widget_Reducer;

export default Sales_PosAnalysis_Dynamics_Pos_Widget_Reducer.reducer;


// export const Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer = createSlice({
//     name: 'Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer',
//     initialState,

//     reducers: {
//         /**
//          * Сохранение выбранного таба
//          */
//         storeSelectedTab: (state, action: PayloadAction<TabListItemIds>) => {
//             state.selectedTab = action.payload;
//         },
//     },
// });

// export const { storeSelectedTab } = Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer.actions;

// export const Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer_Values = (state: RootState) =>
//     state.Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer;

// export default Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer.reducer;
