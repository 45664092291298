import React, { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

import { isMobileInboundWidth } from 'src/theme';

import { generalReducerValues } from '../../../../../General.reducer';
import { withLoading } from '../../../../../tools/API/withLoading';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { shortStringDate } from '../../../../../tools/Strings/shortStringDate';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../reducer';
import WidgetPeriodWrapper from '../../../../../components/Wrappers/WidgetPeriodWrapper/WidgetPeriodWrapper';
import { TOrdering } from '../../../../../components/OrderSwitcher/interfaces';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import TabList from '../../../../../components/TabList/TabList';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';

import Footer from './components/Footer/Footer';
import HeaderCell from './components/HeaderCell';
import Cell from './components/Cell';
import { Leasing_TenantOverview_Reach_Widget_Reducer_Values, reloadWidget, storeSelectedCategory } from './reducer';
import { Tr, TableWrapper } from './styles';

const Reach: React.FC = () => {
    const ref = useRef(null);

    const { preparedData, selectedCategory, widgetName } = useSelector(
        Leasing_TenantOverview_Reach_Widget_Reducer_Values,
    );
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const {
        src: { projectCategories },
        lang,
        mainAreaSize: { width },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const dispatch = useDispatch();

    const [ordering, setOrdering] = useState<{ ordering: TOrdering; primaryType: string }>({
        ordering: 'none',
        primaryType: 'percent',
    });

    const [itemsToShow, setItemsToShow] = useState<number | 'all'>(10);

    useEffect(() => {
        const parentCategoryIds = projectCategories
            ?.filter((item) => !item.parent_id)
            ?.map((element) => String(element.id));
        if (
            parentCategoryIds?.length &&
            (!selectedCategory || (selectedCategory && !parentCategoryIds?.includes(selectedCategory)))
        ) {
            dispatch(storeSelectedCategory(String(parentCategories[0].id)));
        }
    }, [projectCategories]);

    const isMobile = isMobileInboundWidth(width);

    const mainPeriod = localCurrentOptions?.mainPeriod?.id;
    const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];

    const handleOrderToggle = (args: { ordering: TOrdering; id: string }) => {
        setOrdering({ ordering: args.ordering, primaryType: args.id });
    };

    const table = useMemo(() => {
        const WithLoadingTable = withLoading(TableWrapper as ComponentType, { data: preparedData, height: 400 });
        if (Array.isArray(preparedData)) {
            const header = (
                <thead>
                    <tr>
                        <HeaderCell
                            type="tenant"
                            colSpan={2}
                            handleOrderToggle={handleOrderToggle}
                            ordering={ordering.ordering}
                            primaryType={ordering.primaryType}
                            widgetName={widgetName}
                        >
                            {t('Tenant (Traffic)')}
                        </HeaderCell>
                        <HeaderCell
                            type="percent"
                            colSpan={1}
                            handleOrderToggle={handleOrderToggle}
                            ordering={ordering.ordering}
                            primaryType={ordering.primaryType}
                            widgetName={widgetName}
                        >
                            {t('Zone → Tenant')}
                        </HeaderCell>
                        <HeaderCell
                            type="zone"
                            colSpan={2}
                            handleOrderToggle={handleOrderToggle}
                            ordering={ordering.ordering}
                            primaryType={ordering.primaryType}
                            widgetName={widgetName}
                        >
                            {t('Zone (Traffic)')}
                        </HeaderCell>
                    </tr>
                </thead>
            );

            const sortedData = (
                preparedData: any[],
                ordering: {
                    ordering: TOrdering;
                    primaryType: string;
                },
            ) => {
                if (ordering.ordering === 'none') {
                    return cloneDeep(preparedData)?.sort((rowA: any[], rowB: any[]) => {
                        const isSelectedTenant = rowA[0].isSelectedTenant;
                        return isSelectedTenant ? -1 : 1;
                    });
                } else {
                    const multiplier = ordering.ordering === 'up' ? 1 : -1;
                    return cloneDeep(preparedData)?.sort((rowA: any[], rowB: any[]) => {
                        const isASelected = rowA?.[0]?.isSelectedTenant;
                        const isBSelected = rowB?.[0]?.isSelectedTenant;
                        if (isASelected) return -1;
                        if (isBSelected) return 1;

                        const valueA = rowA?.find((cell: any) => cell.type === ordering.primaryType).value;
                        const valueB = rowB?.find((cell: any) => cell.type === ordering.primaryType).value;
                        return multiplier * (valueB - valueA);
                    });
                }
            };

            const rows = sortedData(preparedData, ordering)
                ?.slice(0, itemsToShow === 'all' ? preparedData.length : itemsToShow)
                .map((row, i) => {
                    const cells = row.map((cell: any, i: number) => {
                        return <Cell key={`${cell?.value}mm${i}`} {...cell} />;
                    });
                    const isSelectedTenant = row[0].isSelectedTenant;
                    return (
                        <Tr key={`rrrow ${i}`} isSelectedTenant={isSelectedTenant}>
                            {cells}
                        </Tr>
                    );
                });

            return (
                <WithLoadingTable>
                    <table>
                        {header}
                        <tbody>{rows}</tbody>
                    </table>
                </WithLoadingTable>
            );
        } else {
            return <WithLoadingTable>null</WithLoadingTable>;
        }
    }, [ordering, preparedData, itemsToShow, t]);

    const tabListSelectHandler = (id: string) => {
        dispatch(storeSelectedCategory(id));
    };

    const parentCategories = projectCategories
        ?.filter((item) => !item.parent_id)
        .map((item) => ({
            ...item,
            id: item.id.toString(),
            text: item.object_name,
        }));

    const tabList = useMemo(() => {
        return (
            <TabList
                selectHandler={tabListSelectHandler}
                selectedValue={selectedCategory}
                widgetName="ReachToTheTenant"
                options={parentCategories}
            />
        );
    }, [parentCategories, selectedCategory, t]);
    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t(widgetName)}
                    <WidgetAdditionalControls
                        widgetName={widgetName}
                        pdfDownloadData={{ targetRef: ref }}
                        reloadHandler={reloadWidget}
                    />
                </WidgetTitle>
                {!isMobile && tabList}
            </WidgetTitleWrapper>
            {isMobile && tabList}
            <WidgetPeriodWrapper>{shortStringDate(mainDateRange?.period, lang)}</WidgetPeriodWrapper>
            {table}
            {Array.isArray(preparedData) && (
                <Footer testId={widgetName} itemsToShow={itemsToShow} setItemsToShow={setItemsToShow} />
            )}
        </WidgetWrapper>
    );
};

export default Reach;
