import styled from 'styled-components/macro';

export const Period = styled.div`
    font-size: 1rem;
    font-weight: 500;
`;

export const CarouselWrapper = styled.div`
    margin-top: 15px;
    position: relative;
`;

export const SlideArrowWrapper = styled.div<{ side: 'left' | 'right'; hide: boolean }>`
    position: absolute;
    cursor: pointer;
    top: 50%;
    height: 100%;

    background: ${(p) =>
        p.side === 'left'
            ? 'linear-gradient(-270deg, #fff, #fff 38.67%, hsla(0, 0%, 100%, 0)) !important'
            : 'linear-gradient(270deg, #fff, #fff 38.67%, hsla(0, 0%, 100%, 0)) !important'};
    transform: translateY(-50%);
    display: ${(p) => (p.hide ? 'none' : 'flex')};
    left: ${(p) => (p.side === 'left' ? '0' : 'auto')};
    right: ${(p) => (p.side === 'right' ? '0' : 'auto')};
    width: 100px;
    align-items: center;
    justify-content: ${(p) => (p.side === 'left' ? 'flex-start' : 'flex-end')};
    z-index: 5;
`;

export const NoEventsText = styled.div`
    color: rgba(0, 0, 0, 0.3);
    font-size: 16px;
`;
