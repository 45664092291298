export const columns = [
    { value: 'Event name', eventKey: 'name', forceComponent: true, key: 'name' },
    { value: 'Date from', eventKey: 'date_from', forceComponent: true, key: 'date_from' },
    { value: 'Time from', eventKey: 'date_from', forceComponent: true, key: 'time_from' },
    { value: 'Date to', eventKey: 'date_to', forceComponent: true, key: 'date_to' },
    { value: 'Time to', eventKey: 'date_to', forceComponent: true, key: 'time_to' },
    { value: 'Event type', eventKey: 'event_type', forceComponent: true, key: 'event_type' },
    { value: 'Reporting objects', eventKey: 'data_objects_ids', forceComponent: true, key: 'data_objects_ids' },
    { value: 'Comment', eventKey: 'comment', forceComponent: true, key: 'comment' },
    { value: 'Controls', eventKey: '', forceComponent: true, key: 'controls' },
] as const;

export const columnsKeys = columns.map((element) => element.key);

export type TColumnsKeys = typeof columnsKeys[number];
