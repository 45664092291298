import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';

import { emptyArray, getWeekInfo } from 'src/tools';

import { TSeries } from '../../interfaces';

interface IArgs {
    series: TSeries[];
}

const WEEK_START_DAY_NUMBER = 1;

export const generatePlotBandsForWeekends = (args: IArgs, locale: string) => {
    // const localeInfo = new Intl.Locale(`${locale}`);
    // const { weekend = emptyArray } = localeInfo['weekInfo'];
    // const [firstWeekendDay, lastWeekendDay] = weekend;
    const weekInfo = getWeekInfo(locale);
    const [firstWeekendDay, lastWeekendDay] = weekInfo?.weekend ?? emptyArray;

    const plotBands: Highcharts.AxisPlotBandsOptions[] = [];
    const plotBand: Highcharts.AxisPlotBandsOptions = {
        color: 'rgba(255, 167, 167, 0.3)',
    };
    let temporaryPlotBand = cloneDeep(plotBand);
    const { series } = args;

    const allXValues: number[] = series.reduce((acc: number[], value) => {
        const values = value.data.map((element) => element.x);
        return acc.concat(values);
    }, []);

    const filteredXValues = allXValues?.filter((element, index) => allXValues.indexOf(element) === index).sort();

    const chartWeekendStart = firstWeekendDay - 1;
    const chartWeekendFinish = lastWeekendDay || firstWeekendDay;

    filteredXValues.forEach((x) => {
        const pointWeekday = DateTime.fromMillis(x).weekday;
        if (pointWeekday) {
            if (pointWeekday === chartWeekendStart) {
                temporaryPlotBand.from = x;
            }
            if (pointWeekday === chartWeekendFinish) {
                temporaryPlotBand.to = x;
            }
            if (pointWeekday === WEEK_START_DAY_NUMBER) {
                temporaryPlotBand = cloneDeep(plotBand);
            }
        }
        if (temporaryPlotBand.to && !temporaryPlotBand.from) {
            temporaryPlotBand.from = filteredXValues[0];
        }
        if (temporaryPlotBand.from && !temporaryPlotBand.to) {
            temporaryPlotBand.to = filteredXValues[filteredXValues.length - 1];
        }
        plotBands.push(temporaryPlotBand);
    });

    return plotBands?.filter((v, i, a) => a.findIndex((t) => t.to === v.to && t.from === v.from) === i);
};