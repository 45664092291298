import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import GlobalSpinner from './components/GlobalSpinner/GlobalSpinner';
import Overview from './Chapters/Performance/Dashboard/Dashboard';
import YearOverYear from './Chapters/Performance/YearOverYear/YearOverYear';
import VisitorsInside from './Chapters/Performance/VisitorsInside/VisitorsInside';
import CookiesPage from './components/CookiesPage/CookiesPage';
import Maps from './Chapters/Maps/MapsOverview/MapsOverview';
import PaymentAnalysis from './Chapters/Sales/SalesAnalysis/SalesAnalysis';
import Ratings from './Chapters/Performance/Ratings/Ratings';
import CrossVisits from './Chapters/Maps/CrossVisits/CrossVisits';
import Categories from './Chapters/Configuration/Categories/Categories';
import EventsConfig from './Chapters/Configuration/EventsConfig/EventsConfig';
import PathToPurchase from './Chapters/Performance/PathToPurchase/PathToPurchase';
import Benchmarks from './Chapters/Cases/Benchmarks/Benchmarks';
import Calculator from './Chapters/Cases/Calculator/Calculator';
import TenantOverview from './Chapters/Leasing/TenantOverview/TenantOverview';
import Tenants from './Chapters/Leasing/Tenants/Tenants';
import LeasingCrossVisits from './Chapters/Leasing/CrossVisits/CrossVisits';
import Metrics from './Chapters/Help/Metrics/Metrics';
import EventsMap from './Chapters/Events/EventsMap/EventsMap';
import Events from './Chapters/Events/Events/Events';
import TenantsOverview from './Chapters/Network/TenantsOverview/TenantsOverview';
import SalesMetricsUpload from './Chapters/Configuration/SalesMetricsUpload/SalesMetricsUpload';
import { PosAnalysis } from './Chapters/Sales/PosAnalysis';
import ObjectPanels from './Chapters/Dashboards/ObjectPalnels/ObjectPanels';
import ReportsFromPartners from './Chapters/Reports/FromPartners/FromPartners';
import Analytical from './Chapters/Reports/Analytical/Analytical';
import Advanced from './Chapters/Reports/Advanced/Advanced';
import LocationsOverview from './Chapters/Network/LocationsOverview/LocationsOverview';
import RentPayments from './Chapters/Configuration/RentPayments/RentPayments';
import { Utilities } from './Chapters/Configuration/Utilities/Utilities';
import FpcMonitoring from './Chapters/Configuration/FpcMonitoring/FpcMonitoring';

const Faq = lazy(() => import('./Chapters/Help/Faq/Faq'));

export const AppRouter: React.FC = () => (
    <Routes>
        <Route path="/" element={<Navigate to="/dashboard-designer/object-panels" />} />
        <Route path="cookies" element={<CookiesPage />} />

        <Route path="events">
            <Route path="events-map" element={<EventsMap />} />
            <Route path="events" element={<Events />} />
            {/* <Route path="events-efficiency" element={<EventsEfficiency />} /> */}
        </Route>
        {/* <Route path="management-decisions">
          <Route path="decisions-map" element={<DecisionsMap />} />
          <Route
              path="decisions-configuration"
              element={<DecisionsConfig />}
          />
          <Route
              path="decisions-efficiency"
              element={<DecisionsEfficiency />}
          />
      </Route> */}
        <Route path="network">
            <Route path="locations-overview" element={<LocationsOverview />} />
            <Route path="tenants-overview" element={<TenantsOverview />} />
        </Route>
        <Route path="performance">
            <Route path="overview" element={<Overview />} />
            <Route path="year-over-year" element={<YearOverYear />} />
            <Route path="visitors-inside" element={<VisitorsInside />} />
            <Route path="ratings" element={<Ratings />} />
            <Route path="path-to-purchase" element={<PathToPurchase />} />
        </Route>
        <Route path="dashboard-designer">
            <Route path="object-panels" element={<ObjectPanels />} />
        </Route>
        <Route path="maps">
            <Route path="maps-overview" element={<Maps />} />
            <Route path="cross-visits" element={<CrossVisits />} />
        </Route>
        <Route path="sales">
            <Route path="sales-analysis" element={<PaymentAnalysis />} />
            <Route path="pos-monitoring" element={<PosAnalysis />} />
        </Route>
        <Route path="cases">
            <Route path="benchmarks" element={<Benchmarks />} />
            <Route path="calculator" element={<Calculator />} />
        </Route>
        <Route path="leasing">
            <Route path="tenant-overview" element={<TenantOverview />} />
            <Route path="tenants" element={<Tenants />} />
            <Route path="cross-visits" element={<LeasingCrossVisits />} />
        </Route>
        <Route path="reports">
            <Route path="analytical" element={<Analytical />} />
            <Route path="advanced" element={<Advanced />} />
            <Route path="partners" element={<ReportsFromPartners />} />
        </Route>
        <Route path="configuration">
            <Route path="categories" element={<Categories />} />
            <Route path="events" element={<EventsConfig />} />
            {/* <Route path="metrics" element={<MetricsConfig />} /> */}
            <Route path="sales-import" element={<SalesMetricsUpload />} />
            <Route path="rent-import" element={<RentPayments />} />
            <Route path="utilities" element={<Utilities />} />
            <Route path="fpc-monitoring" element={<FpcMonitoring />} />
        </Route>
        <Route path="helps">
            <Route
                path="faq"
                element={
                    <Suspense fallback={<GlobalSpinner />}>
                        <Faq />
                    </Suspense>
                }
            />
            <Route path="metrics" element={<Metrics />} />
        </Route>
    </Routes>
);
