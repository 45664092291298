import { IExtendedPlan } from './interfaces';

const calculatePlansYPosotion = (allPlans: IExtendedPlan[]): { [planIndex: string]: number } => {
    const result = {};
    let y = 0;
    allPlans.forEach((plan, i, arr) => {
        if (i !== 0) {
            y =
                y +
                arr[i - 1].height * (arr[i - 1].widestPlan.mainPlan.scale / arr[i - 1].scale) +
                arr[i - 1].imageOffset[1] * (arr[i - 1].mainPlan.scale / arr[i - 1].scale);
        } else {
            y = y + plan.imageOffset[1] * (plan.mainPlan.scale / plan.scale);
        }
        result[i] = y;
    });

    return result;
};

export default calculatePlansYPosotion;
