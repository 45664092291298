/**
 * Нахождение позиции для маркера.
 * @param coords Координаты узлов прохода
 * @returns Объект с координатами для размещения маркера.
 */
export const findLabelCoords = (coords: Array<number[]>): { x: number; y: number } => {
    if (coords.length % 2 === 0) {
        const first = coords[coords.length / 2 - 1];
        const second = coords[coords.length / 2];
        return { x: (first[0] + second[0]) / 2, y: (first[1] + second[1]) / 2 };
    }
    const center = coords[Math.floor(coords.length / 2)];
    return { x: center[0], y: center[1] };
};
