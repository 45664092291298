import { useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
// import omit from 'lodash/omit';

import { snakeToCamelCase, transformFields, emptyArray } from 'src/tools';
import { useGeneralSelector } from 'src/hooks';

import { getAny } from '../../getAny';

import { MapGeoPlan, MapPoint, MapLineString } from './interfaces';

export type MapPlan = {
    id: number,
    activeFrom: string,
    createdAt: string,
    floor: number,
    geo2Plan: MapGeoPlan,
    geoBottomLeft: MapPoint,
    geoBottomRight: MapPoint,
    geoTopLeft: MapPoint,
    geoTopRight: MapPoint,
    height: number,
    image: string,
    isAnchor: boolean,
    isMain: boolean,
    length: number,
    line: MapLineString,
    locationId: number,
    notes: string,
    plan2Geo: MapGeoPlan,
    scale: number,
    width: number,
}

// const IGNORE_PLAN_FIELDS = [
//     'active_rom',
//     'created_at',
//     'geo_bottom_left',
//     'geo_bottom_right',
//     'geo_top_left',
//     'geo_top_right',
//     'is_main',
//     'is_anchor',
//     'location_id',
// ];

const transformFieldsOfResponse = (response: Array<object>): Array<MapPlan> => {
    if (!response.length) {
        return emptyArray;
    }

    return response.map((versionInfo) => transformFields<MapPlan>(versionInfo, snakeToCamelCase));
};

export const useFetchPlans = (mapLocationId?: string) => {
    const { token, selectedLocationId, urlsByServices } = useGeneralSelector();

    const requestUrl = useMemo(() => {
        if (!mapLocationId || !token) {
            return null;
        }

        if (urlsByServices?.['core/map-service-back']) {
            return `${urlsByServices['core/map-service-back'].PLANS_URL}?location_id=${mapLocationId}`;
        }
        return null;
    }, [mapLocationId, token, urlsByServices]);

    const isQueryEnabled = !!requestUrl && !!selectedLocationId && !!token;
    const result = useQuery({
        queryKey: [requestUrl, selectedLocationId],
        queryFn: async () => {
            const response = await getAny(requestUrl!, token);
            if (!response || !response.data) {
                console.error(`Data is undefined or null for plans query from URL: ${requestUrl}`);
                return emptyArray; // возвращаем пустой массив, если данных нет
            }

            return transformFieldsOfResponse(response.data);
        },
        staleTime: Infinity,
        enabled: isQueryEnabled,
    });

    return result;
};
