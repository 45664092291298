import { LATEST_DATE } from 'src/constants';
import { emptyArray } from 'src/tools';

import { MapVersion } from '../hooks/MapService/useFetchVersions';

export function findActualVersion(versions?: MapVersion[], dateFrom?: string, dateTo?: string) {
    if (!dateFrom || !dateTo) {
        return undefined;
    }

    const start = new Date(dateFrom);
    const end = new Date(dateTo);

    return (versions || emptyArray).find((version) => {
        const dateFrom = new Date(version.dateFrom);
        const dateTo = new Date(version.dateTo || LATEST_DATE);
        return start >= dateFrom && start <=dateTo && end <= dateTo;
    });
}