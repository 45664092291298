import styled from 'styled-components/macro';

export const Wrapper = styled.div<{ height: number }>`
    width: 30%;
    height: ${(p) => {
        return p.height + 'px';
    }};
    /* border: 1px solid var(--color-secondary-dark); */
    /* flex-grow: 1; */
    padding: var(--space-xxxs);
    overflow: auto;
`;

export const Ul = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;
export const Li = styled.li`
    cursor: pointer;
    width: 100%;
    position: relative;
    overflow: hidden;
`;

export const Color = styled.div.attrs<{ width: string; bgColor: string }>((props) => ({
    style: {
        background: props.bgColor,
        width: props.width,
    },
}))<{ width: string; bgColor: string }>`
    border: 1px solid var(--color-white);
    padding: var(--space-xxxxs);
    overflow: visible;
    display: flex;
    height: 26px;
`;
export const Text = styled.div`
    font-size: x-small;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: var(--space-xxxs);
    flex-shrink: 1;
`;
export const Value = styled.div`
    font-size: x-small;
    white-space: nowrap;
    flex-grow: 1;
    margin-left: auto;
    text-align: right;
    box-sizing: border-box;
`;
export const TextValueWrapper = styled.div`
    position: absolute;
    width: 93%;
    top: 2px;
    left: 10px;
    font-size: x-small;
    display: flex;
    justify-content: space-between;
`;
