import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';

import {
    ButtonWrapper,
    CheckboxWrapper,
    ListWrapper,
    ReportingObjectsListWrapper,
    SearchWrapper,
    Wrapper,
} from './styles';
import Button from '../../../Button/Button';
import Checkbox from '../../../Checkbox/Checkbox';
import Collapse from '../../../Collapse/Collapse';
import Search from '../../../Search/Search';
import useTranslation from '../../../../hooks/useTranslation/useTranslation';
import { getReportingObjects } from '../../../../tools/getReportingObjects';

interface IProps {
    /** Обработчик выбора отчетного объекта */
    onReportingObjectsSelect: (ids: number[]) => void;
    /** Все выбраные id отчетных объектов */
    selectedReportingObjectsIdsTrans: number[];
}

/**
 * Тело контекстного меню со всеми типами отчетных объектов
 */
const ReportingObjectsMenuBody: FC<IProps> = ({ onReportingObjectsSelect, selectedReportingObjectsIdsTrans }) => {
    const INVALID_REPORTING_OBJECTS_TYPES: string[] = [];
    const [selectedReportingObjectsIds, setSelectedReportingObjectsIds] = useState<number[]>(
        selectedReportingObjectsIdsTrans,
    );
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const [filter, setFilter] = useState('');
    const { t } = useTranslation(['translation']);

    const filterOnChange = ({ value }: { value: string }) => {
        setFilter(value);
    };

    const onCheck = (id: number) => (args: { checked: boolean }) => {
        if (args.checked) {
            setSelectedReportingObjectsIds((prevState) => [...prevState, id]);
        } else {
            setSelectedReportingObjectsIds((prevState) => prevState?.filter((element) => element !== id));
        }
    };

    const onSaveButtonClick = () => {
        onReportingObjectsSelect(selectedReportingObjectsIds);
    };

    const list = useMemo(() => {
        if (Object.keys(reportingObjectsByType).length) {
            return getReportingObjects(reportingObjectsByType, t).map((optionsOfReportingObjects) => {
                let collapseSelectedNumberLabel = 0;
                const reportingObjectsList = optionsOfReportingObjects.objects
                    ?.filter((reportingObject) => reportingObject.name.toLowerCase().includes(filter.toLowerCase()))
                    .map((reportingObject) => {
                        const selected = selectedReportingObjectsIds.includes(reportingObject.id);
                        if (selected) collapseSelectedNumberLabel++;
                        return (
                            <CheckboxWrapper key={reportingObject.id}>
                                <Checkbox
                                    handleToggle={onCheck(reportingObject.id)}
                                    label={reportingObject.text}
                                    selected={selected}
                                />
                            </CheckboxWrapper>
                        );
                    });
                return (
                    <Collapse
                        label={`${optionsOfReportingObjects.section} (${collapseSelectedNumberLabel}/${reportingObjectsList.length})`}
                        key={optionsOfReportingObjects.section}
                        addClasses={'SelectCollapse'}
                        iconJustify="space-between"
                        hover="background-none"
                        icon="chevron-right"
                        iconPosition="right"
                    >
                        <ReportingObjectsListWrapper>{reportingObjectsList}</ReportingObjectsListWrapper>
                    </Collapse>
                );
            });
        }
        return null;
    }, [filter, reportingObjectsByType, selectedReportingObjectsIds, t]);

    return (
        <Wrapper>
            <SearchWrapper>
                <Search fullWidth={true} placeholder={t('Filter')} handleChange={filterOnChange} />
            </SearchWrapper>
            <ListWrapper>{list}</ListWrapper>
            <ButtonWrapper>
                <Button appearance="primary" size="small" onClick={onSaveButtonClick}>
                    {t('Save')}
                </Button>
            </ButtonWrapper>
        </Wrapper>
    );
};

export default ReportingObjectsMenuBody;
