import React, { useId, Fragment, useRef, useMemo } from 'react';
import {
    PositioningImperativeRef,
    PositioningVirtualElement,
} from '@fluentui/react-components';

import {
    ContextualMenu,
    MenuConfig,
    getContextualMenuBody,
    getContextMenuRect,
} from 'src/components/ContextualMenu';
import { Identity } from 'src/types';

import { TableContext } from '../interfaces';
// import { getMenuLabel } from '../utils';

import { getMenuConfig } from './menuConfig';
// import { StyledMenuGroupHeader, StyledMenuItem } from './styles';

type ContextMenuProps<
    I extends string = string, // id type
    R extends Identity<I> = Identity<I>,
> = {
    contextRef: React.RefObject<TableContext<I, R>>;
    menuConfig?: MenuConfig<I, R>;

    contextMenuEvent: MouseEvent | null;
};

export const ContextMenu = <I extends string = string, R extends Identity<I> = Identity<I>>({
    contextMenuEvent,
    contextRef,
}: // menuConfig,
ContextMenuProps<I, R>) => {
    if (!contextRef?.current) {
        throw new Error('Table context is not available');
    }

    const contextMenuTargetRef = useRef<PositioningImperativeRef>(null);

    const isOpened = useMemo(() => {
        const virtualElement: PositioningVirtualElement = {
            getBoundingClientRect: getContextMenuRect(contextMenuEvent?.clientX, contextMenuEvent?.clientY),
        };
        contextMenuTargetRef.current?.setTarget(virtualElement);

        return contextMenuEvent !== null;
    }, [contextMenuEvent]);

    const t = contextRef?.current?.t;
    const defaultMenuConfig =useMemo(
        () => getMenuConfig(t),
        [t],
    );

    // TODO: join with menuConfig
    const menuBody = isOpened ? getContextualMenuBody(defaultMenuConfig, contextRef, contextMenuEvent!) : null;

    return (
        <ContextualMenu
            key={`table-menu-${contextRef.current.componentId}`}
            isContextualMenuOpen={isOpened}
            closeContextualMenu={contextRef.current.closeMenu}
            contextualMenuBody={menuBody!}
            positioningRef={contextMenuTargetRef}
        />
    );
};
