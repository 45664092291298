export const getParameterByName = (name: string, url: string) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        let results = regex.exec(url);
    if (!results) {
        return undefined;
    }
    if (!results[2]) {
        return ''; // TODO: WHAT IS IT ???????????????
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
