import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MainPeriodSelect from '../../../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import ObjectsSelect from '../../../../../components/Selects/ObjectsSelect/ObjectsSelect';
import UniversalSelect from '../../../../../components/Selects/UniversalSelect/UniversalSelect';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import { PresetsWrapper } from '../../../../../components/Wrappers/PresetsWrapper/styles';
import { SelectsWrapper } from '../../../../../components/Wrappers/SelectsWrapper/styles';
import { DS } from '../../../../../constants/constants';

import { generalReducerValues } from '../../../../../General.reducer';
import { Reports_Advanced_DetailedBills_Widget_Reducer_Values } from './reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { PREVIEW_TABLE_ADDITIONAL_SETTINGS_OPTIONS } from './constants/constants';
import CreateBgReportButton from './components/CreateBgReportButton/CreateBgReportButton';
import Button from '../../../../../components/Button/Button';
import { useEffect, useState } from 'react';
import Modals from '../../../../../components/Modals/Modals';
import ArchiveModal from './components/ArchiveModal/ArchiveModal';
import { BoxSearch24Regular } from '@fluentui/react-icons';
import useFetchBills from './hooks/useFetchBills';
import OrderModal from './components/OrderModal/OrderModal';

const DetailedBillsOptions = () => {
    const {
        currentModuleID,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { keyWord } = useSelector(Reports_Advanced_DetailedBills_Widget_Reducer_Values);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [archiveModalStatus, setArchiveModalStatus] = useState({ show: false });
    const [orderModalStatus, setOrderModalStatus] = useState({ show: false });
    const [billsCount, setBillsCount] = useState<undefined | null | number>(undefined);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const { data, isFetching, error, refetch } = useFetchBills();

    useEffect(() => {
        setBillsCount(data);
    }, [data]);

    const validObjOptions = [t('Tenants')];

    const onShowArchiveClick = () => {
        setArchiveModalStatus({ show: true });
    };
    const onOrderClick = () => {
        setOrderModalStatus({ show: true });
    };

    const closeArchiveModal = () => {
        setArchiveModalStatus({ show: false });
    };
    const closeOrderModal = () => {
        setOrderModalStatus({ show: false });
    };

    return reportingObjectsByType?.['tenant']?.length ? (
        <>
            <Modals title={t('Reports archive')} closeModal={closeArchiveModal} modalStatus={archiveModalStatus}>
                <ArchiveModal modalStatus={archiveModalStatus} onCloseModal={closeArchiveModal} />
            </Modals>
            <Modals title={t('Order a new report')} closeModal={closeOrderModal} modalStatus={orderModalStatus}>
                <OrderModal modalStatus={orderModalStatus} onCloseModal={closeOrderModal} />
            </Modals>
            <SelectsWrapper>
                <MainPeriodSelect
                    name={`mainPeriod${DS}${keyWord}`}
                    label={`Report period`}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <ObjectsSelect
                    maxSelected={1}
                    mode="multi"
                    name={`reportObject${DS}${keyWord}`}
                    label={`Tenant`}
                    validOptions={validObjOptions}
                    dataObjectFilters={['data_objects_tenant']}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <UniversalSelect
                    options={PREVIEW_TABLE_ADDITIONAL_SETTINGS_OPTIONS}
                    dropdownWidth="auto"
                    name={`additionalFields${DS}${keyWord}`}
                    defaultSelected={PREVIEW_TABLE_ADDITIONAL_SETTINGS_OPTIONS}
                    labelText={t('Additional settings')}
                    localCurrentOptions={localCurrentOptions}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                />
                <CreateBgReportButton billsCount={billsCount} onClick={onOrderClick} />
            </SelectsWrapper>
            <PresetsWrapper>
                <Button icon={<BoxSearch24Regular />} text={t('Show archive')} onClick={onShowArchiveClick} />
            </PresetsWrapper>
        </>
    ) : null;
};

export default DetailedBillsOptions;
