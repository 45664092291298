import { KonvaEventObject } from 'konva/lib/Node';
import React from 'react';
import { Shape } from 'react-konva';


import { Props } from './interfaces';

const Place: React.FC<Props> = ({
    object,
    selectObject,
    selectedMarker,
    onMouseOverLeave,
    heatColor = 'gray',
    reportingObjectsByTypeAndMarker,
    layerType,
    stageIndex,
    changeStageCursor,
}) => {
    const onObjectClick = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        selectObject(object.marker);
    };

    const onMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave?.(object.marker);

        if (changeStageCursor && layerType === 'tenants_layer' && !object.marker) {
            changeStageCursor(stageIndex, 'not-allowed')();
        } else if (changeStageCursor && layerType === 'tenants_layer' && object.marker) {
            changeStageCursor(stageIndex, 'pointer')();
        }
    };

    const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave?.(null);
        changeStageCursor && changeStageCursor(stageIndex, 'pointer')();
    };

    const fill = selectedMarker !== object?.marker ? heatColor : 'rgba(0,0,255,0.5)';

    console.log('ZZZZZ', object)
    const coordPair = object?.coords?.coordinates?.[0] ;

    if (!coordPair) {
        return null;
    }
    const [startX, startY] = coordPair;

    return (
        <Shape
            id={'Shape!@##11'}
            key={'Shape!@##11'}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onClick={onObjectClick}
            onTap={onObjectClick}
            // data-target-id={`polygon_${object?.marker}_${metric}`}
            sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(startX, startY);
                object.coords.coordinates.forEach(([x, y]) => context.lineTo(x, y));
                context.fillStrokeShape(shape);
            }}
            opacity={1}
            // stroke={'blue'}
            fill={fill}
            strokeWidth={2}
        />
    );
};

export default Place;
