const { DateTime } = require('luxon');

export function isLessThanDays(date: string, pastRangeInDays: number) {
    if (date?.length < 9) {
        return false;
    }

    const lastBillDate = DateTime.fromISO(date);
    const currentDate = DateTime.local();
    const diffInDays = currentDate.diff(lastBillDate, 'days').days;

    return diffInDays <= pastRangeInDays;
}