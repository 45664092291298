import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { useGeneralSelector } from 'src/hooks';

import { userSettingsReducerValues } from '../components/UserSettings/reducer';
import { TCurrentOptions } from '../components/UserSettings/interfaces';

/**
 * Кастомный хук для получения настроек для текущего модуля
 */
export const useWidgetCurrentOptions = (externalModuleId?: string) => {
    const { currentModuleID } = useGeneralSelector();
    const moduleId = externalModuleId || currentModuleID;

    const [localCurrentOptions, setLocalCurrentOptions] = useState<TCurrentOptions>();

    const { currentOptions } = useSelector(userSettingsReducerValues);

    useEffect(() => {
        if (currentOptions.currentModuleID !== moduleId || isEqual(localCurrentOptions, currentOptions)) return;
        const newLocal = {};
        Object.keys(currentOptions).forEach((key) => {
            if (!isEqual(localCurrentOptions?.[key], currentOptions[key])) {
                newLocal[key] = currentOptions[key];
            }
        });
        setLocalCurrentOptions({ ...currentOptions, ...newLocal });
        // if (!localCurrentOptions) {
        //     setLocalCurrentOptions({ ...currentOptions, ...newLocal });
        // } else {
        //     setLocalCurrentOptions({ ...localCurrentOptions, ...newLocal });
        // }
    }, [JSON.stringify(currentOptions)]);

    return localCurrentOptions;
};
