import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isMobileInboundWidth } from 'src/theme';
import { useModuleChartsDynamicsSettings, useGeneralSelector } from 'src/hooks';
import WidgetAdditionalControls from 'src/components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetTitleWrapper from 'src/components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import DynamicsPMOWidget from 'src/components/CommonWidgets/DynamicsPMO/DynamicsPMO';
import WidgetWrapper from 'src/components/Wrappers/WidgetWrapper/WidgetWrapper';
import WidgetTitle from 'src/components/Wrappers/WidgetTitle/WidgetTitle';
import usePeriods from 'src/hooks/common/usePeriods';
import { DYNAMICS_PMO_QUERY_KEY } from 'src/components/CommonWidgets/DynamicsPMO/constants/constants';
import { queryClient } from 'src/index';
import { CHART_DESKTOP_HEIGHT, CHART_MOBILE_HEIGHT, DS } from 'src/constants/constants';
import {
    Sales_PosAnalysis_Module_Reducer_Values,
} from 'src/Chapters/Sales/PosAnalysis/reducer';
import { emptyArray, emptyObject } from 'src/tools';

import { posEntitiesByTenantAdapter } from '../api/dataAdapters/posEntitiesByTenantAdapter';
import { useFetchPosEntities } from '../api/useFetchPosEntities';
import { SALES_SECTION } from '../../constants';
import { TenantPosInfo } from '../api/interfaces';

// import { Sales_PaymentAnalysis_Dynamics_Pos_Widget_Reducer, storeSelectedChartType } from './reducer';
import { ChartWrapper, Title } from './styles';
// selectedTab
/**
 * Компонент для отображения графиков динамики по периодам, метрикам и объектам
 */
// const DynamicsPMO: React.FC = memo(() => {
export const DynamicsPosWidget: React.FC = () => {
    const {
        mainAreaSize: { width },
        currentModuleID,
        cfg: { reportingObjectsById },
        rawMetrics,
    } = useGeneralSelector();

    const { activeTenantIds } = useSelector(Sales_PosAnalysis_Module_Reducer_Values);
    const [selectedTenantId] = activeTenantIds || [];

    const { data } = useFetchPosEntities(posEntitiesByTenantAdapter);
    const possByTenant = data || emptyObject;

    const metricParams = useMemo(
        () => {
            const [posIds, aliases] = ((possByTenant?.[selectedTenantId] || []) as TenantPosInfo[]).reduce(
                ([accIds, accAliases], pos) => [
                    [...accIds, ...([pos?.id] || [])],
                    {
                        ...accAliases,
                        [pos?.id]: pos?.registrationId || pos?.factoryId,
                    },
                ],
                [[], {}],
            );

            return {
                posIds,
                aliases,
            }
        },
        [selectedTenantId, possByTenant],
    );

    const salesMetrics = useMemo(
        () => rawMetrics
            .filter(({ section }) => section === SALES_SECTION)
            .map(({ id }) => (id)),
        [rawMetrics],
    );

    const { mainPeriod } = usePeriods(currentModuleID);

    const { t } = useTranslation();
    const ref = useRef(null);

    const dynamicsPmoId = `${currentModuleID}${DS}DynamicsPMO`;

    const isMobile = isMobileInboundWidth(width);

    const {
        viewSettingsProps,
        wrappedDynamicsSettings,
        onOutsideSeriesChange,
    } = useModuleChartsDynamicsSettings(dynamicsPmoId, { weekendsActiveByDefault: true });

    const reloadWidget = () => {
        queryClient.invalidateQueries({ queryKey: [DYNAMICS_PMO_QUERY_KEY, currentModuleID] });
    };

    const reportingObjects = selectedTenantId ? [reportingObjectsById?.[selectedTenantId]] : []

    if (activeTenantIds?.length !== 1) {
        return null;
    }

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    <Title> {t('POS Dynamics')}</Title>
                    <WidgetAdditionalControls
                        widgetName={'POS Dynamics'}
                        reloadHandlerNoDispatch={reloadWidget}
                        pdfDownloadData={{ targetRef: ref }}
                    />
                </WidgetTitle>
                {/* {!isMobile && tabList} */}
            </WidgetTitleWrapper>
            {wrappedDynamicsSettings}
            {/* {isMobile && tabList} */}
            {mainPeriod && salesMetrics && reportingObjects && (
                <ChartWrapper>
                    <DynamicsPMOWidget
                        reportingObjects={reportingObjects}
                        comparePeriods={emptyArray}
                        chartType="pos_metrics"
                        metrics={salesMetrics}
                        metricParams={metricParams}
                        mainPeriod={mainPeriod.period}
                        moduleId={currentModuleID}
                        widgetId="POS-Dynamics"
                        viewSettings={{
                            isShowTitle: false,
                            chartWrapperStyles: {
                                marginTop: 'var(--space-md)',
                                height: isMobile ? `${CHART_MOBILE_HEIGHT}px` : `${CHART_DESKTOP_HEIGHT}px`,
                            },
                            ...viewSettingsProps,
                        }}
                        giveSeriesOutside={onOutsideSeriesChange}
                    />
                </ChartWrapper>
            )}
        </WidgetWrapper>
    );
};