type Cell = { value: string | null };
type Row = Cell[];

export function removeEmptyRowsAndColumns(matrix: Row[]): Row[] {
    // Удаляем полностью пустые строки
    const filteredRows = matrix.filter((row) => row.some((cell) => cell.value && cell.value.trim() !== ''));

    // Находим индексы пустых столбцов
    const columnCount = filteredRows[0]?.length || 0;
    const emptyColumns = new Set<number>();

    for (let col = 0; col < columnCount; col++) {
        const isEmptyColumn = filteredRows.every((row) => {
            const cell = row[col];
            return !cell || !cell.value || cell.value.trim() === '';
        });
        if (isEmptyColumn) {
            emptyColumns.add(col);
        }
    }

    // Удаляем пустые столбцы
    const result = filteredRows.map((row) => row.filter((_, index) => !emptyColumns.has(index)));

    return result;
}
