import { geoMatrixConverter } from '../../../tools/geoMatrixConverter';

const addShift = (arr: number[], shift: number[]) => {
    return [arr[0] + shift[0], arr[1] + shift[1]];
};

const applyMatrix = (arr: number[], matrix: number[][]) => {
    return geoMatrixConverter(arr[0], arr[1], matrix);
};

/**
 * Рекурсивно обходит структуру, и если есть геоджейсон, применяе к нему сдвиг координат
 * @param {any} instance Структура любого типа
 * @param {number[]} shift сдвиг по осям
 */
export const recursiveTransform = (instance: any, matrix: number[][]): any => {
    if (Array.isArray(instance)) {
        instance.forEach((item) => {
            recursiveTransform(item, matrix);
        });
    } else if (typeof instance === 'object' && instance !== null && instance.hasOwnProperty('type')) {
        if (instance.type === 'Polygon' || instance.type === 'LineString') {
            instance.coordinates = instance.coordinates.map((item: number[]) => applyMatrix(item, matrix));
        } else if (instance.type === 'Point') {
            instance.coordinates = applyMatrix(instance.coordinates, matrix);
        } else {
            Object.keys(instance).forEach((k) => {
                recursiveTransform(instance[k], matrix);
            });
        }
    } else if (typeof instance === 'object' && instance !== null) {
        Object.keys(instance).forEach((k) => {
            recursiveTransform(instance[k], matrix);
        });
    } else {
        return;
    }
};
