import { isValidElement, cloneElement, Children, ReactNode } from 'react';

export const retrievePropertyFromChildren = (propertyName: string, children?: ReactNode): string | null => {
    if (!children) {
        return null;
    }

    const childItems = Children.toArray(children);

    for (const child of childItems) {
        if (isValidElement(child)) {
            if (child.props && child.props[propertyName] !== undefined) {
                return child.props[propertyName];
            }

            const foundValue = retrievePropertyFromChildren(propertyName, child.props.children);
            if (foundValue !== null) {
                return foundValue;
            }
        }
    }

    return null;
};

export const getSubBranchForDisplayNameAndExtendsProps = (
    expectedDisplayName: string,
    children?: React.ReactNode,
    targetProps?: object,
): React.ReactNode | null => {
    if (!children) {
        return null;
    }

    const childItems = Children.toArray(children);

    for (const child of childItems) {
        if (!isValidElement(child)) {
            continue;
        }
        const childComponentName = child?.['type']?.['displayName'];;

        if (childComponentName === expectedDisplayName) {
            return child;
        }

        const foundChild = getSubBranchForDisplayNameAndExtendsProps(
            expectedDisplayName,
            child.props.children,
        );

        if (foundChild !== null) {
            const res = cloneElement(child, {
                ...child.props,
                children: foundChild,
                ...targetProps,
            });
            return res;
        }
    }

    return null;
};

export const getChildrenByDisplayName = (expectedDisplayName: string, children?: ReactNode): ReactNode => {
    if (!children) {
        return null;
    }

    const childItems = Children.toArray(children);
    return childItems.find(
        (child) => {
            if (!isValidElement(child)) {
                return null;
            }

            const childComponentName = child?.['type']?.['displayName'];
            if (childComponentName === expectedDisplayName) {
                return cloneElement(child, { ...child.props });
            }

            return null;
        },
    );
}

export const changeChildPropsByDisplayName = (expectedDisplayName: string, children?: ReactNode, props?: object): ReactNode => {
    if (!children) {
        return null;
    }

    const childItems = Children.toArray(children);

    const targetChild = childItems.find((subChild) => {
        if (isValidElement(subChild) && subChild?.['type']?.['displayName'] === expectedDisplayName) {
            return subChild;
        }
        return null;
    });

    if (targetChild) {
        return targetChild;
    }

    return childItems.map((child) => {
        if (!isValidElement(child)) {
            return null;
        }

        const expectedChild = getChildrenByDisplayName(expectedDisplayName, child.props.children);
        const hasExpectedSubChildren = expectedChild != null;

        if (!hasExpectedSubChildren) {
            return child;
        }

        // const newChild = Children.toArray(children)[0];
        // const newChildren = changeChildPropsByDisplayName(expectedDisplayName, child.props.children, props);
        // return cloneElement(newChild, { ...child.props, children: newChild, ...props });
        return { ...child, props: { ...child.props, ...props } };
    });
};
