import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputOnChangeData } from '@fluentui/react-components';

import { IFilterInputProps } from './FilterInput.interfaces';
import { StyledInput } from './styles';

const FilterInput = ({
    disabled,
    changeValue,
    value = '',
    onKeyDown,
    isOutsideControlled = false,
    minHeight,
    onCloseFilter,
}: IFilterInputProps) => {
    const { t } = useTranslation();
    const [filterValue, setFilterValue] = useState(value);

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, data: InputOnChangeData) => {
        event.stopPropagation();

        if (isOutsideControlled) {
            changeValue && changeValue(data.value || '');
        } else {
            setFilterValue(data.value || '');
            changeValue?.(data.value || '');
        }
    };

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (onKeyDown) {
                onKeyDown(event);
                return;
            }

            if ((event.key === 'Escape') || (event.key === 'Enter')) {
                onCloseFilter?.();
            }

        },
        [onKeyDown, onCloseFilter],
    )

    return (
        <StyledInput
            disabled={disabled}
            placeholder={t('Filter')}
            value={isOutsideControlled ? value : filterValue}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            minHeight={minHeight}
        />
    );
};

export default FilterInput;
