import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useMemo } from 'react';
import {
    PositioningImperativeRef,
    PositioningVirtualElement,
    Menu,
    MenuGroup,
    MenuPopover,
    MenuTrigger,
    Divider,
    MenuList,
} from '@fluentui/react-components';

import { useGeneralSelector } from 'src/hooks';
import { toString } from 'src/tools';
import {
    ContextualMenu,
    StyledMenuGroupHeader,
    StyledMenuItem,
    // MenuConfig,
    getContextMenuRect,
    // getContextualMenuBody,
} from 'src/components/ContextualMenu';

import {
    toggleContextMenu,
    Configuration_Categories_Widget_Reducer_Values,
    changeFilters,
    resetFilters,
} from '../../../../reducer';
import { reloadCategories } from '../../../../../../../../../General.reducer';
import { IProjectCategory } from '../../../../../../../../../General.interfaces';
import FilterInput from '../../../../../../../../../components/FilterInput/FilterInput';
import { CategoriesAPI } from '../../../../../../../../../tools/API/categoriesAPI';

import { IContextMenuProps } from './interfaces';

const MINIMAL_MENU_HEIGHT = 150;

/**
 * Компонент Контекстного меню
 */
const ContextMenu: React.FC<IContextMenuProps> = () => {
    const { contextMenu, filters } = useSelector(Configuration_Categories_Widget_Reducer_Values);

    const {
        token,
        user,
        src: { projectCategories },
        urlsByServices,
        mainAreaSize: { height },
    } = useGeneralSelector();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const categoriesUrl = useMemo(() => {
        if (urlsByServices) return urlsByServices['core/admin-service'].CATEGORIES_URL;
        return null;
    }, [urlsByServices]);

    const closeContextualMenu = () => {
        dispatch(toggleContextMenu({ show: false }));
    };

    const changeValue = (value: string, mode?: string) => {
        const colIndex = contextMenu.col ?? undefined;

        (colIndex !== undefined) && dispatch(changeFilters({ value, colIndex, mode }));
        closeContextualMenu();
    };

    const handleResetFilters = () => {
        dispatch(resetFilters());
        closeContextualMenu();
    };

    const availableCategoriesItems = useMemo(() => {
        const addEditCategory =
            (args: { objectId: number | undefined; categoryId: number; categoryName: string; relationId?: number }) =>
            () => {
                if (!args.objectId || !categoriesUrl) {
                    return;
                }
                !relationId &&
                    CategoriesAPI({
                        user,
                        token,
                        chapter: 'data-obj-2-project-category',
                        data: { data_object_id: args.objectId, category_id: args.categoryId },
                        method: 'POST',
                        url: categoriesUrl,
                    }).then(() => {
                        dispatch(reloadCategories());
                    });
                relationId &&
                    CategoriesAPI({
                        user,
                        token,
                        chapter: 'data-obj-2-project-category',
                        data: { category_id: args.categoryId },
                        method: 'PATCH',
                        id: relationId,
                        url: categoriesUrl,
                    }).then(() => {
                        dispatch(reloadCategories());
                    });

                closeContextualMenu();
            };

        const deleteCategory = (relationId: number) => () => {
            if (!relationId || !categoriesUrl) return;
            CategoriesAPI({
                user,
                token,
                chapter: 'data-obj-2-project-category',
                id: relationId,
                method: 'DELETE',
                url: categoriesUrl,
            }).then(() => {
                dispatch(reloadCategories());
            });
            closeContextualMenu();
        };

        const objectId = contextMenu?.object?.id;
        const relationId = contextMenu?.cell?.relation?.id;

        const result = (projectCategories || [])
            .filter((item) => item.parent_id === contextMenu?.cell?.id)
            .sort((a, b) => a.object_name.localeCompare(b.object_name))
            .map((item: IProjectCategory) => {
                const handleMenuItemClick = addEditCategory({
                    objectId,
                    categoryId: item.id,
                    categoryName: item.object_name,
                    relationId,
                });
                // canCheck: true, TODO: check what is it in fui-8
                return (
                    <StyledMenuItem key={String(item.id)} onClick={handleMenuItemClick}>
                        {item.object_name}
                    </StyledMenuItem>
                );
            });

        const handleMenuItemClick = deleteCategory(relationId);
        result.push(
            <StyledMenuItem key={'none'} onClick={handleMenuItemClick}>
                {t('None')}
            </StyledMenuItem>,
        );

        return result;
    }, [
        contextMenu?.cell?.id,
        contextMenu?.cell?.relation?.id,
        contextMenu?.object?.id,
        dispatch,
        closeContextualMenu,
        projectCategories,
        categoriesUrl,
        token,
    ]);

    const column = contextMenu?.col ?? -1;
    const filterValue = filters[toString(column)]?.filter || '';

    const inputSubMenu = (
        <Menu persistOnItemClick hoverDelay={10} key={`filter`}>
            <MenuTrigger disableButtonEnhancement>
                <StyledMenuItem area-disabled key={`filter`}>
                    {t('Filter')}
                </StyledMenuItem>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    <FilterInput
                        value={filterValue}
                        changeValue={(value: string, mode?: string) => dispatch(changeFilters({ value, colIndex: contextMenu.col!, mode }))}
                        onCloseFilter={closeContextualMenu}
                        isOutsideControlled
                    />
                </MenuList>
            </MenuPopover>
        </Menu>
    );

    const filtersMenu = (
        <MenuGroup>
            <StyledMenuGroupHeader key="filters">
                {t('Filters')}
            </StyledMenuGroupHeader>
            <Divider />
            <MenuList key="filters-items">
                {inputSubMenu}
                <StyledMenuItem key="filterByValue" onClick={() => changeValue(contextMenu.cell?.value || '')}>
                    {t('Filter by value')}
                </StyledMenuItem>
                <StyledMenuItem key="filterByAnyValue" onClick={() => changeValue('__', 'any')}>
                    {t('Filter by any value')}
                </StyledMenuItem>
                <StyledMenuItem key="filterByNoValue" onClick={() => changeValue('__', 'no')}>
                    {t('Filter by no value')}
                </StyledMenuItem>
                <StyledMenuItem key="reset" onClick={handleResetFilters}>
                    {t('Reset filters')}
                </StyledMenuItem>
            </MenuList>
            {column > 0 && (
                <>
                    <StyledMenuGroupHeader key="add-edit-category">
                        {t('Add/Edit category')}
                    </StyledMenuGroupHeader>
                    <Divider />
                    <MenuList key="add-edit-category-items">
                        {availableCategoriesItems}
                    </MenuList>
                </>
            )}
        </MenuGroup>
    );

    const menuStyles = useMemo(
        () => {
            const calcHeight = height / 2;
            return ({
                padding: '0',
                maxHeight: calcHeight < MINIMAL_MENU_HEIGHT
                    ? MINIMAL_MENU_HEIGHT
                    : calcHeight,
            });
        },
        [height],
    );

    const contextMenuTargetRef = useRef<PositioningImperativeRef>(null);

    const cursorCoords = contextMenu?.cursorCoords;

    const isOpened = useMemo(() => {
        const { x, y } = cursorCoords || {};
        const virtualElement: PositioningVirtualElement = {
            getBoundingClientRect: getContextMenuRect(x, y),
        };
        contextMenuTargetRef.current?.setTarget(virtualElement);

        return !!cursorCoords;
    }, [cursorCoords]);

    return (
        <ContextualMenu
            styles={menuStyles}
            key="events-filters-menu"
            isContextualMenuOpen={isOpened}
            closeContextualMenu={closeContextualMenu}
            contextualMenuBody={filtersMenu!}
            positioningRef={contextMenuTargetRef}
        />
    );
};

export default ContextMenu;
