import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { useEffect } from 'react';
import { Reports_ByTenantsComparison_Widget_Reducer_Values, resetReducer, storeGrid } from '../reducer';
import { generalReducerValues } from '../../../../../../General.reducer';
import { ISelectedOption } from '../../../../../../components/Selects/Select/interfaces';
import { DS } from '../../../../../../constants/constants';
import {
    IDataObj2ProjectCategory,
    IMetricResponseItem,
    IProjectCategory,
    IResponseItem,
    ISheetCell,
    TMetricResponse,
} from 'src/General.interfaces';
import { DateTime } from 'luxon';
import { valueFormatter } from 'src/tools/Strings/valueFormatter';
import getDifferenceBetweenNumbers from 'src/tools/getDifferenceBetweenNumbers';
import { isDateRangesIntersect } from 'src/tools/isDateRangesIntersect';
import { AdditionalFieldsIds } from 'src/Chapters/Reports/interfaces';

/** Кастомный хук для подготовки данных */
const usePrepareData = () => {
    const { rawMetricsData, keyWord } = useSelector(Reports_ByTenantsComparison_Widget_Reducer_Values);
    const {
        currentModuleID,
        cfg: { projectCategoriesById, dataObj2ProjectCategoryByObjId },
        structures,
    } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const {
        src: { reportingObjects },
        allMetrics,
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /** Обнуление редьюсера при смене локации */
    useEffect(() => {
        dispatch(resetReducer());
    }, [localCurrentOptions?.selectedLocationId]);

    const generateCategories = (objId: number) => {
        const parentCategories = Object.values(projectCategoriesById).filter((value) => {
            return !value.parent_id && value.is_active;
        });

        const tenantCategories: { key: string; value: string }[] = [];

        const currentTenantCategoryRelations = dataObj2ProjectCategoryByObjId[objId] as
            | undefined
            | IDataObj2ProjectCategory[];

        parentCategories.forEach((parentCategory) => {
            if (currentTenantCategoryRelations) {
                // Поиск связка для родительской категории
                const parentCategoryTenantRelation = currentTenantCategoryRelations.find((tenantCategory) => {
                    return projectCategoriesById[tenantCategory.category_id]?.parent_id === parentCategory.id;
                });
                // Если связка найдена добавляем категорию в массив
                if (parentCategoryTenantRelation) {
                    tenantCategories.push({
                        key: parentCategory.object_name,
                        value: projectCategoriesById[parentCategoryTenantRelation.category_id].object_name,
                    });
                } else {
                    tenantCategories.push({
                        key: parentCategory.object_name,
                        value: '—',
                    });
                }
            } else {
                tenantCategories.push({
                    key: parentCategory.object_name,
                    value: '—',
                });
            }
        });

        return tenantCategories;
    };

    const getArrange = (structName: string, objId: number, dateRange: string[]) => {
        const keyMap = {
            relations_tenant2place: 'Place(s)',
            relations_tenant2zone: 'Zone(s)',
            relations_tenant2floor: 'Floor',
        };
        const fieldMap = {
            relations_tenant2place: 'place_name',
            relations_tenant2zone: 'zone_name',
            relations_tenant2floor: 'floor',
        };

        if (!structures) return { key: t(keyMap[structName]), value: '—' };
        const struct = structures[structName]?.filter((item: { tenant_id: number }) => item.tenant_id === objId);
        const validPObjects =
            struct
                ?.filter((item: { date_from: string; date_to: string }) => {
                    return isDateRangesIntersect([item.date_from, item.date_to], dateRange);
                })
                .map((item: any) => {
                    return item[fieldMap[structName]];
                }) || [];
        return { key: t(keyMap[structName]), value: validPObjects.join(', ') || '—' };
    };

    /** Создание таблицы после получения метрик */
    useEffect(() => {
        if (Array.isArray(rawMetricsData)) {
            const additionalFieldsIds = localCurrentOptions?.[`additionalFields${DS}${keyWord}`]?.map(
                (element: ISelectedOption) => element.id,
            );

            // const grid = generateGrid(rawMetricsData, reportingObjects, allMetrics, t, additionalFieldsIds);

            const timeFreq = rawMetricsData[0][0].context.time_freq;

            //Собираем предварительную структуру
            const dataArray: Array<any>[] = [];

            rawMetricsData.forEach((resp: TMetricResponse) => {
                resp.forEach((item: IMetricResponseItem) => {
                    const metricId = item.context.metric;
                    const timeZone = item.context.data_objects[0].timezone;
                    const timeRange = item.context.time_range;
                    const isMainPeriod = item.context.alias === 'main';
                    const isMainMetric = isMainPeriod;
                    const units = allMetrics?.find((m) => m.id === item.context.metric)?.units || '';
                    const objId = item.context.data_objects[0].id;
                    const objName = reportingObjects?.find((item) => item.id === objId)?.name || '';
                    item.items.forEach((data: IResponseItem, timeIndex) => {
                        const row: any[] = [{ key: t('Object'), value: objName }];

                        if (additionalFieldsIds?.includes(AdditionalFieldsIds.AddId)) {
                            row.unshift({ key: 'Id', value: String(objId) });
                        }
                        if (additionalFieldsIds?.includes(AdditionalFieldsIds.AddTimeZone)) {
                            row.push({ key: t('Time zone'), value: timeZone });
                        }

                        if (additionalFieldsIds?.includes(AdditionalFieldsIds.AddCategories)) {
                            row.push(
                                ...generateCategories(objId).map((item) => {
                                    return { key: item.key, value: item.value, isMainPeriod };
                                }),
                            );
                        }

                        if (additionalFieldsIds?.includes(AdditionalFieldsIds.AddPlace)) {
                            row.push(getArrange('relations_tenant2place', objId, timeRange));
                        }
                        if (additionalFieldsIds?.includes(AdditionalFieldsIds.AddZone)) {
                            row.push(getArrange('relations_tenant2zone', objId, timeRange));
                        }
                        if (additionalFieldsIds?.includes(AdditionalFieldsIds.AddFloor)) {
                            row.push(getArrange('relations_tenant2floor', objId, timeRange));
                        }

                        let innerId = ``;
                        if (timeFreq === null) {
                            const value = `${timeRange[0]} – ${timeRange[1]}`;
                            row.push({ key: t('Period'), value, isMainPeriod, timeIndex });
                            innerId = `${objId}:${value}`;
                        } else {
                            const value =
                                timeFreq !== 'H'
                                    ? DateTime.fromISO(data.time).toISODate()
                                    : DateTime.fromISO(data.time).toFormat('yyyy-MM-dd HH:mm');
                            const key = timeFreq !== 'H' ? t('Date') : t('DateTime');
                            const weekDay = DateTime.fromISO(data.time).weekdayShort;
                            row.push({ key, value, isMainPeriod, timeIndex, weekDay });
                            innerId = `${objId}:${value}`;
                        }

                        row.push({
                            metric: metricId,
                            value: data.value,
                            // value: valueFormatter({ value: data.value, units, showUnits: false, numericOutput: true }),
                            isMainMetric,
                        });
                        row.push({ key: 'innerId', value: innerId });
                        dataArray.push(row);
                    });
                });
            });

            //Собираем все метрики основного периода
            const mainRows: { [x: string]: Array<any> } = {};

            dataArray.forEach((item) => {
                if (item.some((cell) => cell.isMainPeriod)) {
                    const id = item?.find((cell) => cell.key === 'innerId')?.value || ('Y' as string);
                    if (mainRows.hasOwnProperty(id)) {
                        const metricCell = item?.find((cell) => cell.metric);
                        if (metricCell) {
                            mainRows[id] = [...mainRows[id], metricCell];
                        }
                    } else {
                        mainRows[id] = item;
                        // mainRows[id] = item?.filter((cell) => cell.key !== 'innerId');
                    }
                }
            });

            //Добавляем все метрики периода сравнения
            const grid = Object.values(mainRows)
                .map((value) => value)
                .map((item) => {
                    const mainId = item?.find((cell) => cell.key === 'innerId').value.split(':')[0];
                    const mainTimeIndex = item?.find((cell) => cell.timeIndex)?.timeIndex;
                    const row = [...item?.filter((cell) => cell.key !== 'innerId')];
                    dataArray.forEach((r) => {
                        const id = r?.find((cell) => cell.key === 'innerId').value.split(':')[0];
                        const timeIndex = r?.find((cell) => cell.timeIndex)?.timeIndex;
                        const isMain = r.some((cell) => cell.isMainPeriod);
                        if (mainId === id && !isMain && mainTimeIndex === timeIndex) {
                            const compareDate = r?.find((cell) => cell.timeIndex !== undefined);
                            if (compareDate && !row.some((item) => item.value === compareDate.value)) {
                                row.push({ ...compareDate, key: t('Compare date') });
                            }

                            //  if (additionalFieldsIds?.includes('addPlace')) {
                            //      row.push(generatePlaces(id, timeRange));
                            //  }
                            const metricCell = r?.find((cell) => cell.metric);
                            if (metricCell) {
                                row.push(metricCell);
                            }
                        }
                    });
                    return row;
                })
                //Добавляем ячейки с дельтой и процентами, если надо
                .map((row) => {
                    const newRow: ISheetCell[] = [];
                    row.forEach((cell) => {
                        if (cell.isMainMetric === false) {
                            const mainValue = row?.find(
                                (item) => item.metric === cell.metric && item.isMainMetric,
                            )?.value;
                            const compareValue = Number(cell.value);

                            const metricName = allMetrics?.find((m) => m.id === cell.metric)?.text || '';
                            newRow.push(cell);
                            if (additionalFieldsIds?.includes(AdditionalFieldsIds.AddDelta)) {
                                const units = allMetrics?.find((m) => m.id === cell.metric)?.units || '';
                                const delta = Number(mainValue) - Number(compareValue);

                                newRow.push({
                                    value: valueFormatter({
                                        value: delta,
                                        units,
                                        numericOutput: true,
                                        showUnits: false,
                                    }),
                                    key: `Δ ${t(metricName, { ns: 'metrics' })}`,
                                });
                            }
                            if (additionalFieldsIds?.includes(AdditionalFieldsIds.AddPercent)) {
                                const percent = valueFormatter({
                                    value: getDifferenceBetweenNumbers(Number(mainValue), Number(compareValue))
                                        .percentDifference,
                                    units: '%',
                                    numericOutput: true,
                                    showUnits: false,
                                });
                                newRow.push({ value: `${percent}`, key: `% ${t(metricName, { ns: 'metrics' })}` });
                            }
                        } else if (cell.weekDay) {
                            newRow.push(cell);
                            if (additionalFieldsIds?.includes(AdditionalFieldsIds.AddWeekDay)) {
                                if (cell.isMainPeriod) {
                                    newRow.push({ key: t('Week day'), value: t(cell.weekDay) });
                                } else {
                                    newRow.push({ key: t('Compare week day'), value: t(cell.weekDay) });
                                }
                            }
                        } else {
                            newRow.push(cell);
                        }
                    });
                    return newRow;
                })
                .map((row) => {
                    return row.map((cell) => {
                        if (cell.metric) {
                            const units = allMetrics?.find((m) => m.id === cell.metric)?.units || '';
                            const precision = allMetrics?.find((m) => m.id === cell.metric)?.round_decimal_places || 2;
                            return {
                                ...cell,
                                value: valueFormatter({
                                    value: cell.value,
                                    units,
                                    precision,
                                    numericOutput: true,
                                    showUnits: false,
                                }),
                            };
                        } else {
                            return cell;
                        }
                    });
                })
                .filter((row) => {
                    const dataCells = row.filter((cell) => cell.metric);
                    let flag = true;

                    if (!additionalFieldsIds?.includes(AdditionalFieldsIds.ShowEmpty)) {
                        flag = dataCells.some((metric) => {
                            return metric.value !== '–';
                        });
                    }

                    return flag;
                });

            try {
                //Добавляем первую строку таблицы
                const firstRow = grid[0].map((item) => {
                    const metricName = allMetrics?.find((m) => m.id === item.metric)?.text || '';
                    const units = allMetrics?.find((m) => m.id === item.metric)?.units || '';
                    return {
                        value: item.key || `${t(metricName, { ns: 'metrics' })}, ${t(units)}` || '',
                        readOnly: true,
                    };
                });
                grid.unshift(firstRow);
            } catch (error) {}

            grid && dispatch(storeGrid(grid));
        }
    }, [localCurrentOptions?.[`additionalFields${DS}${keyWord}`], rawMetricsData, t]);
};

export default usePrepareData;
