/**
 * Список допустимых цветов
 */

export enum Size {
    /**
     * Пустое значение
     */
    Empty = '',
    /**
     * Самое маленькое закругление
     */
    Xs = 'xs',
    /**
     * Маленькое закругление(по умолчанию)
     */
    Sm = 'sm',
    /**
     * Среднее закругление
     */
    Md = 'md',
    /**
     * Большое закругление
     */
    Lg = 'lg',
    /**
     * Очень большое закругление
     */
    Xl = 'xl',
    /**
     * Закругление равно 0
     */
    Unset = 'unset',
    /**
     * Закругление равно 50%
     */
    Circle = 'circle'
}
