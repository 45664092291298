import { useDispatch, useSelector } from 'react-redux';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import { SelectsWrapper } from '../../../components/Wrappers/SelectsWrapper/styles';
import ObjectsSelect from '../../../components/Selects/ObjectsSelect/ObjectsSelect';
import { changeOptions } from '../../../components/UserSettings/reducer';
import { generalReducerValues } from '../../../General.reducer';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { WidgetWrapperStyled } from '../../../components/Wrappers/WidgetWrapper/styles';
import UniversalDatePicker from '../../../components/Selects/UniversalDatePicker/UniversalDatePicker';
import { useTranslation } from 'react-i18next';
import { useGetCorrectionBills } from './api/useGetCorrectionBills';
import LoadingBox from '../../../components/LoadingBox/LoadingBox';
import Table from './components/Table/Table';
import TabList from '../../../components/TabList/TabList';
import { ITabListOptionsItem } from '../../../components/TabList/interfaces';
import { useEffect, useMemo } from 'react';
import {
    Configuration_SalesMetricsUpload_Module_Reducer_Values,
    storeDataFromFile,
    storeDataToEdit,
    storeDateRangeFromFile,
    storeObjIdsFromFile,
    storeSelectedTab,
    storeTimeFreq,
    toggleValidationModalStatus,
} from './reducer';
import { TableWrapper } from './styles';
import DataEdit from './components/DataEdit/DataEdit';
import { TSelectedTab } from './interfaces';
import { useIsMutating } from '@tanstack/react-query';
import { POST_CORRECTION_BILLS_QUERY_KEY } from './constants/constants';
import FileUpload from './components/FileUpload/FileUpload';
import Modals from '../../../components/Modals/Modals';
import ValidationModal from './components/ValidationModal/ValidationModal';

const SalesMetricsUpload = () => {
    const { user, currentModuleID, isRtlLanguage, selectedLocationId } = useSelector(generalReducerValues);
    const { selectedTab, timeFreq, validationModalStatus } = useSelector(
        Configuration_SalesMetricsUpload_Module_Reducer_Values,
    );
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { data, isFetching, refetch } = useGetCorrectionBills();
    const isPostMutationLoading = useIsMutating({ mutationKey: [POST_CORRECTION_BILLS_QUERY_KEY] });

    const TAB_LIST_ITEMS: ITabListOptionsItem[] = useMemo(() => {
        return [
            { id: 'summaryTable', text: 'Summary table' },
            { id: 'metricsTable', text: 'Metrics table' },
            { id: 'uploadFile', text: 'Upload from file' },
        ];
    }, [timeFreq]);

    const tabListSelectHandler = (id: TSelectedTab) => {
        dispatch(storeSelectedTab(id));
    };

    useEffect(() => {
        dispatch(storeDataFromFile([]));
        dispatch(storeObjIdsFromFile([]));
        dispatch(storeDateRangeFromFile(null));
    }, [selectedLocationId]);

    useEffect(() => {
        if (timeFreq === 'mixed') {
            dispatch(storeSelectedTab('summaryTable'));
        }
    }, [timeFreq]);

    useEffect(() => {
        refetch();
    }, [localCurrentOptions]);

    useEffect(() => {
        if (data?.metricsTableData) {
            dispatch(storeDataToEdit(data.metricsTableData));
        }
    }, [data?.metricsTableData]);

    useEffect(() => {
        data?.timeFreq !== undefined && dispatch(storeTimeFreq(data.timeFreq));
    }, [data?.timeFreq]);

    const closeValidationModal = () => {
        dispatch(toggleValidationModalStatus({ show: false, data: [] }));
    };

    const showLoader = isFetching || isPostMutationLoading;

    const style: React.CSSProperties = { maxWidth: 'none' };

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <UniversalDatePicker name={'period'} labelText={t('Period')} yearsOnly />
                        <ObjectsSelect
                            canSelectEntireSection={true}
                            validOptions={['Tenants']}
                            dataObjectFilters={['data_objects_tenant']}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                    </SelectsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <Modals
                topColor={'var(--color-accent-dark)'}
                title={t('Validation errors')}
                modalStatus={validationModalStatus}
                closeModal={closeValidationModal}
            >
                <ValidationModal data={validationModalStatus.data || []} closeModal={closeValidationModal} />
            </Modals>
            <WrapperContainer style={style}>
                <WidgetWrapperStyled isRtlLanguage={isRtlLanguage}>
                    <TabList
                        options={TAB_LIST_ITEMS}
                        selectHandler={tabListSelectHandler}
                        selectedValue={selectedTab}
                        widgetName={currentModuleID}
                    />
                    {showLoader ? (
                        <LoadingBox height={400} text={t('Loading...')} />
                    ) : (
                        <TableWrapper>
                            {data && selectedTab === 'summaryTable' && <Table data={data.summaryTableData} />}
                            {data && selectedTab === 'metricsTable' && <DataEdit />}
                            {data && selectedTab === 'uploadFile' && <FileUpload />}
                        </TableWrapper>
                    )}
                </WidgetWrapperStyled>
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default SalesMetricsUpload;
