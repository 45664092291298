import { KonvaEventObject } from 'konva/lib/Node';
import React from 'react';
import { Group, Shape } from 'react-konva';
import { scaleTransformFoo } from '../../../../../../../../../tools/scaleTransformFoo';
import { Props } from './interfaces';

const Zone: React.FC<Props> = ({ object, selectObject, onMouseOverLeave, selectedMarker, stageScale, heatColor }) => {
    const onObjectClick = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        selectObject(object.zoneMarker);
    };

    const onMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave?.(`${object.zoneMarker}+${object.frontId}`);
    };

    const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave?.(null);
    };

    const fill = selectedMarker !== object.zoneMarker ? heatColor : 'rgba(0,0,255,0.5)';

    const [startX, startY] = object.coords.coordinates[0];
    return (
        <Shape
            id={'Shape!@##11'}
            key={'Shape!@##11'}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onClick={onObjectClick}
            sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(startX, startY);
                object.coords.coordinates.forEach(([x, y]) => context.lineTo(x, y));
                context.fillStrokeShape(shape);
            }}
            opacity={1}
            stroke={'white'}
            fill={fill}
            strokeWidth={scaleTransformFoo(4, stageScale)}
        />
    );
};

export default Zone;
