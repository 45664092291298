import { useTranslation } from 'src/hooks/useTranslation';
import Tooltip from 'src/components/Tooltip/Tooltip';

import { IndicatorStyled } from './styles';

export const ActivityIndicator = ({
    isActive,
}: { isActive?: boolean}) => {
    const { t } = useTranslation(['translation']);

    const content = isActive ? t('Active') : t('Inactive');
    return (
        <div>
            <Tooltip content={content}>
                <IndicatorStyled isActive={isActive}/>
            </Tooltip>
        </div>
    );
};