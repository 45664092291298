import styled from 'styled-components/macro';

export const ValueWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Td = styled.td<{ isFirstColumn?: boolean }>`
    vertical-align: middle !important;
    padding: ${(p) => {
        if (p.className?.includes('editing')) {
            return '0px !important';
        }
        return 'var(--space-xxxxs) !important';
    }};
    max-width: max-content;
    background-color: ${(p) => (p.isFirstColumn ? 'var(--color-secondary-light) !important' : `transparent`)};

    :first-child {
        > span {
            text-align: left;
            display: block !important;
        }
    }
    color: rgb(36, 36, 36) !important;
    > span {
        text-align: right;
        white-space: nowrap;
    }
`;

export const Th = styled.th`
    padding: var(--space-xxxs) !important;
    vertical-align: middle !important;
    background-color: var(--color-secondary-light) !important;
    color: rgb(36, 36, 36) !important;

    :first-child {
        > span {
            text-align: left;
            display: block;
        }
    }
    > span {
        text-align: right;
        white-space: nowrap;
    }
`;

export const Ul = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;
