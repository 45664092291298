/**
 * Размеры тени
 */
export enum ShadowSize {
    /**
     * Пустое значение
     */
    Empty = '',
    /**
     * Значение по умолчанию
     */
    Default = 'md',
    /**
     * Маленький размер
     */
    Sm = 'sm',
    /**
     * Средний размер
     */
    Md = 'md',
    /**
     * Средний размер
     */
    Unset = 'unset'
}
