import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { emptyObject, returnValueOrDefault } from 'src/tools';

import { RootState } from '../../../../../store';
import { TDynamicsSeries } from '../Dynamics/interfaces';

import { IDynamicsSettings, IReducerState, DynamicsSettingsByDefault } from './interfaces';

const initialState: IReducerState = {
    dynamicsSettingsById: {},
};

const Dynamics_Settings_Reducer = createSlice({
    name: 'Dynamics_Settings_Reducer',
    initialState,
    reducers: {
        /** Заполнение начальных данных по ключу ID */
        fillInitialDynamicsSettingsById: (
            state,
            action: PayloadAction<{ id: string; series?: TDynamicsSeries[] } & DynamicsSettingsByDefault>,
        ) => {
            const { series: payloadSeries, ...defaultValues } = action?.payload || emptyObject;

            const initialData: IDynamicsSettings = {
                weekends: {
                    isActive: returnValueOrDefault(defaultValues?.weekendsActiveByDefault!, true),
                },
                events: {
                    isActive: returnValueOrDefault(defaultValues?.eventsActiveByDefault!, false),
                },
                weather: {
                    isActive: returnValueOrDefault(defaultValues?.weatherActiveByDefault!, false),
                },
                trendSeries: {
                    selectedSeriesIds: [],
                },
                averageSeries: {
                    selectedSeriesIds: [],
                },
                series: payloadSeries || [],
            };

            state.dynamicsSettingsById[action.payload.id] = initialData;
        },

        /** Обновление настроек по ключу */
        updateDynamicsSettingsById: (
            state,
            action: PayloadAction<{
                id: string;
                data: { key: string; value: object; shouldRewrite?: boolean };
            }>,
        ) => {
            const { id, data } = action.payload;
            const dynamicsSettingsById = cloneDeep(state.dynamicsSettingsById);

            const currentValue = dynamicsSettingsById[id] as IDynamicsSettings | undefined;

            if (currentValue) {
                state.dynamicsSettingsById[id] = {
                    ...currentValue,
                    [data.key]: data.shouldRewrite
                        ? data.value
                        : {
                              ...currentValue[data.key],
                              ...data.value,
                          },
                };
            }
        },
    },
});

export const { fillInitialDynamicsSettingsById, updateDynamicsSettingsById } = Dynamics_Settings_Reducer.actions;

export const Dynamics_Settings_Reducer_Values = (state: RootState) => state.DynamicsSettingsReducer;

export default Dynamics_Settings_Reducer.reducer;
