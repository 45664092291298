import _ from 'lodash';
import { DateTime } from 'luxon';
import {
    TMetricResponse,
    IReportingObject,
    IMetricResponseItem,
    IResponseItem,
    ISheetCell,
    IMetric,
} from '../../../../../../General.interfaces';
import { TTimeFreq } from '../../../../../../tools/API/interfaces';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';
import { TFunction } from 'i18next';

export const generateGrid = (
    response: TMetricResponse[],
    reportingObjects: IReportingObject[],
    allMetrics: IMetric[],
    selectedAddCols: string[],
    selectedMetrics: string[],
    t: TFunction,
) => {
    const dataArray: Array<ISheetCell>[] = [];

    const timeFreq: TTimeFreq = response[0][0].context.time_freq as TTimeFreq;
    const timeRange = response[0][0].context.time_range;

    _.sortBy(response, (metric: TMetricResponse) => {
        const metricId = metric[0].context.metric;
        return selectedMetrics.indexOf(metricId);
    }).forEach((metric: TMetricResponse) => {
        metric.forEach((item: IMetricResponseItem) => {
            const metricId = item.context.metric;
            const timeZone = item.context.data_objects[0].timezone;
            const objId = item.context.data_objects[0].id;
            const objName = reportingObjects?.find((item) => item.id === objId)?.name || '';
            item.items.forEach((data: IResponseItem) => {
                const row: Array<ISheetCell> = [{ key: 'Object', value: objName }];
                const units = allMetrics?.find((m) => m.id === item.context.metric)?.units || '';
                if (selectedAddCols?.includes('addId')) {
                    row.unshift({ key: 'Id', value: String(objId) });
                }

                let innerId = ``;

                if (timeFreq === null) {
                    const value = `${timeRange[0]} – ${timeRange[1]}`;
                    row.push({ key: 'Period', value });
                    innerId = `${objId}:${value}`;
                } else {
                    const value =
                        timeFreq !== 'H'
                            ? DateTime.fromISO(data.time).toISODate()
                            : DateTime.fromISO(data.time).toFormat('yyyy-MM-dd HH:mm');
                    const key = timeFreq !== 'H' ? 'Date' : 'DateTime';
                    row.push({ key, value: value || '' });
                    innerId = `${objId}:${value}`;
                    const weekDay = DateTime.fromISO(data.time).weekdayShort;

                    if (selectedAddCols?.includes('addWeekDay')) {
                        row.push({ key: 'Week day', value: t(weekDay || '') });
                    }
                }

                if (selectedAddCols?.includes('addTimeZone')) {
                    row.push({ key: 'Time zone', value: timeZone });
                }

                row.push({
                    metric: metricId,
                    value: valueFormatter({ value: data.value, units, showUnits: false, numericOutput: true }),
                });
                row.push({ key: 'innerId', value: innerId });

                dataArray.push(row);
            });
        });
    });

    const rows: { [x: string]: Array<ISheetCell> } = {};
    dataArray.forEach((item) => {
        const id = item?.find((cell) => cell.key === 'innerId')?.value || ('Y' as string);
        if (rows.hasOwnProperty(id)) {
            const metricCell = item?.find((cell) => cell.metric);

            if (metricCell) {
                rows[id] = [...rows[id], metricCell];
            }
        } else {
            rows[id] = item?.filter((cell) => cell.key !== 'innerId');
        }
    });

    const grid: Array<ISheetCell[]> = _.sortBy(Object.values(rows), (row) => {
        return row.find((item) => item.key === 'Object')?.value;
    })
        .filter((row) => {
            const dataCells = row.filter((cell) => cell.metric);
            let flag = true;

            if (!selectedAddCols?.includes('showEmpty')) {
                flag = dataCells.some((metric) => {
                    return metric.value !== '–';
                });
            }

            return flag;
        })
        .map((value) => value);

    const firstRow = Object.values(rows)[0]?.map((item) => {
        if (!item?.metric) return { value: t(item?.key || ''), readOnly: true };
        const metricName = allMetrics?.find((m) => m.id === item.metric)?.text || '';
        const units = allMetrics?.find((m) => m.id === item.metric)?.units || '';
        return { value: item.key || `${t(metricName, { ns: 'metrics' })}, ${t(units)}` || '', readOnly: true };
    });
    grid.unshift(firstRow);
    return grid;
};
