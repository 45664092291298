import calculatePlansYPosotion from '../tools/calculatePlansYPosotion';
import { createRef, useEffect, useRef, useState } from 'react';
import getExtendedPlans from '../tools/getExtendedPlans';
import getLatestPlans from '../tools/getLatestPlans';
import fitStage from '../tools/fitStage';
import {
    IStageScale,
    IUseStageInitialDataResponse,
    IUseStageInitialDataArgs,
    IExtendedPlan,
    ILatestPlan,
} from '../tools/interfaces';

/**
 * Кастомный хук для получения данных, необходимых для корректной работы холстов
 * @param stageWidthCalculationFactor коэффициент для вычисления ширины холста
 * @param stageAreaSize размеры холста
 * @param plans все планы
 * @returns данные для корректной работы холстов
 */
const useStageInitialData = (args: IUseStageInitialDataArgs): IUseStageInitialDataResponse => {
    const { stageWidthCalculationFactor, stageAreaSize, plans } = args;
    const [stageScale, setStageScale] = useState<IStageScale>({ stageScale: 1, stageX: 0, stageY: 0 });
    const [extendedPlans, setExtendedPlans] = useState<IExtendedPlan[]>([]);
    const [hoveredMarker, setHoveredMarker] = useState<string | null>(null);
    const [widestPlan, setWidestPlan] = useState<ILatestPlan | null>(null);
    const [stageWidth, setStageWidth] = useState(stageAreaSize.width);
    const [latestPlans, setLatestPlans] = useState<ILatestPlan[]>([]);
    const [plansYPosition, setPlansYPosition] = useState({});
    const [stageHeight, setStageHeight] = useState(0);
    const refsOfStages = useRef([]);

    /** Сохранение y координаты для каждого из планов, при их объединении */
    useEffect(() => {
        setPlansYPosition(calculatePlansYPosotion(extendedPlans));
    }, [extendedPlans]);

    useEffect(() => {
        // add or remove refs
        refsOfStages.current = Array(extendedPlans.length)
            .fill(null)
            .map((_, i) => refsOfStages.current[i] || createRef());
    }, [extendedPlans]);

    /** Получение последних (актуальных) планов */
    useEffect(() => {
        setLatestPlans(getLatestPlans(plans));
    }, [plans]);

    /** Изменение ширины в зависимости от размеров области отображения и коэффициента */
    useEffect(() => {
        setStageWidth(stageAreaSize.width * stageWidthCalculationFactor);
    }, [stageAreaSize, stageWidthCalculationFactor]);

    /** Получение самого широкого плана */
    useEffect(() => {
        const [widestPlan] = latestPlans.sort((a, b) => b.width / b.scale - a.width / a.scale);
        setWidestPlan(widestPlan);
    }, [latestPlans]);

    /** Получение масштаба */
    useEffect(() => {
        if (widestPlan) {
            const stageScale = fitStage(widestPlan, stageWidth);
            setStageScale((prevScale) => ({ ...prevScale, stageScale: isNaN(stageScale) ? 1 : stageScale }));
        }
    }, [widestPlan, stageWidth]);

    /** Получение массива расширенных планов */
    useEffect(() => {
        if (widestPlan) setExtendedPlans(getExtendedPlans(latestPlans, widestPlan));
    }, [latestPlans, widestPlan]);

    /** Получение высоты */
    useEffect(() => {
        const height = latestPlans.reduce((acc, plan) => {
            const planRatio = plan.width / plan.height;
            const stageHeight = Math.round(stageWidth / planRatio);
            return acc + stageHeight;
        }, 0);

        setStageHeight(height);
    }, [latestPlans, stageWidth]);

    /** Изменение масштаба */
    const changeStageScale = (stageScale: IStageScale) => setStageScale(stageScale);

    /** Сохранение маркера, на который навелись */
    const changeHoveredMarker = (value: string | null) => setHoveredMarker(value);

    return {
        changeHoveredMarker,
        changeStageScale,
        plansYPosition,
        extendedPlans,
        hoveredMarker,
        refsOfStages,
        stageHeight,
        stageWidth,
        stageScale,
        widestPlan,
    };
};

export default useStageInitialData;
