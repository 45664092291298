export function rgba2hex(input: string): string {
    // Regular expression to check if the input is a valid HEX color code
    const hexRegex = /^#(?:[A-Fa-f0-9]{3,4}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/;

    if (hexRegex.test(input)) {
        // The input is a HEX color code; return it as is
        return input;
    } else {
        // Regular expression to parse RGBA color codes
        const rgbaRegex = /^rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})(?:\s*,\s*(\d*(?:\.\d+)?))?\s*\)$/i;
        const result = rgbaRegex.exec(input);

        if (result) {
            // Extract RGB and alpha values from the RGBA string
            const r = parseInt(result[1], 10);
            const g = parseInt(result[2], 10);
            const b = parseInt(result[3], 10);
            const a = result[4] !== undefined ? parseFloat(result[4]) : 1;

            // Validate that RGB values are between 0 and 255 and alpha is between 0 and 1
            if (r >= 0 && r <= 255 && g >= 0 && g <= 255 && b >= 0 && b <= 255 && a >= 0 && a <= 1) {
                // Convert RGB values to two-digit hexadecimal strings
                const rHex = r.toString(16).padStart(2, '0');
                const gHex = g.toString(16).padStart(2, '0');
                const bHex = b.toString(16).padStart(2, '0');

                // Include alpha in HEX if it's less than 1
                if (a < 1) {
                    const alphaHex = Math.round(a * 255)
                        .toString(16)
                        .padStart(2, '0');
                    return `#${rHex}${gHex}${bHex}${alphaHex}`.toUpperCase();
                } else {
                    return `#${rHex}${gHex}${bHex}`.toUpperCase();
                }
            } else {
                throw new Error('Invalid RGBA color values');
            }
        } else {
            throw new Error('Input string is neither HEX nor RGBA format');
        }
    }
}
