import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    width: 35vh;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

export const ContentWrapper = styled.div`
    flex-grow: 1;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
