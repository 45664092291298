import { ISeriesItem, TSeriesType } from './interfaces';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

/**
 * Функция для выравнивания серий по дням недели
 * @param series Серии
 * @returns выравненные серии
 */
const alignSeriesByWeekday = (series: TSeriesType[]): TSeriesType[] => {
    try {
        let xValues: number[] = [];

        const newSeries = cloneDeep(series)
            .sort((a, _) => (a.isMain ? -1 : 1))
            .map((element, seriesIndex) => {
                if (seriesIndex === 0) {
                    element.data?.forEach((item: ISeriesItem) => {
                        xValues.push(item.x);
                    });
                } else {
                    if (
                        DateTime.fromMillis(xValues[0]).weekday !==
                        DateTime.fromISO(element.data?.[0]?.['name']).weekday
                    ) {
                        let daysCounter = 1;
                        while (
                            DateTime.fromMillis(xValues[0]).weekday !==
                            DateTime.fromISO(element.data?.[0]?.['name']).plus({ days: daysCounter }).weekday
                        ) {
                            daysCounter++;
                        }

                        element.data = element.data?.map((item: ISeriesItem) => ({
                            ...item,
                            x: DateTime.fromMillis(item.x).minus({ days: daysCounter }).toMillis(),
                        }));
                    }
                }
                return element;
            });

        return newSeries;
    } catch {
        return series;
    }
};

export default alignSeriesByWeekday;
