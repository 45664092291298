import { MenuGroupHeader, MenuItem, PopoverSurface } from '@fluentui/react-components';
import styled from 'styled-components/macro';

export const StyledPopoverSurface = styled(PopoverSurface)`
    overflow-y: auto !important;
`;

export const StyledMenuGroupHeader = styled(MenuGroupHeader)`
    color: ${(p) => p.theme.colors.primaryLight};
`;

export const StyledMenuItem = styled(MenuItem)`
    outline: none;
    :hover {
        color: var(--color-primary-darker);
    }

    & > span {
        max-width: '150px';
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

    }
`;