import React, { useCallback } from 'react';

import { TDataObjectsFilterTypes } from 'src/hooks/dataObjectsSelectFilters/interfaces';
import { storeActiveTenantId, storeSelectedTenantsRanges } from 'src/Chapters/Sales/PosAnalysis/reducer';
import { IChangeOptionsArgs } from 'src/components/UserSettings/interfaces';
import ModuleWrapper from 'src/components/Wrappers/ModuleWrapper/ModuleWrapper';
import ObjectsSelect from 'src/components/Selects/ObjectsSelect/ObjectsSelect';
import { useGeneralSelector } from 'src/hooks';

import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import Preset from '../../../components/Preset/Preset';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../components/UserSettings/reducer';

import { TenantsPosWidget } from './widgets/TenantsPosWidget';
import { PosAnalyticsWidget } from './widgets/PosAnalyticsWidget/PosAnalyticsWidget';
import { StructureDynamicsWidget } from './widgets/StructureDynamicsWidget';
import { DynamicsPosWidget } from './widgets/DynamicsPosWidget';

const style: React.CSSProperties = { maxWidth: 'none' };

const TENANTS_FILTER = ['data_objects_tenant'] as TDataObjectsFilterTypes[];

export const PosAnalysis: React.FC = () => {
    const { currentModuleID } = useGeneralSelector();
    const dispatch = useAppDispatch();

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const handleChangeOptions = useCallback(
        (args: IChangeOptionsArgs) => {
            dispatch(changeOptions(args));
            dispatch(storeActiveTenantId(null));
            dispatch(storeSelectedTenantsRanges(null));
        },
        [dispatch],
    );

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={handleChangeOptions}
                        />

                        <ObjectsSelect
                            isSelectActiveTenantsIfEmpty
                            canSelectEntireSection
                            dataObjectFilters={TENANTS_FILTER}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={handleChangeOptions}
                            validOptions={['Tenants']}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset currentOptionsKeys={['selectedReportingObjectsIds', 'mainPeriod']} />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>

            <WrapperContainer style={style}>
                <TenantsPosWidget />
                <PosAnalyticsWidget />
                <DynamicsPosWidget />
                <StructureDynamicsWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};
