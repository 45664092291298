/**
 * Список углов
 */

export enum Exclude {
    /**
     * Пустое значение
     */
    Empty = '',
    /**
     * Верхний правый угол
     */
    TopRight = 'top-right',
    /**
     * Верхний левый угол
     */
    TopLeft = 'top-left',
    /**
     * Нижний правый угол
     */
    BottomRight = 'bottom-right',
    /**
     * Нижний левый угол
     */
    BottomLeft = 'bottom-left'
}