import { DateTime } from 'luxon';

export function convertDateToISO(dateString: string): string {
    let isoDate = '';

    if (DateTime.fromISO(dateString).isValid) {
        isoDate = dateString;
    } else if (navigator.language.includes('ru') && DateTime.fromFormat(dateString, 'dd.MM.yyyy').isValid) {
        isoDate = String(DateTime.fromFormat(dateString, 'dd.MM.yyyy').toISODate());
    } else if (navigator.language.includes('en') && DateTime.fromFormat(dateString, 'MM.dd.yyyy').isValid) {
        isoDate = String(DateTime.fromFormat(dateString, 'MM.dd.yyyy').toISODate());
    }

    return isoDate;
}
