import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { downloadSignal, Reports_Advanced_SimplePaymentType_Widget_Reducer_Values } from './reducer';
import { Table, TableWrapper } from './styles';
import ReactDataSheet from 'react-datasheet';
import { withLoading } from '../../../../../tools/API/withLoading';
import ButtonsPanel from '../../../components/ButtonsPanel/ButtonsPanel';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { generalReducerValues } from '../../../../../General.reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import Title from '../../../../../components/Title/Title';
import { useFetchData } from './hooks/useFetchData';
import { DS } from '../../../../../constants/constants';
import { useGetReportFileName } from '../../../../../hooks/useGetReportFileName';

const SimplePaymentType: React.FC = () => {
    const { keyWord } = useSelector(Reports_Advanced_SimplePaymentType_Widget_Reducer_Values);
    const { currentModuleID } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const reportFileName = useGetReportFileName(keyWord, 'Simple payment type report');
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    // const { data } = useFetchData();

    const table = useMemo(() => {
        return null;
    }, []);

    const reportFormat = useMemo(() => {
        return localCurrentOptions?.[`reportFormat${DS}${keyWord}`]?.[0]?.id;
    }, [keyWord, localCurrentOptions]);

    const onDownloadClick = () => {
        dispatch(downloadSignal());
    };

    return (
        <WidgetWrapper styles={{ marginTop: 10 }}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    <Title>{t('Simple payment type report')}</Title>
                </WidgetTitle>
            </WidgetTitleWrapper>
            {reportFormat === 'plain' && <Table>{table}</Table>}
            <ButtonsPanel
                moduleName={`${currentModuleID}`}
                grid={[]}
                onPrepareClick={onDownloadClick}
                keyWord={keyWord}
                reportFileName={reportFileName}
            />
        </WidgetWrapper>
    );
};

export default SimplePaymentType;
