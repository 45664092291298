import { DateTime } from 'luxon';

import { TFunction, TTranslationNS } from 'src/hooks/useTranslation';
import { toString, uniqueArray, isLessThanDays } from 'src/tools';

import { TenantPosInfo, PointOfSale, ReportingObjectsById } from '../interfaces';

const DAYS_INTERVAL_TO_CHECK_POS_ACTIVITY = 30;

const getFirstLastDate = (prevPos: TenantPosInfo, currPos: TenantPosInfo) => {
    const dates = [prevPos.firstDate, prevPos.lastDate, currPos.firstDate, currPos.lastDate]
        .filter(Boolean)
        .map((dateStr) => DateTime.fromISO(dateStr).toMillis());

    return {
        firstDate: DateTime.fromMillis(Math.min(...dates)).toISODate(),
        lastDate: DateTime.fromMillis(Math.max(...dates)).toISODate(),
    };
};

export const posDataByTenantId = (
    results: PointOfSale[],
    reportingObjectsById: ReportingObjectsById,
    t: TFunction<TTranslationNS>,
) => {
    return results.reduce((acc, rawPosData) => {
        const connector = rawPosData.connector;
        const tenantId = rawPosData.connector.tenant_id;

        const tenantName = reportingObjectsById[tenantId].object_name;

        const info = {
            factoryId: toString(rawPosData?.kkt_factory_number || ''), // name todo retrieve from data
            registrationId: rawPosData.kkt_reg_number,
            accumulatorId: rawPosData.fn_factory_number, // Fiscal device POS ID

            dateFrom: connector.date_from || rawPosData.date_from || '',
            dateTo: connector.date_to || rawPosData.date_to || '',

            firstDate: rawPosData?.first_bill_date || '-',
            firstTime: '-',
            lastDate: rawPosData?.last_bill_date || '-',
            lastTime: '-',
            sales: '-',
            receiptsCount: '-',

            tenantId,
            id: rawPosData.id.toString(),
            // id: `${tenantId.toString()}-${rawPosData.connector.id}`,
            tenantName,
            posName: rawPosData.name || '',

            orgName: rawPosData.connector.legal_entity || '',
            inn: rawPosData.connector.tenant_inn,
            posType: t(rawPosData.connector.connector_type || ''), // type todo retrieve from data
            // isPosOnline: rawPosData.connector.is_active,
            rawPosData,
        } as TenantPosInfo;

        const prevData = acc[tenantId] || ([] as TenantPosInfo[]);
        const prevPosData = prevData.find(({ registrationId }) => info.registrationId === registrationId);
        const firstLastReceipts = prevPosData ? getFirstLastDate(prevPosData, info) : undefined;

        const posData = prevPosData
            ? ({
                  ...prevPosData,
                  id: `${prevPosData.id}|${info.id}`,
                  factoryId: uniqueArray([...prevPosData.factoryId.split(','), info.factoryId]).join(','),
                  ...firstLastReceipts,
                  ...(firstLastReceipts?.lastDate && {
                      posActivity: isLessThanDays(firstLastReceipts.lastDate, DAYS_INTERVAL_TO_CHECK_POS_ACTIVITY),
                  }),
              } as TenantPosInfo)
            : {
                  ...info,
                  posActivity: !rawPosData?.last_bill_date
                      ? false
                      : isLessThanDays(rawPosData?.last_bill_date, DAYS_INTERVAL_TO_CHECK_POS_ACTIVITY),
              };

        // const len = posData.factoryId.split(',').length;
        // if (len > 1) {
        // }

        return {
            ...acc,
            [tenantId.toString()]: [
                ...prevData.filter(({ registrationId }) => info.registrationId !== registrationId),
                posData,
            ],
        };
    }, {} as Record<string, TenantPosInfo[]>);
};

export const aggregateField = <T = {}, R = unknown>(entities: T[], valueReducer: (value: T) => R) => {
    return entities.reduce((acc, entity) => [...acc, valueReducer(entity)], [] as R[]);
};
