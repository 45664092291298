import Body from './components/Body/Body';
import Header from './components/Header/Header';
import { TableWrapper, StyledTable } from './styles';

/**
 * Компонент для отображения таблицы
 */
const Table = () => {
    return (
        <TableWrapper>
            <StyledTable>
                <Header />
                <Body />
            </StyledTable>
        </TableWrapper>
    );
};

export default Table;
