import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    padding: 10px;
`;

export const Label = styled.div`
    color: rgba(0, 0, 0, 0.4);
    display: inline-block;
    position: relative;
    margin-bottom: 5px;
`;

export const ItemWrapper = styled.div`
    margin-top: 15px;
    :first-child {
        margin-top: 0px;
    }
`;

export const Text = styled.div`
    margin-top: 15px;
    first-child {
        margin-top: 0px;
    }
    span {
        display: inline-block;
        font-weight: 600;
        margin-right: 15px;
    }
`;
