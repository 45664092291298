import styled from 'styled-components';

export const Wrapper = styled.div`
    border-bottom: 1px solid #dce0e0;
`;

export const Text = styled.div`
    color: var(--color-primary);
    text-transform: capitalize;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    padding: 15px;
    display: flex;
    width: 100%;
`;
