import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues, reloadCategories } from '../../../../../../../../General.reducer';
import { Divider, Footer, Inputs, Wrapper } from './styles';
import { CategoriesAPI } from '../../../../../../../../tools/API/categoriesAPI';
import Button from '../../../../../../../../components/Button/Button';
import TextInput from '../../../../../../../../components/TextInput/TextInput';
import Select from '../../../../../../../../components/Selects/Select/Select';
import { TSelectedOptions } from '../../../../../../../../components/Selects/Select/interfaces';
import axios from 'axios';
import { toggleLoader } from '../../../reducer';

const ModalAddSubCategory: React.FC<{
    handleOKClick: () => void;
    modalStatus: { show: boolean; parentId?: number | null; mode?: string };
}> = ({ modalStatus, handleOKClick }) => {
    const [name, setName] = useState('');
    const [standardName, setStandardName] = useState<string | null>(null);
    const {
        token,
        user,
        selectedProjectId,
        urlsByServices,
        currentModuleID,
        src: { standardCategories, projectCategories },
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const categoriesUrl = useMemo(() => {
        if (urlsByServices?.['core/admin-service']) return urlsByServices['core/admin-service'].CATEGORIES_URL;
        return null;
    }, [urlsByServices]);

    const handleChange = (args: { value: string }) => {
        setName(() => args.value);
    };

    const existingCategoriesNames = useMemo(() => {
        const projectCategoriesNames = projectCategories
            .filter((item) => !item.parent_id)
            .map((item) => item.object_name.toLowerCase());
        return projectCategoriesNames;
    }, [projectCategories]);

    const allCategoriesNames = useMemo(() => {
        const standartNames = standardCategories.map((item) => item.name.toLowerCase());
        const projectCategoriesNames = projectCategories
            .filter((item) => !item.parent_id)
            .map((item) => item.object_name.toLowerCase());
        return [...standartNames, ...projectCategoriesNames];
    }, [projectCategories, standardCategories]);

    const standardCategoriesOptions = useMemo(() => {
        return standardCategories
            .filter((item) => !existingCategoriesNames.includes(item.name.toLowerCase()))
            .map((item) => ({
                id: item.name,
                text: item.name,
            }));
    }, [existingCategoriesNames, standardCategories]);

    const onOKClick = useCallback(() => {
        if (!user?.permissions.includes('app/client | modules/configuration/categories | feature/edit')) {
            return;
        }
        if (name && modalStatus.parentId !== undefined && selectedProjectId && categoriesUrl) {
            CategoriesAPI({
                user,
                method: 'POST',
                token,
                chapter: 'project-categories',
                url: categoriesUrl,
                data: {
                    object_name: name,
                    parent_id: modalStatus?.parentId,
                    project_id: selectedProjectId,
                    is_active: true,
                },
            }).then(() => {
                dispatch(reloadCategories());
            });
        } else if (!name && standardName && modalStatus.parentId !== undefined && selectedProjectId && categoriesUrl) {
            dispatch(toggleLoader(true));
            CategoriesAPI({
                user,
                method: 'POST',
                token,
                chapter: 'project-categories',
                url: categoriesUrl,
                data: {
                    object_name: standardName,
                    parent_id: modalStatus?.parentId,
                    project_id: selectedProjectId,
                    is_active: true,
                },
            }).then(async (response) => {
                if (response.status) {
                    const parentId = response.data.id;
                    const requests = standardCategories
                        .filter((item) => item.name === standardName)[0]
                        .items.map((item) => {
                            return CategoriesAPI({
                                user,
                                method: 'POST',
                                token,
                                chapter: 'project-categories',
                                url: categoriesUrl,
                                data: {
                                    object_name: item.name,
                                    parent_id: parentId,
                                    project_id: selectedProjectId,
                                    is_active: true,
                                },
                            });
                        });

                    await axios.all(requests).then((res) => {
                        dispatch(reloadCategories());
                        dispatch(toggleLoader(false));
                    });
                }
            });
        }
        setName(() => '');
        handleOKClick();
    }, [
        categoriesUrl,
        dispatch,
        handleOKClick,
        modalStatus.parentId,
        name,
        selectedProjectId,
        standardCategories,
        standardName,
        token,
    ]);

    const handleSelect = (args: TSelectedOptions) => {
        args[0].id && setStandardName(() => String(args[0].id));
    };

    return (
        <Wrapper>
            <Inputs>
                {/* {standardCategoriesOptions.length && modalStatus.mode === 'category' ? (
                    <Select
                        testId={`${currentModuleID}_standardCategoriesOptions`}
                        iconType="chevron-down"
                        text={t('Select standard category')}
                        mode="single"
                        showFilter={false}
                        options={standardCategoriesOptions}
                        handleSelect={handleSelect}
                        disabled={!!name}
                    />
                ) : null}
                {standardCategoriesOptions.length && modalStatus.mode === 'category' ? (
                    <Divider>{t('or')}</Divider>
                ) : null} */}
                <TextInput
                    placeholder={t('Type custom name')}
                    handleChange={handleChange}
                    outsideValue={name}
                    hasError={allCategoriesNames.includes(name.toLowerCase())}
                />
            </Inputs>
            <Footer>
                <Button
                    appearance="primary"
                    text={t('Add')}
                    onClick={onOKClick}
                    disabled={allCategoriesNames.includes(name.toLowerCase())}
                />
            </Footer>
        </Wrapper>
    );
};

export default ModalAddSubCategory;
