import { DateTime } from 'luxon';

interface IArgs {
    /** Тип значения, которое будет плюсовать при итерации */
    durationType: 'months' | 'days';
    /** Тип даты, за которую нужно получить все значения типа durationType */
    mode: 'year' | 'month';
    /** Дата */
    date: DateTime;
}

/**
 * Функция для получения всех значений типа (durationType) за переданную дату
 */
const getRanges = (args: IArgs): DateTime[] => {
    const { date, mode, durationType } = args;
    const result: DateTime[] = [];
    let cursor = date.startOf(mode);
    while (cursor < date.endOf(mode)) {
        result.push(cursor);
        cursor = cursor.plus({ [durationType]: 1 });
    }
    return result;
};

export default getRanges;
