import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Maps_MapsOverview_Module_Reducer_Values, storeColorData } from '../../../reducer';
import { IColorsByMarker } from '../../../interfaces';
import { cloneDeep, isNumber } from 'lodash';
import { getFiltered } from '../tools/getFiltered';
import { generalReducerValues } from '../../../../../../General.reducer';
import { HSL2RGB } from '../../../../../../tools/hls2rgb';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { filterValidDateRanges } from '../../../../../../tools/filterValidDateRanges';
import getDifferenceBetweenNumbers from '../../../../../../tools/getDifferenceBetweenNumbers';
import { IMetricResponseItem } from '../../../../../../General.interfaces';
import { DS } from '../../../../../../constants/constants';

export const usePrepareData = () => {
    const {
        cfg: { reportingObjectsByType },
        structures,
        selectedLocationId,
    } = useSelector(generalReducerValues);
    const {
        selectedObjectType,
        selectedGroupId,
        selectedZoneId,
        zonesByGroup,
        selectedPlaceId,
        metricsData,
        selectedRelationId,
        moduleName,
    } = useSelector(Maps_MapsOverview_Module_Reducer_Values);

    const dispatch = useDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    const showComparison: Boolean = localCurrentOptions?.['mapsSettings']
        .map((item: { id: string }) => item.id)
        ?.includes('comparison');

    useEffect(() => {
        dispatch(storeColorData(null));

        if (!structures?.relations_passway2dataobj || !structures?.['relations_tenant2place']) return;
        const opacity = selectedObjectType !== 'passway' ? 0.6 : 1;

        if (!Array.isArray(metricsData)) {
            dispatch(storeColorData(null));
            return;
        }

        const mainPeriod = localCurrentOptions?.mainPeriod?.id;
        const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];
        const period = mainDateRange?.period;

        const filteredPassway2Dataobj = filterValidDateRanges(structures?.['relations_passway2dataobj'], period);
        const validTenantIds = filterValidDateRanges(structures?.['relations_tenant2place'], period).map(
            (item) => item.tenant_id,
        );

        let dataMetrics: any[] = cloneDeep(metricsData);

        if (selectedObjectType === 'passway') {
            if (selectedRelationId === 'zone' && selectedGroupId) {
                const zoneMarker = zonesByGroup[selectedGroupId]?.filter(
                    (item) => String(item.id) === String(selectedZoneId),
                )[0].marker;
                const allowedPasswayMarkers: string[] = filteredPassway2Dataobj
                    ?.filter((item) => item.dataobj_type === 'zone' && item.dataobj_marker === zoneMarker)
                    .map((item) => item.passway_marker);
                dataMetrics = getFiltered(dataMetrics, allowedPasswayMarkers);
            } else if (selectedRelationId === 'place' && selectedPlaceId) {
                const placeMarker = reportingObjectsByType['place']?.filter(
                    (item) => String(item.id) === String(selectedPlaceId),
                )[0].marker;
                const allowedPasswayMarkers: string[] = filteredPassway2Dataobj
                    ?.filter((item) => item.dataobj_type === 'place' && item.dataobj_marker === placeMarker)
                    .map((item) => item.passway_marker);
                dataMetrics = getFiltered(dataMetrics, allowedPasswayMarkers);
            } else if (selectedRelationId === 'floor') {
                const allowedPasswayMarkers: string[] = filteredPassway2Dataobj
                    ?.filter((item) => item.dataobj_type === 'floor')
                    .map((item) => item.passway_marker);
                dataMetrics = getFiltered(dataMetrics, allowedPasswayMarkers);
            } else if (selectedRelationId === 'location') {
                const allowedPasswayMarkers: string[] = filteredPassway2Dataobj
                    ?.filter((item) => item.dataobj_type === 'location')
                    .map((item) => item.passway_marker);
                dataMetrics = getFiltered(dataMetrics, allowedPasswayMarkers);
            }
        }

        if (selectedObjectType === 'tenant' && dataMetrics[0]) {
            dataMetrics = dataMetrics.map((metric) => {
                return metric?.filter((item: any) => {
                    return validTenantIds.includes(item?.context?.data_objects[0].id);
                });
            });
        }

        const colorsByMarker: IColorsByMarker = {};

        if (!showComparison) {
            dataMetrics.forEach((metric: IMetricResponseItem[]) => {
                const alias = metric?.[0]?.context?.alias?.split(DS)?.[0] || '';
                const data = {};
                metric.forEach((obj) => {
                    const marker = obj.context.data_objects[0].marker;
                    data[marker] = { value: obj.items[0].value };
                });
                const values = Object.values(data).map((item: any) => Number(item.value));

                const maxValue = Math.max(...values);
                const minValue = Math.min(...values);
                metric.forEach((obj) => {
                    const id = obj.context.data_objects[0].id;
                    const name = obj.context.data_objects[0].name;
                    let marker = obj.context.data_objects[0].marker;

                    const value = obj.items[0].value;
                    const normValue = isNumber(value) ? (value - minValue) / (maxValue - minValue) : 0;

                    let heatColor = HSL2RGB(normValue * 130, 200, 30, opacity);
                    if (isNaN(normValue)) {
                        heatColor = 'rgba(0, 0, 0, 0.2)';
                    }
                    // const heatColor = HSL2RGB(240 - normValue * 240, 100, 40, opacity);
                    data[marker] = { normValue, heatColor, value, id, name, marker };
                });
                colorsByMarker[alias] = data;
            });
        } else {
            dataMetrics.forEach((metric: IMetricResponseItem[]) => {
                const alias = metric[0]?.context.alias?.split(DS)[0] || '';
                const period = metric[0]?.context.alias?.split(DS)[1] || '';
                if (period === 'main') return;
                const data = {};
                const mainMetric: IMetricResponseItem[] = dataMetrics?.find((item) => {
                    const mainAlias = item[0]?.context.alias?.split(DS)[0] || '';
                    const period = item[0]?.context.alias?.split(DS)[1] || '';
                    return mainAlias === alias && period === 'main';
                });
                metric.forEach((obj) => {
                    const compareId = obj.context.data_objects[0].id;
                    const name = obj.context.data_objects[0].name;
                    let marker = obj.context.data_objects[0].marker;
                    const mainObj = mainMetric?.find((item) => item.context.data_objects[0].id === compareId);
                    const mainValue = mainObj?.items[0].value;
                    const value =
                        mainValue && obj.items[0].value
                            ? getDifferenceBetweenNumbers(mainValue, obj.items[0].value).percentDifference
                            : undefined;

                    if (value !== undefined) {
                        data[marker] = {
                            marker,
                            id: compareId,
                            name,
                            value,
                        };
                    }
                });
                const values = Object.values(data)
                    ?.filter((item: any) => item.value !== undefined)
                    .map((item: any) => Number(item.value));
                let maxValue = Math.max(...values.map((item) => Math.abs(item)));
                if (maxValue > 50) maxValue = 50;
                const minValue = -maxValue;

                Object.keys(data).forEach((key) => {
                    const obj = data[key];
                    const value = Math.abs(obj.value) <= 50 ? obj.value : Math.sign(obj.value) * 50;
                    const normValue = (value - minValue) / (maxValue - minValue) || 0;

                    const heatColor = HSL2RGB(normValue * 130, 200, 30, opacity);
                    // const heatColor = HSL2RGB(240 - normValue * 240, 100, 40, opacity);
                    data[key] = { ...obj, heatColor, normValue };
                });

                colorsByMarker[alias] = data;
            });
        }

        dispatch(storeColorData(colorsByMarker));
    }, [
        localCurrentOptions?.mainPeriod?.id,
        structures?.['relations_tenant2place'],
        selectedLocationId,
        dispatch,
        metricsData,
        selectedGroupId,
        selectedObjectType,
        showComparison,
        structures,
        selectedRelationId,
        zonesByGroup,
        selectedZoneId,
        reportingObjectsByType,
        selectedPlaceId,
    ]);
};
