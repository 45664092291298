import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IExtendedEvent } from '../../../../../../../../interfaces';
import { Item, Wrapper } from './styles';

interface IProps {
    /** Обработчик нажатия на кнопку редактировать событие */
    onEditClick: (id: number) => void;
    /** Событие */
    event: IExtendedEvent;
}

const EventContextualMenuBody: FC<IProps> = ({ event, onEditClick }) => {
    const { t } = useTranslation();

    const editHandler = () => {
        onEditClick(event.id);
    };

    return (
        <Wrapper>
            <Item onClick={editHandler}>{t('Edit')}</Item>
        </Wrapper>
    );
};

export default EventContextualMenuBody;
