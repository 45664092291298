import type { WeekInfo } from 'src/types';

export const weekInfoData: Record<string, WeekInfo> = {
    aa: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'aa-DJ': { firstDay: 6, weekend: [6, 7], minimalDays: 1 },
    'aa-ER': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ab: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    af: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'af-NA': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    agq: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ak: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    am: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    an: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    ann: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    apc: { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    ar: { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ar-AE': { firstDay: 6, weekend: [6, 7], minimalDays: 1 },
    'ar-BH': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ar-DJ': { firstDay: 6, weekend: [6, 7], minimalDays: 1 },
    'ar-DZ': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ar-EG': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ar-EH': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ar-ER': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ar-IL': { firstDay: 7, weekend: [5, 6], minimalDays: 1 },
    'ar-IQ': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ar-JO': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ar-KM': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ar-KW': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ar-LB': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ar-LY': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ar-MA': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ar-MR': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ar-OM': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ar-PS': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ar-QA': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ar-SA': { firstDay: 7, weekend: [5, 6], minimalDays: 1 },
    'ar-SD': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ar-SO': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ar-SS': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ar-SY': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ar-TD': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ar-TN': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ar-YE': { firstDay: 7, weekend: [5, 6], minimalDays: 1 },
    arn: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    as: { firstDay: 7, weekend: [7], minimalDays: 1 },
    asa: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ast: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    az: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'az-Arab': { firstDay: 6, weekend: [5], minimalDays: 1 },
    'az-Arab-IQ': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'az-Arab-TR': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'az-Cyrl': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'az-Latn': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ba: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    bal: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'bal-Arab': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'bal-Latn': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    bas: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    be: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'be-tarask': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    bem: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    bew: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    bez: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    bg: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    bgc: { firstDay: 7, weekend: [7], minimalDays: 1 },
    bgn: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'bgn-AE': { firstDay: 6, weekend: [6, 7], minimalDays: 1 },
    'bgn-AF': { firstDay: 6, weekend: [4, 5], minimalDays: 1 },
    'bgn-IR': { firstDay: 6, weekend: [5], minimalDays: 1 },
    'bgn-OM': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    bho: { firstDay: 7, weekend: [7], minimalDays: 1 },
    blo: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    blt: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    bm: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'bm-Nkoo': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    bn: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'bn-IN': { firstDay: 7, weekend: [7], minimalDays: 1 },
    bo: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'bo-IN': { firstDay: 7, weekend: [7], minimalDays: 1 },
    br: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    brx: { firstDay: 7, weekend: [7], minimalDays: 1 },
    bs: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'bs-Cyrl': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'bs-Latn': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    bss: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    byn: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ca: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'ca-AD': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'ca-ES-valencia': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'ca-FR': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'ca-IT': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    cad: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    cch: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ccp: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'ccp-IN': { firstDay: 7, weekend: [7], minimalDays: 1 },
    ce: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    ceb: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    cgg: { firstDay: 1, weekend: [7], minimalDays: 1 },
    cho: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    chr: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    cic: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    ckb: { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ckb-IR': { firstDay: 6, weekend: [5], minimalDays: 1 },
    co: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    cs: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    csw: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    cu: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    cv: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    cy: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    da: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'da-GL': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    dav: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    de: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'de-AT': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'de-BE': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'de-CH': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'de-IT': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'de-LI': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'de-LU': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    dje: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    doi: { firstDay: 7, weekend: [7], minimalDays: 1 },
    dsb: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    dua: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    dv: { firstDay: 5, weekend: [6, 7], minimalDays: 1 },
    dyo: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    dz: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    ebu: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    ee: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ee-TG': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    el: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'el-CY': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'el-polyton': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    en: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-001': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-150': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-AE': { firstDay: 6, weekend: [6, 7], minimalDays: 1 },
    'en-AG': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-AI': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-AS': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-AT': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-AU': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-BB': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-BE': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-BI': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-BM': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-BS': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-BW': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-BZ': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-CA': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-CC': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-CH': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-CK': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-CM': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-CX': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-CY': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-DE': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-DG': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-DK': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-DM': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-Dsrt': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-ER': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-FI': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-FJ': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-FK': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-FM': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-GB': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-GD': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-GG': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-GH': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-GI': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-GM': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-GU': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-GY': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-HK': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-ID': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-IE': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-IL': { firstDay: 7, weekend: [5, 6], minimalDays: 1 },
    'en-IM': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-IN': { firstDay: 7, weekend: [7], minimalDays: 1 },
    'en-IO': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-JE': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-JM': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-KE': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-KI': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-KN': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-KY': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-LC': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-LR': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-LS': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-MG': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-MH': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-MO': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-MP': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-MS': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-MT': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-MU': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-MV': { firstDay: 5, weekend: [6, 7], minimalDays: 1 },
    'en-MW': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-MY': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-NA': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-NF': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-NG': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-NL': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-NR': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-NU': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-NZ': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-PG': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-PH': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-PK': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-PN': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-PR': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-PW': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-RW': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-SA': { firstDay: 7, weekend: [5, 6], minimalDays: 1 },
    'en-SB': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-SC': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-SD': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'en-SE': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-SG': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-SH': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-SI': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-SL': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-SS': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-SX': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-SZ': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-Shaw': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'en-TC': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-TK': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-TO': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-TT': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-TV': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-TZ': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-UG': { firstDay: 1, weekend: [7], minimalDays: 1 },
    'en-UM': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-VC': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-VG': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-VI': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-VU': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-WS': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-ZA': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'en-ZM': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'en-ZW': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    eo: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    es: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'es-419': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'es-AR': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'es-BO': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'es-BR': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-BZ': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-CL': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'es-CO': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-CR': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'es-CU': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'es-DO': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-EA': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'es-EC': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'es-GQ': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'es-GT': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-HN': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-IC': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'es-MX': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-NI': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-PA': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-PE': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-PH': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-PR': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-PY': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-SV': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-US': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'es-UY': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'es-VE': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    et: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    eu: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    ewo: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    fa: { firstDay: 6, weekend: [5], minimalDays: 1 },
    'fa-AF': { firstDay: 6, weekend: [4, 5], minimalDays: 1 },
    ff: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Adlm': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Adlm-BF': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Adlm-CM': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Adlm-GH': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Adlm-GM': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Adlm-GW': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Adlm-LR': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Adlm-MR': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Adlm-NE': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Adlm-NG': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Adlm-SL': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Adlm-SN': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Latn': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Latn-BF': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Latn-CM': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Latn-GH': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Latn-GM': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Latn-GN': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Latn-GW': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Latn-LR': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Latn-MR': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Latn-NE': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Latn-NG': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ff-Latn-SL': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    fi: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    fil: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    fo: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'fo-DK': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    fr: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'fr-BE': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'fr-BF': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-BI': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-BJ': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-BL': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-CA': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'fr-CD': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-CF': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-CG': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-CH': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'fr-CI': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-CM': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-DJ': { firstDay: 6, weekend: [6, 7], minimalDays: 1 },
    'fr-DZ': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'fr-GA': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-GF': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'fr-GN': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-GP': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'fr-GQ': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-HT': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-KM': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-LU': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'fr-MA': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-MC': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'fr-MF': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-MG': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-ML': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-MQ': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'fr-MR': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-MU': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-NC': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-NE': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-PF': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-PM': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-RE': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'fr-RW': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-SC': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-SN': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-SY': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'fr-TD': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-TG': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-TN': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-VU': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-WF': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'fr-YT': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    frr: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    fur: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    fy: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    ga: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'ga-GB': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    gaa: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    gd: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    gez: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'gez-ER': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    gl: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    gn: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    gsw: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'gsw-FR': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'gsw-LI': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    gu: { firstDay: 7, weekend: [7], minimalDays: 1 },
    guz: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    gv: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    ha: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ha-Arab': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ha-Arab-SD': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'ha-GH': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ha-NE': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    haw: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    he: { firstDay: 7, weekend: [5, 6], minimalDays: 1 },
    hi: { firstDay: 7, weekend: [7], minimalDays: 1 },
    'hi-Latn': { firstDay: 7, weekend: [7], minimalDays: 1 },
    hnj: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'hnj-Hmnp': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    hr: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'hr-BA': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    hsb: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    hu: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    hy: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ia: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    id: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    ie: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    ig: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ii: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    io: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    is: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    it: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'it-CH': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'it-SM': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'it-VA': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    iu: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'iu-Latn': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    ja: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    jbo: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    jgo: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    jmc: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    jv: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    ka: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    kab: { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    kaj: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    kam: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    kcg: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    kde: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    kea: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ken: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    kgp: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    khq: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ki: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    kk: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    kkj: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    kl: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    kln: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    km: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    kn: { firstDay: 7, weekend: [7], minimalDays: 1 },
    ko: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'ko-CN': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ko-KP': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    kok: { firstDay: 7, weekend: [7], minimalDays: 1 },
    kpe: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'kpe-GN': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ks: { firstDay: 7, weekend: [7], minimalDays: 1 },
    'ks-Arab': { firstDay: 7, weekend: [7], minimalDays: 1 },
    'ks-Deva': { firstDay: 7, weekend: [7], minimalDays: 1 },
    ksb: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ksf: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ksh: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    ku: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    kw: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    kxv: { firstDay: 7, weekend: [7], minimalDays: 1 },
    'kxv-Deva': { firstDay: 7, weekend: [7], minimalDays: 1 },
    'kxv-Latn': { firstDay: 7, weekend: [7], minimalDays: 1 },
    'kxv-Orya': { firstDay: 7, weekend: [7], minimalDays: 1 },
    'kxv-Telu': { firstDay: 7, weekend: [7], minimalDays: 1 },
    ky: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    la: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    lag: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    lb: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    lg: { firstDay: 1, weekend: [7], minimalDays: 1 },
    lij: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    lkt: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    lmo: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    ln: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ln-AO': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ln-CF': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ln-CG': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    lo: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    lrc: { firstDay: 6, weekend: [5], minimalDays: 1 },
    'lrc-IQ': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    lt: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    lu: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    luo: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    luy: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    lv: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    mai: { firstDay: 7, weekend: [7], minimalDays: 1 },
    mas: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'mas-TZ': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    mdf: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    mer: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    mfe: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    mg: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    mgh: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    mgo: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    mi: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    mic: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    mk: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ml: { firstDay: 7, weekend: [7], minimalDays: 1 },
    mn: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'mn-Mong': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'mn-Mong-MN': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    mni: { firstDay: 7, weekend: [7], minimalDays: 1 },
    'mni-Beng': { firstDay: 7, weekend: [7], minimalDays: 1 },
    'mni-Mtei': { firstDay: 7, weekend: [7], minimalDays: 1 },
    moh: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    mr: { firstDay: 7, weekend: [7], minimalDays: 1 },
    ms: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ms-Arab': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ms-Arab-BN': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ms-BN': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ms-ID': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'ms-SG': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    mt: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    mua: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    mus: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    my: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    myv: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    mzn: { firstDay: 6, weekend: [5], minimalDays: 1 },
    naq: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    nb: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'nb-SJ': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    nd: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    nds: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'nds-NL': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    ne: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'ne-IN': { firstDay: 7, weekend: [7], minimalDays: 1 },
    nl: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'nl-AW': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'nl-BE': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'nl-BQ': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'nl-CW': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'nl-SR': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'nl-SX': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    nmg: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    nn: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    nnh: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    no: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    nqo: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    nr: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    nso: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    nus: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    nv: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    ny: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    nyn: { firstDay: 1, weekend: [7], minimalDays: 1 },
    oc: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'oc-ES': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    om: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'om-KE': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    or: { firstDay: 7, weekend: [7], minimalDays: 1 },
    os: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'os-RU': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    osa: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    pa: { firstDay: 7, weekend: [7], minimalDays: 1 },
    'pa-Arab': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'pa-Guru': { firstDay: 7, weekend: [7], minimalDays: 1 },
    pap: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'pap-AW': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    pcm: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    pis: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    pl: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    prg: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    ps: { firstDay: 6, weekend: [4, 5], minimalDays: 1 },
    'ps-PK': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    pt: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'pt-AO': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'pt-CH': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'pt-CV': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'pt-GQ': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'pt-GW': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'pt-LU': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'pt-MO': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'pt-MZ': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'pt-PT': { firstDay: 7, weekend: [6, 7], minimalDays: 4 },
    'pt-ST': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'pt-TL': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    qu: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'qu-BO': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'qu-EC': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    quc: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    raj: { firstDay: 7, weekend: [7], minimalDays: 1 },
    rhg: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'rhg-Rohg': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'rhg-Rohg-BD': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    rif: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    rm: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    rn: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ro: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ro-MD': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    rof: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ru: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'ru-BY': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ru-KG': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ru-KZ': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ru-MD': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ru-UA': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    rw: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    rwk: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    sa: { firstDay: 7, weekend: [7], minimalDays: 1 },
    sah: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    saq: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    sat: { firstDay: 7, weekend: [7], minimalDays: 1 },
    'sat-Deva': { firstDay: 7, weekend: [7], minimalDays: 1 },
    'sat-Olck': { firstDay: 7, weekend: [7], minimalDays: 1 },
    sbp: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    sc: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    scn: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    sd: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'sd-Arab': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'sd-Deva': { firstDay: 7, weekend: [7], minimalDays: 1 },
    sdh: { firstDay: 6, weekend: [5], minimalDays: 1 },
    'sdh-IQ': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    se: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'se-FI': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'se-SE': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    seh: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    ses: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    sg: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    shi: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'shi-Latn': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'shi-Tfng': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    shn: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'shn-TH': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    si: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    sid: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    sk: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    skr: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    sl: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    sma: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'sma-NO': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    smj: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'smj-NO': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    smn: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    sms: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    sn: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    so: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'so-DJ': { firstDay: 6, weekend: [6, 7], minimalDays: 1 },
    'so-ET': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'so-KE': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    sq: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'sq-MK': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'sq-XK': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    sr: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'sr-Cyrl': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'sr-Cyrl-BA': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'sr-Cyrl-ME': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'sr-Cyrl-XK': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'sr-Latn': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'sr-Latn-BA': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'sr-Latn-ME': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'sr-Latn-XK': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ss: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'ss-SZ': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ssy: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    st: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'st-LS': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    su: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'su-Latn': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    sv: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'sv-AX': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    'sv-FI': { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    sw: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'sw-CD': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'sw-KE': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'sw-UG': { firstDay: 1, weekend: [7], minimalDays: 1 },
    syr: { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    'syr-SY': { firstDay: 6, weekend: [5, 6], minimalDays: 1 },
    szl: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    ta: { firstDay: 7, weekend: [7], minimalDays: 1 },
    'ta-LK': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ta-MY': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'ta-SG': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    te: { firstDay: 7, weekend: [7], minimalDays: 1 },
    teo: { firstDay: 1, weekend: [7], minimalDays: 1 },
    'teo-KE': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    tg: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    th: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    ti: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'ti-ER': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    tig: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    tk: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    tn: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'tn-BW': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    to: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    tok: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    tpi: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    tr: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'tr-CY': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    trv: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    trw: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    ts: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    tt: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    twq: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    tyv: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    tzm: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ug: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    uk: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    und: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    ur: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'ur-IN': { firstDay: 7, weekend: [7], minimalDays: 1 },
    uz: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'uz-Arab': { firstDay: 6, weekend: [4, 5], minimalDays: 1 },
    'uz-Cyrl': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'uz-Latn': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    vai: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'vai-Latn': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'vai-Vaii': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    ve: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    vec: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    vi: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    vmw: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    vo: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    vun: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    wa: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    wae: { firstDay: 1, weekend: [6, 7], minimalDays: 4 },
    wal: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    wbp: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    wo: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    xh: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    xnr: { firstDay: 7, weekend: [7], minimalDays: 1 },
    xog: { firstDay: 1, weekend: [7], minimalDays: 1 },
    yav: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    yi: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    yo: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'yo-BJ': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    yrl: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'yrl-CO': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'yrl-VE': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    yue: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'yue-Hans': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'yue-Hant': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    za: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    zgh: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    zh: { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'zh-Hans': { firstDay: 1, weekend: [6, 7], minimalDays: 1 },
    'zh-Hans-HK': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'zh-Hans-MO': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'zh-Hans-SG': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'zh-Hant': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'zh-Hant-HK': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    'zh-Hant-MO': { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
    zu: { firstDay: 7, weekend: [6, 7], minimalDays: 1 },
};