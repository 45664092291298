import styled from 'styled-components/macro';

export const AlertContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
    color: var(--color-secondary-darker);
`;

export const PanelHeader = styled.div`
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: var(--space-xxs);
    margin-top: 0;
`;

export const PanelTitle = styled.div`
    display: flex;
    align-items: center;
    margin-right: var(--space-xs);
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 6px;
`;

export const Controls = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;
