import { FC, useState } from 'react';

import Stack from '../../../../../../../components/Stack/Stack';
import ReportingObjectsTabs from '../ReportingObjectsTabs/ReportingObjectsTabs';
import useFetchData from '../../api/useFetchData';
import Dynamics from '../../../../../../../components/Charts/Dynamics/Dynamics';

import { IProps } from './interfaces';

const Chart: FC<IProps> = ({ onOutsideSeriesChange, ...props }) => {
    const { data } = useFetchData(props);

    const [selectedReportingObjectId, setSelectedReportingObjectId] = useState(props.reportingObjectsIds[0]);

    const onReportingObjectSelect = (id: number) => {
        setSelectedReportingObjectId(id);
    };

    return (
        <Stack direction="column" gap={15}>
            <ReportingObjectsTabs
                selectedReportingObjectId={selectedReportingObjectId}
                reportingObjectsIds={props.reportingObjectsIds}
                onSelect={onReportingObjectSelect}
            />
            {data?.chartOptionsByReportingObjectId?.[selectedReportingObjectId] && (
                <div>
                    <Dynamics
                        onOutsideSeriesChange={onOutsideSeriesChange}
                        {...data.chartOptionsByReportingObjectId[selectedReportingObjectId]}
                    />
                </div>
            )}
        </Stack>
    );
};

export default Chart;
