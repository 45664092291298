import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IPeriod, IPeriodObj } from '../../../General.interfaces';
import { generalReducerValues } from '../../../General.reducer';
import { stringDate } from '../../../tools/Strings/stringDate';
import { userSettingsReducerValues, changeOptions } from '../../UserSettings/reducer';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import Select from '../Select/Select';
import Modals from '../../Modals/Modals';
import DateRangePicker from '../../DateRangePicker/DateRangePicker';
import { useAppDispatch } from '../../../hooks/useSettings';
import { appendRest, getRest } from '../../../hooks/useCurrentOptions';
import { shortStringDate } from '../../../tools/Strings/shortStringDate';

const ComparePeriodSelect: React.FC<IProps> = ({
    mode = 'multi',
    validOptions = [],
    name = 'comparePeriods',
    oneDayOnly = false,
    defaultPeriods,
    yearsOnly = false,
    maxSelected = 3,
    localCurrentOptions,
    changeOptions,
    ...props
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [modalStatus, setModalStatus] = useState({ show: false });
    const [outsideCloseDropdown, setOutsideCloseDropdown] = useState({ close: false });
    const { currentOptions } = useSelector(userSettingsReducerValues);
    const { lang, currentModuleID, selectedLocationId } = useSelector(generalReducerValues);
    const comparePeriods = localCurrentOptions?.[name];
    const localCurrentModule = localCurrentOptions?.['currentModuleID'];

    useEffect(() => {
        if (!comparePeriods?.length && selectedLocationId && localCurrentModule === currentModuleID) {
            if (!defaultPeriods) {
                changeOptions({
                    newOptions: {
                        [name]: [{ id: 'sameMonthdays' }],
                    },
                });
            } else {
                changeOptions({
                    newOptions: {
                        [name]: defaultPeriods.map((item) => ({ id: item })),
                    },
                });
            }
        }
    }, [
        comparePeriods?.length,
        currentModuleID,
        JSON.stringify(defaultPeriods),
        dispatch,
        localCurrentModule,
        selectedLocationId,
    ]);

    const compareDateRanges = useMemo(() => {
        const rest = getRest(name);
        const rangesKey = appendRest('compareDateRanges', rest);
        return currentOptions[rangesKey];
    }, [JSON.stringify(currentOptions), name]);

    const outsideSelected = compareDateRanges?.filter((item: { id: string }) =>
        comparePeriods?.find((p: { id: string }) => p.id === item.id),
    );

    const options =
        validOptions.length === 0
            ? compareDateRanges
            : compareDateRanges?.filter((range: { id: string }) => validOptions.includes(range.id));

    const storePeriods = (
        x: {
            id: string | number | null;
            period?: IPeriodObj;
        }[],
    ) => {
        selectedLocationId &&
            changeOptions({
                newOptions: {
                    [name]: x as IPeriod[],
                },
            });
    };

    const handleSelect = (args: { id: string | null | number; period?: IPeriodObj }[]) => {
        if (mode === 'multi') {
            const comparePeriods = args.map((item) => {
                if (item.id === 'selectCustom') {
                    return { id: item.id, period: item?.period };
                } else {
                    return { id: item.id };
                }
            });
            props.handleSelect && props.handleSelect(comparePeriods);
            storePeriods(comparePeriods);
            setModalStatus({ show: false });
        } else if (mode === 'single') {
            if (args[0].id === 'selectCustom') {
                setModalStatus({ show: true });
            } else {
                props.handleSelect && props.handleSelect([{ id: args[0].id }]);
                storePeriods([{ id: args[0].id }]);
                setModalStatus({ show: false });
            }
        }
    };

    const closeModal = () => {
        const isCustomPeriodSelected = comparePeriods?.some((item: { id: string }) => item.id === 'selectCustom');
        if (!isCustomPeriodSelected) {
            comparePeriods &&
                props.handleSelect &&
                props.handleSelect([...comparePeriods] as {
                    id: string | number | null;
                    period?: IPeriodObj;
                }[]);
            comparePeriods &&
                storePeriods(
                    comparePeriods as {
                        id: string | number | null;
                        period?: IPeriodObj;
                    }[],
                );
        }
        setModalStatus({ show: false });
    };

    const handleCheck = (args: { id: string | null | number; period?: string[] } & { checked: boolean }) => {
        if (args.id === 'selectCustom' && args.checked) {
            setModalStatus({ show: true });
        }
    };

    const handleDateRangeChange = (period: { dateFrom: string; dateTo: string }) => {
        period &&
            comparePeriods &&
            props.handleSelect &&
            props.handleSelect([
                ...comparePeriods?.filter((item: { id: string }) => item.id !== 'selectCustom'),
                { id: 'selectCustom', period },
            ] as {
                id: string | number | null;
                period?: IPeriodObj;
            }[]);
        comparePeriods &&
            mode === 'multi' &&
            storePeriods([
                ...comparePeriods?.filter((item: { id: string }) => item.id !== 'selectCustom'),
                { id: 'selectCustom', period },
            ] as {
                id: string | number | null;
                period?: IPeriodObj;
            }[]);
        comparePeriods &&
            mode === 'single' &&
            storePeriods([{ id: 'selectCustom', period }] as {
                id: string | number | null;
                period?: IPeriodObj;
            }[]);
        setModalStatus({ show: false });
        setOutsideCloseDropdown({ close: true });
    };

    const getOutsideText = (period: IPeriod) => {
        if (!period) return '';
        const dates = compareDateRanges?.find((item: { id: string }) => item.id === period.id)?.period;
        return shortStringDate({ dateFrom: dates?.dateFrom, dateTo: dates?.dateTo }, lang);

        // if (dates?.dateFrom === dates?.dateTo) {
        //     return stringDate(dates?.dateFrom, lang);
        // } else {
        //     return `${stringDate(dates?.dateFrom, lang)} – ${stringDate(dates?.dateTo, lang)}`;
        // }
    };

    return (
        <Wrapper>
            <Modals title="" modalStatus={modalStatus} closeModal={closeModal}>
                <DateRangePicker
                    handleDateRangeChange={handleDateRangeChange}
                    oneDayOnly={oneDayOnly}
                    yearsOnly={yearsOnly}
                />
            </Modals>
            <Select
                // disabled={locations.length === 0}
                testId={`${currentModuleID}_comparePeriods`}
                options={options}
                dropdownWidth="lg"
                text={t('Selected')}
                dropdownAlign="left"
                showFilter={false}
                mode={mode}
                labelText={t('Comparison period')}
                iconType="chevron-down"
                handleSelect={handleSelect}
                handleCheck={handleCheck}
                outsideCloseDropdown={outsideCloseDropdown}
                outsideText={getOutsideText(outsideSelected?.[0])}
                outsideSelected={outsideSelected}
                minSelected={1}
                maxSelected={maxSelected}
                bgColor="warning-lighter"
            />
        </Wrapper>
    );
};

export default ComparePeriodSelect;
