import { useEffect, useState } from 'react';
import { IArgs } from '../styles/styles.interfaces';

const useClasses = ({ styles: { addClasses } }: IArgs): string[] => {
    const [classes, setClasses] = useState<string[]>([]);

    useEffect(() => {
        const classes = [];
        if (addClasses) {
            classes.push(addClasses);
        }
        setClasses(classes);
    }, [addClasses]);

    return classes;
};

export default useClasses;
