import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues, reloadCategories } from '../../../../../../../../General.reducer';
import { Divider, Footer, Inputs, Wrapper } from './styles';
import { CategoriesAPI } from '../../../../../../../../tools/API/categoriesAPI';
import Button from '../../../../../../../../components/Button/Button';
import TextInput from '../../../../../../../../components/TextInput/TextInput';
import Select from '../../../../../../../../components/Selects/Select/Select';
import { TSelectedOptions } from '../../../../../../../../components/Selects/Select/interfaces';
import axios from 'axios';
import { toggleLoader } from '../../../reducer';

const ModalEditCategory: React.FC<{
    handleOKClick: () => void;
    modalStatus: { show: boolean; id?: number };
}> = ({ modalStatus, handleOKClick }) => {
    const [name, setName] = useState('');
    const [standardName, setStandardName] = useState<string | null>(null);
    const {
        token,
        user,
        selectedProjectId,
        urlsByServices,
        currentModuleID,
        src: { standardCategories, projectCategories },
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        const name = projectCategories.find((item) => item?.id === modalStatus?.id)?.object_name;
        name && setName(() => name);
    }, [modalStatus?.id, projectCategories]);

    const categoriesUrl = useMemo(() => {
        if (urlsByServices?.['core/admin-service']) return urlsByServices['core/admin-service'].CATEGORIES_URL;
        return null;
    }, [urlsByServices]);

    const handleChange = (args: { value: string }) => {
        setName(() => args.value);
    };

    const existingCategoriesNames = useMemo(() => {
        const projectCategoriesNames = projectCategories
            .filter((item) => !item.parent_id)
            .map((item) => item.object_name.toLowerCase());
        return projectCategoriesNames;
    }, [projectCategories]);

    const onOKClick = useCallback(() => {
        if (!user?.permissions.includes('app/client | modules/configuration/categories | feature/edit')) {
            return;
        }
        if (name && modalStatus.id !== undefined && selectedProjectId && categoriesUrl) {
            dispatch(toggleLoader(true));
            CategoriesAPI({
                user,
                method: 'PATCH',
                token,
                chapter: 'project-categories',
                url: categoriesUrl,
                id: modalStatus.id,
                data: {
                    object_name: name,
                },
            }).then(() => {
                dispatch(reloadCategories());
                dispatch(toggleLoader(false));
            });
            handleOKClick();
        }
    }, [categoriesUrl, dispatch, modalStatus.id, name, selectedProjectId, token, user]);

    return (
        <Wrapper>
            <Inputs>
                <TextInput
                    // placeholder={t('Type custom name')}
                    handleChange={handleChange}
                    outsideValue={name}
                    hasError={existingCategoriesNames.includes(name.toLowerCase())}
                />
            </Inputs>
            <Footer>
                <Button
                    appearance="primary"
                    text={t('Edit')}
                    onClick={onOKClick}
                    disabled={existingCategoriesNames.includes(name.toLowerCase())}
                />
            </Footer>
        </Wrapper>
    );
};

export default ModalEditCategory;
