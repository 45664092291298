import React, { RefObject, createRef, useEffect, useMemo, useRef, useState } from 'react';
import { Stage, Layer, Group } from 'react-konva';

import { useDispatch, useSelector } from 'react-redux';
import { useWidgetCurrentOptions } from '../../../../../../../hooks/useWidgetCurrentOptions';
import { Maps_MapsOverview_Module_Reducer_Values, storeSelectedMarker, storeOverMarker } from '../../../../reducer';
import { DateTime } from 'luxon';
import { geoMatrixConverner } from '../../core/geoMatrixConverter';
import { KonvaEventObject } from 'konva/lib/Node';
import { handleWheelScale } from '../../core/handleWheelScale';
import { Cont1, Floor, Overlay, StageWrapper, StageWrapperJoin, Wrapper } from './styles';
import { fitStage } from '../../core/fitStage';
import UrlImage from '../UrlImage/UrlImage';
import Geometry from '../Geometry/Geometry';
import { Props } from './interfaces';
import { generalReducerValues } from '../../../../../../../General.reducer';
import LoadingBox from '../../../../../../../components/LoadingBox/LoadingBox';
import { Stage as IStage } from 'konva/lib/Stage';

const Floors: React.FC<Props> = ({
    metric,
    layerType,
    canvasAreaSize,
    scale,
    changeScale,
    openTenantInformation,
    setRatingHeight,
}) => {
    const stageRef = useRef<HTMLDivElement>(null);

    const refsOfStages = useRef([]);

    const [widgetSettings, setWidgetSettings] = useState<string[]>([]);
    const [stageHeight, setStageHeight] = useState(1);
    const [stageWidth, setStageWidth] = useState(1);

    const dispatch = useDispatch();
    const {
        plans,
        layers,
        selectedMarker,
        selectedGroupId,
        colorsByMarker,
        zonesByGroup,
        selectedZoneId,
        overMarker,
        selectedRelationId,
        metricsData,
        selectedPlaceId,
        moduleName,
    } = useSelector(Maps_MapsOverview_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    const {
        cfg: { reportingObjectsByType, reportingObjectsByTypeAndMarker },
        allMetrics,
        mainAreaSize,
    } = useSelector(generalReducerValues);

    useEffect(() => {
        const width = stageRef?.current?.offsetWidth || 1;
        setStageWidth(width);
    }, [stageRef?.current?.offsetWidth, widgetSettings]);

    useEffect(() => {
        if (localCurrentOptions?.['mapsSettings']) {
            setWidgetSettings(localCurrentOptions?.['mapsSettings'].map((item: { id: string }) => item.id));
        }
    }, [localCurrentOptions?.['mapsSettings']]);

    useEffect(() => {
        setRatingHeight(stageHeight);
    }, [stageHeight]);

    useEffect(() => {
        if (widgetSettings.includes('joinFloors')) {
            setStageHeight(canvasAreaSize.height - 300);
        } else {
            setStageHeight(stageRef?.current?.offsetHeight || 1);
        }
    }, [widgetSettings, canvasAreaSize, stageRef?.current?.offsetHeight]);

    const latestPlans = useMemo(() => {
        const result = {};
        Array.isArray(plans) &&
            plans?.forEach((plan) => {
                const mainPlan = plans?.filter((item) => plan.floor === item?.floor)?.filter((item) => item.is_main)[0];
                if (result[plan.floor]) {
                    if (
                        DateTime.fromISO(plan.active_from).toMillis() >=
                        DateTime.fromISO(result[plan.floor].active_from).toMillis()
                    ) {
                        result[plan.floor] = { ...plan, mainPlan };
                    }
                } else {
                    result[plan.floor] = { ...plan, mainPlan };
                }
            });
        return result;
    }, [plans]);

    const widestPlan = useMemo(() => {
        return Object.keys(latestPlans)
            .map((key) => latestPlans[key])
            .sort((a, b) => b.width / b.scale - a.width / a.scale)[0];
    }, [latestPlans]);

    const metricsNumber = localCurrentOptions?.['selectedMetrics']?.length
        ? localCurrentOptions?.['selectedMetrics']?.length
        : 1;

    // const stageWidth = widgetSettings.includes('showRatings')
    //     ? (0.7 * canvasAreaSize.width) / metricsNumber
    //     : canvasAreaSize.width / metricsNumber;

    const finalPlans = Object.keys(latestPlans)
        .map((key) => {
            const plan = latestPlans[key];
            let imageOffset = [0, 0];
            const vector = geoMatrixConverner(0, 0, plan.plan2geo);
            if (vector && vector[0]) {
                imageOffset = geoMatrixConverner(vector[0], vector[1], widestPlan.mainPlan.geo2plan).map((item) => {
                    if (Math.abs(item) < 0.1) {
                        return 0;
                    } else {
                        return item;
                    }
                });
            }
            return { ...plan, imageOffset, widestPlan };
        })
        .sort((a, b) => b.floor - a.floor);
    useEffect(() => {
        let stageScale = fitStage(widestPlan, stageWidth);

        if (isNaN(stageScale)) stageScale = 1;
        changeScale({
            stageScale,
            stageX: 0,
            stageY: 0,
        });
    }, [widestPlan, stageWidth]);

    useEffect(() => {
        // add or remove refs
        refsOfStages.current = Array(finalPlans.length)
            .fill(null)
            .map((_, i) => refsOfStages.current[i] || createRef());
    }, [finalPlans]);

    const selectObject = (marker: string) => {
        // dispatch(storeSelectedMarker(marker));
    };

    const onMouseOverLeave = (marker: string | null) => {
        dispatch(storeOverMarker(marker));
    };

    /** Функция для изменения курсора на холсте */
    const changeStageCursor = (stageIndex: number, cursor: string) => () => {
        const container = (refsOfStages.current![stageIndex] as IStage | null)?.container();
        if (container) {
            container.style.cursor = cursor;
        }
    };

    const geometry = (plan: any, metric: string | number, planIndex = 0) => {
        const showLabels = widgetSettings.includes('showLabels');

        let passwayRelationShapeMarker: string | null = null;

        if (selectedRelationId === 'place' && selectedPlaceId) {
            passwayRelationShapeMarker = reportingObjectsByType['place']?.filter(
                (item) => String(item.id) === String(selectedPlaceId),
            )[0].marker;
        } else if (selectedRelationId === 'zone' && selectedGroupId) {
            passwayRelationShapeMarker = zonesByGroup[selectedGroupId]?.filter(
                (item) => String(item.id) === String(selectedZoneId),
            )[0].marker;
        }

        return (
            <Geometry
                layerType={layerType}
                layers={layers}
                overMarker={overMarker}
                passwayRelationShapeMarker={passwayRelationShapeMarker}
                showLabels={showLabels}
                selectedRelationId={selectedRelationId}
                plan={plan}
                colorsByMarker={colorsByMarker}
                metric={String(metric)}
                selectObject={selectObject}
                onMouseOverLeave={onMouseOverLeave}
                allMetrics={allMetrics}
                selectedMarker={selectedMarker}
                selectedGroupId={selectedGroupId}
                widgetSettings={widgetSettings}
                stageScale={scale.stageScale}
                openTenantInformation={openTenantInformation}
                reportingObjectsByTypeAndMarker={reportingObjectsByTypeAndMarker}
                stageIndex={planIndex}
                changeStageCursor={changeStageCursor}
            />
        );
    };

    const onStageDblClick = (e: KonvaEventObject<MouseEvent>) => {
        e.evt.preventDefault();
        let stageScale = fitStage(widestPlan, stageWidth, e);

        if (isNaN(stageScale)) stageScale = 1;
        changeScale({
            stageScale,
            stageX: 0,
            stageY: 0,
        });
    };

    const onStageClick = (e: KonvaEventObject<MouseEvent>) => {
        e.evt.preventDefault();
        dispatch(storeSelectedMarker(null));
    };

    const onStageWheel = (e: KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();
        changeScale(handleWheelScale(e));
    };

    let y = 0;

    // return widgetSettings.includes('joinFloors') ? <JoinedFloors /> : <SeparateFloors />;

    const overlay = useMemo(() => {
        if (metricsData?.['status'] === 'Loading') {
            return (
                <Overlay>
                    <LoadingBox height={400} text={metricsData?.['message'] || ''}></LoadingBox>
                </Overlay>
            );
        } else if (metricsData?.['status'] === 'Error') {
            return (
                <Overlay>
                    <LoadingBox height={400} isError text={metricsData?.['message']?.['log'] || ''}></LoadingBox>
                </Overlay>
            );
        } else {
            return null;
        }
    }, [metricsData]);

    return (
        <Wrapper isRatingShown={widgetSettings.includes('showRatings')} ref={stageRef}>
            {widgetSettings.includes('joinFloors') ? (
                <StageWrapperJoin height={mainAreaSize.height - 300}>
                    {overlay}
                    <Stage
                        ref={(el) => ((refsOfStages.current![0] as IStage | null) = el)}
                        width={stageWidth}
                        height={stageHeight}
                        draggable
                        scaleX={scale.stageScale}
                        scaleY={scale.stageScale}
                        x={scale.stageX}
                        y={scale.stageY}
                        onWheel={onStageWheel}
                        onClick={onStageClick}
                        onDblClick={onStageDblClick}
                        id={metric}
                    >
                        {finalPlans.map((plan, i, arr) => {
                            if (i !== 0) {
                                y =
                                    y +
                                    arr[i - 1].height * (arr[i - 1].widestPlan.mainPlan.scale / arr[i - 1].scale) +
                                    arr[i - 1].imageOffset[1] * (arr[i - 1].mainPlan.scale / arr[i - 1].scale);
                            } else {
                                y = y + plan.imageOffset[1] * (plan.mainPlan.scale / plan.scale);
                            }

                            return (
                                <Layer y={y} id={`imageLayer` + i} draggable={false} key={plan.floor - i}>
                                    <Group key={'YYY' + i} draggable={false}>
                                        {widgetSettings.includes('showPlans') && (
                                            <UrlImage
                                                src={plan.image}
                                                planBlueprintScale={plan.widestPlan.mainPlan.scale / plan.scale}
                                                imageOffset={plan.imageOffset}
                                            />
                                        )}
                                        {geometry(plan, metric)}
                                    </Group>
                                </Layer>
                            );
                        })}
                    </Stage>
                </StageWrapperJoin>
            ) : (
                <Cont1>
                    {finalPlans.map((plan, i, arr) => {
                        const planRatio = plan.width / plan.height;
                        const stageHeight = Math.round(stageWidth / planRatio);

                        return (
                            <StageWrapper key={`canvas${i}${plan.floor}`}>
                                {overlay}
                                <Floor>{plan.floor}</Floor>
                                <Stage
                                    ref={(el) => ((refsOfStages.current![i] as IStage | null) = el)}
                                    width={stageWidth}
                                    height={stageHeight}
                                    draggable
                                    scaleX={scale.stageScale}
                                    scaleY={scale.stageScale}
                                    x={scale.stageX}
                                    y={scale.stageY}
                                    onWheel={onStageWheel}
                                    onClick={onStageClick}
                                    onDblClick={onStageDblClick}
                                    id={`canvas${i}`}
                                >
                                    <Layer id={`imageLayer` + i} draggable={false}>
                                        <Group key={'YYY' + i} draggable={false}>
                                            {widgetSettings.includes('showPlans') && (
                                                <UrlImage
                                                    src={plan.image}
                                                    planBlueprintScale={plan.widestPlan.mainPlan.scale / plan.scale}
                                                    imageOffset={plan.imageOffset}
                                                />
                                            )}
                                            {geometry(plan, metric, i)}
                                        </Group>
                                    </Layer>
                                </Stage>
                            </StageWrapper>
                        );
                    })}
                </Cont1>
            )}
        </Wrapper>
    );
};

export default Floors;
