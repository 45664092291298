import axios from 'axios';
import { getHeaders } from './getHeaders';
import { ICommonArgs } from './interfaces';

const requestPlans = async (args: ICommonArgs) => {
    const { token, url } = args;
    const headers = getHeaders(token)

    try {
        const response = await axios({
            method: 'GET',
            headers,
            url,
        });

        return response;
    } catch (error) {
        return error;
    }
};

export default requestPlans;
