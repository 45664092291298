import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(250, 249, 248, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
`;
