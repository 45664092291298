import { CellWrapper, RowWrapper } from './styles';
import { ITooltipBodyProps } from './interfaces';
import { ChartDot } from '../../../styles';
import { FC, useMemo } from 'react';

/**
 * Компонент для отображения "тела" тултипа
 * @param showSeriesName Флаг, отвечающий за отобажение названия серии
 * @param body Все элементы тултипа
 */
const TooltipBody: FC<ITooltipBodyProps> = ({ body, showSeriesName = false }) => {
    const rows = useMemo(() => {
        return body && body.length
            ? body.map((row) => (
                  <table key={row.title}>
                      <tr key={row.title}>
                          <td>{row.title}</td>
                          {row.data.map((cell, i) => {
                              return (
                                  <td key={`${cell.value}--${i}`}>{`${cell.key ? cell.key + ': ' : ''}${
                                      cell.value
                                  }`}</td>
                              );
                          })}
                      </tr>
                  </table>
              ))
            : null;
    }, [body]);

    return <>{rows}</>;
};

export default TooltipBody;
