import { DateTime } from 'luxon';
import { TSeries } from '../../interfaces';

/**
 * Функция для выравнивания серий по дням недели
 * @param series Серии
 * @param xValues Все значения по оси X (для главной серии)
 * @returns выравненные серии
 */
const alignSeriesByWeekday = (series: TSeries[], xValues: number[]): TSeries[] => {
    const newSeries = series.map((element) => {
        if (DateTime.fromMillis(xValues[0]).weekday !== DateTime.fromMillis(element.data[0]['initialX']).weekday) {
            const daysCounter =
                DateTime.fromMillis(xValues[0]).weekday - DateTime.fromMillis(element.data[0]['initialX']).weekday;

            element.data = element.data.map((item) => ({
                ...item,
                x: DateTime.fromMillis(item.x)
                    .minus({ days: daysCounter < 0 ? 7 + daysCounter : daysCounter })
                    .toMillis(),
            }));
        }

        return element;
    });

    return newSeries;
};

export default alignSeriesByWeekday;
