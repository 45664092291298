import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FocusTable, TableRange, ColumnOptions, CellDataType } from 'src/components/FocusTable';
import { useAppDispatch } from 'src/hooks/useSettings';
import {
    storeActiveTenantId,
    storeSelectedTenantsRanges,
    storeActivePosOfTenantsIds,
    storeSelectedPosOfTenantsRanges,
    Sales_PosAnalysis_Module_Reducer_Values,
} from 'src/Chapters/Sales/PosAnalysis/reducer';
import LoadingBox from 'src/components/LoadingBox/LoadingBox';
import { emptyArray, chunks } from 'src/tools';
import { withLoading } from 'src/tools/API/withLoading';
import getResponseStatus from 'src/tools/API/helpers/getResponseStatus';

import { useFetchPosEntities } from '../../../api/useFetchPosEntities';
import { tenantsAndPosAdapter } from '../../../api/dataAdapters/tenantsAndPosAdapter';
import { CELL_STYLE, WIDGET_NAME } from '../../../../constants';

import { LoaderWrapper } from './styles';

interface TenantsListProps {}

const columnsOptions: ColumnOptions[] = [
    { id: 'id', visible: false },
    { id: 'tenantName', label: 'Tenant', sortOrder: 'asc' },
    { id: 'orgName', label: 'Legal entity name' },
    { id: 'inns', label: 'EIN', isCancellableSort: true, sortOrder: 'desc', align: 'right' },
    { id: 'posType', style: { whiteSpace: 'pre-line' }, label: 'Connection types' },
    {
        id: 'posMaxCount',
        label: 'Total POS count (Reg. POS ID)',
        align: 'right',
        dataType: CellDataType.Number,
        valueFormatter: (value: number, context) => {
            if (value === -1) {
                return context.t('No data');
            }
            return value;
        },
    },
    {
        id: 'posCount',
        label: 'POS count (Reg. POS ID) for the period',
        align: 'right',
    },
];

export const TenantsList: React.FC<TenantsListProps> = () => {
    const dispatch = useAppDispatch();

    const { selectedTenantsRanges } = useSelector(Sales_PosAnalysis_Module_Reducer_Values);

    const selectedCells = useMemo(() => {
        if (!selectedTenantsRanges || selectedTenantsRanges.length === 0) {
            return null;
        }

        const [firstChunk] = [...chunks<number>(selectedTenantsRanges || [], 4)];
        const [startRow, startCol, endRow, endCol] = firstChunk;

        return {
            start: { row: startRow, col: startCol },
            end: { row: endRow, col: endCol },
        } as TableRange;
    }, [selectedTenantsRanges]);

    const { data: tableData = emptyArray, error, isFetching } = useFetchPosEntities(tenantsAndPosAdapter);

        const WithLoading = useMemo(() => {
        const Component = withLoading(LoaderWrapper, {
            data: getResponseStatus({
                isDataExists: !!tableData,
                // isDataExists: data ? data?.isDataExists : false,
                isFetching: isFetching,
                error: error,
                data: tableData,
            }),
            height: 400,
        });

        return Component;
    }, [tableData, error, isFetching]);

    const onSelectionChange = useCallback(
        (range: TableRange | null, selectedRows: typeof tableData) => {
            dispatch(storeActivePosOfTenantsIds([]));
            dispatch(storeSelectedPosOfTenantsRanges(null));

            if (range === null || !(selectedRows?.length > 0)) {
                dispatch(storeActiveTenantId(null));
                dispatch(storeSelectedTenantsRanges(null));
                return;
            }

            const { start, end } = range;

            const selectedTenantIds = selectedRows.map(({ id }) => Number(id));

            dispatch(storeActiveTenantId(selectedTenantIds?.length > 0 ? selectedTenantIds : null));
            dispatch(storeSelectedTenantsRanges([start.row, start.col, end.row, end.col]));
        },
        [dispatch],
    );

    if (isFetching) {
        return <LoadingBox height={400} />;
    }

    return (
        <WithLoading>
            <FocusTable
                componentId="tenantList"
                nameForExports={WIDGET_NAME}
                value={tableData}
                wrapperStyle={{ maxHeight: '300px' }}
                columnsOptions={columnsOptions}
                selectedCells={selectedCells}
                onSelectionChange={onSelectionChange}
                cellStyle={CELL_STYLE}
                // onChangeConfig={(arg: any) => {}}
                // initialConfig={{ sorters: [{ sortOrder: 'desc', columnId: 'inns' }] }}
            />
        </WithLoading>
    );
};
