import { WeekInfo } from 'src/types';

import { weekInfoData } from './weekInfoData';

const normalizeLocale = (loc: string) => {
  const [lang, region] = loc.split('-')
  return region ? `${lang.toLowerCase()}-${region.toUpperCase()}` : lang.toLowerCase()
}

export const getWeekInfo = (locale: string) => {
    const normalizedLocale = normalizeLocale(locale)

    let match: WeekInfo | undefined = weekInfoData[normalizedLocale]

    if (!match) {
        const mainLanguage = normalizedLocale.split('-')[0]
        match = weekInfoData[mainLanguage]
    }

    if (!match) {
        match = Object.entries(weekInfoData).find(([key]) => normalizeLocale(key) === normalizedLocale)?.[1]
    }

    return match
}