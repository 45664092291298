import { DateTime } from 'luxon';

/**
 * Checks if two date ranges intersect.
 *
 * @param dateRange1 - The first date range as an array of two strings or null values.
 *                     The first element is the start date and the second element is the end date.
 * @param dateRange2 - The second date range as an array of two strings or null values.
 *                     The first element is the start date and the second element is the end date.
 * @returns `true` if the date ranges intersect, otherwise `false`.
 *
 * The function handles null values as open-ended ranges (infinite past or future).
 * If the start date is null, it is considered as negative infinity.
 * If the end date is null, it is considered as positive infinity.
 */
export const isDateRangesIntersect = (dateRange1: (string | null)[], dateRange2: (string | null)[]): boolean => {
    // Преобразуем даты или устанавливаем бесконечности
    const date1Start = dateRange1[0] ? DateTime.fromISO(dateRange1[0]) : null;
    const date1End = dateRange1[1] ? DateTime.fromISO(dateRange1[1]) : null;
    const date2Start = dateRange2[0] ? DateTime.fromISO(dateRange2[0]) : null;
    const date2End = dateRange2[1] ? DateTime.fromISO(dateRange2[1]) : null;

    // Проверяем пересечение интервалов с учётом бесконечностей
    const condition1 = date1Start === null || date2End === null || date1Start <= date2End;

    const condition2 = date1End === null || date2Start === null || date1End >= date2Start;

    return condition1 && condition2;
};
