import { useEffect, useRef } from 'react';

import { emptyArray } from 'src/tools';
import { Identity } from 'src/types';

import { ContextMenu } from './components/ContextMenu';
import { defaultValueRenderer, defaultDataRenderer } from './defaultRenderers';
import { usePrepareTableProps } from './usePrepareTableProps';
import { FocusTableProps, WrapperStyleProps } from './interfaces';
import { StyledCell, StyledTable, StyledTableWrapper } from './styles';

export const FocusTable = <I extends string = string, R extends Identity<I> = Identity<I>>({
    wrapperStyle,
    ...props
}: FocusTableProps<I, R> & { wrapperStyle?: WrapperStyleProps }) => {
    const {
        columnsOptions = emptyArray,
        headerCellRenderer,
        tableContextRef,
        contextMenuEvent,
        onMouseDown,
        ...restProps
    } = usePrepareTableProps<I, R>({ ...props });

    const wrapperRef = useRef<HTMLDivElement>(null);
    // const isRtl = document.documentElement.getAttribute('dir') === 'rtl';
    // useEffect(() => {
    //     // tableContextRef.current.isRtl = isRtl;
    // }, [isRtl]);
    const wrapperElement = wrapperRef.current;

    useEffect(() => {
        const handleKeyDownListener = (event: globalThis.KeyboardEvent) => {
            event.preventDefault(); // Prevent default behavior for key press
            event.stopPropagation(); // Stop the event from propagating further
        };

        const element = wrapperElement; // closure - to unsubscribe
        if (element === null || element === undefined) {
            return;
        }

        element!.addEventListener('keydown', handleKeyDownListener);
        return () => element!.removeEventListener('keydown', handleKeyDownListener);
    }, [wrapperElement]);

    const cellRenderer = ({ cell, row, col, ...props }: any) => {
        const column = columnsOptions[col];
        const style = columnsOptions[col]?.style;
        const handleMouseDown = (event: MouseEvent) => {
            // event to show menu on selected range
            onMouseDown(event);

            if (event.button === 2) {
                // to show menu on selected range
                event.preventDefault();
                return;
            }
            // const selection = {
            //     start: { i: row, j: col },
            //     end: { i: row, j: col },
            // };
            // tableContextRef.current.selection = selection;
            // handlers should to work for other mouse buttons
            props.onMouseDown(event);
        };
        const { align } = column;
        const renderer = column?.cellRenderer || column?.valueFormatter;

        const value = cell?.value !== undefined ? cell.value : null;

        return (
            <StyledCell data-row={row} data-col={col} align={align} {...props} onMouseDown={handleMouseDown} {...style}>
                {renderer ? renderer(value, tableContextRef.current) : value}
            </StyledCell>
        );
    };

    const sheetRenderer = ({ ...props }: any) => {
        return (
            <table className={props.className}>
                <thead>
                    <tr>{columnsOptions.map(headerCellRenderer)}</tr>
                </thead>
                <tbody>{props.children}</tbody>
            </table>
        );
    };

    return (
        <StyledTableWrapper {...wrapperStyle} ref={wrapperRef}>
            <StyledTable
                {...restProps}
                valueRenderer={defaultValueRenderer}
                cellRenderer={cellRenderer}
                dataRenderer={defaultDataRenderer}
                sheetRenderer={sheetRenderer}
                data={restProps.data}
            />

            <ContextMenu contextRef={tableContextRef} contextMenuEvent={contextMenuEvent} />
        </StyledTableWrapper>
    );
};
