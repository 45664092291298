import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { isMobileInboundWidth } from 'src/theme';
import WidgetTitle from 'src/components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from 'src/components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetAdditionalControls from 'src/components/WidgetAdditionalControls/WidgetAdditionalControls';

import { generalReducerValues } from '../../../../../General.reducer';
import { getReportingObjects } from '../../../../../tools/getReportingObjects';
import FiltersMonitor from '../../../../../components/Filters/FiltersMonitor/FiltersMonitor';
import FiltersSelect from '../../../../../components/Filters/FiltersSelect/FiltersSelect';
import Stack from '../../../../../components/Stack/Stack';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { withLoading } from '../../../../../tools/API/withLoading';
import getResponseStatus from '../../../../../tools/API/helpers/getResponseStatus';
import UniversalSelect from '../../../../../components/Selects/UniversalSelect/UniversalSelect';
import { IChangeOptionsArgs } from '../../../../../components/UserSettings/interfaces';

import useFetchData from './api/useFetchData';
import { TABLE_SETTINGS, TABLE_SETTINGS_SELECT_NAME } from './constants/constants';
import { ITableSetting } from './interfaces';
import Footer from './components/Footer/Footer';
import Table from './components/Table/Table';
import WidgetTabList from './components/WidgetTabList/WidgetTabList';
import { IContext, SalesOfTenantsContext } from './context';
import {
    Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values,
    storeTableItemsToShow,
    storeTableSetting,
} from './reducer';
import { FiltersWrapper, LoaderWrapper } from './styles/styles';

/**
 * Компонент для отображения виджета продажи арендаторов
 */
const SalesOfTenants = () => {
    const {
        cfg: { reportingObjectsByType },
        mainAreaSize: { width },
    } = useSelector(generalReducerValues);
    const { selectedTableSetting } = useSelector(Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values);

    const { data, isFetching, error, refetch } = useFetchData();

    const wrapperRef = useRef(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isMobile = isMobileInboundWidth(width);

    useEffect(() => {
        if (!isMobile) {
            dispatch(storeTableItemsToShow('all'));
        }
    }, [isMobile, dispatch]);

    const WithLoading = useMemo(() => {
        const Component = withLoading(LoaderWrapper, {
            data: getResponseStatus({
                isDataExists: data ? data?.isDataExists : false,
                isFetching,
                error,
                data,
            }),
            height: 400,
        });

        return Component;
    }, [error, data, isFetching]);

    const filterValidOptions = useMemo(() => {
        if (Object.keys(reportingObjectsByType)) {
            const lockedFields = ['location', 'passway', 'place'];
            return getReportingObjects(reportingObjectsByType, t)
                .map((item) => ({
                    headerText: item.section,
                    itemKey: item.key,
                }))
                ?.filter((item) => !lockedFields.includes(item.itemKey))
                .map((element) => element.headerText);
        }
        return [];
    }, [reportingObjectsByType, t]);

    const reloadWidget = () => {
        refetch();
    };

    const onTableSettingSelect = (args: IChangeOptionsArgs) => {
        if (Array.isArray(args.newOptions[TABLE_SETTINGS_SELECT_NAME])) {
            const [setting] = args.newOptions[TABLE_SETTINGS_SELECT_NAME] as ITableSetting[];
            dispatch(storeTableSetting(setting));
        }
    };

    const context: IContext = {
        isMobile,
        data,
        reloadWidget,
    };

    return (
        <SalesOfTenantsContext.Provider value={context}>
            <WidgetWrapper ref={wrapperRef}>

                <WidgetTitleWrapper>
                    <WidgetTitle>
                        {t('Sales of tenants')}
                        <WidgetAdditionalControls
                            pdfDownloadData={{ targetRef: wrapperRef }}
                            reloadHandlerNoDispatch={context?.reloadWidget}
                            widgetName="Sales of tenants"
                        />
                    </WidgetTitle>
                    {!context?.isMobile && <WidgetTabList />}
                </WidgetTitleWrapper>

                {isMobile && <WidgetTabList />}
                <FiltersWrapper>
                    <Stack flexWrap={'wrap'} alignItems={'flex-end'} gap={10}>
                        <UniversalSelect
                            localCurrentOptions={{ [TABLE_SETTINGS_SELECT_NAME]: [selectedTableSetting] }}
                            name={TABLE_SETTINGS_SELECT_NAME}
                            changeOptions={onTableSettingSelect}
                            labelText={t('Table settings')}
                            options={TABLE_SETTINGS}
                            iconType="chevron-down"
                            showFilter={false}
                            mode="single"
                        />
                        <FiltersSelect label="" validOptions={filterValidOptions} />
                        <FiltersMonitor />
                    </Stack>
                </FiltersWrapper>
                <WithLoading>
                    <Table />
                </WithLoading>
                {!isFetching && <Footer />}
            </WidgetWrapper>
        </SalesOfTenantsContext.Provider>
    );
};

export default SalesOfTenants;
