import { tokens } from '@fluentui/react-components';
import { PanelResizeHandle } from 'react-resizable-panels';
import styled from 'styled-components';

export const ResizeHandleOuter = styled(PanelResizeHandle)`
    flex: 0 0 1.5em;
    position: relative;
    outline: none;
    --background-color: transparent;

    &[data-resize-handle-active] {
        --background-color: ${() => tokens.colorNeutralBackground3Hover};
    }
`;

export const ResizeHandleInner = styled.div`
    position: absolute;
    top: 0.25em;
    bottom: 0.25em;
    left: 0.25em;
    right: 0.25em;
    border-radius: 0.25em;
    background-color: ${() => tokens.colorNeutralBackground3Hover};
    transition: background-color 0.2s linear;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Icon = styled.svg`
    width: 1em;
    height: 1em;
    display: block; /* Это убирает дополнительные отступы */
    margin: auto; /* Центрация в контейнере */
`;
