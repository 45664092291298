import { useGetMetrics as useCasesBenchmarcsSalesWidget } from '../Chapters/Cases/Benchmarks/widgets/Sales/hooks/useFetchData';
import { useGetMetrics as useCasesBenchmarcsTrafficWidget } from '../Chapters/Cases/Benchmarks/widgets/Traffic/hooks/useFetchData';
import { useGetRawData as useCrossVisitsModuleRawData } from '../Chapters/Maps/CrossVisits/hooks/useFetchData';
// import { useGetMetrics as useDashboardTrafficWidget } from '../Chapters/Performance/Dashboard/widgets/TrafficOld/hooks/useFetchData';
import { useGetMetrics as useBasicReportWidget } from '../Chapters/Reports/Analytical/widgets/BasicReportWidget/hooks/useFetchData';
import { useGetMetrics as useComparisonReportWidget } from '../Chapters/Reports/Analytical/widgets/ComparisonReportWidget/hooks/useFetchData';
import { useGetRawData as useTenantsWidgetReducer } from '../Chapters/Reports/Analytical/widgets/TenantsReportWidget/hooks/useFetchData';
import { useGetMetrics as useTenantsComparisonRawData } from '../Chapters/Reports/Analytical/widgets/TenantsComparisonReportWidget/hooks/useFetchData';
import { useGetRawData as useIntersectionsWidgetReducer } from '../Chapters/Reports/Analytical/widgets/IntersectionsReportWidget/hooks/useFetchData';
//-----------------Leasing:Tenant overview
import { useGetMetrics as useLeasing_TO_Reach_Widget } from '../Chapters/Leasing/TenantOverview/widgets/Reach/hooks/useFetchData';
import { useGetMetrics as useLeasing_TO_Reference_Widget } from '../Chapters/Leasing/TenantOverview/widgets/Reference/hooks/useFetchData';
import { useGetMetrics as useMapsOverviewWidget } from '../Chapters/Maps/MapsOverview/widgets/MapsWidget/hooks/useFetchData';
import { useGetRawData as usePathToPurchaseWidgetRawData } from '../Chapters/Performance/PathToPurchase/widgets/PathToPurchaseWidget/hooks/useFetchData';
import { useGetMetrics as usePerformanceVisitorsInsideWidget } from '../Chapters/Performance/VisitorsInside/widgets/VisitorsInside/hooks/useFetchData';
import { useFetchData as usePerformanceRatingsWidget } from '../Chapters/Performance/Ratings/widgets/RatingList/hooks/useFetchData';
// import { useGetMetrics as usePerformanceYearOverYearWidget } from '../Chapters/Performance/YearOverYear/widgets/YearOverYear/hooks/useFetchData';
import { useGetRawData as useLeasing_TO_IntersectionsWithTenants_Widget } from '../Chapters/Leasing/TenantOverview/widgets/IntersectionsWithTenants/hooks/useFetchData';
//-----------------Leasing:Tenants
import { useGetRawData as useLeasingTenantsWidgetRawData } from '../Chapters/Leasing/Tenants/widgets/TenantsWidget/hooks/useFetchData';
//-----------------Leasing:Cross-visits
import { useGetRawData as useLeasingCrossVisitsWidgetRawData } from '../Chapters/Leasing/CrossVisits/widgets/LeasingCrossVisitsWidget/hooks/useFetchData';
//-----------------Events:Events
import { useGetRawData as useEventsTableOfObjectsWidget } from '../Chapters/Events/Events/widgets/TableOfObjects/hooks/useFetchData';
//-----------------Network:Portfolio overview
//-----------------Reports:Advanced reports
// import { usePlainReportOffset as useDetailedBillsPlainReportOffset } from '../Chapters/Reports/Advanced/widgets/DetailedBills/hooks/usePlainReportOffset';

//-----------------Common

import { usePatchUserDashboards } from '../components/DashboardsCommon/hooks/usePatchUserDashboards';

/**
 * Компонент для вызова всех кастомных хуков, которые запрашивают данные
 */
const DataComponent = () => {
    //-----------------Common
    usePatchUserDashboards();
    //-----------------Performance:Dashboard
    // useDashboardTrafficWidget();
    //-----------------Performance:YearOverYear
    // usePerformanceYearOverYearWidget();
    //-----------------Performance:VisitorsInside
    usePerformanceVisitorsInsideWidget();
    //-----------------Performance:Ratings
    usePerformanceRatingsWidget();
    //-----------------Performance:PathToPurchase
    usePathToPurchaseWidgetRawData();
    //-----------------Maps:MapsOverview
    useMapsOverviewWidget();
    //-----------------Maps:CrossVisits
    useCrossVisitsModuleRawData();
    //-----------------Cases:Benchmarks
    useCasesBenchmarcsTrafficWidget();
    useCasesBenchmarcsSalesWidget();
    //-----------------Leasing:Tenant overview
    useLeasing_TO_Reach_Widget();
    useLeasing_TO_Reference_Widget();
    useLeasing_TO_IntersectionsWithTenants_Widget();
    //-----------------Reports
    useBasicReportWidget();
    useComparisonReportWidget();
    useIntersectionsWidgetReducer();
    useTenantsWidgetReducer();
    useTenantsComparisonRawData();
    //-----------------Leasing:TenantsWidget
    useLeasingTenantsWidgetRawData();
    //-----------------Leasing:Cross-visits
    useLeasingCrossVisitsWidgetRawData();
    //-----------------Events:Events
    useEventsTableOfObjectsWidget();
    //-----------------Sales:Payment analysis
    //-----------------Network:Portfolio overview
    //-----------------Reports:Advanced reports
    // useDetailedBillsPlainReportOffset();
    return null;
};

export default DataComponent;
