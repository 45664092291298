import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { Events_EventsMap_Widget_Reducer_Values } from '../../../../Events/EventsMap/widgets/EventsMapWidget/reducer';
import WidgetsNavigation from '../../components/WidgetsNavigation/WidgetsNavigation';
import { TColumnsKeys } from './components/Table/constants/constants';
import Table from './components/Table/Table';
import { IContext, EventsManagementContext } from './context';
import usePrepareData from './hooks/usePrepareData';
import {
    addNewFilter,
    addNewRow,
    cancelChanges,
    Configuration_EventsConfig_EventManagement_Widget_Reducer_Values,
    addDeletedRowToChangedData,
    resetFilters,
    saveChanges,
    storeContextMenuTarget,
    storeTimezone,
    updateCellValue,
} from './redcuer';
import useFetchData from './hooks/useFetchData';
import { IContextMenuTarget, TFiltersMode } from './interfaces';
import Button from '../../../../../components/Button/Button';
import { ButtonsWrapper, SaveAndCancelButtonsWrapper } from './styles';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { useSendSimpleAnalytics } from '../../../../../hooks/useAnalytics';

/**
 * Компонент для отображения всех событий
 */
const EventsManagement = () => {
    const {
        timeZone,
        currentModuleID,
        user,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);

    const [filters, setFilters] = useState<null | Record<string, { filter: string; key: string; mode?: TFiltersMode }>>(null);

    const setFilter = useCallback(
        (value: { filter: string; key: string; mode?: TFiltersMode } | null) => setFilters(
            (_state) => {
                if (!value) {
                    return null;
                }

                return ({
                    // ...(state === null) ? {} : state,
                    [value.key]: value,
                });
            },
        ),
        [],
    );

    const { tableData, changedData, contextMenuTarget, filtersByCellKey } = useSelector(
        Configuration_EventsConfig_EventManagement_Widget_Reducer_Values,
    );

    const { eventTypes, eventTypesById } = useSelector(Events_EventsMap_Widget_Reducer_Values);
    const sendAnalytics = useSendSimpleAnalytics();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef(null);
    usePrepareData();
    useFetchData();

    useEffect(() => {
        dispatch(storeTimezone(timeZone));
    }, [timeZone]);

    const updateCellValueTrans = (data: { frontId: string; key: TColumnsKeys; value: any }) => {
        dispatch(updateCellValue(data));
    };

    const addNewFilterTrans = (data: { filter: string; key: string; mode?: TFiltersMode }) => {
        dispatch(addNewFilter(data));
    };

    const storeContextMenuTragetTrans = (data: null | IContextMenuTarget) => {
        dispatch(storeContextMenuTarget(data));
    };

    const onCloneClickTrans = (frontId: string) => {
        dispatch(addNewRow(frontId));
    };

    const addDeletedRowToChangedDataTrans = (data: { frontId: string }) => {
        dispatch(addDeletedRowToChangedData(data));
    };

    const onAddEmptyRowClick = () => {
        dispatch(addNewRow());
    };

    const onCancelClick = () => {
        dispatch(cancelChanges());
    };

    const onSaveClick = () => {
        sendAnalytics(`events_configuration`, 'reachGoal', `${currentModuleID}:EventsManagement`);
        dispatch(saveChanges());
    };

    const resetFiltersTrans = () => {
        dispatch(resetFilters());
    };

    const allowSaveChanges = useMemo(() => {
        const hasError = Object.values(changedData).some((item) => item.type === 'ERROR');
        if (Object.keys(changedData).length && !hasError) {
            return true;
        } else {
            return false;
        }
    }, [changedData]);

    const context: IContext = {
        eventTypesById,
        reportingObjectsById,
        contextMenuTarget,
        filtersByCellKey,
        changedData,
        eventTypes,
        tableData,
        addDeletedRowToChangedDataTrans,
        storeContextMenuTragetTrans,
        updateCellValueTrans,
        addNewFilterTrans,
        resetFiltersTrans,
        onCloneClickTrans,

    };

    console.log(context.filtersByCellKey)

    return (
        <EventsManagementContext.Provider value={context}>
            <WidgetWrapper ref={ref}>
                <WidgetTitleWrapper>
                    <WidgetTitle>
                        {t('Events management')}
                        <WidgetAdditionalControls
                            pdfDownloadData={{ targetRef: ref }}
                            widgetName={'Events management'}
                        />
                    </WidgetTitle>
                    <WidgetsNavigation />
                </WidgetTitleWrapper>
                {user?.permissions.includes('app/client | modules/configuration/events | feature/edit') && (
                    <ButtonsWrapper>
                        <Button appearance="primary" onClick={onAddEmptyRowClick}>
                            {t('Add empty row')}
                        </Button>

                        <SaveAndCancelButtonsWrapper>
                            <Button disabled={!allowSaveChanges} onClick={onSaveClick}>
                                {t('Save changes')}
                            </Button>
                            <Button baseColor="warning" onClick={onCancelClick}>
                                {t('Cancel changes')}
                            </Button>
                        </SaveAndCancelButtonsWrapper>
                    </ButtonsWrapper>
                )}
                <Table />
            </WidgetWrapper>
        </EventsManagementContext.Provider>
    );
};

export default EventsManagement;
