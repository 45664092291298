import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import { isMobileInboundWidth } from 'src/theme';
import UniversalDatePicker from 'src/components/Selects/UniversalDatePicker/UniversalDatePicker';
import { userSettingsReducerValues, changeOptions } from 'src/components/UserSettings/reducer';
import { useFetchPlans, useFetchVersions } from 'src/tools/API';
import { useGeneralSelector } from 'src/hooks';
import { emptyObject, mlFromLocation } from 'src/tools';

// import ComparePeriodSelect from '../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
// import MetricsSelect from '../../../components/Selects/MetricsSelect/MetricsSelect';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import Preset from '../../../components/Preset/Preset';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import UniversalSelect from '../../../components/Selects/UniversalSelect/UniversalSelect';
// import { stringDate } from '../../../tools/Strings/stringDate';
import { generalReducerValues } from '../../../General.reducer';
import { ISettings } from '../../../components/UserSettings/interfaces';
// import { changeOptions } from '../../../components/UserSettings/reducer';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import { useAppDispatch } from '../../../hooks/useSettings';

import { FPC_Monitoring_Module_Reducer_Values, storeSelectedVersionId } from './reducer';
import MonitoringWidget from './widgets/MonitoringWidget/MonitoringWidget';
import { IPlan, IVersion } from 'src/General.interfaces';

// const DEFAULT_PERIOD = [ { dateFrom: undefined}, { dateTo: undefined }];
const SETTINGS_OPTIONS = [
    { id: 'showPlans', text: 'Show plans', section: '' },
    { id: 'showPerimeters', text: 'Show Perimeters', section: 'perimeter_layer' },
    { id: 'showLabels', text: 'Show labels', section: '' },
    { id: 'joinFloors', text: 'Join floors', section: '' },
];

const DEFAULT_SELECTED = [
    // SETTINGS_OPTIONS.find(({ id }) => id === 'showRatings'),
    SETTINGS_OPTIONS.find(({ id }) => id === 'showPerimeters')!,
];

const Monitoring: React.FC = () => {
    const { t } = useTranslation();

    const {
        lang,
        locations,
        optionPanelHeight,
        mainAreaSize: { width },
        selectedLocationId,
        currentModuleID,
    } = useGeneralSelector();

    const location = locations?.find((item) => item.id === selectedLocationId);
    const mlId = mlFromLocation(location);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const { data: plans } = useFetchPlans(mlId);
    const { data: versions } = useFetchVersions(mlId);

    // const { currentOptions } = useSelector(userSettingsReducerValues);

    const dispatch = useAppDispatch();

    // const isMobile = isMobileInboundWidth(width);

    // useEffect(() => {
    //     if (versions.length) {
    //         const id = cloneDeep(versions as IVersion[]).sort((a, b) => {
    //             return (
    //                 DateTime.fromISO(b.date_from || '2300-01-01').toMillis() -
    //                 DateTime.fromISO(a.date_from || '2300-01-01').toMillis()
    //             );
    //         })[0].id;
    //         dispatch(storeSelectedVersionId(id));
    //     }
    // }, [dispatch, versions]);


    // useEffect(() => {
    //     if (isMobile && selectedLocationId && localCurrentOptions?.selectedMetrics) {
    //         dispatch(
    //             changeOptions({
    //                 newOptions: {
    //                     selectedMetrics: [localCurrentOptions?.selectedMetrics[0]],
    //                 },
    //             }),
    //         );
    //     }
    // }, [isMobile, selectedLocationId, localCurrentOptions?.selectedMetrics, dispatch]);

    const showComparison: Boolean = localCurrentOptions?.['mapsSettings']
        ?.map((item: { id: string }) => item.id)
        ?.includes('comparison');

    // const handleDateRangeChange = useCallback(
    //     (period: { dateFrom: string; dateTo: string }) => {
    //         selectedLocationId &&
    //             dispatch(changeOptions({
    //                 newOptions: {
    //                     mainPeriod: { id: 'selectCustom', period },
    //                 },
    //             }));
    //     },
    //     [dispatch, selectedLocationId],
    // );
    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        {/* <MainPeriodSelect
                            oneDayOnly
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        /> */}
                        <UniversalDatePicker
                            name={'period'}
                            labelText={t('Indexing date')}
                            oneDayOnly
                        />

                        <UniversalSelect
                            options={SETTINGS_OPTIONS}
                            name={'mapsSettings'}
                            labelText={'Settings'}
                            iconType={'setting'}
                            defaultSelected={DEFAULT_SELECTED}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={[
                                'selectedMetrics',
                                'comparePeriods',
                                'mainPeriod',
                                'mapsSettings' as keyof ISettings,
                            ]}
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <WrapperContainer key={optionPanelHeight} style={{ maxWidth: 'unset' }}>
                <MonitoringWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Monitoring;
