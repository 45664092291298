import React, { useEffect, useState } from 'react';

interface ColorPickerProps {
    value?: string;
    onChange?: (color: string) => void;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ value = '#000000', onChange }) => {
    const [color, setColor] = useState<string>(value);

    useEffect(() => {
        setColor(() => value);
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newColor = event.target.value;
        setColor(newColor);
        if (onChange) {
            onChange(newColor);
        }
    };

    return <input type="color" value={color} onChange={handleChange} />;
};
