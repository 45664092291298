import { ILocation } from 'src/General.interfaces';

import { getParameterByName } from './getParameterByName';

export const mlFromLocation = (location?: ILocation) => {
    if (location) {
        return getParameterByName('location_id', location?.map_service_link);
    }
    return undefined;
};
