import { Group, Image } from 'react-konva';
import { IProps } from './interfaces';
import { FC, useId } from 'react';
import useImage from 'use-image';

/**
 * Комопонент, отвечающий за отображение слоя с картинкой
 * @param coordinates Координнаты позиционирования картинки
 * @param imageSrc Сслыка на картинку
 * @param scale Скейл картинки
 */
const ImageGroup: FC<IProps> = ({ imageSrc, scale, coordinates }) => {
    const [image, status] = useImage(imageSrc);
    const id = useId();

    return (
        <Group id={`imageLayer-${id}`}>
            <Image scaleX={scale} scaleY={scale} x={coordinates.x} y={coordinates.y} image={image} />
        </Group>
    );
};

export default ImageGroup;
