import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    font-weight: 500;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: var(--text-lg);
    padding: var(--space-xxs) 0;
`;
