import { useSelector } from 'react-redux';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { generalReducerValues } from '../../../General.reducer';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { WidgetWrapperStyled } from '../../../components/Wrappers/WidgetWrapper/styles';
import { useTranslation } from 'react-i18next';
import TabList from '../../../components/TabList/TabList';
import { ITabListOptionsItem } from '../../../components/TabList/interfaces';
import {
    Configuration_Utilities_Module_Reducer_Values,
    storeSelectedTab,
    toggleValidationModalStatus,
} from './reducer';
import { TableWrapper } from './styles';
import { Matching } from './widgets/Matching/Matching';
import { TSelectedTab } from './interfaces';
import { useIsMutating } from '@tanstack/react-query';
import Stack from 'src/components/Stack/Stack';
import WidgetTitle from 'src/components/Wrappers/WidgetTitle/WidgetTitle';

export const Utilities = () => {
    const { currentModuleID, isRtlLanguage } = useSelector(generalReducerValues);
    const { selectedTab } = useSelector(Configuration_Utilities_Module_Reducer_Values);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const TAB_LIST_ITEMS: ITabListOptionsItem[] = [
        { id: 'matching', text: 'Soft matching' },
        { id: 'structures', text: 'Structures' },
        // { id: 'metricsTable', text: 'Metrics table' },
        // { id: 'uploadFile', text: 'Upload from file' },
    ];

    const tabListSelectHandler = (id: TSelectedTab) => {
        dispatch(storeSelectedTab(id));
    };

    const style: React.CSSProperties = { maxWidth: 'none' };

    return (
        <ModuleWrapper>
            <WrapperContainer style={style}>
                <WidgetWrapperStyled isRtlLanguage={isRtlLanguage}>
                    <Stack alignItems="center" justifyContent="space-between">
                        <WidgetTitle>
                            {t(TAB_LIST_ITEMS.find((item) => item.id === selectedTab)?.text || '')}
                        </WidgetTitle>
                        <TabList
                            options={TAB_LIST_ITEMS}
                            selectHandler={tabListSelectHandler}
                            selectedValue={selectedTab}
                            widgetName={currentModuleID}
                        />
                    </Stack>
                    <TableWrapper>{selectedTab === 'matching' && <Matching />}</TableWrapper>
                </WidgetWrapperStyled>
            </WrapperContainer>
        </ModuleWrapper>
    );
};
