import _ from 'lodash';
import React from 'react';
import { getFixedLines } from '../../../../../../../tools/getFixedLines';
import { Props } from './interfaces';
import { Color, Li, Ul, Wrapper, Text, Value, TextValueWrapper } from './styles';
import { Maps_MapsOverview_Module_Reducer_Values, storeOverMarker } from '../../../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';
import { useWidgetCurrentOptions } from '../../../../../../../hooks/useWidgetCurrentOptions';
import { useTranslation } from 'react-i18next';
import { generalReducerValues } from '../../../../../../../General.reducer';
import Tooltip from '../../../../../../../components/Tooltip/Tooltip';

const Ratings: React.FC<Props> = ({ colorsByMarker, metric, height, openTenantInformation }) => {
    const { moduleName } = useSelector(Maps_MapsOverview_Module_Reducer_Values);
    const {
        allMetrics,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    if (!colorsByMarker || !colorsByMarker[metric]) return null;

    const onMouseOver = (marker: string) => (e: React.MouseEvent) => {
        dispatch(storeOverMarker(marker));
    };

    const onMouseLeave = (e: React.MouseEvent) => {
        dispatch(storeOverMarker(null));
    };

    const onRatingItemClick = (id: number, name: string) => () => {
        if (reportingObjectsById?.[id]?.object_type === 'tenant' && openTenantInformation) {
            openTenantInformation({
                module: 'Leasing:Tenant overview',
                tenantData: {
                    text: name,
                    id,
                },
                selectedMetrics: [metric],
                path: '/leasing/tenant-overview',
            });
        }
    };

    const showComparison =
        localCurrentOptions?.['mapsSettings']?.find((item: any) => item.id === 'comparison') !== undefined;

    const units = showComparison ? '%' : allMetrics?.find((m) => m.id === metric)?.units || '';

    const list = _.map(Object.keys(colorsByMarker[metric]), (marker) => {
        return colorsByMarker[metric][marker];
    })
        ?.filter((item) => item.value !== undefined)
        .sort((a, b) => b.value - a.value)
        .map((item, i) => {
            const value = Number(Number(item?.value).toFixed(2));
            let text = `${getFixedLines(item.name, 25)}`;
            let content = `${item.name}: ${valueFormatter({ value, units })}`;

            return (
                <Li
                    key={`${item.name}${item.id}00${i}`}
                    onClick={onRatingItemClick(item.id, item.name)}
                    data-test-id={`${item.id}_${metric}`}
                    onMouseOver={onMouseOver(item.marker)}
                    onMouseLeave={onMouseLeave}
                >
                    <Tooltip content={content} positioning="left">
                        <Color width={`${item.normValue * 100}%`} bgColor={item.heatColor}>
                            <TextValueWrapper>
                                <Text>{text}</Text>
                                <Value>{`${valueFormatter({ value, units, t })}`}</Value>
                            </TextValueWrapper>
                        </Color>
                    </Tooltip>
                </Li>
            );
        });
    return (
        <Wrapper height={height}>
            <Ul>{list}</Ul>
        </Wrapper>
    );
};

export default Ratings;
