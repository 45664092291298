import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

import { SALES_POS_ANALYSIS_QUERY_KEY } from 'src/constants';
import { getRequest } from 'src/tools/API/appBackendAPI';
import usePeriods from 'src/hooks/common/usePeriods';
import useTranslation from 'src/hooks/useTranslation/useTranslation';
import { useWidgetCurrentOptions } from 'src/hooks/useWidgetCurrentOptions';
import { emptyArray } from 'src/tools';
import { useGeneralSelector } from 'src/hooks';

import { DataAdapter } from './interfaces';

// TODO extends it from pattern src/Chapters/Performance/Dashboard/widgets/AnalysisByArea/api/useFetchData.ts
export const useFetchPosEntities = <Args, Result>(dataAdapter: DataAdapter<Args, Result>) => {
    const {
        cfg: { reportingObjectsById },
        currentModuleID,
        token,
    } = useGeneralSelector();

    const { t } = useTranslation(['fsfReports']);

    const { mainPeriod } = usePeriods(currentModuleID);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const periodFromMain = mainPeriod?.period;

    const tenantIds = localCurrentOptions?.selectedReportingObjectsIds || emptyArray;
    const hasTenantsParams = tenantIds?.length !== 0;

    const [pl] = localCurrentOptions?.pl || [];

    const paramsForAll = [
        ...(pl?.id && !hasTenantsParams ? [`project_location_id=${pl?.id}`] : []),
        ...(hasTenantsParams ? [`tenant_ids=${tenantIds!.join(',')}`] : []),
        'without_data=false',
        'limit=1000',
    ];
    const queryKey = [SALES_POS_ANALYSIS_QUERY_KEY, pl?.id, tenantIds, periodFromMain, true];

    const enabled = Boolean(dataAdapter)
        && Boolean(token)
        && Boolean(periodFromMain)
        && (Boolean(pl?.id) || hasTenantsParams);

    const { data: allTimeResponse, isFetched } = useQuery({
        queryKey: queryKey,
        enabled,
        queryFn: async () => {
            const paramsUrl = paramsForAll?.length > 0 ? `?${paramsForAll.join('&')}` : '';
            const requestUrl = `https://fsf-api.sales-flow.ru/api/points-of-sale/${paramsUrl}`;
            return await getRequest(requestUrl, token);
        },
    });


    const params = [
        ...(pl?.id && !hasTenantsParams ? [`project_location_id=${pl?.id}`] : []),
        ...(hasTenantsParams ? [`tenant_ids=${tenantIds!.join(',')}`] : []),
        ...(periodFromMain?.dateFrom ? [`date_from=${periodFromMain.dateFrom}`] : []), // !isAllTime
        ...(periodFromMain?.dateTo ? [`date_to=${periodFromMain.dateTo}`] : []), //!isAllTime
        'limit=1000',
    ];
    queryKey.pop();

    return useQuery({
        queryKey,
        enabled: isFetched && enabled,
        queryFn: async () => {
            const paramsUrl = params?.length > 0 ? `?${params.join('&')}` : '';
            const requestUrl = `https://fsf-api.sales-flow.ru/api/points-of-sale/${paramsUrl}`;
            return await getRequest(requestUrl, token);
        },
        select: useCallback(
            (response: unknown) => dataAdapter({ response, allTimeResponse, reportingObjectsById, t } as Args),
            [dataAdapter, allTimeResponse, reportingObjectsById, t],
        ),
    });
};