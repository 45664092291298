import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import { useGeneralSelector } from 'src/hooks';
import { isTabletOutboundWidth, isMobileInboundWidth } from 'src/theme';
import { useFetchPlans, useFetchVersions } from 'src/tools/API';
import { mlFromLocation } from 'src/tools';

import { generalReducerValues } from '../../../../../General.reducer';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { FPC_Monitoring_Module_Reducer_Values, storeObjectsType, storeSelectedGroup } from '../../reducer';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import LoadingBox from '../../../../../components/LoadingBox/LoadingBox';
import { ZONES_WORD } from '../../../../../constants/constants';
import { getObjSectionName } from '../../../../../tools/Strings/getObjSectionName';
import TabList from '../../../../../components/TabList/TabList';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { useSendSimpleAnalytics } from '../../../../../hooks/useAnalytics';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';

import { geoMatrixConverner } from './core/geoMatrixConverter';
import Ratings from './components/Ratings/Ratings';
import Floors from './components/Floors/Floors';
import { IScale } from './components/Floors/interfaces';
import { IOpenTenantInformationArgs } from './interfaces';
import { CanvasWrapper, Cont, MetricContainer } from './styles';

const MonitoringWidget: React.FC = () => {
    const { moduleName } = useSelector(FPC_Monitoring_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const canvasAreaRef = useRef(null);
    // const sendAnalytics = useSendSimpleAnalytics();
    const [widgetSettings, setWidgetSettings] = useState<string[]>([]);
    const {
        allMetrics,
        mainAreaSize,
        locations,
        selectedLocationId,
        // sendYaAnalytics,
        cfg: { reportingObjectsByType },
    } = useGeneralSelector();

    const location = locations?.find((item) => item.id === selectedLocationId);
    const mlId = mlFromLocation(location);

    const { data: plans } = useFetchPlans(mlId);
    const { data: versions } = useFetchVersions(mlId);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [scale, set_scale] = useState({
        stageScale: 1,
        stageX: 0,
        stageY: 0,
    });
    const { selectedObjectType, colorsByMarker, metricsData } = useSelector(FPC_Monitoring_Module_Reducer_Values);
    const [ratingHeight, setRatingHeight] = useState(1);

    useEffect(() => {
        if (localCurrentOptions?.['mapsSettings']) {
            setWidgetSettings(localCurrentOptions?.['mapsSettings'].map((item: { id: string }) => item.id));
        }
    }, [localCurrentOptions?.['mapsSettings']]);

    const existingTypes = ['tenant', 'zone', 'floor'];

    const tabListItems = [
        {
            id: 'sensors',
            layerType: 'perimeter_layer',
            text: t('Sensors'),
        },
        {
            id: 'structures',
            layerType: 'perimeter_layer',
            text: t('Structures'),
        },
    ];

    const isMobile = isMobileInboundWidth(mainAreaSize.width);

    const tabListSelectHandler = (id: string) => {
        dispatch(storeObjectsType(id));
    };

    const latestPlans = {};

    Array.isArray(plans) &&
        plans?.forEach((plan) => {
            const mainPlan = plans?.filter((item) => plan.floor === item?.floor)?.filter((item) => item.isMain)[0];

            if (latestPlans[plan.floor]) {
                if (
                    DateTime.fromISO(plan.activeFrom).toMillis() >=
                    DateTime.fromISO(latestPlans[plan.floor].active_from).toMillis()
                ) {
                    latestPlans[plan.floor] = { ...plan, mainPlan };
                }
            } else {
                latestPlans[plan.floor] = { ...plan, mainPlan };
            }
        });

    const widestPlan = Object.keys(latestPlans)
        .map((key) => latestPlans[key])
        .sort((a, b) => b.width / b.scale - a.width / a.scale)[0];

    const finalPlans = Object.keys(latestPlans)
        .map((key) => {
            const plan = latestPlans[key];
            let imageOffset = [0, 0];
            const vector = geoMatrixConverner(0, 0, plan.plan2geo);
            if (vector && vector[0]) {
                imageOffset = geoMatrixConverner(vector[0], vector[1], widestPlan.mainPlan.geo2plan).map((item) => {
                    if (Math.abs(item) < 0.1) {
                        return 0;
                    } else {
                        return item;
                    }
                });
            }
            return { ...plan, imageOffset, widestPlan };
        })
        .sort((a, b) => b.floor - a.floor);

    const layerType = tabListItems?.find((item) => item.id === selectedObjectType)?.layerType || 'places_layer';

    const metricsNumber = localCurrentOptions?.['selectedMetrics']?.length
        ? localCurrentOptions?.['selectedMetrics']?.length
        : 1;

    const workAreaSize = useMemo(() => {
        const sideBarVisible = isTabletOutboundWidth(mainAreaSize.width);
        return sideBarVisible ? { width: mainAreaSize.width - 250, height: mainAreaSize.height } : mainAreaSize;
    }, [mainAreaSize]);

    const changeScale = (args: IScale) => {
        set_scale(args);
    };

    const diagram = useMemo(() => {
        return finalPlans[0] ? (
            <Cont key={`canvas--`}>
                <MetricContainer>
                    <Floors scale={scale} changeScale={changeScale} canvasAreaSize={workAreaSize} />
                </MetricContainer>
            </Cont>
        ) : (
            <LoadingBox height={400} text={t('Loading...')} />
        );
        // }
    }, [
        allMetrics,
        workAreaSize,
        colorsByMarker,
        finalPlans,
        layerType,
        localCurrentOptions,
        metricsData,
        scale,
        ratingHeight,
        t,
        widgetSettings,
    ]);

    const tabList = useMemo(() => {
        return (
            <TabList
                selectHandler={tabListSelectHandler}
                selectedValue={selectedObjectType}
                widgetName="MapsOverview"
                options={tabListItems}
            />
        );
    }, [existingTypes, selectedObjectType, t]);

    return (
        <WidgetWrapper>
            <WidgetTitleWrapper>
                <WidgetTitle>{t('Monitoring')}</WidgetTitle>
                {!isMobile && tabList}
            </WidgetTitleWrapper>
            {isMobile && tabList}
            {/* <Selects /> */}
            <CanvasWrapper ref={canvasAreaRef}>{diagram}</CanvasWrapper>
        </WidgetWrapper>
    );
};

export default MonitoringWidget;
