import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    min-width: 30vw;
`;

export const Label = styled.span`
    position: relative;
    display: inline-block;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.4);
`;

export const InputWrapper = styled.div`
    margin-top: 15px;
`;

export const CsvFormatButtonWrapper = styled.div`
    display: inline-block;
`;
