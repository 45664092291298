import { DateTime } from 'luxon';

interface IResponse {
    weekNumber: number | null;
    week: { data: DateTime | null }[];
}

/**
 * Функция распределяет массив дней месяца месяца по сетке из 6-ти недель календаря
 * @param days Массив дней месяца
 */
export const arrangeDaysToMonthGrid = (days: DateTime[]): IResponse[] => {
    const monthGrid: IResponse[] = [];
    let daysIndex = 0;

    for (let monthWeek = 1; monthWeek <= 6; monthWeek++) {
        const week = [];
        let weekNumber = null;
        for (let weekDay = 1; weekDay <= 7; weekDay++) {
            if (days[daysIndex] && weekDay === days[daysIndex].weekday) {
                weekNumber = days[daysIndex].weekNumber;
                week.push({ data: days![daysIndex] });
                daysIndex++;
            } else {
                week.push({ data: null });
            }
        }
        monthGrid.push({ weekNumber, week });
    }

    return monthGrid;
};
