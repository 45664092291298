
import { useGeneralSelector } from 'src/hooks';
import { useFetchLayers, useFetchPlans, useFetchVersions, findActualVersion } from 'src/tools/API';
import { mlFromLocation, emptyArray } from 'src/tools';

export const useCommonMapsData = (periodFromTo: { dateFrom: string, dateTo: string }) => {
    const { locations, selectedLocationId } = useGeneralSelector();

    const location = locations?.find((item) => item.id === selectedLocationId);
    const mapLocationId = mlFromLocation(location);

    const { data: plans = emptyArray } = useFetchPlans(mapLocationId);

    const { data: versions = emptyArray } = useFetchVersions(mapLocationId);

    const { dateFrom, dateTo } = periodFromTo;

    const actualVersion = findActualVersion(versions, dateFrom, dateTo);
    const { data: layers = emptyArray } = useFetchLayers(mapLocationId, actualVersion?.id);

    return {
        mapLocationId,
        locationId: location?.id,
        plans,
        versions,
        layers,
    };
}