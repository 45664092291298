import { KonvaEventObject } from 'konva/lib/Node';
import React, { useMemo } from 'react';
import { Arrow, Group, Circle, Line, Shape } from 'react-konva';

import { theme } from 'src/theme';

import { findLabelCoords } from '../../../../../../../../../tools/findLabelCoords';
import { scaleTransformFoo } from '../../../../../../../../../tools/scaleTransformFoo';
import { ObjectLabel } from '../../../../../../../../../components/ObjectLabel/ObjectLabel';
import { getArrowPoint } from '../../../../core/getArrowPoint';
import { PcSensorProps } from './interfaces';

export const PcSensor: React.FC<PcSensorProps> = ({
    object,
    selectObject,
    selectedMarker,
    colorsByMarker,
    planScale,
    stageScale,
    overMarker,
    heatColor,
    onMouseOverLeave,
    followedBy,
}) => {
    const onObjectClick = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        selectObject?.(object.marker);
    };

    const onMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave?.(object.marker);
    };

    const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave?.(null);
    };

    // const arrows = () => {
    //     const result: JSX.Element[] = [];
    //     object.passLine.coordinates.forEach((point, i, arr) => {
    //         if (i === 0) return;
    //         const center = [(point[0] + arr[i - 1][0]) / 2, (point[1] + arr[i - 1][1]) / 2];
    //         const arrowEndPoint = getArrowPoint(point, i, arr, planScale || 1, object.connectivityMargin / 2, 'end');
    //         const arrowStartPoint = getArrowPoint(
    //             point,
    //             i,
    //             arr,
    //             planScale || 1,
    //             object.connectivityMargin / 2,
    //             'start',
    //         );

    //         result.push(
    //             <React.Fragment key={`${i}--${point[0]}`}>
    //                 <Arrow
    //                     points={[arrowStartPoint[0], arrowStartPoint[1], arrowEndPoint[0], arrowEndPoint[1]]}
    //                     stroke="red"
    //                     strokeWidth={scaleTransformFoo(3, stageScale)}
    //                     pointerWidth={scaleTransformFoo(8, stageScale)}
    //                     pointerLength={scaleTransformFoo(12, stageScale)}
    //                 />
    //             </React.Fragment>,
    //         );
    //     });
    //     return result;
    // };

    const stroke = selectedMarker !== object.marker ? heatColor : 'rgba(0,0,255,0.5)';
    const strokeWidth = scaleTransformFoo(2, stageScale);
    const mainRadius = scaleTransformFoo(15, stageScale);
    const outerRadius = scaleTransformFoo(20, stageScale);
    const radius = scaleTransformFoo(4, stageScale);
    const offsetDistance = scaleTransformFoo(6, stageScale);
    const centralFill = heatColor;
    // const centralFill = followedBy !== null ? theme.colors.blueLight : theme.colors.warning;

    // const points = useMemo(() => {
    //     const result: Array<number> = [];
    //     object.passLine.coordinates.forEach((point) => {
    //         result.push(...point);
    //     });
    //     return result;
    // }, [object.passLine.coordinates]);
    const [centerX, centerY] = object.centerPoint.coordinates;
    return (
        <Group>
            {/* {connectivityShape} */}
            <Circle
                x={centerX}
                y={centerY}
                radius={outerRadius}
                fill={theme.colors.white}
                // stroke={theme.colors.contrast}
                // strokeWidth={strokeWidth}
            />
            <Circle
                x={centerX}
                y={centerY}
                radius={mainRadius}
                fill={centralFill}
                onClick={onObjectClick}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                // onDragMove={onCentralDragMove}
                // draggable={selected}
            />

            <Circle
                x={centerX - offsetDistance}
                y={centerY}
                radius={radius}
                fill={'white'}
                onClick={onObjectClick}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                // stroke={'black'}
                // strokeWidth={strokeWidth}
            />
            <Circle
                x={centerX + offsetDistance}
                y={centerY}
                radius={radius}
                fill={'white'}
                onClick={onObjectClick}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                // stroke={'black'}
                // strokeWidth={strokeWidth}
            />
            {/* {arrows()} */}
        </Group>
    );
};
