import { AxiosError, AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { getRequest, postRequest, patchRequest, deleteRequest } from '../../../../../../../tools/API/appBackendAPI';
import { IEventType } from '../../../../../../Events/EventsMap/widgets/EventsMapWidget/interfaces';
import { storeEventTypes } from '../../../../../../Events/EventsMap/widgets/EventsMapWidget/reducer';
import DeleteConfirmModalBody from './components/DeleteConfirmModalBody/DeleteConfirmModalBody';
import { ColorBoxWrapper, Footer, InputWrapper, Txt, Wrapper } from './styles';
import Button from '../../../../../../../components/Button/Button';
import TextInput from '../../../../../../../components/TextInput/TextInput';
import Modals from '../../../../../../../components/Modals/Modals';
import Stack from '../../../../../../../components/Stack/Stack';
import { ColorPicker } from 'src/components/ColorPicker/ColorPicker';

interface IProps {
    closeModal: () => void;
    eventTypeToEdit?: IEventType | null;
}

const CreateModalBody: React.FC<IProps> = ({ closeModal, eventTypeToEdit }) => {
    const [deleteConformModalStatus, setDeleteConformModalStatus] = useState({ show: false });
    const { token, selectedLocationId, urlsByServices, user } = useSelector(generalReducerValues);
    const [color, setColor] = useState('#FF0000');
    const [name, setName] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (eventTypeToEdit) {
            setColor(() => eventTypeToEdit.color);
            setName(() => eventTypeToEdit.name);
        }
    }, [eventTypeToEdit]);

    const eventTypesUrl = useMemo(() => {
        if (urlsByServices) {
            return urlsByServices['app/app-backend'].LOCATION_EVENT_TYPES_URL;
        }
        return null;
    }, [urlsByServices]);

    const openDeleteConfirmModal = () => {
        setDeleteConformModalStatus({ show: true });
    };

    const closeDeleteConfirmModal = () => {
        setDeleteConformModalStatus({ show: false });
    };

    const updateColor = React.useCallback((color: string) => {
        setColor(() => color);
    }, []);

    const onNameChange = (args: { value: string }) => {
        setName(() => args.value);
    };

    const getRequestFoo = useCallback(() => {
        if (eventTypesUrl && selectedLocationId) {
            return getRequest(`${eventTypesUrl}?pl_id=${selectedLocationId}`, token, dispatch).then(
                (response: AxiosError | AxiosResponse) => {
                    if (Array.isArray(response['data'])) {
                        dispatch(storeEventTypes(response['data']));
                    }
                },
            );
        }
        return null;
    }, [eventTypesUrl, selectedLocationId, token, dispatch]);

    const onCreateClick = () => {
        if (!user?.permissions.includes('app/client | modules/configuration/events | feature/edit')) return;

        const data = { pl_id: selectedLocationId, name, color };
        eventTypesUrl &&
            postRequest(eventTypesUrl, token, data, dispatch).then(() => {
                getRequestFoo();
            });

        setName(() => '');
        closeModal();
    };

    const onUpdateClick = () => {
        if (!user?.permissions.includes('app/client | modules/configuration/events | feature/edit')) return;

        const data = { ...eventTypeToEdit, pl_id: selectedLocationId, name, color };

        eventTypesUrl &&
            patchRequest({ url: `${eventTypesUrl}${data.id}/`, token, data, dispatch }).then(() => {
                getRequestFoo();
            });

        setName(() => '');
        closeModal();
    };

    const onDelete = () => {
        if (!user?.permissions.includes('app/client | modules/configuration/events | feature/edit')) return;

        if (eventTypeToEdit && eventTypesUrl) {
            deleteRequest(eventTypesUrl, token, eventTypeToEdit.id, dispatch).then(() => {
                getRequestFoo();
            });

            setName(() => '');
            closeDeleteConfirmModal();
            closeModal();
        }
    };

    return (
        <Wrapper>
            <Modals
                title={t('Delete event type?')}
                closeModal={closeDeleteConfirmModal}
                modalStatus={deleteConformModalStatus}
                topColor="fff"
            >
                <DeleteConfirmModalBody onDelete={onDelete} closeModal={closeDeleteConfirmModal} />
            </Modals>
            <InputWrapper>
                <TextInput
                    fullWidth={true}
                    placeholder={t('Event type name')}
                    outsideValue={name}
                    handleChange={onNameChange}
                />
                <ColorBoxWrapper>
                    <ColorPicker value={color} onChange={updateColor} />
                    <Txt>{t('Event type color')}</Txt>
                </ColorBoxWrapper>
            </InputWrapper>
            <Footer>
                {eventTypeToEdit ? (
                    <Stack direction={'row'} alignItems="center" gap={10}>
                        <Button text={t('Delete')} baseColor="accent" onClick={openDeleteConfirmModal} />
                        <Button appearance="primary" text={t('Save')} onClick={onUpdateClick} />
                    </Stack>
                ) : (
                    <Button appearance="primary" text={t('Create')} onClick={onCreateClick} />
                )}
            </Footer>
        </Wrapper>
    );
};

export default CreateModalBody;
