import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { FocusTable, ColumnOptions, TableRange, CellDataType } from 'src/components/FocusTable';
import { Sales_PosAnalysis_Module_Reducer_Values } from 'src/Chapters/Sales/PosAnalysis/reducer';
import { DS } from 'src/constants/constants';
import { valueFormatter, emptyArray, chunks, emptyObject, filterBySelectors, toString, formatNumber } from 'src/tools';
import Stack from 'src/components/Stack/Stack';
import { useGeneralSelector } from 'src/hooks';
import { Dynamics_Settings_Reducer_Values } from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/reducer';
import { IDynamicsSettings } from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/interfaces';
import { ISelectedOption, UniversalSelect } from 'src/components/Selects';
import { useTranslation } from 'src/hooks/useTranslation';

import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { withLoading } from '../../../../../tools/API/withLoading';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { changeOptions } from '../../../../../components/UserSettings/reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import getResponseStatus from '../../../../../tools/API/helpers/getResponseStatus';
import { CELL_STYLE, SALES_SECTION } from '../../constants';
import { useFetchPosEntities } from '../api/useFetchPosEntities';
import { posEntitiesByTenantAdapter } from '../api/dataAdapters/posEntitiesByTenantAdapter';
import { TenantPosInfo } from '../api/interfaces';

import useFetchData from './api/useFetchData';
import { LoaderWrapper } from './styles';
import { METRICS_POS_ANALYTICS_SELECT } from './constants';

const WIDGET_NAME = 'Pos Analytics';

const DEFAULT_METRIC_IDS = ['fsf_sales_novat', 'fsf_sales_return_novat', 'fsf_bill_count'];

const columnsOptions: ColumnOptions[] = [
    { id: 'id', label: 'POS ID', visible: false },
    { id: 'tenantName', label: 'Tenant' },
    { id: 'inn', label: 'EIN', align: 'right' },
    { id: 'posType', label: 'Connection type' },
    { id: 'factoryId', label: 'Factory POS ID', align: 'right' },
    { id: 'registrationId', label: 'Registration POS ID', align: 'right' },
];

export const PosAnalyticsWidget: React.FC = () => {
    const ref = useRef(null);
    const { currentModuleID, allMetrics, lang } = useGeneralSelector();

    const { t } = useTranslation(['translation']);
    const tMetrics = useTranslation(['metrics']).t;
    const dispatch = useAppDispatch();

    const structureDynamicId = `${currentModuleID}${DS}StructureDynamics`;

    const { dynamicsSettingsById } = useSelector(Dynamics_Settings_Reducer_Values);
    const viewSettings = dynamicsSettingsById[structureDynamicId] as IDynamicsSettings | undefined;

    const { activeTenantIds, selectedPosOfTenantsRanges } = useSelector(Sales_PosAnalysis_Module_Reducer_Values);
    const [selectedTenantId] = activeTenantIds || [];

    const selectedCells = useMemo(() => {
        if (!selectedPosOfTenantsRanges || selectedPosOfTenantsRanges.length === 0) {
            return null;
        }

        const [firstChunk] = [...chunks<number>(selectedPosOfTenantsRanges || [], 4)];
        const [startRow, startCol, endRow, endCol] = firstChunk;

        return {
            start: { row: startRow, col: startCol },
            end: { row: endRow, col: endCol },
        } as TableRange;
    }, [selectedPosOfTenantsRanges]);

    const salesMetrics = useMemo(
        () =>
            allMetrics
                .filter(({ section }) => section === SALES_SECTION)
                .map(({ id, text }) => ({ id, text: tMetrics(text) })),
        [allMetrics, tMetrics],
    );

    const defaultMetrics = useMemo(
        () => filterBySelectors(allMetrics, DEFAULT_METRIC_IDS, (metric, id) => metric.id === id),
        [allMetrics],
    );

    const { data: tenantInfo } = useFetchPosEntities(posEntitiesByTenantAdapter);

    const possByTenant = tenantInfo || (emptyObject as Record<string, TenantPosInfo[]>);

    const posesData = useMemo(
        () => (activeTenantIds || []).reduce((acc, tenantId) => [...acc, ...(possByTenant?.[tenantId] || [])], []),
        [activeTenantIds, possByTenant],
    );

    const {
        data = emptyArray,
        error,
        isFetching,
        refetch,
    } = useFetchData({
        tenantId: selectedTenantId,
        posesData,
        viewSettings,
    });

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const WithLoading = useMemo(() => {
        const Component = withLoading(LoaderWrapper, {
            data: getResponseStatus({
                isDataExists: !!data,
                // isDataExists: data ? data?.isDataExists : false,
                isFetching: isFetching,
                error: error,
                data: data,
            }),
            height: 400,
        });

        return Component;
    }, [data, error, isFetching]);

    const selectedMetrics = localCurrentOptions?.[METRICS_POS_ANALYTICS_SELECT] ?? emptyArray;

    const columnsWithMetrics = useMemo(() => {
        const metricColumns = selectedMetrics.map(({ id: metricId }: ISelectedOption) => {
            const metricProps = allMetrics.find(({ id }) => id === metricId)!;
            const fractionDigits = metricProps['round_decimal_places'] ?? 0;
            const units = metricProps['units'] ?? '';
            // const formatStyle = metricProps['units'] === 'currency'
            //     ? 'currency'
            //     : 'decimal'

            return {
                id: metricId,
                label: `${tMetrics(toString(metricProps.text))}, ${t(units)}`,
                align: 'right',
                cellRenderer: (value?: number | string | null) => {
                    return valueFormatter({ value, precision: fractionDigits });
                },
                dataType: CellDataType.Number,
                ...(fractionDigits !== undefined && { precision: fractionDigits }),
            };
        });

        return [...columnsOptions, ...metricColumns];
    }, [selectedMetrics, allMetrics, tMetrics, t]);

    const rows = useMemo(() => {
        const metricValues = data.map((responseItem) => {
            const [firstResponse] = responseItem;
            const [firstItem] = firstResponse.items;
            return [toString(firstResponse.context.alias ?? 'main'), firstItem.value, firstResponse.context.metric];
        });

        return posesData.map((pos) => {
            const posMetrics = metricValues
                .filter(([alias, _, __]) => toString(alias).includes(toString(pos.registrationId)))
                .reduce(
                    (acc, [_, value, metric]) => ({
                        ...acc,
                        [metric!]: value,
                    }),
                    {},
                );

            return {
                ...pos,
                ...posMetrics,
            };
        });
    }, [posesData, data]);

    if (activeTenantIds?.length !== 1) {
        return null;
    }

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t(WIDGET_NAME)}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref }}
                        widgetName={WIDGET_NAME}
                        reloadHandlerNoDispatch={refetch}
                    />
                </WidgetTitle>
                <Stack flexWrap={'wrap'} gap={15}>
                    <UniversalSelect
                        name={METRICS_POS_ANALYTICS_SELECT}
                        options={salesMetrics}
                        defaultSelected={defaultMetrics}
                        labelText={t('Metrics')}
                        dropdownWidth="auto"
                        mode="multi"
                        maxSelected={4}
                        minSelected={1}
                        localCurrentOptions={localCurrentOptions}
                        changeOptions={(args) => dispatch(changeOptions(args))}
                    />
                </Stack>
            </WidgetTitleWrapper>

            <WithLoading>
                <FocusTable
                    wrapperStyle={{ marginTop: '30px', maxHeight: '300px' }}
                    componentId="posAnalytics"
                    nameForExports={WIDGET_NAME}
                    value={rows}
                    columnsOptions={columnsWithMetrics}
                    cellStyle={CELL_STYLE}
                    selectedCells={selectedCells}
                />
            </WithLoading>
        </WidgetWrapper>
    );
};
