import { KonvaEventObject } from 'konva/lib/Node';
import { ILatestPlan } from './interfaces';

/**
 * Функция. Служит для втискивания плана в Stage по ширине.
 * @param containerWidth Ширина панели Draw
 * @param plan Актуальынй пан
 * @param e Событие
 */
const fitStage = (plan: ILatestPlan, containerWidth: number, e?: any) => {
    if (!plan) return 1;
    let stageScale = 1;

    const imageWidth = (plan.width * (plan.mainPlan.scale / plan.scale)) as number;

    if (imageWidth) {
        stageScale = containerWidth / imageWidth;
    } else if (e) {
        const layersArr: any[] = Array.from(e.currentTarget.children);
        const imageWidth = layersArr?.filter((item) => item.attrs.id === 'imageLayer')[0].children[0].children[0].attrs
            .image.naturalWidth;
        stageScale = containerWidth / imageWidth;
    }
    return stageScale;
};

export default fitStage;
