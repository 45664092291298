import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { RootState } from '../../store';
import generateId from '../../tools/generateId';
import { IAlertPayload, IState } from './interfaces';

const initialState: IState = {
    alerts: [],
};

const AlertsReducer = createSlice({
    name: 'AlertsReducer',
    initialState,
    reducers: {
        /**
         * Добавление нового алерта
         */
        storeNewAlert: (state, action: PayloadAction<IAlertPayload>) => {
            const { alerts } = cloneDeep(state);

            const newAlert = {
                ...action.payload,
                id: generateId(),
            };

            // Если уже есть алерт с таким же алиасом, то ничего не делаем
            if (action.payload.alias) {
                if (alerts.find((alert) => alert.alias === action.payload.alias)) {
                    return;
                }
            }

            // Если уже есть алерт с таким же текстом, то ничего не делаем
            if (alerts.find((alert) => alert.text === action.payload.text)) {
                return;
            }

            alerts.push(newAlert);

            state.alerts = alerts;
        },

        /**
         * Удаление алерта
         */
        removeAlert: (state, action: PayloadAction<string>) => {
            const { alerts } = cloneDeep(state);

            const filteredAlerts = alerts?.filter((element) => element.id !== action.payload);

            state.alerts = filteredAlerts;
        },

        /**
         * Удаление алерта
         */
        removeAlertByAlias: (state, action: PayloadAction<string>) => {
            const { alerts } = cloneDeep(state);

            const filteredAlerts = alerts?.filter((element) => element.alias !== action.payload);

            state.alerts = filteredAlerts;
        },
    },
});

export const { storeNewAlert, removeAlert, removeAlertByAlias } = AlertsReducer.actions;

export const AlertsReducerValues = (state: RootState) => state.AlertsReducer;

export default AlertsReducer.reducer;
