import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

//------------------- Dashboards ----------------------
import Dashboards_Reducer from './Dashboards.reducer';
import Dashboard_General_Module_Reducer from './Chapters/Dashboards/ObjectPalnels/reducer';
//------------------- Performance ----------------------
import Performance_Dashboard_Module_Reducer from './Chapters/Performance/Dashboard/reducer';
import Performance_Dashboard_Summary_Widget_Reducer from './Chapters/Performance/Dashboard/widgets/SummaryTable/reducer';
import Performance_Dashboard_ByArea_Widget_Reducer from './Chapters/Performance/Dashboard/widgets/AnalysisByArea/reducer';
import Performance_Dashboard_DynamicsPMO_Widget_Reducer from './Chapters/Performance/Dashboard/widgets/DynamicsPMO/reducer';
import Performance_Dashboard_Traffic_Widget_Reducer from './Chapters/Performance/Dashboard/widgets/TrafficOld/reducer';
import Performance_VisitorsInside_Widget_Reducer from './Chapters/Performance/VisitorsInside/widgets/VisitorsInside/reducer';
import Performance_PathToPurchase_Widget_Reducer from './Chapters/Performance/PathToPurchase/widgets/PathToPurchaseWidget/reducer';
import Performance_YoY_YoY_Widget_Reducer from './Chapters/Performance/YearOverYear/widgets/YearOverYear/reducer';
import Performance_YoY_YoY_PeriodModalBody_Component_Reducer from './Chapters/Performance/YearOverYear/widgets/YearOverYear/components/Table/components/PeriodModalBody/reducer';
import Performance_Ratings_Module_Reducer from './Chapters/Performance/Ratings/reducer';
import Performance_Ratings_RatingList_Widget_Reducer from './Chapters/Performance/Ratings/widgets/RatingList/reducer';
//------------------- Network ----------------------
import Network_Section_Reducer from './Chapters/Network/reducer';
import Network_Portfolio_Overview_Module_Reducer from './Chapters/Network/LocationsOverview/reducer';
import Network_Tenants_Overview_Module_Reducer from './Chapters/Network/TenantsOverview/reducer';
//------------------- Maps ----------------------
import Maps_CrossVisits_Module_Reducer from './Chapters/Maps/CrossVisits/reducer';
import Maps_CrossVisits_Widget_Reducer from './Chapters/Maps/CrossVisits/widgets/CrossVisitsWidget/reducer';
import Maps_MapsOverview_Module_Reducer from './Chapters/Maps/MapsOverview/reducer';
import Maps_MapsOverview_Widget_Reducer from './Chapters/Maps/MapsOverview/widgets/MapsWidget/reducer';
//------------------- Sales ----------------------
import Sales_PosAnalysis_Module_Reducer from './Chapters/Sales/PosAnalysis/reducer';
import Sales_PosAnalysis_Dynamics_Pos_Widget_Reducer from './Chapters/Sales/PosAnalysis/widgets/DynamicsPosWidget/reducer';
import Sales_PosAnalysis_StructureDynamics_Widget_Reducer from './Chapters/Sales/PosAnalysis/widgets/StructureDynamicsWidget/reducer';
import Sales_PaymentAnalysis_Module_Reducer from './Chapters/Sales/SalesAnalysis/reducer';
import Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer from './Chapters/Sales/SalesAnalysis/widgets/PaymentStructure/reducer';
import Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer from './Chapters/Sales/SalesAnalysis/widgets/StructureDynamics/reducer';
import Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer from './Chapters/Sales/SalesAnalysis/widgets/SalesOfTenants/reducer';
//------------------- Cases ----------------------
import Cases_Benchmarks_Module_Reducer from './Chapters/Cases/Benchmarks/reducer';
import Cases_Benchmarks_Sales_Widget_Reducer from './Chapters/Cases/Benchmarks/widgets/Sales/reducer';
import Cases_Benchmarks_Traffic_Widgwet_Reducer from './Chapters/Cases/Benchmarks/widgets/Traffic/reducer';
import Cases_Calculator_Calculator_Widget_Reducer from './Chapters/Cases/Calculator/widgets/CalculatorWidget/reducer';
//------------------- Leasing ----------------------
import Leasing_TenantOverview_Module_Reducer from './Chapters/Leasing/TenantOverview/reducer';
import Leasing_TenantOverview_Info_Widget_Reducer from './Chapters/Leasing/TenantOverview/widgets/Info/reducer';
import Leasing_TenantOverview_Dynamics_Widget_Reducer from './Chapters/Leasing/TenantOverview/widgets/DynamicsPMO/reducer';
import Leasing_TenantOverview_Reach_Widget_Reducer from './Chapters/Leasing/TenantOverview/widgets/Reach/reducer';
import Leasing_TenantOverview_Reference_Widget_Reducer from './Chapters/Leasing/TenantOverview/widgets/Reference/reducer';
import Leasing_TenantOverview_Summary_Widget_Reducer from './Chapters/Leasing/TenantOverview/widgets/SummaryTable/reducer';
import Leasing_CrossVisits_Module_Reducer from './Chapters/Leasing/CrossVisits/reducer';
import Leasing_CrossVisits_Widget_Reducer from './Chapters/Leasing/CrossVisits/widgets/LeasingCrossVisitsWidget/reducer';
import Leasing_TenantOverview_IntersectionsWithTenants_Widget_Reducer from './Chapters/Leasing/TenantOverview/widgets/IntersectionsWithTenants/reducer';
import Leasing_Tenants_Tenants_Widget_Reducer from './Chapters/Leasing/Tenants/widgets/TenantsWidget/reducer';
//------------------- Reports ----------------------
import Reports_Analytical_Module_Reducer from './Chapters/Reports/Analytical/reducer';
import Advanced_Reports_Module_Reducer from './Chapters/Reports/Advanced/reducer';
import Reports_Basic_Widget_Reducer from './Chapters/Reports/Analytical/widgets/BasicReportWidget/reducer';
import Reports_Comparison_Widget_Reducer from './Chapters/Reports/Analytical/widgets/ComparisonReportWidget/reducer';
import Reports_Intersections_Widget_Reducer from './Chapters/Reports/Analytical/widgets/IntersectionsReportWidget/reducer';
import Reports_ByTenants_Widget_Reducer from './Chapters/Reports/Analytical/widgets/TenantsReportWidget/reducer';
import Reports_ByTenantsComparison_Widget_Reducer from './Chapters/Reports/Analytical/widgets/TenantsComparisonReportWidget/reducer';
import Reports_From_Partners_Module_Reducer from './Chapters/Reports/FromPartners/reducer';
import Reports_Bartel_Widget_Reducer from './Chapters/Reports/FromPartners/widgets/Bartel/reducer';
import Reports_Advanced_SimplePaymentType_Widget_Reducer from './Chapters/Reports/Advanced/widgets/SimplePaymentType/reducer';
import Reports_Advanced_DetailedBills_Widget_Reducer from './Chapters/Reports/Advanced/widgets/DetailedBills/reducer';
//------------------- Configuration ----------------------
import Configuration_Categories_Widget_Reducer from './Chapters/Configuration/Categories/widgets/Categories/reducer';
import Configuration_EventsConfig_Module_Redcuer from './Chapters/Configuration/EventsConfig/reducer';
import Configuration_EventsConfig_EventManagement_Widget_Reducer from './Chapters/Configuration/EventsConfig/widgets/EventsManagement/redcuer';
import Configuration_Metrics_Module_Reducer from './Chapters/Configuration/Metrics/reducer';
import Configuration_SalesMetricsUpload_Module_Reducer from './Chapters/Configuration/SalesMetricsUpload/reducer';
import Configuration_RentPayments_Module_Reducer from './Chapters/Configuration/RentPayments/reducer';
import Configuration_Utilities_Module_Reducer from './Chapters/Configuration/Utilities/reducer';
import FPC_Monitoring_Module_Reducer from './Chapters/Configuration/FpcMonitoring/reducer';
//------------------- Events --------------------
import Events_EventsMap_Widget_Reducer from './Chapters/Events/EventsMap/widgets/EventsMapWidget/reducer';
import Events_Events_Module_Reducer from './Chapters/Events/Events/reducer';
import Events_Events_Summary_Widget_Reducer from './Chapters/Events/Events/widgets/SummaryTable/reducer';
import Events_Events_DynamicsPMO_Widget_Reducer from './Chapters/Events/Events/widgets/DynamicsPMO/reducer';
import Events_Events_TableOfObjects_Widget_Reducer from './Chapters/Events/Events/widgets/TableOfObjects/reducer';
//------------------- Decisions ----------------------
import Decisions_DecisionsMap_Widget_Reducer from './Chapters/Decisions/DecisionsMap/widgets/DecisionsMapWidget/reducer';
import Decisions_DecisionsConfig_Module_Reducer from './Chapters/Decisions/DecisionsConfig/reducer';
import Decisions_DecisionsConfig_DecisionsManagement_Widget_Reducer from './Chapters/Decisions/DecisionsConfig/widgets/DecisionsManagement/reducer';

//------------------- Other ----------------------
import GeneralReducer from './General.reducer';
import UserSettingsReducer from './components/UserSettings/reducer';
import PresetReducer from './components/Preset/reducer';
import CabinetPreferencesReducer from './components/CabinetPreferences/reducer';
import ChartSettingsReducer from './components/ChartSettings/reducer';
import AlertsReducer from './components/Alert/reducer';
import DynamicsSettingsReducer from './components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/reducer';

export const store = configureStore({
    reducer: {
        //------------------- Dashboards ----------------------
        Dashboards_Reducer,
        Dashboard_General_Module_Reducer,
        //------------------- Performance ----------------------
        Performance_Dashboard_Module_Reducer,
        Performance_Dashboard_Summary_Widget_Reducer,
        Performance_Dashboard_ByArea_Widget_Reducer,
        Performance_Dashboard_Traffic_Widget_Reducer,
        Performance_Dashboard_DynamicsPMO_Widget_Reducer,
        Performance_VisitorsInside_Widget_Reducer,
        Performance_YoY_YoY_PeriodModalBody_Component_Reducer,
        Performance_YoY_YoY_Widget_Reducer,
        Performance_PathToPurchase_Widget_Reducer,
        Performance_Ratings_Module_Reducer,
        Performance_Ratings_RatingList_Widget_Reducer,
        //------------------- Network ----------------------
        Network_Section_Reducer,
        Network_Portfolio_Overview_Module_Reducer,
        Network_Tenants_Overview_Module_Reducer,
        //------------------- Maps ----------------------
        Maps_MapsOverview_Module_Reducer,
        Maps_MapsOverview_Widget_Reducer,
        Maps_CrossVisits_Module_Reducer,
        Maps_CrossVisits_Widget_Reducer,
        //------------------- Sales ----------------------
        Sales_PosAnalysis_Module_Reducer,
        Sales_PosAnalysis_Dynamics_Pos_Widget_Reducer,
        Sales_PosAnalysis_StructureDynamics_Widget_Reducer,

        Sales_PaymentAnalysis_Module_Reducer,
        Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer,
        Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer,
        Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer,
        //------------------- Cases ----------------------
        Cases_Benchmarks_Module_Reducer,
        Cases_Benchmarks_Traffic_Widgwet_Reducer,
        Cases_Benchmarks_Sales_Widget_Reducer,
        Cases_Calculator_Calculator_Widget_Reducer,
        //------------------- Leasing ----------------------
        Leasing_TenantOverview_Module_Reducer,
        Leasing_TenantOverview_Info_Widget_Reducer,
        Leasing_TenantOverview_Summary_Widget_Reducer,
        Leasing_TenantOverview_Dynamics_Widget_Reducer,
        Leasing_TenantOverview_Reach_Widget_Reducer,
        Leasing_TenantOverview_Reference_Widget_Reducer,
        Leasing_Tenants_Tenants_Widget_Reducer,
        Leasing_CrossVisits_Module_Reducer,
        Leasing_CrossVisits_Widget_Reducer,
        Leasing_TenantOverview_IntersectionsWithTenants_Widget_Reducer,
        //------------------- Reports ----------------------
        Reports_Analytical_Module_Reducer,
        Advanced_Reports_Module_Reducer,
        Reports_Basic_Widget_Reducer,
        Reports_Comparison_Widget_Reducer,
        Reports_Intersections_Widget_Reducer,
        Reports_ByTenants_Widget_Reducer,
        Reports_ByTenantsComparison_Widget_Reducer,
        Reports_From_Partners_Module_Reducer,
        Reports_Bartel_Widget_Reducer,
        Reports_Advanced_SimplePaymentType_Widget_Reducer,
        Reports_Advanced_DetailedBills_Widget_Reducer,
        //------------------- Configuration ----------------------
        Configuration_Categories_Widget_Reducer,
        Configuration_EventsConfig_Module_Redcuer,
        Configuration_EventsConfig_EventManagement_Widget_Reducer,
        Configuration_Metrics_Module_Reducer,
        Configuration_SalesMetricsUpload_Module_Reducer,
        Configuration_RentPayments_Module_Reducer,
        Configuration_Utilities_Module_Reducer,
        FPC_Monitoring_Module_Reducer,
        //------------------- Events --------------------
        Events_EventsMap_Widget_Reducer,
        Events_Events_Module_Reducer,
        Events_Events_Summary_Widget_Reducer,
        Events_Events_DynamicsPMO_Widget_Reducer,
        Events_Events_TableOfObjects_Widget_Reducer,
        //------------------- Decisions ----------------------
        Decisions_DecisionsMap_Widget_Reducer,
        Decisions_DecisionsConfig_Module_Reducer,
        Decisions_DecisionsConfig_DecisionsManagement_Widget_Reducer,
        //------------------- Other ----------------------
        GeneralReducer,
        UserSettingsReducer,
        PresetReducer,
        CabinetPreferencesReducer,
        ChartSettingsReducer,
        AlertsReducer,
        DynamicsSettingsReducer,
    },
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    // middleware: [],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
