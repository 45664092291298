import { useEffect, RefObject } from 'react';

type MutationCallback = (type: 'childList' | 'attributes', mutation: MutationRecord) => void;

export const useDomObserver = (targetNode: Element | null, onChange: MutationCallback) => {
    useEffect(() => {
        if (!targetNode) {
            return;
        }

        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                console.log('MUTATION', mutation.type);

                if (mutation.type === 'childList') {
                    onChange('childList', mutation);
                }
                if (mutation.type === 'attributes') {
                    onChange('attributes', mutation);
                }
            }
        });

        const config: MutationObserverInit = {
            childList: true,
            attributes: true,
            subtree: true,
        };

        observer.observe(targetNode, config);

        return () => observer.disconnect();
    }, [targetNode, onChange]);
};
