import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useMemo } from 'react';
import { Group } from 'react-konva';
import { useTranslation } from 'react-i18next';

import {
    MapLayer,
    MapLayerByType,
    MapZonesLayer,
    MapPassWayLayer,
    MapPcISensorLayer,
    MapPerimeterLayer,
    MapZone,
    MapPlaceLayer,
    MapPlan,
} from 'src/tools/API';
import { POLYGON_NEUTRAL_COLOR } from 'src/Chapters/Configuration/FpcMonitoring/constants';
import { emptyArray } from 'src/tools';
import { theme } from 'src/theme';

import { recursiveTransform } from '../../core/transformCoords';
import { ObjectLabel } from '../../../../../../../components/ObjectLabel/ObjectLabel';
import { findLabelCoords } from '../../../../../../../tools/findLabelCoords';
import { findPolygonCenter } from '../../../../../../../tools/findPolygonCenter';
import { ILayer, IPerimeter, IPlace, IZone, TLayerData } from '../../../../../../../General.interfaces';
import { valueFormatter } from '../../../../../../../tools/Strings/valueFormatter';
import generateId from '../../../../../../../tools/generateId';

import Place from './components/Place/Place';
import { Props } from './interfaces';
import PassWay from './components/PassWay/PassWay';
// import { IPassWay } from './components/PassWay/interfaces';
import Zone from './components/Zone/Zone';
import { PcSensor } from './components/PcSensor/PcSensor';
import Perimeter from './components/Perimeter/Perimeter';

const getTransformedLayer = (layers: MapLayer[], finalPlan: unknown, layerType: string) => {
    const [actualLayer] = layers?.filter(
        (item) => item.layerType === layerType && item.floor === finalPlan?.['floor'],
    );
    if (!actualLayer?.data) {
        return null;
    }

    const transLayer = cloneDeep(actualLayer);
    recursiveTransform(transLayer, finalPlan?.['mainPlan'].plan2geo);
    recursiveTransform(transLayer, finalPlan?.['widestPlan'].mainPlan.geo2plan);
    return transLayer;
};

// const isZone = (abstractData: TLayerData): abstractData is IZone => {
//     return Object.hasOwn(abstractData, 'group_marker');
// };

// const hasCoords = (abstractData: TLayerData): abstractData is IPlace | IPerimeter => {
//     return Object.hasOwn(abstractData, 'coords');
// };

const hasLayerProperty = <T extends TLayerData>(abstractData: TLayerData, propertyName: string): abstractData is T => {
    return Object.hasOwn(abstractData, propertyName);
};

type LayerRenderer<T> = (props: T & { layerType: string; object: any; }) => JSX.Element | null;

const layersRenderersMap: { [key: string]: LayerRenderer<any> } = {
    'places_layer': (props) => {
        console.log('PLACE', props)
        return (
            <Place
                layerType={'places_layer'}
                object={props.object}
                key={props?.object?.marker || ''}
                heatColor={props.heatColor || POLYGON_NEUTRAL_COLOR} //Provide default
                {...props}
            />
        );
    },
    'tenants_layer': (props) => (
        <Place
            layerType={'tenants_layer'}
            object={props.object}
            key={props.object.marker || generateId()} // Handle optional marker
            heatColor={props.heatColor || POLYGON_NEUTRAL_COLOR}
            {...props}
        />
    ),
    'perimeter_layer': (props) => (
        <Perimeter
            object={props.object}
            key={props.object.marker}
            heatColor={props.heatColor || POLYGON_NEUTRAL_COLOR}
            {...props}
        />
    ),
    'pass_ways_layer': (props) => (
        <PassWay
            object={props.object} //Type assertion for better type safety
            key={props.object.marker}
            heatColor={props.heatColor || POLYGON_NEUTRAL_COLOR}
            planScale={props.planScale}
            stageScale={props.stageScale}
            {...props}
        />
    ),
    'pc_sensor_ipoints_layer': (props) => (
        <PcSensor
            object={props.object} //Type assertion for better type safety
            key={props.object.marker}
            heatColor={props.heatColor || POLYGON_NEUTRAL_COLOR}
            planScale={props.planScale}
            stageScale={props.stageScale}
            {...props}
        />
    ),
};

const Geometry: React.FC<Props> = ({
    layerType = 'places_layer',
    layers = [],
    plan,
    showLabels,
    stageScale,
    widgetSettings,
    selectedGroupId,
    allMetrics,
    sensorStatusesByMarker,
    ...props
}) => {
    const { t } = useTranslation();

    const backPerimeter = useMemo(() => {
        const transPerimeterLayer = getTransformedLayer(layers, plan, 'perimeter_layer');
        return layerType !== 'perimeter_layer'
            ? (transPerimeterLayer?.data || emptyArray).map((data) => {
                const layerData = data as MapPerimeterLayer;
                return (
                    <Perimeter
                        object={layerData}
                        key={layerData!.frontId}
                        heatColor={'rgba(250, 250, 250, 0.8)'}
                        {...props}
                    />
                );
              })
            : null;
    }, [layers, layerType, plan, props]);

    const passwayRelationGeometry = () => {
        if (!props.passwayRelationShapeMarker && props.selectedRelationId !== 'location') {
            return null;
        }
        if (layerType !== 'pass_ways_layer') {
            return null
        }
        if (props.selectedRelationId === 'zone') {
            const transZonesLayer = getTransformedLayer(layers, plan, 'zones_layer');
            const zonesOfSelectedGroup = ((transZonesLayer?.data || emptyArray) as MapZonesLayer[])
                .find((zonesData) => zonesData.groupMarker === selectedGroupId);
            if (!zonesOfSelectedGroup) {
                return null
            }

            const passWayRelatedZone = ((zonesOfSelectedGroup?.zones || emptyArray) as MapZone[])
                .find((zone) => zone.zoneMarker === props.passwayRelationShapeMarker);

            return passWayRelatedZone ? (
                <Zone object={passWayRelatedZone} stageScale={stageScale} heatColor={'rgba(0,0,50,0.4)'} {...props} />
            ) : null;
        }
        if (props.selectedRelationId === 'place') {
            const transPlacesLayer = getTransformedLayer(layers, plan, 'places_layer');
            const placeObject = ((transPlacesLayer?.data || emptyArray) as MapPlaceLayer[])
                .find((item) => item.marker === props.passwayRelationShapeMarker);

            return placeObject ? <Place object={placeObject} heatColor={'rgba(0,0,50,0.4)'} {...props} /> : null;
        }
        if (props.selectedRelationId === 'location') {
            const transPerimeterLayer = getTransformedLayer(layers, plan, 'perimeter_layer');
            return ((transPerimeterLayer?.data || emptyArray) as MapPerimeterLayer[])
                .map((data) => (
                    <Perimeter key={data.frontId} object={data} heatColor={'rgba(0,0,50,0.4)'} {...props} />
                ));
        }
        return null;
    };

    const transPcsSensorLayer = getTransformedLayer(layers, plan, 'pc_sensor_ipoints_layer');
    const layerPciPointDataObjects = (transPcsSensorLayer?.data || emptyArray) as MapPcISensorLayer[];

//     const sensors = layers.filter(
// (layer): layer is MapLayerByType<'pc_sensor_ipoints_layer'> =>
//     layer.layerType === 'pc_sensor_ipoints_layer'
// );
    const pciPoints = layerPciPointDataObjects
        // .filter(({ layerType }) => layerType === 'pc_sensor_ipoints_layer')
        .map((dataObject) => {
            const renderer = layersRenderersMap['pc_sensor_ipoints_layer']; // Get the renderer function
            const heatColor = sensorStatusesByMarker?.[dataObject.marker]?.description.fill || theme.colors.secondaryDark;
            return renderer({
                object: dataObject,
                planScale: plan?.scale,
                stageScale,
                heatColor,
            });
        });

    const transLayer = getTransformedLayer(layers, plan, layerType);
    const geometry = transLayer?.data
        ?.filter((item: TLayerData | { marker?: string }) =>
            layerType === 'zones_layer'
            || layerType === 'perimeter_layer'
            // || layerType === 'tenants_layer'
                ? true
                : false,
        )
        .map((layer) => {
            const renderer = layersRenderersMap[layerType]; // Get the renderer function
            return !!renderer ? renderer({
                object: layer,
                heatColor: POLYGON_NEUTRAL_COLOR,
                planScale: plan?.scale,
                stageScale,
                ...props,
            }) : null;  // Return null if no renderer in layersRenderersMap
        });
        // .map((layer) => {
        //     switch (layerType) {
        //         case 'places_layer':
        //             const heatColor = POLYGON_NEUTRAL_COLOR;
        //             return (
        //                 <Place
        //                     layerType={'places_layer'}
        //                     object={layer}
        //                     key={layer.marker}
        //                     heatColor={heatColor}
        //                     {...props}
        //                 />
        //             );
        //         case 'tenants_layer': {
        //             const heatColor = POLYGON_NEUTRAL_COLOR;

        //             const key = generateId();

        //             return (
        //                 <Place layerType={'tenants_layer'} object={layer} key={key} heatColor={heatColor} {...props} />
        //             );
        //         }
        //         case 'perimeter_layer': {
        //             const heatColor = POLYGON_NEUTRAL_COLOR;
        //             return <Perimeter object={layer} key={layer.marker} heatColor={heatColor} {...props} />;
        //         }

        //         case 'pass_ways_layer': {
        //             const heatColor = POLYGON_NEUTRAL_COLOR;
        //             return (
        //                 <PassWay
        //                     object={layer as MapPassWay}
        //                     key={layer.marker}
        //                     heatColor={heatColor}
        //                     planScale={plan.scale}
        //                     stageScale={stageScale}
        //                     {...props}
        //                 />
        //             );
        //         }

        //         default:
        //             return null;
        //     }
        // });

    const generateValue = (str: string | number | undefined) => {
        if (str === undefined) return '';
        let result = valueFormatter({ value: str });
        if (widgetSettings.includes('comparison')) {
            result = result + '%';
        }
        return result;
    };

    // const labels = transLayer?.data
    //     ?.filter((item) =>
    //         layerType === 'zones_layer' || layerType === 'perimeter_layer'
    //             ? true
    //             : allowedMarkers.includes(item.marker ?? ''),
    //     )
    //     .map((layer) => {
    //         switch (layerType) {
    //             case 'places_layer':
    //             case 'tenants_layer': {
    //                 if (!hasLayerProperty<IPlace>(layer, 'coords')) {
    //                     return null;
    //                 }

    //                 const labelCoords = findPolygonCenter(layer.coords.coordinates);
    //                 let value = generateValue(props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['value']);
    //                 const name = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['name'];
    //                 const id = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['id'];
    //                 return (
    //                     <ObjectLabel
    //                         key={id}
    //                         stageScale={stageScale}
    //                         x={labelCoords?.x}
    //                         y={labelCoords?.y}
    //                         name={name}
    //                         value={`${value} ${units}`}
    //                         selected={false}
    //                         planScale={plan.scale}
    //                     />
    //                 );
    //             }
    //             case 'pass_ways_layer':
    //                 if (!hasLayerProperty<IPassWay>(layer, 'passLine')) {
    //                     return null;
    //                 }
    //                 const labelCoords = findLabelCoords(layer.passLine.coordinates);
    //                 const name = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['name'];
    //                 const value = generateValue(props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['value']);
    //                 const id = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['id'];
    //                 return (
    //                     <ObjectLabel
    //                         key={id}
    //                         stageScale={stageScale}
    //                         x={labelCoords?.x}
    //                         y={labelCoords?.y}
    //                         name={name}
    //                         value={`${value} ${units}`}
    //                         selected={false}
    //                         planScale={plan.scale}
    //                     />
    //                 );

    //             case 'zones_layer':
    //                 if (!layer) {
    //                     return null;
    //                 } else {
    //                     if (hasLayerProperty<IZone>(layer, 'group_marker') && layer.group_marker === selectedGroupId) {
    //                         return layer.zones.map((object: IZone, i: number) => {
    //                             const labelCoords = findPolygonCenter(object.coords.coordinates);
    //                             const name = props.colorsByMarker?.[props.metric]?.[object.zone_marker]?.['name'];
    //                             const value = generateValue(
    //                                 props.colorsByMarker?.[props.metric]?.[object.zone_marker]?.['value'],
    //                             );
    //                             const id = props.colorsByMarker?.[props.metric]?.[object.zone_marker]?.['id'] + `${i}`;
    //                             return (
    //                                 <ObjectLabel
    //                                     key={id}
    //                                     stageScale={stageScale}
    //                                     x={labelCoords?.x}
    //                                     y={labelCoords?.y}
    //                                     name={name}
    //                                     value={`${value} ${units}`}
    //                                     selected={false}
    //                                     planScale={plan.scale}
    //                                 />
    //                             );
    //                         });
    //                     } else {
    //                         return null;
    //                     }
    //                 }

    //             default:
    //                 return null;
    //         }
    //     });

    // const overLabel = transLayer?.data
    //     ?.filter((item) =>
    //         layerType === 'zones_layer' || layerType === 'perimeter_layer'
    //             ? true
    //             : allowedMarkers.includes(item?.marker ?? ''),
    //     )
    //     .map((layer) => {
    //         let value = generateValue(props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['value']);
    //         switch (layerType) {
    //             case 'places_layer':
    //             case 'tenants_layer': {
    //                 if (!hasLayerProperty<IPlace>(layer, 'coords')) {
    //                     return null;
    //                 }
    //                 const labelCoords = findPolygonCenter(layer.coords.coordinates);
    //                 const name = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['name'];
    //                 const id = generateId();
    //                 // const id = props.colorsByMarker?.[props.metric]?.[data.marker]?.['id'];
    //                 return props.overMarker === layer.marker ? (
    //                     <ObjectLabel
    //                         key={id}
    //                         stageScale={stageScale}
    //                         x={labelCoords?.x}
    //                         y={labelCoords?.y}
    //                         name={name}
    //                         value={`${value} ${units}`}
    //                         selected={false}
    //                         planScale={plan.scale}
    //                     />
    //                 ) : null;
    //             }
    //             case 'pass_ways_layer':
    //                 if (!hasLayerProperty<IPassWay>(layer, 'passLine')) {
    //                     return null;
    //                 }
    //                 const labelCoords = findLabelCoords(layer.passLine.coordinates);
    //                 const name = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['name'];
    //                 const id = props.colorsByMarker?.[props.metric]?.[layer.marker ?? '']?.['id'];
    //                 return props.overMarker === layer.marker ? (
    //                     <ObjectLabel
    //                         key={id}
    //                         stageScale={stageScale}
    //                         x={labelCoords?.x}
    //                         y={labelCoords?.y}
    //                         name={name}
    //                         value={`${value} ${units}`}
    //                         selected={false}
    //                         planScale={plan.scale}
    //                     />
    //                 ) : null;

    //             case 'zones_layer':
    //                 if (!layer) {
    //                     return null;
    //                 }

    //                 if (!hasLayerProperty<IZone>(layer, 'zones')) {
    //                     return null;
    //                 }

    //                 if (layer.group_marker === selectedGroupId) {
    //                     return layer.zones.map((object: IZone) => {
    //                         const labelCoords = findPolygonCenter(object.coords.coordinates);
    //                         const name = props.colorsByMarker?.[props.metric]?.[object.zone_marker]?.['name'];
    //                         const value = generateValue(
    //                             props.colorsByMarker?.[props.metric]?.[object.zone_marker]?.['value'],
    //                         );
    //                         const id = props.colorsByMarker?.[props.metric]?.[object.zone_marker]?.['id'];

    //                         return props.overMarker === `${object.zone_marker}` ? (
    //                             <ObjectLabel
    //                                 key={id}
    //                                 stageScale={stageScale}
    //                                 x={labelCoords?.x}
    //                                 y={labelCoords?.y}
    //                                 name={name}
    //                                 value={`${value} ${units}`}
    //                                 selected={false}
    //                                 planScale={plan.scale}
    //                             />
    //                         ) : null;
    //                     });
    //                 } else {
    //                     return null;
    //                 }

    //             default:
    //                 return null;
    //         }
    //     });

    return (
        <Group>
            {widgetSettings.includes('showPerimeters') && backPerimeter}
            {passwayRelationGeometry()}
            {geometry}
            {pciPoints}
            {/* <div>AAAAA</div> */}
            {/* {showLabels && labels}
            {!showLabels && overLabel} */}
        </Group>
    );
};

export default Geometry;
