import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../store';
import { ISettings, IReducerState, IAccountParams, TCurrentOptions, IChangeOptionsArgs } from './interfaces';
import { getStoreMethod } from '../../tools/getStoreMethod';

export const defaultOptions: ISettings = {
    mainPeriod: { id: 'previousMonth' },
    detail: { id: null, text: 'Without detail' },
    comparePeriods: [{ id: 'sameMonthdays' }],
    selectedMetrics: ['fpc_sum_pass_count_in_wh'],
};

const initialState: IReducerState = {
    accParamsID: null,
    settings: null,
    currentOptions: {
        ...defaultOptions,
        currentModuleID: 'Dashboard designer:Object panels',
        selectedLocationId: null,
        mainDateRanges: [],
        compareDateRanges: [],
    },
};

export const UserSettingsReducer = createSlice({
    name: 'UserSettingsReducer',
    initialState,

    reducers: {
        /**
         * Сохранение настроек.
         */
        storeCurrentOptions: (state, action: PayloadAction<{ [x: string]: any }>) => {
            const newCurrentOptions = {};
            Object.keys(action.payload).forEach((key) => {
                newCurrentOptions[key] = action.payload[key];
            });

            state.currentOptions = newCurrentOptions as TCurrentOptions;
        },

        storeAccParams: (state, action: PayloadAction<{ id: number; params: IAccountParams }>) => {
            state.accParamsID = action.payload.id;
            state.settings = action.payload.params;
        },

        changeSettings: (state, action: PayloadAction<IAccountParams>) => {
            state.settings = action.payload;
        },
    },
});

export const { storeAccParams, changeSettings, storeCurrentOptions } = UserSettingsReducer.actions;

export const userSettingsReducerValues = (state: RootState) => state.UserSettingsReducer;

export default UserSettingsReducer.reducer;

export const changeOptions =
    (args: IChangeOptionsArgs): AppThunk =>
    (dispatch, getState) => {
        let { newOptions, currentModuleID = '', isGlobal = false } = args;
        const settings = cloneDeep(getState().UserSettingsReducer).settings || {};
        if (!currentModuleID) {
            currentModuleID = cloneDeep(getState().GeneralReducer.currentModuleID);
        }

        const { modulesConfig } = cloneDeep(getState().GeneralReducer);
        let fullKey = ``;
        const pl: [{ id: number | string }] = (newOptions?.['pl'] || settings?.['global']?.['pl']) as [
            { id: number | string },
        ];
        Object.keys(newOptions).forEach((key) => {
            const storeMethod = getStoreMethod(modulesConfig, currentModuleID, key);
            if (storeMethod === 'byML') {
                fullKey = `${storeMethod}:${currentModuleID}:${pl[0].id}`;
            } else if (storeMethod === 'byM') {
                fullKey = `${storeMethod}:${currentModuleID}`;
            } else if (storeMethod === 'byL') {
                fullKey = `${storeMethod}:${pl[0].id}`;
            } else if (storeMethod === 'global' || isGlobal) {
                fullKey = `global`;
            } else return;
            let options = settings?.[fullKey];
            if (!options) settings[fullKey] = { ...defaultOptions };
            settings[fullKey][key] = newOptions[key];
        });

        dispatch(changeSettings(settings));
    };
