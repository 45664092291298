import { useMutation } from '@tanstack/react-query';
import axios, { Axios, AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { generalReducerValues } from 'src/General.reducer';
import { Configuration_Utilities_Module_Reducer_Values } from '../../../reducer';
import { ICell } from '../../../interfaces';
import { useWidgetCurrentOptions } from 'src/hooks';

type PostDataArgs = {
    url: string;
    main_arrs: string[][];
    comp_arrs: string[][];
    o2o_max_dist?: number;
    partial_rate?: number;
};

// Тип для объекта внутри массива "matchings"
type Matching = {
    j: number;
    comp_token: string;
    dist: number;
};

// Основной тип для данных
export type DataItem = {
    i: number;
    main_token: string;
    matchings: Matching[];
};

const postData = async ({ url, main_arrs, comp_arrs, o2o_max_dist = 0.3, partial_rate = 0.5 }: PostDataArgs) => {
    const data = { main_arrs, comp_arrs, o2o_max_dist, partial_rate };
    const response = await axios.post(url, data);
    return response.data;
};

export const useLevenstain = () => {
    const { dataToEdit, selectedFields } = useSelector(Configuration_Utilities_Module_Reducer_Values);

    const { currentModuleID } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const o2o_max_dist = Number(localCurrentOptions?.['o2o']?.[0]?.id) || 0.3;

    const dictColName = selectedFields.dict?.[0].id?.toString() || '';
    const sourceColName = selectedFields.source?.[0].id;

    const findColIndex = (data: ICell[][], field: string) => {
        return data?.find((row) => row.some((cell) => cell.value === field))?.findIndex((cell) => cell.value === field);
    };

    const getArrToCompare = (data: ICell[][], field: string): string[] => {
        if (findColIndex(data, field) !== undefined) {
            const index = findColIndex(data, field) as number;
            const result = data
                .map((row) => row[index]?.value)
                .filter((value): value is string => value !== null && value !== undefined && value !== '');
            result.shift();
            return result;
        } else {
            return [];
        }
    };

    const main_arrs = getArrToCompare(dataToEdit['dict'], dictColName);
    const comp_arrs = getArrToCompare(dataToEdit['source'], sourceColName);

    var { urlsByServices } = useSelector(generalReducerValues);
    const levensteinUrl = urlsByServices?.['core/structure-service']
        ? urlsByServices['core/admin-service'].LEVENSTEIN_URL
        : '';

    const mutation = useMutation<DataItem[][], AxiosError>({
        mutationKey: ['TablesMatch'],
        mutationFn: () => {
            return postData({ url: levensteinUrl, o2o_max_dist, main_arrs: [main_arrs], comp_arrs: [comp_arrs] });
        },
        onSuccess: () => {},
    });

    return mutation;
};
