import { useEffect, useState } from 'react';
import { IArgs } from '../styles/styles.interfaces';
import _ from 'lodash';

const DEFAULT_CLASS_PADDING_PREFIX = 'space_inner_';
const DEFAULT_CLASS_MARGIN_PREFIX = 'space_outer_';

const useIndent = ({ styles: { padding, margin } }: IArgs): string[] => {
    const [classes, setClasses] = useState<string[]>([]);

    useEffect(() => {
        const classes: string[] = [];

        const paddingClasses = padding?.split(' ').map((item) => {
            return `${DEFAULT_CLASS_PADDING_PREFIX}${item}`;
        });

        const marginClasses = margin?.split(' ').map((item) => {
            return `${DEFAULT_CLASS_MARGIN_PREFIX}${item}`;
        });

        if (marginClasses) {
            classes.push(...marginClasses);
        }

        if (paddingClasses) {
            classes.push(...paddingClasses);
        }

        setClasses(classes);
    }, [padding, margin]);

    return classes;
};

export default useIndent;
