import { useEffect, useState } from 'react';
import { IArgs } from '../styles/styles.interfaces';

const useColor = ({
    ref,
    styles: { color, bgColor },
    constants: { DEFAULT_CLASS_COLOR_PREFIX, DEFAULT_SEPARATOR_NAME_SPACE_PREFIX, DEFAULT_CLASS_BACKGROUND_PREFIX },
}: IArgs): string[] => {
    const [classes, setClasses] = useState<string[]>([]);

    useEffect(() => {
        const colorClasses = [];
        if (color) {
            colorClasses.push(`${DEFAULT_CLASS_COLOR_PREFIX}${DEFAULT_SEPARATOR_NAME_SPACE_PREFIX}${color}`);
        }

        if (bgColor) {
            colorClasses.push(`${DEFAULT_CLASS_BACKGROUND_PREFIX}${DEFAULT_SEPARATOR_NAME_SPACE_PREFIX}${bgColor}`);
        }

        setClasses(colorClasses);
    }, [color, bgColor]);

    return classes;
};

export default useColor;
