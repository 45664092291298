import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    padding: 16px;
    width: 280px;
`;

export const SearchWrapper = styled.div`
    margin-bottom: var(--space-xxs);
`;

export const ReportingObjectsListWrapper = styled.div`
    max-height: 250px;
    overflow: auto;
`;

export const ListWrapper = styled.div`
    max-height: 350px;
    overflow: auto;
`;

export const ButtonWrapper = styled.div`
    margin-top: var(--space-xxs);
    display: flex;
    justify-content: flex-end;
`;

export const CheckboxWrapper = styled.div`
    padding: var(--space-xxxxs) 0;
`;
