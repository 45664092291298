import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    /* margin-right: var(--space-sm); */
    /* input {
        max-width: 100px;
    } */
`;

export const HeaderWrapper = styled.div`
    width: 100%;
    min-height: 62px;
    border-bottom: 1px solid var(--color-border-gray);
    display: flex;
    justify-content: center;
`;

export const Label = styled.div`
    font-size: 12px;
    opacity: 0.4;
`;

export const HeaderCentralArea = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    max-width: 1230px;
    padding: 0 var(--space-md);
`;

export const HeaderControlsArea = styled.div`
    display: flex;
    align-items: center;
`;

export const LangWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: var(--space-md);
    width: 36px;
    height: 36px;
    border: 1px solid var(--color-border-gray);
`;
