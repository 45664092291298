import { useEffect } from 'react';
import { isNumber, isUndefined } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { useGeneralSelector, useFilteredObjects } from 'src/hooks';

import { storePreraredData, Leasing_TenantOverview_Reference_Widget_Reducer_Values } from '../reducer';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../../reducer';
import { filterValidDateRanges } from '../../../../../../tools/filterValidDateRanges';
import { ITenant2FloorRelation, ITenant2ZoneRelation, TMetricResponse } from '../../../../../../General.interfaces';
import getDifferenceBetweenNumbers from '../../../../../../tools/getDifferenceBetweenNumbers';
import { DS, ZONES_MIN_CONTENT_PERCENTAGE } from '../../../../../../constants/constants';
import { ITenantData, ITenantMetricsData } from '../interfaces';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';

export const usePrepareData = () => {
    const { t } = useTranslation(['translation']);
    const dispatch = useDispatch();
    const {
        cfg: { tenant2ZoneByTenantId, tenant2FloorByTenantId, reportingObjectsById },
        src: { projectCategories, dataObj2ProjectCategory },
    } = useSelector(generalReducerValues);
    const { allTenantsData, selectedTab } = useSelector(Leasing_TenantOverview_Reference_Widget_Reducer_Values);
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);

    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    const filteredTenants = useFilteredObjects('tenant', {
        mode: 'multi',
        validOptions: [t('Tenants')],
        dataObjectFilters: ['data_objects_tenant'],
    });

    const filteredTenantsIds = filteredTenants.map(({ id }) => id);

    const selectedTenantsData = !Array.isArray(allTenantsData)
        ? allTenantsData
        : allTenantsData.map((dataSeries) =>
              dataSeries.filter((data) => filteredTenantsIds.includes(data.context.data_objects?.[0].id)),
          );

    useEffect(() => {
        if (!Array.isArray(selectedTenantsData)) return;

        const selectedTenantId = localCurrentOptions?.selectedReportingObjectsIds?.[0];

        if (selectedTab === 'otherTenants') {
            const otherTenants = localCurrentOptions?.['otherTenants']
                ?.filter((element: { id: number; text: string }) => element.id !== selectedTenantId)
                ?.map((element: { id: number; text: string }) => element.id);
            const allTenants = [selectedTenantId];
            if (Array.isArray(otherTenants)) {
                allTenants.push(...otherTenants);
            }
            const preparedData = generateTableData(allTenants, selectedTenantsData, '');
            dispatch(storePreraredData(preparedData));
        } else {
            const mainPeriod = localCurrentOptions?.mainDateRanges?.filter(
                (item) => item.id === localCurrentOptions?.mainPeriod?.id,
            )[0]?.period;

            const zoneOfSelectedTenant = selectedTenantId
                ? filterValidDateRanges(
                      tenant2ZoneByTenantId?.[selectedTenantId]?.filter(
                          (item) => item.content_percentage >= ZONES_MIN_CONTENT_PERCENTAGE,
                      ),
                      mainPeriod,
                  )
                : [];

            const sameZoneTenants = filterValidDateRanges(
                Object.values(tenant2ZoneByTenantId)
                    .reduce((acc, value) => {
                        const result: ITenant2ZoneRelation[] = [];
                        Array.isArray(value) &&
                            value?.forEach((item) => {
                                if (item.zone_marker === zoneOfSelectedTenant?.[0]?.zone_marker) {
                                    result.push(item);
                                }
                            });
                        return [...acc, ...result];
                    }, [])
                    ?.filter((item) => item.content_percentage >= ZONES_MIN_CONTENT_PERCENTAGE),
                mainPeriod,
            )
                .map((item) => item.tenant_id)
                ?.filter((id) => id !== selectedTenantId);

            const floorOfSelectedTenant = selectedTenantId
                ? filterValidDateRanges(tenant2FloorByTenantId?.[selectedTenantId], mainPeriod)
                : [];

            const sameFloorTenants = filterValidDateRanges(
                Object.values(tenant2FloorByTenantId).reduce((acc, value) => {
                    const result: ITenant2FloorRelation[] = [];
                    Array.isArray(value) &&
                        value?.forEach((item) => {
                            if (item.floor === floorOfSelectedTenant?.[0]?.floor) {
                                result.push(item);
                            }
                        });
                    return [...acc, ...result];
                }, []),
                mainPeriod,
            )
                .map((item) => item.tenant_id)
                ?.filter((id) => id !== selectedTenantId);

            //--------------------------------------

            const selectedTenantCategories = dataObj2ProjectCategory?.filter(
                (item) => item.data_object_id === selectedTenantId,
            );

            const existingParentCategoryIds: number[] = [];

            const preparedCategoryData = selectedTenantCategories.reduce((acc, tenantCategory) => {
                const parentCategoryId = projectCategories?.find(
                    (item) => item.id === tenantCategory.category_id,
                )?.parent_id;

                let isParentCatExist = false;

                if (parentCategoryId) {
                    if (existingParentCategoryIds.includes(parentCategoryId)) {
                        isParentCatExist = true;
                    } else {
                        existingParentCategoryIds.push(parentCategoryId);
                    }
                }

                if (parentCategoryId && existingParentCategoryIds.includes(parentCategoryId)) {
                    existingParentCategoryIds.push(parentCategoryId);
                }

                const parentCategoryName =
                    projectCategories?.find((item) => item.id === parentCategoryId)?.object_name || '';

                const sameCategoryTenants = dataObj2ProjectCategory
                    ?.filter((item) => item.category_id === tenantCategory.category_id)
                    .map((item) => {
                        return item.data_object_id;
                    })
                    ?.filter((id) => id !== selectedTenantId);

                const result = generateTableData(sameCategoryTenants, selectedTenantsData, parentCategoryName, false);
                !isParentCatExist &&
                    result.unshift(generateSummaryRow(sameCategoryTenants, selectedTenantsData, parentCategoryName));

                return [...acc, ...result];
            }, []);

            const preparedCategoryDataByCatAlias: {
                [alias: string]: { category: ITenantData; tenants: ITenantData[] };
            } = (preparedCategoryData as ITenantData[]).reduce((acc, value: ITenantData) => {
                if (value.tenantId === -1) {
                    acc[value.alias] = {
                        category: value,
                        tenants: [] as ITenantData[],
                    };
                } else {
                    acc[value.alias].tenants.push(value);
                }

                return acc;
            }, {});

            const sortedPreparedCategoryData = Object.values(preparedCategoryDataByCatAlias).reduce(
                (acc: ITenantData[], value) => {
                    const { tenants, category } = value;
                    const sortedTenants = tenants.sort(
                        (a, b) => Number(b.metricsData?.[0].main) - Number(a.metricsData?.[0].main),
                    );

                    const result = [category, ...sortedTenants];
                    return acc.concat(result);
                },
                [],
            );

            //--------------------------------------

            const setectedTenantData = generateTableData([selectedTenantId], selectedTenantsData, 'selectedTenant');

            const preparedZoneData = generateTableData(sameZoneTenants, selectedTenantsData, 'zone');
            preparedZoneData.unshift(generateSummaryRow(sameZoneTenants, selectedTenantsData, 'zone'));

            const preparedFloorData = generateTableData(sameFloorTenants, selectedTenantsData, 'floor');
            preparedFloorData.unshift(generateSummaryRow(sameFloorTenants, selectedTenantsData, 'floor'));

            dispatch(
                storePreraredData([
                    ...setectedTenantData,
                    ...preparedZoneData,
                    ...preparedFloorData,
                    ...sortedPreparedCategoryData,
                ]),
            );
        }
    }, [
        allTenantsData,
        dataObj2ProjectCategory,
        dispatch,
        localCurrentOptions,
        projectCategories,
        selectedTab,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
    ]);

    const generateTableData = (
        tenantsArr: Array<number | undefined>,
        allTenantsData: TMetricResponse[],
        alias: string,
        shouldSortData = true,
    ): ITenantData[] => {
        const selectedTenantId = localCurrentOptions?.selectedReportingObjectsIds?.[0];
        const result = tenantsArr
            .filter((item) => !isUndefined(item))
            .filter((item) => filteredTenantsIds.includes(item!))
            .map((tenantId: number) => {
                const tenantName = reportingObjectsById?.[tenantId]?.name || tenantId.toString();

                const metricsData: ITenantMetricsData[] = (localCurrentOptions?.selectedMetrics || []).map((metric) => {
                    const selectedTenantMain = allTenantsData
                        ?.find((item) => item[0]?.context?.alias === `${metric}${DS}main`)
                        ?.find((item) => item.context.data_objects[0]?.id === selectedTenantId)?.items[0].value;

                    const selectedTenantComparison = allTenantsData
                        ?.find((item) => item[0]?.context?.alias === `${metric}${DS}comparison`)
                        ?.find((item) => item.context.data_objects[0]?.id === selectedTenantId)?.items[0].value;

                    const selectedTenantDeviation =
                        isNumber(selectedTenantMain) &&
                        isNumber(selectedTenantComparison) &&
                        selectedTenantComparison > 0
                            ? Number(
                                  getDifferenceBetweenNumbers(selectedTenantMain, selectedTenantComparison)
                                      .percentDifference,
                              )
                            : null;

                    const main = allTenantsData
                        ?.find((item) => item[0]?.context?.alias === `${metric}${DS}main`)
                        ?.find((item) => item.context.data_objects[0]?.id === tenantId)?.items[0].value;

                    const referenceCompare =
                        selectedTenantMain && main
                            ? Number(getDifferenceBetweenNumbers(selectedTenantMain, main).percentDifference)
                            : '–';

                    const compare = allTenantsData
                        ?.find((item) => item[0]?.context?.alias === `${metric}${DS}comparison`)
                        ?.find((item) => item.context.data_objects[0]?.id === tenantId)?.items[0].value;

                    const deviation: string | number =
                        main && compare ? Number(getDifferenceBetweenNumbers(main, compare).percentDifference) : '–';

                    const deviationByReferenceCompare: string | number =
                        isNumber(selectedTenantDeviation) && isNumber(deviation)
                            ? selectedTenantDeviation - deviation
                            : '-';

                    return { metric, main, deviation, referenceCompare, deviationByReferenceCompare };
                });

                const isSelectedTenant = tenantId === selectedTenantId;
                return { tenantId, tenantName, metricsData, isSelectedTenant, alias };
            });

        if (shouldSortData) {
            return result.sort((a, b) => Number(b.metricsData?.[0].main) - Number(a.metricsData?.[0].main));
        }

        return result;
    };

    const generateSummaryRow = (
        tenantsArr: Array<number>,
        allTenantsData: TMetricResponse[],
        alias: string,
    ): ITenantData => {
        const selectedTenantId = localCurrentOptions?.selectedReportingObjectsIds?.[0];
        const metricsData = (localCurrentOptions?.selectedMetrics || []).map((metric) => {
            /** Значение метрики для выбранного арендатора */
            const selectedTenantMain = allTenantsData
                ?.find((item) => item[0]?.context?.alias === `${metric}${DS}main`)
                ?.find((item) => item.context.data_objects[0]?.id === selectedTenantId)?.items[0].value;

            /** Значение метрики выбранного арендатора для периода сравнения */
            const selectedTenantComparison = allTenantsData
                ?.find((item) => item[0]?.context?.alias === `${metric}${DS}comparison`)
                ?.find((item) => item.context.data_objects[0]?.id === selectedTenantId)?.items[0].value;

            /** Разница в процентах основного периода и сравнения для выбранного арендатора */
            const selectedTenantDeviation =
                isNumber(selectedTenantMain) && isNumber(selectedTenantComparison) && selectedTenantComparison > 0
                    ? Number(
                          getDifferenceBetweenNumbers(selectedTenantMain, selectedTenantComparison).percentDifference,
                      )
                    : null;

            /** Сумма по всей группе */
            const main = tenantsArr.reduce((acc, tenantId) => {
                const mTraffic =
                    allTenantsData
                        ?.find((item) => item?.[0]?.context?.alias === `${metric}${DS}main`)
                        ?.find((item) => item.context.data_objects[0].id === tenantId)?.items[0].value || 0;
                return acc + mTraffic;
            }, 0);

            /** Элементы группы, по основному периоду, у которых значение не null */
            const refTenantsWithDataMain = allTenantsData
                ?.find((item) => item?.[0]?.context?.alias === `${metric}${DS}main`)
                ?.filter(
                    (item) =>
                        tenantsArr.includes(item.context.data_objects[0].id) &&
                        isNumber(item.items[0].value) &&
                        isFinite(item.items[0].value),
                );

            /** Среднее для группы */
            const mainAvg = refTenantsWithDataMain?.length ? main / refTenantsWithDataMain?.length : 0;

            /** Сравнение выбранного арендатора с группой */
            const referenceCompare =
                isNumber(selectedTenantMain) && main > 0
                    ? Number(getDifferenceBetweenNumbers(selectedTenantMain, main).percentDifference)
                    : null;

            /** Сравнение выбранного арендатора со средним по группе */
            const referenceCompareByMainAvg =
                isNumber(selectedTenantMain) && mainAvg > 0
                    ? Number(getDifferenceBetweenNumbers(selectedTenantMain, mainAvg).percentDifference)
                    : null;

            /** Значение для периода сравнения группы */
            const compare = tenantsArr.reduce((acc, tenantId) => {
                const mTraffic =
                    allTenantsData
                        ?.find((item) => item?.[0]?.context.alias === `${metric}${DS}comparison`)
                        ?.find((item) => item.context.data_objects[0].id === tenantId)?.items[0].value || 0;
                return acc + mTraffic;
            }, 0);

            /** Элементы группы, по периоду сравнения, у которых значение не null */
            const refTenantsWithDataCompare = allTenantsData
                ?.find((item) => item?.[0]?.context?.alias === `${metric}${DS}comparison`)
                ?.filter((item) => {
                    return (
                        tenantsArr.includes(item.context.data_objects[0].id) &&
                        isNumber(item.items[0].value) &&
                        isFinite(item.items[0].value)
                    );
                });

            /** Среднее для периода сравнения */
            const compareAvg = refTenantsWithDataCompare?.length ? compare / refTenantsWithDataCompare?.length : 0;

            const deviation: string | number =
                main && compare ? Number(getDifferenceBetweenNumbers(main, compare).percentDifference) : '–';

            const deviationAvg =
                main && compare && refTenantsWithDataCompare?.length
                    ? Number(getDifferenceBetweenNumbers(mainAvg, compareAvg).percentDifference)
                    : '–';

            const deviationByReferenceCompare: string | number =
                isNumber(deviation) && isNumber(selectedTenantDeviation) ? selectedTenantDeviation - deviation : '-';

            return {
                metric,
                main,
                deviation,
                referenceCompare,
                mainAvg,
                deviationAvg,
                referenceCompareByMainAvg,
                deviationByReferenceCompare,
            };
        });

        const result = {
            tenantId: -1,
            tenantName: `${t('Tenants of')} ${t(alias)}`,
            isSelectedTenant: false,
            metricsData,
            alias,
            collapseable: true,
        };

        return result;
    };
};
