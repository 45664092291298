import { useSelector } from 'react-redux';
import {
    Configuration_Utilities_Module_Reducer_Values,
    fillTable,
    resetState,
    storeSelectedField,
} from '../../reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { useTranslation } from 'react-i18next';
import { Panel, PanelGroup } from 'react-resizable-panels';

import ResizeHandle from 'src/components/ResizeHandle/ResizeHandle';
import { IChangeOptionsArgs, TSelectedOptions, UniversalSelect } from 'src/components/Selects';
import { Controls, PanelHeader, PanelTitle } from './styles';
import { Button } from 'src/components/Button';
import Table from './components/Table/Table';
import { useLevenstain } from './api/useLevenstain';
import { ICell } from '../../interfaces';
import { useEffect } from 'react';
import { generateMatchingsTable } from '../../tools/generateMatchingsTable';
import { generalReducerValues } from 'src/General.reducer';
import { useWidgetCurrentOptions } from 'src/hooks';
import { changeOptions } from 'src/components/UserSettings/reducer';
import IconButton from 'src/components/IconButton/IconButton';
import { ArrowClockwiseFilled } from '@fluentui/react-icons';
import { generateEmptyData } from '../../tools/generateEmptyData';
import Tooltip from 'src/components/Tooltip/Tooltip';

interface IProps {}

export const Matching: React.FC<IProps> = () => {
    const { dataToEdit, selectedFields } = useSelector(Configuration_Utilities_Module_Reducer_Values);
    const { currentModuleID } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { mutate, isPending, data } = useLevenstain();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (data) {
            const matched = generateMatchingsTable(data, dataToEdit);
            dispatch(fillTable({ data: matched, tableKey: 'result' }));
        }
    }, [data]);

    const getOptions = (name: string): TSelectedOptions => {
        return (dataToEdit?.[name] || [])
            .filter((row: ICell[]) => row.some((cell) => cell.value !== ''))[0]
            ?.map((cell: ICell) => {
                return cell.value;
            })
            ?.filter((value: string | null) => value)
            ?.map((value: string | null) => {
                return { id: value ?? '', text: value ?? '' };
            });
    };

    const handleChangeMatchField = (tableKey: string) => (args: IChangeOptionsArgs) => {
        dispatch(
            storeSelectedField({ tableKey, selectedField: args.newOptions as Pick<IChangeOptionsArgs, 'newOptions'> }),
        );
    };

    const handleMatchClick = () => {
        mutate();
    };

    const handleRefreshClick = () => {
        dispatch(resetState());
    };

    const handleResetSourceClick = () => {
        dispatch(fillTable({ data: generateEmptyData(), tableKey: 'source' }));
        dispatch(fillTable({ data: generateEmptyData(), tableKey: 'result' }));
        dispatch(storeSelectedField({ tableKey: 'source', selectedField: undefined }));
    };

    const handleResetDictClick = () => {
        dispatch(fillTable({ data: generateEmptyData(), tableKey: 'dict' }));
        dispatch(fillTable({ data: generateEmptyData(), tableKey: 'result' }));
        dispatch(storeSelectedField({ tableKey: 'dict', selectedField: undefined }));
    };

    return (
        <>
            <PanelGroup autoSaveId="matching" direction="horizontal">
                <Panel>
                    <PanelHeader>
                        <PanelTitle>{t('Source')}</PanelTitle>
                        <Controls>
                            <Tooltip content={t('Reset source')}>
                                <IconButton
                                    styles={{ paddingBottom: 7, marginRight: 10 }}
                                    icon={<ArrowClockwiseFilled />}
                                    onClick={handleResetSourceClick}
                                ></IconButton>
                            </Tooltip>
                            <UniversalSelect
                                name={'source'}
                                mode="single"
                                labelText={t('Field name')}
                                options={getOptions('source')}
                                changeOptions={handleChangeMatchField('source')}
                                localCurrentOptions={selectedFields}
                            />
                        </Controls>
                    </PanelHeader>
                    <Table data={dataToEdit['source'] ?? []} tableKey={'source'} />
                </Panel>
                <ResizeHandle id="tables1" direction="horizontal" className="non_printable" />
                <Panel className="non_printable">
                    <PanelHeader>
                        <PanelTitle>{t('Dictionary')}</PanelTitle>
                        <Controls>
                            <Tooltip content={t('Reset dictionary')}>
                                <IconButton
                                    styles={{ paddingBottom: 7, marginRight: 10 }}
                                    icon={<ArrowClockwiseFilled />}
                                    onClick={handleResetDictClick}
                                ></IconButton>
                            </Tooltip>
                            <UniversalSelect
                                name={'dict'}
                                mode="single"
                                labelText={t('Field name')}
                                options={getOptions('dict')}
                                changeOptions={handleChangeMatchField('dict')}
                                localCurrentOptions={selectedFields}
                            />
                        </Controls>
                    </PanelHeader>
                    <Table data={dataToEdit['dict'] ?? []} tableKey={'dict'} />
                </Panel>
                <ResizeHandle id="tables2" direction="horizontal" className="non_printable" />
                <Panel className="non_printable">
                    <PanelHeader>
                        <PanelTitle>{t('Result')}</PanelTitle>
                        <Controls>
                            <Tooltip content={t('Reset all')}>
                                <IconButton
                                    styles={{ paddingBottom: 7, marginRight: 10 }}
                                    icon={<ArrowClockwiseFilled />}
                                    onClick={handleRefreshClick}
                                ></IconButton>
                            </Tooltip>
                            <UniversalSelect
                                name={'o2o'}
                                mode="single"
                                labelText={t('o2o max distance')}
                                defaultSelected={[{ id: '0.3', text: '0.3' }]}
                                options={[0, 0.1, 0.2, 0.3, 0.4].map((value) => ({
                                    id: value.toString(),
                                    text: value.toString(),
                                }))}
                                dropdownWidth="sm"
                                localCurrentOptions={localCurrentOptions}
                                changeOptions={(args) => dispatch(changeOptions(args))}
                            />
                            <Button
                                appearance="primary"
                                onClick={handleMatchClick}
                                disabled={!selectedFields['dict'] || !selectedFields['source'] || isPending}
                                style={{ marginLeft: '10px' }}
                            >
                                {isPending ? t('Loading...') : t('Match')}
                            </Button>
                        </Controls>
                    </PanelHeader>
                    <Table data={dataToEdit['result'] ?? []} tableKey={'result'} />
                </Panel>
            </PanelGroup>
        </>
    );
};
