import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';

import { isMobileInboundWidth } from 'src/theme';

import ComparePeriodSelect from '../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import MetricsSelect from '../../../components/Selects/MetricsSelect/MetricsSelect';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import Preset from '../../../components/Preset/Preset';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import UniversalSelect from '../../../components/Selects/UniversalSelect/UniversalSelect';
import { stringDate } from '../../../tools/Strings/stringDate';
import { generalReducerValues } from '../../../General.reducer';
import { ISettings } from '../../../components/UserSettings/interfaces';
import { changeOptions } from '../../../components/UserSettings/reducer';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import { useAppDispatch } from '../../../hooks/useSettings';

import { Maps_MapsOverview_Module_Reducer_Values, storeSelectedVersionId } from './reducer';
import MapsWidget from './widgets/MapsWidget/MapsWidget';

const Maps: React.FC = () => {
    const { versions, selectedVersionId } = useSelector(Maps_MapsOverview_Module_Reducer_Values);
    const {
        lang,
        optionPanelHeight,
        mainAreaSize: { width },
        selectedLocationId,
        currentModuleID,
    } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const dispatch = useAppDispatch();

    const isMobile = isMobileInboundWidth(width);

    useEffect(() => {
        if (versions.length) {
            const id = cloneDeep(versions).sort((a, b) => {
                return (
                    DateTime.fromISO(b.date_from || '2300-01-01').toMillis() -
                    DateTime.fromISO(a.date_from || '2300-01-01').toMillis()
                );
            })[0].id;
            dispatch(storeSelectedVersionId(id));
        }
    }, [dispatch, versions]);

    useEffect(() => {
        if (isMobile && selectedLocationId && localCurrentOptions?.selectedMetrics) {
            dispatch(
                changeOptions({
                    newOptions: {
                        selectedMetrics: [localCurrentOptions?.selectedMetrics[0]],
                    },
                }),
            );
        }
    }, [isMobile, selectedLocationId, localCurrentOptions?.selectedMetrics, dispatch]);

    const showComparison: Boolean = localCurrentOptions?.['mapsSettings']
        .map((item: { id: string }) => item.id)
        ?.includes('comparison');

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        {showComparison && (
                            <ComparePeriodSelect
                                mode="single"
                                localCurrentOptions={localCurrentOptions}
                                changeOptions={(args) => dispatch(changeOptions(args))}
                            />
                        )}
                        <MetricsSelect
                            maxSelected={isMobile ? 1 : 2}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <UniversalSelect
                            options={[
                                { id: 'comparison', text: 'Comparison' },
                                { id: 'showPlans', text: 'Show plans' },
                                { id: 'showPerimeters', text: 'Show Perimeters' },
                                { id: 'showLabels', text: 'Show labels' },
                                { id: 'showRatings', text: 'Show ratings' },
                                { id: 'joinFloors', text: 'Join floors' },
                            ]}
                            name={'mapsSettings'}
                            labelText={'Settings'}
                            iconType={'setting'}
                            defaultSelected={[
                                { id: 'showRatings', text: 'Show ratings' },
                                { id: 'showPerimeters', text: 'Show Perimeters' },
                            ]}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={[
                                'selectedMetrics',
                                'comparePeriods',
                                'mainPeriod',
                                'mapsSettings' as keyof ISettings,
                            ]}
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <WrapperContainer key={optionPanelHeight} style={{ maxWidth: 'unset' }}>
                <MapsWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Maps;
