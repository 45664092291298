import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { toggleArray } from 'src/tools';

import { changeCurrentModuleID, changeLang, generalReducerValues } from '../General.reducer';
import { TLang } from '../General.interfaces';
import { AppDispatch, RootState } from '../store';
import { storeNewAlert, AlertsReducerValues, removeAlert } from '../components/Alert/reducer';
import { cabinetPreferencesValues } from '../components/CabinetPreferences/reducer';

import { useAlarmMessage } from './useAlarmMessage';
import { useCookiesAlert } from './useCookiesAlert';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Кастомный хук для работы настройками пользователя (выбранный язык, выбраннвя локация, выбранный модуль)
 */
const useSettings = (): void => {
    const { i18n } = useTranslation();
    const [searchParams] = useSearchParams();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const { lang, modulesConfig, domain } = useSelector(generalReducerValues);
    const {
        sidebar: { openSections },
    } = useSelector(cabinetPreferencesValues);
    const { alerts } = useSelector(AlertsReducerValues);

    useCookiesAlert();

    /** Изменение текущего модуля */
    useEffect(() => {
        if (modulesConfig.length === 0 || Object.keys(Object.fromEntries(searchParams)).length) return;

        const moduleId = modulesConfig.find((item) => item.service.path === location.pathname)?.module_id;
        const moduleName = modulesConfig.find((item) => item.service.path === location.pathname)?.module_name;
        const version = modulesConfig.find((item) => item.service.path === location.pathname)?.version;

        moduleId && dispatch(changeCurrentModuleID(moduleId));

        const testVersionModuleAlertsIds = alerts
            .filter((item) => {
                return item?.alias === 'testVersionModule';
            })
            .map((item) => item.id);

        // if (version && !testVersionModuleAlertsIds.length) {
        //     dispatch(
        //         storeNewAlert({
        //             type: 'info',
        //             text: `${t(
        //                 'The module is in test mode and user settings may be lost! Contact us if you have any questions.',
        //             )}`,
        //             duration: 15,
        //             alias: 'testVersionModule',
        //         }),
        //     );
        // } else {
        //     if (testVersionModuleAlertsIds.length && !version) {
        //         testVersionModuleAlertsIds.forEach((id) => {
        //             dispatch(removeAlert(id));
        //         });
        //     }
        // }
        // navigate(path, { replace: true });
    }, [modulesConfig, location.pathname, searchParams]);

    /** Запись изменения языка в библиатеку i18n и в localStorage */
    useEffect(() => {
        if (!lang) return;
        const localStorageLang: string | null = localStorage.getItem('lang');
        i18n.changeLanguage(lang);
        if (localStorageLang !== lang) {
            localStorage.setItem('lang', lang);
        }
    }, [lang]);

    useEffect(() => {
        // const aa = i18n.getResourceBundle('ar', 'translations')?.[lang]?.translations;
        // i18n.addResources('ar', 'translation', aa);
    }, [i18n, lang]);
};

export default useSettings;
