import styled from 'styled-components/macro';

export const MetricContainer = styled.section`
    margin-top: var(--space-xxs);
    display: flex;
    gap: 10px;
`;

export const CanvasWrapper = styled.div`
    /* display: flex; */
`;
export const Cont = styled.div``;

export const Floor = styled.div`
    position: absolute;
    width: var(--space-md);
    height: var(--space-md);
    background-color: var(--color-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 2;
    font-weight: 500;
`;

export const StageWrapper = styled.div`
    cursor: pointer;
    position: relative;
    /* border: 1px solid var(--color-secondary-dark); */
    /* padding: 10px; */
    margin-bottom: 10px;
    background-color: var(--color-white);
`;
