import { geoMatrixConverter } from '../../tools/geoMatrixConverter';
import { IExtendedPlan, ILatestPlan } from './interfaces';

const getExtendedPlans = (latestPlans: ILatestPlan[], widestPlan: ILatestPlan): IExtendedPlan[] => {
    return latestPlans
        .map((plan) => {
            let imageOffset: [number, number] = [0, 0];
            const vector = geoMatrixConverter(0, 0, plan.plan2geo);
            if (vector && vector[0]) {
                imageOffset = geoMatrixConverter(vector[0], vector[1], widestPlan.mainPlan.geo2plan).map((item) => {
                    if (Math.abs(item) < 0.1) {
                        return 0;
                    } else {
                        return item;
                    }
                }) as [number, number];
            }
            return { ...plan, imageOffset, widestPlan };
        })
        .sort((a, b) => b.floor - a.floor);
};

export default getExtendedPlans;
