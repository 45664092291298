import { TFunction } from 'i18next';
import { isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { AxisLabelsFormatterContextObject } from 'highcharts';

import { currenciesMap, DS } from 'src/constants';

import { IMetric, IMetricResponseItem, TLang, TMetricResponse } from '../../../../../../General.interfaces';
import { PAYMENT_TYPE_TEXT_MAP } from '../../../../constants/constants';
import { TabListItemIds } from '../../../../enums';
import { IExtendedChartPoint } from '../../../../../../components/Charts/interfaces';
import { ISeriesPoint, TSeries } from '../../../../../../components/Charts/Dynamics/interfaces';
import datetimeFormatHelper from '../../../../../../tools/Strings/datetimeFormatHelper';
import { IChartDetailing } from '../../../../../../constants/chartDetailing';
import getDifferenceBetweenNumbers from '../../../../../../tools/getDifferenceBetweenNumbers';

// import { PAYMENT_TYPE_TEXT_MAP } from '../../../constants/constants';
// import { TabListItemIds } from 'src/enums';

import { TDataAdapterResponse } from '../interfaces';
import { IDynamicsSettings } from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/interfaces';

interface IArgs {
    response: TMetricResponse[];
    allMetrics: IMetric[];
    detail?: IChartDetailing;
    t: TFunction;
    lang: TLang;
    selectedTab: TabListItemIds;
    currencyCode: number;
    viewSettings?: IDynamicsSettings;
}

const dataAdapter = (args: IArgs): TDataAdapterResponse => {
    const { response, t, allMetrics, lang, detail, selectedTab, currencyCode, viewSettings } = args;
    const currencySymbol: string =
        currencyCode && currenciesMap[currencyCode] ? currenciesMap[currencyCode].symbol : '₽';

    const structureSeries: TSeries[] = [];
    const dynamicsSeries: TSeries[] = [];

    const [mainPeriodResponse, comparePeriodResponse] = response.reduce(
        (acc: [IMetricResponseItem[], IMetricResponseItem[]], metricResponse) => {
            metricResponse.forEach((metricResponseItem) => {
                const splittedAlias = metricResponseItem.context.alias?.split(DS)[0] as undefined | 'main' | 'compare';

                switch (splittedAlias) {
                    case 'main':
                        acc[0].push(metricResponseItem);
                        break;
                    case 'compare':
                        acc[1].push(metricResponseItem);
                        break;

                    default:
                        break;
                }
            });
            return acc;
        },
        [[], []],
    );

    const xAxisLabelsFormating = function (this: AxisLabelsFormatterContextObject) {
        return datetimeFormatHelper({
            selectedChartDetailing: detail || null,
            dateInMillis: this.value as number,
            isCategory: true,
            lang,
            t,
        });
    };

    const tooltipTitleFormatter = (value: string | number) => {
        return datetimeFormatHelper({
            selectedChartDetailing: detail || null,
            dateInMillis: value as number,
            isCategory: false,
            lang,
            t,
        });
    };

    mainPeriodResponse.forEach((metricResponseItem, index) => {
        const metricFromStore = allMetrics.find((metric) => metric.id === metricResponseItem.context.metric);
        const splittedAlias = metricResponseItem.context.alias?.split(DS);

        // let seriesName = splittedAlias?.[splittedAlias?.length - 1] || metricFromStore?.text;
        let seriesName = '';

        switch (selectedTab) {
            case TabListItemIds.PaymentType: {
                seriesName =
                    PAYMENT_TYPE_TEXT_MAP[splittedAlias?.[splittedAlias?.length - 1] || ''] ||
                    metricFromStore?.text ||
                    '';
                break;
            }

            case TabListItemIds.OperationType: {
                const opName = metricResponseItem.context.alias?.includes('return') ? 'Returns' : 'Sales';
                const opVat = metricResponseItem.context.metric?.includes('novat') ? '(Ex. VAT)' : '(Inc. VAT)';
                seriesName = `${opName} ${opVat}`;
                break;
            }

            default:
                break;
        }

        const compareMetricResponseItem = comparePeriodResponse.find((item) => {
            const compareSplittedAlias = item.context.alias?.split(DS);

            return compareSplittedAlias?.slice(1).join(DS) === splittedAlias?.slice(1).join(DS);
        });

        if (seriesName) {
            const [structureData, dynamicsData] = metricResponseItem.items.reduce(
                (acc: [IExtendedChartPoint[], IExtendedChartPoint[]], item, index) => {
                    const compareItem = compareMetricResponseItem?.items[index];

                    let compareValue: number | null = null;

                    if (
                        compareItem &&
                        isNumber(item.value) &&
                        isNumber(compareItem?.value) &&
                        compareItem.value !== 0
                    ) {
                        compareValue = Number(
                            getDifferenceBetweenNumbers(item.value, compareItem?.value)?.percentDifference,
                        );
                    }

                    const result = {
                        units: currencySymbol,
                        name: t(seriesName),
                        subTitle: splittedAlias?.[1] ? t(metricFromStore?.text || '') : '',
                        x: DateTime.fromISO(item.time).toMillis(),
                        y: item.value,
                        showUnits: true,
                    };

                    acc[0].push(result);
                    acc[1].push({ ...result, y: compareValue, units: '%' });

                    return acc;
                },
                [[], []],
            );

            structureSeries.push({
                name: t(seriesName),
                data: structureData as ISeriesPoint[],
                id: seriesName,
                type: 'column',
                isMain: false,
            });

            dynamicsSeries.push({
                name: t(seriesName),
                data: dynamicsData as ISeriesPoint[],
                id: seriesName,
                type: dynamicsData.length === 1 ? 'column' : index === 0 ? 'areaspline' : 'spline',
                isMain: index === 0,
            });
        }
    });

    return {
        structureChartOptions: {
            series: structureSeries,
            columnsStacking: 'normal',
            columnsGrouping: true,
            sharedTooltips: false,
            inactiveSeriesOpacity: 0.2,
            numberOfSeriesToDispaly: null,
            tooltipTitleFormatter,
            xAxisLabelsFormating,
            // xAxis: {
            //     // labels: {
            //     //     formatter: xAxisLabelsFormatter,
            //     //     style: {
            //     //         fontSize: '12px',
            //     //     },
            //     // },
            //     crosshair: {
            //         dashStyle: 'Dash',
            //         color: '#babbbc',
            //         width: 1,
            //     },
            //     lineWidth: 0,
            //     margin: 0,
            //     title: { text: null },
            //     type: 'datetime',
            //     // tickInterval: getSeriesTickInterval({ series: finalSeries }),
            //     // plotBands: finalPlotBands,
            //     // ...args.viewSettings?.additionalChartOptions?.options?.xAxis,
            // },
        },
        dynamicsChartOptions: {
            series: dynamicsSeries,
            inactiveSeriesOpacity: 0.2,
            numberOfSeriesToDispaly: null,
            sharedTooltips: true,
            tooltipTitleFormatter,
            xAxisLabelsFormating,
        },
        isDataExists: true,
    };
};

export default dataAdapter;
