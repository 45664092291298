import { emptyArray } from '../dummy';

export function transformFields<T extends object = {}>(item: Array<object> | object, transformFunc: (key: string) => string): T {
    if (Array.isArray(item)) {
        return item.map((subItem) => transformFields(subItem, transformFunc)) as unknown as T;
    } else if (item !== null && typeof item === 'object') {
        const transformedObject: Record<string, unknown> = {};
        for (const [key, value] of Object.entries(item)) {
            transformedObject[transformFunc(key)] = transformFields(value, transformFunc);
        }
        return transformedObject as T;
    }
    return item;
    // } else if (item !== null && typeof item === 'object') {
    //     const transformedObject: Record<string, unknown> = {};
    //     for (const [key, value] of Object.entries(item)) {
    //         transformedObject[transformFunc(key)] = transformFields(value, transformFunc);
    //     }
    //     return transformedObject as T;
    // }
}

export function makeTransformFieldsByPairs(
    fieldPairs: Array<[string, string]>,
    throwForUnknownKey?: boolean,
): (key: string) => string {
    const defaultPairs = !throwForUnknownKey ? emptyArray : null;
    return (key: string) => {
        const [_, renamedKey] = fieldPairs.find(([originalKey]) => originalKey === key) ?? defaultPairs!;
        return renamedKey || key;
    };
}

export function makeBackTransformFieldsByPairs(
    fieldPairs: Array<[string, string]>,
    throwForUnknownKey?: boolean,
): (key: string) => string {
    const defaultPairs = !throwForUnknownKey ? emptyArray : null;
    return (key: string) => {
        const [renamedKey] = fieldPairs.find(([_, originalKey]) => originalKey === key) ?? defaultPairs!;
        return renamedKey || key;
    };
}