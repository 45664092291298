export function replaceCommas(input: string): string {
    // Считаем количество запятых в строке
    const commaMatches = input.match(/,/g);
    const commaCount = commaMatches ? commaMatches.length : 0;

    if (commaCount > 1) {
        // Если больше одной запятой, удаляем все запятые
        return input.replace(/,/g, '');
    } else if (commaCount === 1) {
        // Если одна запятая, обрабатываем в зависимости от количества цифр после неё
        const commaIndex = input.indexOf(',');
        const afterComma = input.slice(commaIndex + 1);

        // Ищем последовательность цифр сразу после запятой
        const digitsAfterCommaMatch = afterComma.match(/^\d+/);
        const digitsAfterComma = digitsAfterCommaMatch ? digitsAfterCommaMatch[0] : '';

        if (digitsAfterComma.length > 2) {
            // Если после запятой больше 2 цифр, удаляем запятую
            return input.replace(',', '');
        } else if (digitsAfterComma.length >= 1) {
            // Если после запятой 1 или 2 цифры, заменяем запятую на точку
            return input.replace(',', '.');
        } else {
            // Если после запятой нет цифр, удаляем запятую
            return input.replace(',', '');
        }
    } else {
        // Если запятых нет или другое, возвращаем исходную строку
        return input;
    }
}
