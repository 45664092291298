import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { IMetricCell, TTF } from '../interfaces';

export const getDateRange = (data: IMetricCell[][], timeFreq: TTF): { dateFrom: string; dateTo: string } => {
    const datesArr = data
        .filter((row) => row.some((cell) => cell.billData))
        .map((row) => {
            return row[0].billData?.date ?? '';
        });

    const sorted = sortBy(datesArr, (item) => DateTime.fromISO(item).toMillis());
    const dateFrom = sorted[0];
    let dateTo = sorted[sorted.length - 1];

    if (timeFreq === 'MS' && dateTo && DateTime.fromISO(dateTo).isValid) {
        dateTo = DateTime.fromISO(dateTo).endOf('month').toISODate() || dateTo;
    }

    return { dateFrom, dateTo };
};
