export const idToTitle = (text?: string, camelized?: boolean) => {
    const replacedText = (text || '').replace(/([A-Z])/g, ' $1');
    if (camelized) {
        return replacedText.charAt(0).toUpperCase() + replacedText.slice(1);
    }
    const result = replacedText
        .split(' ')
        .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
        .join(' ');
    return result.charAt(0).toUpperCase() + result.slice(1);
};

export function capitalizeFirst(string: string = ''): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function snakeToCamelCase(inputString: string): string {
    return inputString.replace(/_([a-z])/g, (match, group1) => group1.toUpperCase());
}

export function camelToSnakeCase(camelCaseString: string): string {
    return camelCaseString
        .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2') // Handle multiple uppercase followed by uppercase and lowercase
        .replace(/([a-z0-9])([A-Z])/g, '$1_$2')   // Handle lowercase or number followed by uppercase
        .toLowerCase();
}