import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    border: 1px solid #ebebeb;
    border-radius: 5px;
    width: 270px;
    display: flex;
    flex-direction: column;
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 1rem;
    border-bottom: 1px solid #ebebeb;
    padding: 15px;
`;

export const Step = styled.div`
    border-bottom: 1px solid #ebebeb;
    :last-child {
        border-bottom: none;
    }
    position: relative;
    overflow: hidden;
`;

export const StepContentWrapper = styled.div`
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
`;

export const Percentage = styled.div`
    z-index: 2;
    position: relative;
    font-weight: 500;
    font-size: 14px;
`;

export const ReportingObjectInfo = styled.div`
    z-index: 2;
    position: relative;
    flex: 0 0 70%;
    span {
        font-weight: 500;

        text-transform: capitalize;
    }
`;

export const PercentageFill = styled.div<{ width: number }>`
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: ${({ width }) => width + '%'};
    background: #ddf5fc;
    transform: translateX(-50%);
`;

export const NoData = styled.div`
    padding: 15px;
    font-size: 14px;
    color: #aaa;
`;
