import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import { Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values } from '../reducer';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';
import { useMemo } from 'react';
import { IPeriod, TMetricResponse } from '../../../../../../General.interfaces';
import {
    CORRECTION_MAP,
    OPERATION_TYPES,
    PAYMENT_TYPES,
    SALES_METRIC_NAMES,
    VAT_SELECT,
} from '../../../../constants/constants';
import { TabListItemIds } from '../../../../enums';
import { IMetricParams, IRequestMetricsArgs, TTimeFreq } from '../../../../../../tools/API/interfaces';
import { DS } from '../../../../../../constants/constants';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../../../tools/API/dataAccessors/metricsDataAccessor';
import { useQuery } from '@tanstack/react-query';
import { useDataAdapter } from './useDataAdapter';

const useFetchData = () => {
    const {
        token,
        allMetrics,
        currentModuleID,
        currencyCode,
        cfg: { reportingObjectsByType, reportingObjectsById },
    } = useSelector(generalReducerValues);

    const dataAdapter = useDataAdapter();

    const { selectedTab } = useSelector(Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const fetchData = useRequestMetrics();

    const { t } = useTranslation(['metrics', 'translation']);

    const [queryKey, queryFn, adapter] = useMemo(() => {
        const correctionReceipts = localCurrentOptions?.['correctionReceipts']?.[0].id as string | undefined;
        const mainPeriod = localCurrentOptions?.mainDateRanges?.find(
            (item) => item.id === localCurrentOptions?.mainPeriod?.id,
        ) as IPeriod | undefined;
        const comparePeriod = localCurrentOptions?.compareDateRanges?.find(
            (item) => item.id === localCurrentOptions?.comparePeriods?.[0]?.id,
        ) as IPeriod | undefined;

        const vatMode: string = localCurrentOptions?.[VAT_SELECT]?.[0]?.id ?? 'novat';

        if (
            mainPeriod?.period &&
            comparePeriod?.period &&
            reportingObjectsByType['tenant']?.length &&
            token &&
            correctionReceipts
        ) {
            const tenantsIds = reportingObjectsByType['tenant'].map((element) => element.id);

            const periods = {
                main: mainPeriod,
                compare: comparePeriod,
            };

            const generateRequests = (opTypes: string[], metric: string, pType?: string) => {
                const result: IRequestMetricsArgs[] = [];
                Object.entries(periods).forEach(([key, period]) => {
                    opTypes.forEach((opType) => {
                        let alias = `${key}${DS}${opType}`;

                        if (pType) {
                            alias += `${DS}${pType}`;
                        }

                        result.push(
                            prepareArgs({
                                timeRange: [period.period?.dateFrom || '', period.period?.dateTo || ''],
                                alias,
                                objIds: tenantsIds,
                                metric,
                                timeFreq: null,
                                token,
                                metricParams: {
                                    operation_type: opType,
                                    bill_type: CORRECTION_MAP[correctionReceipts],
                                    payment_sum: pType,
                                },
                            }),
                        );
                    });
                });

                return result;
            };

            const requestArgs = SALES_METRIC_NAMES.reduce((acc: IRequestMetricsArgs[], mn) => {
                const metric = `${mn}${vatMode}`;

                switch (selectedTab) {
                    case TabListItemIds.PaymentType: {
                        const requests = PAYMENT_TYPES.reduce((acc: IRequestMetricsArgs[], pType) => {
                            acc.push(...generateRequests([OPERATION_TYPES[0]], metric, pType));
                            return acc;
                        }, []);

                        return [...acc, ...requests];
                    }

                    case TabListItemIds.OperationType: {
                        const requests = generateRequests(OPERATION_TYPES, metric);
                        return [...acc, ...requests];
                    }

                    default:
                        return acc;
                }
            }, []);

            const args: IMetricsDAArgs = { requestArgs, fetchData };

            return [
                [
                    'salesOfTenants',
                    vatMode,
                    mainPeriod?.period,
                    comparePeriod?.period,
                    selectedTab,
                    tenantsIds,
                    correctionReceipts,
                ],
                () => metricsDataAccessor(args),
                (response: TMetricResponse[]) =>
                    dataAdapter({ response, t, allMetrics, reportingObjectsById, selectedTab, currencyCode }),
            ];
        }
        return [[]];
    }, [
        allMetrics,
        fetchData,
        localCurrentOptions,
        reportingObjectsById,
        reportingObjectsByType,
        selectedTab,
        t,
        token,
        currencyCode,
    ]);

    return useQuery({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(adapter),
        select: adapter,
        staleTime: 6 * 3600 * 1000,
    });
};

export default useFetchData;

interface IPrepareArgs {
    timeRange: [string, string];
    alias: string;
    metric: string;
    objIds: number[];
    token: string;
    timeFreq?: TTimeFreq;
    metricParams?: IMetricParams;
}

const prepareArgs = (args: IPrepareArgs): IRequestMetricsArgs => {
    const { timeRange, alias, metric, objIds, token, metricParams, timeFreq = null } = args;
    return {
        object_aggregation: false,
        time_range: timeRange,
        time_freq: timeFreq,
        obj_ids: objIds,
        metric,
        alias,
        token,
        metric_params: metricParams,
    };
};
