import GeometryGroup from '../StageGroups/GeometryGroup/GeometryGroup';
import ImageGroup from '../StageGroups/ImageGroup/ImageGroup';
import { IProps } from './interfaces';
import { Layer } from 'react-konva';
import { FC } from 'react';

/**
 * Компонент дял отображения всех нужных групп элементов
 * @param yCoordinate y координата для слоя
 * @param stageIndex индекс холста
 * @param showPlans Показать / скрыть планы
 * @param stage экземпляр класса холста
 * @param plan сам план
 */
const FinalLayer: FC<IProps> = ({ stageIndex = 0, stage, plan, yCoordinate = 0, showPlans }) => {
    return (
        <Layer y={yCoordinate}>
            {showPlans && (
                <ImageGroup
                    coordinates={{ x: plan.imageOffset[0], y: plan.imageOffset[1] }}
                    scale={plan.widestPlan.mainPlan.scale / plan.scale}
                    imageSrc={plan.image}
                />
            )}
            <GeometryGroup stageIndex={stageIndex} stage={stage} plan={plan} />
        </Layer>
    );
};

export default FinalLayer;
