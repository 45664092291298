import { RefObject, useEffect, useState } from 'react';
import { IArgs } from '../styles/styles.interfaces';

const useHover = ({
    ref,
    styles: { hover },
    constants: { DEFAULT_SEPARATOR_MODIFICATION_PREFIX, DEFAULT_CLASS_HOVER_PREFIX },
}: IArgs): string[] => {
    const [classes, setClasses] = useState<string[]>([]);

    useEffect(() => {
        hover && setClasses([`${DEFAULT_CLASS_HOVER_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}${hover}`]);
    }, [hover]);

    const hoverHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();
        hover &&
            setClasses([...classes, `${DEFAULT_CLASS_HOVER_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}active`]);
    };

    const leaveHandler = () => {
        hover && setClasses([`${DEFAULT_CLASS_HOVER_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}${hover}`]);
    };

    useEffect(() => {
        if (ref?.current) {
            ref.current.addEventListener('mouseover', () => hoverHandler);
            ref.current.addEventListener('mouseleave', leaveHandler);

            return () => {
                ref.current?.removeEventListener('mouseover', () => hoverHandler);
                ref.current?.removeEventListener('mouseleave', leaveHandler);
            };
        } else {
            return;
        }
    }, [ref?.current]);

    return classes;
};

export default useHover;
