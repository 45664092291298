export const filterBySelectors = <T, S>(
    items: T[],
    selectors: S[],
    selectorPredicate: ((item: T, selector: S) => boolean),
    // hasUndefined: boolean = false,
) =>
    selectors.reduce(
        (acc, selector) => [...acc, ...items.filter((item) => selectorPredicate(item, selector))],
        [],
    );
