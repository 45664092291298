export const geoMatrixConverner = (x: number, y: number, matrix: Array<Array<number>>) => {
    const px = x * matrix[0][0] + y * matrix[0][1] + 1 * matrix[0][2];
    const py = x * matrix[1][0] + y * matrix[1][1] + 1 * matrix[1][2];
    return [px, py];
};

export const MultiplyMatrix = (A: Array<Array<number>>, B: Array<Array<number>>) => {
    const rowsA = A.length;
    const colsA = A[0].length;
    const rowsB = B.length;
    const colsB = B[0].length;
    const C = Array();
    if (colsA !== rowsB) return [0, 0];
    for (let i = 0; i < rowsA; i++) C[i] = Array();
    for (let k = 0; k < colsB; k++) {
        for (let i = 0; i < rowsA; i++) {
            let t = 0;
            for (let j = 0; j < rowsB; j++) t += A[i][j] * B[j][k];

            C[i][k] = t;
        }
    }
    return C;
};
