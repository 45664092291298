import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITenant2PlaceRow, ITenant2ZoneRow } from '../../../../../../General.interfaces';
import { generalReducerValues } from '../../../../../../General.reducer';
import { filterValidDateRanges } from '../../../../../../tools/filterValidDateRanges';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../../reducer';
import { storePreraredData } from '../reducer';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';

const infoMap = {
    sumArea: 'Total area',
    placeNames: 'Places',
    floors: 'Floors',
    zones: 'Zones',
    categories: 'Categories',
};

export const usePrepareData = () => {
    const {
        selectedLocationId,
        structures,
        src: { projectCategories, dataObj2ProjectCategory },
    } = useSelector(generalReducerValues);
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { t } = useTranslation(['translation']);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!structures?.relations_tenant2place || !structures?.relations_tenant2zone) return;
        const mainPeriod = localCurrentOptions?.mainPeriod?.id;
        const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];

        const tenantId = localCurrentOptions?.selectedReportingObjectsIds?.[0];
        const filteredTenant2place = filterValidDateRanges(
            structures.relations_tenant2place?.filter((item: ITenant2PlaceRow) => {
                return item.tenant_id === tenantId;
            }),
            mainDateRange?.period,
        );

        const filteredTenant2zone = filterValidDateRanges(
            structures.relations_tenant2zone?.filter((item: ITenant2ZoneRow) => {
                return item.tenant_id === tenantId;
            }),
            mainDateRange?.period,
        );

        const sumArea = valueFormatter({
            value: filteredTenant2place?.reduce((acc, item: ITenant2PlaceRow) => {
                return acc + item.area;
            }, 0),
        });

        const placeNames = _.uniq(
            filteredTenant2place?.reduce((acc, item: ITenant2PlaceRow) => {
                return [...acc, item.place_name];
            }, []),
        ).join(', ');

        const floors = _.uniq(
            filteredTenant2place?.reduce((acc, item: ITenant2PlaceRow) => {
                return [...acc, item.floor];
            }, []),
        ).join(', ');

        const zones = _.uniq(
            filteredTenant2zone?.reduce((acc, item: ITenant2ZoneRow) => {
                return [...acc, item.zone_name];
            }, []),
        ).join(', ');

        const categories = dataObj2ProjectCategory
            .filter((item) => item.data_object_id === tenantId)
            .map((item) => item.category_id)
            .map((id) => {
                const parent_id = projectCategories.find((item) => item.id === id)?.parent_id;
                const key = projectCategories.find((item) => item.id === parent_id)?.object_name;
                const value = projectCategories.find((item) => item.id === id)?.object_name;
                return `${key || ''}: ${value || ''}`;
            })
            .join(',\n');

        const preparedData = [
            {
                key: 'sumArea',
                data: sumArea,
                name: t(infoMap.sumArea),
            },
            {
                key: 'placeNames',
                data: placeNames,
                name: t(infoMap.placeNames),
            },
            {
                key: 'floors',
                data: floors,
                name: t(infoMap.floors),
            },
            {
                key: 'zones',
                data: zones,
                name: t(infoMap.zones),
            },
            {
                key: 'categories',
                data: categories,
                name: t(infoMap.categories),
            },
        ];

        dispatch(storePreraredData(preparedData));
    }, [dispatch, localCurrentOptions, selectedLocationId, t, structures]);
};
