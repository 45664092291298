export const weekDays: { shortName: string; name: string; weekNumber: null | number }[] = [
    { shortName: 'wk', name: 'wk', weekNumber: null },
    { shortName: 'Mon', name: 'Monday', weekNumber: 1 },
    { shortName: 'Tue', name: 'Tuesday', weekNumber: 2 },
    { shortName: 'Wed', name: 'Wednesday', weekNumber: 3 },
    { shortName: 'Thu', name: 'Thursday', weekNumber: 4 },
    { shortName: 'Fri', name: 'Friday', weekNumber: 5 },
    { shortName: 'Sat', name: 'Saturday', weekNumber: 6 },
    { shortName: 'Sun', name: 'Sunday', weekNumber: 7 },
];
