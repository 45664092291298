import styled from 'styled-components/macro';

export const MetricValuesWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const NameWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
