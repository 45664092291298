import React, { CSSProperties, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleWrapper } from './styles';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';

const WidgetTitleWrapper: React.FC<{ children: ReactNode; styles?: CSSProperties }> = ({ children, styles }) => {
    const { isRtlLanguage } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    return (
        <TitleWrapper isRtlLanguage={isRtlLanguage ? true : false} style={styles}>
            {children}
        </TitleWrapper>
    );
};

WidgetTitleWrapper.displayName = 'WidgetTitleWrapper';

export default WidgetTitleWrapper;
