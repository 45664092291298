import React, { useId } from 'react';
import {
    MenuGroupHeader,
    MenuGroup,
    Menu,
    MenuTrigger,
    MenuList,
    MenuPopover,
    Divider,
} from '@fluentui/react-components';
import { DismissFilled, FilterSyncFilled } from '@fluentui/react-icons';

import useTranslation from 'src/hooks/useTranslation/useTranslation';
import { toString } from 'src/tools';
import { StyledMenuGroupHeader, StyledMenuItem } from 'src/components/ContextualMenu';

import { TableFilter, FilterUpdater } from '../interfaces';

import { StyledIcon } from './styles';

type FilterMenuProps = {
    columnFilters: TableFilter[];
    changeFilter: (filterUpdaterOrFilters: FilterUpdater | TableFilter[]) => void;
};

export const FilterMenu: React.FC<FilterMenuProps> = ({ columnFilters, changeFilter }) => {
    const { t } = useTranslation();

    const menuItems = (columnFilters || [])?.map((filter) => {
        const filterTitle = `${toString(filter.filterValue)}`;
        const removeFilter = () => {
            changeFilter((prevFilter) => prevFilter.filter(({ filterId }) => filter.filterId !== filterId));
        };

        return (
            <StyledMenuItem key={`${filter.filterId}-${toString(filter.filterValue)}`} onClick={removeFilter}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {filterTitle}
                    <DismissFilled style={{ marginLeft: 10, marginTop: 2 }} />
                </span>
            </StyledMenuItem>
        );
    });

    if (menuItems.length > 1) {
        const [firstFilter] = columnFilters;
        const { columnId } = firstFilter;
        const removeColumnFilters = () => {
            changeFilter((prevFilter) => prevFilter.filter((filter) => filter.columnId !== columnId));
        };
        menuItems.push(
            <StyledMenuItem key={`remove-${columnId}`} onClick={removeColumnFilters}>
                {t('Column filters')}
                <DismissFilled />
            </StyledMenuItem>,
        );
    }

    const menuId = useId();

    return (
        <Menu key={menuId}>
            <MenuTrigger disableButtonEnhancement>
                <span>
                    <StyledIcon>
                        <FilterSyncFilled title={t('Column filters')} />
                    </StyledIcon>
                </span>
            </MenuTrigger>

            <MenuPopover>
                <MenuGroup>
                    <StyledMenuGroupHeader>
                        {t('Remove column filters')}
                    </StyledMenuGroupHeader>
                    <Divider />
                    <MenuList>{menuItems}</MenuList>
                </MenuGroup>
            </MenuPopover>
        </Menu>
    );
};
