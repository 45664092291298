import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    height: 100%;
    display: flex;
    padding: 10px;
    align-items: center;
    color: var(--color-contrast-higher);
    justify-content: flex-end;

    span {
        padding: 0px !important;
    }
`;
