export function arrayBeforeMatch<T>(
    arr: T[],
    predicate: (item: T) => boolean,
): T[] {
    const index = arr.findIndex(predicate);

    if (index === -1) {
        return [];
    }

    return arr.slice(0, index);
}