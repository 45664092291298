import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
    margin-top: var(--space-xxs);

    padding: var(--space-xxs) 0;
    /* max-height: 700px; */
    overflow: auto;
    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
    }
    tr {
        border: 1px solid var(--default-border-color);
    }
    td,
    th {
        padding: var(--space-xxxxs) var(--space-xxxxs);
        height: var(--space-lg);
    }
    thead {
        font-weight: 700;
    }
    td:not(:first-child) {
        text-align: right;
    }
    th {
        text-align: right;
    }
`;

export const Cell = styled.td<{ isTitle: boolean; color: string; bgColor: string }>`
    font-size: ${(p) => (p.isTitle ? '16px' : '14px')};
    font-weight: ${(p) => (p.isTitle ? 700 : 500)};
    max-width: ${(p) => (p.isTitle ? '250px' : 'auto')};
    color: ${(p) => p.color};
    background-color: ${(p) => p.bgColor};
`;

export const Row = styled.tr<{ isTitleRow: boolean }>`
    /* background-color: ${(p) => (p.isTitleRow ? 'var(--color-secondary-light)' : 'transparent')}; */
`;
