import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { emptyArray } from 'src/tools';
import { useGeneralSelector } from 'src/hooks';

import { getAny } from '../../getAny';

export type MapVersion = {
    id: number;
    coreLocationId: number;
    dateFrom: string;
    dateTo: string;
};

const transformFieldsOfResponse = (response: Array<object>): Array<MapVersion> => {
    if (!response.length) {
        return emptyArray;
    }

    return response.map((versionInfo) => ({
        id: versionInfo['id'],
        coreLocationId: versionInfo['location_id'],
        dateFrom: versionInfo['date_from'],
        dateTo: versionInfo['date_to'],
    }));
};

export const useFetchVersions = (mapLocationId?: string) => {
    const { token, urlsByServices } = useGeneralSelector();

    const requestUrl = useMemo(() => {
        if (!mapLocationId || !token) return null;

        if (urlsByServices?.['core/map-service-back']) {
            return `${urlsByServices['core/map-service-back'].VERSIONS_URL}?location_id=${mapLocationId}`;
        }
        return null;
    }, [token, urlsByServices]);

    const isQueryEnabled = !!requestUrl && !!mapLocationId && !!token;
    const result = useQuery({
        queryKey: [requestUrl, mapLocationId],
        queryFn: async () => {
            const response = await getAny(requestUrl!, token);
            if (!response || !response.data) {
                console.error(`Data is undefined or null for versions query from URL: ${requestUrl}`);
                return emptyArray; // возвращаем пустой массив, если данных нет
            }

            return transformFieldsOfResponse(response.data);
        },
        staleTime: Infinity,
        enabled: isQueryEnabled,
    });

    return result;
};
