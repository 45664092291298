import { Icon, ResizeHandleInner, ResizeHandleOuter } from './styles'; // Import the ResizeHandleOuter component

type ResizeHandleProps = {
    className?: string;
    id?: string;
    direction: 'horizontal' | 'vertical';
};

export default function ResizeHandle({ className = '', id, direction }: ResizeHandleProps) {
    // Выбираем путь SVG в зависимости от направления
    const getPath = () => {
        if (direction === 'horizontal') {
            return 'M6,8V11H9V2H11V22H9V13H6V16L2,12L6,8M22,12L18,16V13H15V22H13V2H15V11H18V8L22,12Z';
        }
        // Путь по умолчанию для горизонтального направления
        return 'M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z';
    };

    return (
        <ResizeHandleOuter className={className} id={id}>
            <ResizeHandleInner>
                <Icon viewBox="0 0 24 24">
                    <path fill="currentColor" d={getPath()} />
                </Icon>
            </ResizeHandleInner>
        </ResizeHandleOuter>
    );
}
