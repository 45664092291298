import { useEffect, useState } from 'react';
import { IArgs } from '../styles/styles.interfaces';
import _ from 'lodash';
import { Exclude } from './configurations/exclude.enum';
import { SizeType } from './configurations/size.type';
import { Size } from './configurations/size.enum';

const useBorderRadius = ({
    ref,
    styles: { borderRadius, borderRadiusExclude },
    constants: { DEFAULT_CLASS_BORDER_RADIUS_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX },
}: IArgs): string[] => {
    const [classes, setClasses] = useState<string[]>([]);

    /**
     * Проверка: есть ли есть ли такие углы, возвращает массив валидных значений
     * @param exclude - значение параметра Exclude
     */
    const isValidExclude = (exclude: string): string[] => {
        const excludeArray: string[] = _.replace(exclude, /[\s]/g, '').split(',');
        const validExcludeArray: string[] = [];
        _.forEach(excludeArray, (item: string): void => {
            if (_.values(Exclude).includes(item as Exclude)) {
                validExcludeArray.push(item);
            }
        });

        return validExcludeArray;
    };

    /**
     * Проверка: есть ли такой размер?
     * @param size - размер скругления угла
     */
    const isValidSize = (size: SizeType): boolean => {
        const key: string = _.capitalize(size);
        return Boolean((Size as Record<string, string>)[key]);
    };

    useEffect(() => {
        const classes: string[] = [];
        if (borderRadius && isValidSize(borderRadius as SizeType)) {
            classes.push(`${DEFAULT_CLASS_BORDER_RADIUS_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}${borderRadius}`);
        }

        if (borderRadiusExclude && borderRadius) {
            const excludeValue: string[] = isValidExclude(borderRadiusExclude);
            _.forEach(excludeValue, (item: string): void => {
                classes.push(`${DEFAULT_CLASS_BORDER_RADIUS_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}${item}--unset`);
            });
        }

        setClasses(classes);
    }, [DEFAULT_CLASS_BORDER_RADIUS_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX, borderRadius, borderRadiusExclude]);

    return classes;
};

export default useBorderRadius;
