import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
    overflow: auto;
    margin-top: var(--space-xxs);
`;

export const Table = styled.table`
    width: 100%;
    height: 100%;
`;

export const Th = styled.th<{ paddingLeft?: string; paddingRight?: string }>`
    white-space: nowrap;
    padding-left: ${(p) => p.paddingLeft || '10px'};
    padding-right: ${(p) => p.paddingRight || '10px'};
`;

export const Td = styled.td<{ paddingTop?: string; paddingLeft?: string; paddingRight?: string }>`
    padding-top: ${(p) => p.paddingTop || '20px'};
    padding-left: ${(p) => p.paddingLeft || '10px'};
    padding-right: ${(p) => p.paddingRight || '10px'};
    :first-child {
        width: 100%;
        min-width: 150px;
    }
`;

export const Rating = styled.div<{ width: number | null }>`
    width: ${(p) => (p.width ? p.width + '%' : '0px')};
    height: 100%;
    height: 10px;
    border-radius: 5px;
    background-color: var(--color-info);
    height: 10px;
`;

export const RatingWrapper = styled.div<{ text: string }>`
    background-color: var(--color-secondary);
    border-radius: 5px;
    position: relative;

    &:before {
        content: ${(props) => {
            if (props.text) {
                const escapedText = props.text.replace(/"/g, '\\"'); // Экранируем кавычки
                return `"${escapedText}"`;
            }
            return '';
        }};
        position: absolute;
        top: -20px;
        left: 0;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const Value = styled.div<{ color?: string; fontWeight?: string }>`
    white-space: nowrap;
    line-height: 14px;
    color: ${(p) => p.color || 'inherit'};
    font-weight: ${(p) => p.fontWeight || 'normal'};
`;
