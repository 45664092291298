import styled from 'styled-components/macro';

export const CalendarWrapper = styled.div`
    border: 1px solid #dce0e0;
    border-radius: 5px;
    position: relative;
    margin-top: 20px;
    width: 100%;
`;

export const ShifterLine = styled.div`
    justify-content: space-between;
    width: calc(100% - 20px);
    position: absolute;
    display: flex;
    z-index: 100;
    left: 10px;
    top: 11px;
`;

export const IconWrapper = styled.div`
    border: 1px solid var(--color-secondary-dark) !important;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 26px;
    width: 26px;
    :hover {
        background-color: var(--color-primary-lighter);
    }
`;
