import styled, { css } from 'styled-components';

const Indicator = styled.div<{isActive?: boolean, content?: unknown}>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: background-color 0.3s ease;
`;

export const IndicatorStyled = styled(Indicator)<{ size?: string, isActive?: boolean }>`
    width: ${(p) => p.size || '20px'};
    height: ${(p) => p.size || '20px'};
    background-color: ${(p) => p.isActive ? p.theme.colors.primaryLight : p.theme.colors.secondaryDark};
    margin: 0 auto;
`;
