import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Item, Wrapper } from './styles';

interface IProps {
    onLogout: () => void;
}

/**
 * КОмпонент для отображения выпадающего списка при нажатии на имя пользователя
 */
const LogoutContextualMenuBody: FC<IProps> = ({ onLogout }) => {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <Item onClick={onLogout}>{t('Logout')}</Item>
        </Wrapper>
    );
};

export default LogoutContextualMenuBody;
