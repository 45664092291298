import { DataToEdit, ICell } from '../interfaces';
import { DataItem } from '../widgets/Matching/api/useLevenstain';
import { removeEmptyRowsAndColumns } from './removeEmptyRowsAndColumns';
import { replaceRepeating } from './replaceRepeating';

export const generateMatchingsTable = (matchedData: DataItem[][] | undefined, initialData: DataToEdit): ICell[][] => {
    if (!matchedData?.length) return [];
    const normalizedInitialSource = removeEmptyRowsAndColumns(initialData['source']);
    const normalizedInitialDict = removeEmptyRowsAndColumns(initialData['dict']);
    // const firstRow = [...(normalizedInitialSource?.[0] ?? []), ...(normalizedInitialDict?.[0] ?? [])];
    const firstRow = replaceRepeating<ICell>([normalizedInitialSource?.[0] ?? [], normalizedInitialDict?.[0] ?? []]);
    normalizedInitialDict.shift();
    normalizedInitialSource.shift();

    const body = matchedData[0].map((item) => {
        const dictRow = normalizedInitialDict[item.i];
        const sourceRow = normalizedInitialSource[item.matchings[0].j];
        try {
            return [...sourceRow, ...dictRow];
        } catch (e) {
            return [];
        }
    });

    const sourceRest = normalizedInitialSource
        .filter((_, index) => !matchedData[0].some((item) => item.matchings[0].j === index))
        .map((row) => {
            return [...row, ...Array(normalizedInitialDict[0].length).fill({ value: '' })];
        });

    const dictRest = normalizedInitialDict
        .filter((_, index) => !matchedData[0].some((item) => item.i === index))
        .map((row) => {
            return [...Array(normalizedInitialSource[0].length).fill({ value: '' }), ...row];
        });

    const emptyRow = Array(firstRow.length).fill({ value: '' });

    return [firstRow, ...body, emptyRow, emptyRow, ...sourceRest, ...dictRest];
};
