import { utils, writeFile } from 'xlsx-with-styles';

import { toString, idToTitle, emptyObject } from 'src/tools';
import { Identity } from 'src/types';

import { ColumnOptions, TableContext } from './interfaces';

const convertor = (value: unknown) => {
    if (value && isFinite(Number(toString(value).replaceAll(' ', '')))) {
        return Number(String(value).replaceAll(' ', ''));
    }

    return value;
};

export const xlsxExport = <
    I extends string = string, // id type
    R extends Identity<I> = Identity<I>,
>({
    data,
    tableId,
    columns,
    bookName,
    context,
}: // keyWord = '',
// text = 'Download',
// currentModuleID,
// additionalGrid = [],
{
    data: R[];
    tableId: string;
    // text?: string;
    columns: ColumnOptions<I, R>[];
    bookName?: string;
    context: TableContext<I, R>;
    // currentModuleID: string;
    // keyWord?: string;
    // additionalGrid?: ISheetCell[][];
}) => {
    // const sendAnalytics = useSendSimpleAnalytics();
    // const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    // const { allMetrics } = useSelector(generalReducerValues);
    // const { t } = useTranslation();

    const columnIds = columns.map((column) => column.id);
    const columnTitles = columns.map((column) => context.t(column.label?.toString() || idToTitle(column.id)));

    const rowsWithFilteredColumns = data.map((row) =>
        columnIds.reduce((acc, colId) => {
            const { valueFormatter, dataType, precision } =
                columns.find(({ id }) => id === colId) || (emptyObject as ColumnOptions<I, R>);
            const value = row[toString(colId)];

            return {
                ...acc,
                [colId]: {
                    v: valueFormatter ? valueFormatter(value, context) : value,
                    ...(dataType && { t: dataType }), // The Excel cell type
                    ...(dataType &&
                        precision !== undefined &&
                        precision >= 0 && {
                            s: {
                                numFmt: precision === 0 ? '0' : `0${'.'.padEnd(precision, '0')}`,
                            },
                        }), // The cell style (if any)
                },
            };
        }, []),
    );

    // const valuesGrid = rowsWithFilteredColumns.reduce(
    //     (acc, row) => [
    //         ...acc, //
    //         => id.map(({ id, valueFormatter }) => {
    //             const value = row[toString(id)];
    //             return valueFormatter
    //                 ? valueFormatter(value, context)
    //                 : convertor(value);
    //         }),
    //     ],
    //     [] as Array<unknown[]>,
    // );

    const saveFile = (fileExtension: string) => {
        const wb = utils.book_new();

        // const worksheet = utils.json_to_sheet([
        //     columnTitles,
        //     Array(NUMBER_OF_EMPTY_ROWS).fill(''),
        //     grid, //
        // ]);

        const worksheet = utils.json_to_sheet(rowsWithFilteredColumns, { header: columnIds });
        utils.sheet_add_aoa(worksheet, [columnTitles], { origin: 'A1' });

        const colsWidths = columns.map((_, i) => {
            const maxLengthInRow = Math.max(
                // ...valuesGrid.map((row: unknown[]) => {
                ...rowsWithFilteredColumns.map((row: unknown[]) => {
                    return toString(row[i])?.length || 11;
                }), //
            );
            const width = maxLengthInRow * 2 + 1;
            return { width, height: 3 };
        });
        Object.keys(worksheet).forEach((key) => {
            /* all keys that do not begin with "!" correspond to cell addresses */
            const rowIndex = Number(key.replace(/[A-Z]/g, ''));

            if (key[0] !== '!') {
                const cell = worksheet[key];

                let bold = false;
                let patternType = 'none';
                let bgColor = { rgb: 'FFFFFFFF' };
                let bottom = { style: 'hair', color: { auto: 1 } };

                cell.s = {
                    ...cell.s, // use previously defined style (number formatting and precision)
                    fill: {
                        patternType: rowIndex !== 1 ? patternType : 'solid',
                        fgColor: rowIndex !== 1 ? bgColor : { rgb: 'FFEDF0F3' },
                    },
                    font: {
                        // name: 'Times New Roman',
                        sz: 14,
                        color: { rgb: 'FF000000' },
                        bold,
                        italic: false,
                        underline: false,
                    },
                    border: {
                        top: { style: 'hair', color: { auto: 1 } },
                        right: { style: 'hair', color: { auto: 1 } },
                        bottom,
                        left: { style: 'hair', color: { auto: 1 } },
                    },
                };
            }
        });

        worksheet['!cols'] = colsWidths;

        utils.book_append_sheet(wb, worksheet, 'worksheet');

        // const metricGroups = Array.from(
        //     new Set(
        //         localCurrentOptions?.[keyWord ? `selectedMetrics${DS}${keyWord}` : 'selectedMetrics']?.map(
        //             (item: string) => {
        //                 return allMetrics?.find((metric) => metric.id === item)?.section;
        //             },
        //         ),
        //     ),
        // );

        // if (metricGroups?.length) {
        //     metricGroups.forEach((group) => {
        //         sendAnalytics(`${keyWord || 'report'}_downloaded_${group}`);
        //     });
        // } else {
        //     sendAnalytics(`${keyWord || 'report'}_downloaded`);
        // }

        writeFile(wb, `${bookName || tableId}.` + fileExtension);
    };

    saveFile('xlsx');
};
