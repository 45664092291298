import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    padding: 10px;
`;

export const ItemsWrapper = styled.div`
    margin-top: 10px;
    :first-child {
        margin-top: 0px;
    }
`;
