import styled from 'styled-components/macro';

export const Wrapper = styled.div``;

export const Item = styled.div`
    cursor: pointer;
    width: 100%;
    padding: 5px 10px;
    :hover {
        background-color: #a1b8f2;
    }
`;
