import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { IEvent, IEventType, IExtendedEvent } from '../../interfaces';
import { Action, ActionKind, IState } from './interfaces';
import validateFormValues from './tools/validateFormValues';

export const initialState: IState = {
    period: { dateFrom: '', dateTo: '' },
    selectedReportingObjectsIds: [],
    selectedReportingObjectsIdsError: false,
    eventToUpdate: null,
    eventTypeError: false,
    periodError: false,
    nameError: false,
    eventsToCreate: [],
    timeFrom: '00:00',
    timeFromError: false,
    timeTo: '23:59',
    timeToError: false,
    eventType: null,
    comment: '',
    name: '',
};

export const reducer = (state = initialState, action: Action): IState => {
    switch (action.type) {
        case ActionKind.StoreName: {
            return {
                ...state,
                name: action.payload,
                nameError: false,
            };
        }
        case ActionKind.ToggleNameError: {
            return {
                ...state,
                nameError: action.payload,
            };
        }
        case ActionKind.StoreEventType: {
            return {
                ...state,
                eventType: action.payload,
                eventTypeError: false,
            };
        }
        case ActionKind.ToggleEventTypeError: {
            return {
                ...state,
                eventTypeError: action.payload,
            };
        }
        case ActionKind.StorePeriod: {
            return {
                ...state,
                period: action.payload,
                periodError: false,
            };
        }
        case ActionKind.TogglePeriodError: {
            return {
                ...state,
                periodError: action.payload,
            };
        }
        case ActionKind.StoreComment: {
            return {
                ...state,
                comment: action.payload,
            };
        }
        case ActionKind.StoreSelectedReportingObjectsIds: {
            return {
                ...state,
                selectedReportingObjectsIds: action.payload,
                selectedReportingObjectsIdsError: false,
            };
        }
        case ActionKind.ToggleSelectedReportingObjectsIdsError: {
            return {
                ...state,
                selectedReportingObjectsIdsError: action.payload,
            };
        }
        case ActionKind.StoreTimeFrom: {
            return {
                ...state,
                timeFrom: action.payload,
                timeFromError: false,
            };
        }
        case ActionKind.StoreTimeTo: {
            return {
                ...state,
                timeTo: action.payload,
                timeToError: false,
            };
        }

        case ActionKind.FillDataFromEvent: {
            return fillDataFromEvent(state, action.payload);
        }

        case ActionKind.StoreEventToUpdate: {
            return storeEventToUpdate(state, action.payload);
        }

        case ActionKind.StoreEventsToCreate: {
            return storeEventsToCreate(state, action.payload);
        }
        case ActionKind.ResetReducer: {
            return initialState;
        }
        default:
            return state;
    }
};

const getISOdt = (str: string, tz: string) => {
    return DateTime.fromISO(str, { zone: tz as string }).toISO();
};

/**
 * Сохранение события, котое нужно обновить
 */
const storeEventToUpdate = (
    state: IState,
    payload: {
        event: IExtendedEvent;
    } | null,
) => {
    let eventToUpdate: null | IExtendedEvent = null;
    const stateCopy = cloneDeep(state);

    if (payload !== null) {
        const validationResponse = validateFormValues({
            selectedReportingObjectsIds: stateCopy.selectedReportingObjectsIds,
            eventType: stateCopy.eventType,
            timeFrom: stateCopy.timeFrom,
            period: stateCopy?.period,
            timeTo: stateCopy.timeTo,
            name: stateCopy.name,
        });

        if (validationResponse.hasError) {
            return {
                ...stateCopy,
                ...validationResponse.result,
            };
        } else {
            const { event } = payload;
            const color = state.eventType?.color || 'red';
            const eventTypeName = state.eventType?.name || '';
            eventToUpdate = {
                index: 0,
                date_from: getISOdt(`${stateCopy?.period.dateFrom}T${stateCopy.timeFrom}:00`, event.timezone) ?? '',
                date_to: getISOdt(`${stateCopy?.period.dateTo}T${stateCopy.timeTo}:00`, event.timezone) ?? '',
                data_objects_ids: stateCopy.selectedReportingObjectsIds,
                event_type: stateCopy.eventType?.id || 0,
                comment: stateCopy.comment,
                event_type_color: color,
                name: stateCopy.name,
                pl_id: event.pl_id,
                id: event.id,
                timezone: event.timezone,
                event_type_name: eventTypeName,
            };
        }
    }

    return {
        ...stateCopy,
        eventToUpdate,
    };
};

/**
 * Запонение полей, если выбрано событие
 */
const fillDataFromEvent = (state: IState, payload: { event: IEvent & { eventType: IEventType } }) => {
    const {
        event: { name, date_from, date_to, eventType, comment, data_objects_ids, timezone },
    } = payload;

    return {
        ...state,
        selectedReportingObjectsIds: data_objects_ids,
        period: {
            dateFrom: DateTime.fromISO(date_from, { zone: timezone }).toFormat('yyyy-MM-dd'),
            dateTo: DateTime.fromISO(date_to, { zone: timezone }).toFormat('yyyy-MM-dd'),
        },
        timeFrom: DateTime.fromISO(date_from, { zone: timezone }).toFormat('HH:mm'),
        timeTo: DateTime.fromISO(date_to, { zone: timezone }).toFormat('HH:mm'),
        eventType,
        comment,
        name,
    };
};

/**
 * Сохранение событий для создания
 */
const storeEventsToCreate = (
    state: IState,
    payload: {
        projectLocationId: number;
        timeZone: string;
    },
) => {
    const { projectLocationId, timeZone } = payload;
    const stateCopy = cloneDeep(state);
    const result: IEvent[] = [];

    const validationResponse = validateFormValues({
        selectedReportingObjectsIds: stateCopy.selectedReportingObjectsIds,
        eventType: stateCopy.eventType,
        timeFrom: stateCopy.timeFrom,
        period: stateCopy?.period,
        timeTo: stateCopy.timeTo,
        name: stateCopy.name,
    });

    if (validationResponse.hasError) {
        return {
            ...stateCopy,
            ...validationResponse.result,
        };
    } else {
        const color = state.eventType?.color;
        const eventTypeName = state.eventType?.name;
        const eventFromForm: IEvent = {
            id: 0,
            data_objects_ids: stateCopy.selectedReportingObjectsIds,
            date_from: getISOdt(`${stateCopy?.period.dateFrom}T${stateCopy.timeFrom}:00`, timeZone) ?? '',
            date_to: getISOdt(`${stateCopy?.period.dateTo}T${stateCopy.timeTo}:00`, timeZone) ?? '',
            event_type: stateCopy.eventType?.id || 0,
            comment: stateCopy.comment,
            pl_id: projectLocationId,
            event_type_color: color || 'red',
            name: stateCopy.name,
            timezone: timeZone,
            event_type_name: eventTypeName || '',
        };
        result.push(eventFromForm);
        return {
            ...stateCopy,
            eventsToCreate: result,
        };
    }
};
