import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import { useMemo } from 'react';
import { getRequest } from '../../../tools/API/appBackendAPI';
import { useQueries, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { EVENT_TYPES_QUERY_KEY } from '../constants/constants';
import { IEventType } from '../interfaces';
import { isBoolean, isNumber } from 'lodash';
import { Network_Section_Reducer_Values } from '../../Network/reducer';

interface IArgs {
    isQueryEnabled?: boolean;
    isNetwork?: boolean;
}

/**
 * Получение типов событий
 */
const useGetEventTypes = (args?: IArgs) => {
    const { selectedLocationId, token, urlsByServices } = useSelector(generalReducerValues);
    const networkReducerValues = useSelector(Network_Section_Reducer_Values);

    const [LocationsEventTypesUrl] = useMemo(() => {
        if (urlsByServices?.['app/app-backend']) {
            return [urlsByServices['app/app-backend'].LOCATION_EVENT_TYPES_URL];
        }
        return [];
    }, [urlsByServices]);

    return useQueries({
        queries: (args?.isNetwork
            ? networkReducerValues.cfg.reportingObjectsByType?.['location']?.map((item) => item.pl_id) ?? []
            : [selectedLocationId]
        )
            .filter((id) => isNumber(id))
            .map((id) => {
                return {
                    queryKey: [EVENT_TYPES_QUERY_KEY, id],
                    queryFn: () =>
                        // getRequest(`${LocationsEventTypesUrl}?event_type__pl_id=${id}`, token, undefined, true),
                        getRequest(`${LocationsEventTypesUrl}?pl_id=${id}`, token, undefined, true),
                    select: (response: AxiosResponse<IEventType[]>) => response.data,
                    enabled: args?.isQueryEnabled ?? false,
                    staleTime: 6 * 3600 * 1000,
                    retry: 0,
                };
            }),
    });
};

export default useGetEventTypes;
