import React, { Fragment, useRef, useMemo } from 'react';
import {
    PositioningImperativeRef,
    PositioningVirtualElement,
    Menu,
    MenuGroup,
    MenuPopover,
    MenuTrigger,
    Divider,
    MenuList,
} from '@fluentui/react-components';
// import { t } from 'i18next';

import { ContextualMenu } from 'src/components/ContextualMenu';
import { Identity } from 'src/types';

import {
    ContextMenuItem,
    GroupMenuItem,
    MenuItem,
    SubMenuItem,
    MenuConfig,
    MenuSplitter,
    MenuContextBase,
    // Context,
} from './interfaces';
// import { getLabel } from '../utils';

// import { getMenuConfig } from './menuConfig';
import { StyledMenuGroupHeader, StyledMenuItem } from './styles';

export const getContextMenuRect = (x = 0, y = 0) => {
    return () => ({
        height: 0,
        bottom: y,
        right: x,
        width: 0,
        left: x,
        top: y,
        x,
        y,
    });
};

export function isMenuItem<
    I extends string,
    R extends Identity<I>,
    E extends object = {},
    C extends MenuContextBase = MenuContextBase
>(item: ContextMenuItem<I, R, E, C>): item is MenuItem<I, R, E, C> {
    return item !== null && typeof item === 'object' && 'id' in item && !('subItems' in item);
}

export function isSubMenuItem<
    I extends string,
    R extends Identity<I>,
    E extends object = {},
    C extends MenuContextBase = MenuContextBase
>(
    item: ContextMenuItem<I, R, E, C>,
): item is SubMenuItem<I, R, E, C> {
    return item !== null && typeof item === 'object' && 'id' in item && 'subItems' in item;
}

export function isGroupMenuItem<
    I extends string,
    R extends Identity<I>,
    E extends object = {},
    C extends MenuContextBase = MenuContextBase,

>(
    item: ContextMenuItem<I, R, E, C>,
): item is GroupMenuItem<I, R, E, C> {
    return item !== null && typeof item === 'object' && 'id' in item && 'groupItems' in item;
}

export function isMenuSplitter(item: MenuSplitter | MenuItem<any, any> | SubMenuItem<any, any>): item is MenuSplitter {
    return item === null || item === undefined || item === '';
}

type ContextMenuProps<
    I extends string = string, // id type
    R extends Identity<I> = Identity<I>,
    E extends object = {},
    C extends MenuContextBase = MenuContextBase
> = {
    contextRef: React.RefObject<C>;
    menuConfig: MenuConfig<I, R, E, C>;
    menuEvent: MouseEvent | null;
};

const renderMenuItem = <
    I extends string,
    R extends Identity<I>,
    E extends object = {},
    C extends MenuContextBase = MenuContextBase
>(
    menuItem: MenuItem<I, R, E, C> | SubMenuItem<I, R, E, C> | MenuSplitter,
    itemIndex: number,
    contextRef: React.RefObject<C>,
    menuEvent: MouseEvent,
    deep = 0,
): React.ReactNode => {
    const context = contextRef.current!;

    if (isMenuSplitter(menuItem)) {
        return <Divider key={`${context?.componentId || ''}-divider-${deep}-${itemIndex}`} />;
    }

    // const emitter = prepareCellEvent(contextRef, contextMenuEvent);

    // const label = getLabel(menuItem.id, menuItem.label, t);

    if (isSubMenuItem(menuItem)) {

        if (menuItem.subItems?.length > 1) {
            // validate unique menu items in menu list
            menuItem.subItems
                .forEach((subItem) => {
                    if (!isMenuItem(subItem)) {
                        return
                    }
                    if (subItem?.renderer?.['isUniqueMenuItem'] === true) {
                        throw new Error(`Menu subitem "${subItem.label}" with key "${subItem.id}" should be used as single item in SubMenu "${menuItem.id}"!`);
                    }
                });
        }

        return (
            <Menu persistOnItemClick hoverDelay={10} key={`${context?.componentId || ''}-submenu-${menuItem.id}-${deep}`}>
                <MenuTrigger disableButtonEnhancement>
                    <StyledMenuItem area-disabled key={`${menuItem.id}-${itemIndex}`}>
                        {menuItem.label}
                    </StyledMenuItem>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {menuItem.subItems.map((subItem, index) =>
                            renderMenuItem(subItem, index, contextRef, menuEvent, deep + 1),
                        )}
                    </MenuList>
                </MenuPopover>
            </Menu>
        );
    }

    if (isGroupMenuItem(menuItem)) {
        if (menuItem.groupItems?.length > 1) {
            // validate unique menu items in menu group
            menuItem.groupItems
                .reduce((acc, subMenuOrItem) => {
                    return [
                        ...acc,
                        // ...isSubMenuItem(subMenuOrItem) ? subMenuOrItem.subItems : [],
                        ...isMenuItem(subMenuOrItem) ? [subMenuOrItem] : [],
                    ]
                }, [])
                .forEach((subItem) => {
                    if (!isMenuItem(subItem)) {
                        return
                    }
                    if (subItem?.renderer?.['isUniqueMenuItem'] === true) {
                        throw new Error(`Menu subitem "${subItem.label}" with key "${subItem.id}" should be used as single item in GroupMenu "${menuItem.id}"!`);
                    }
                });
        }
        return (
            <MenuGroup key={`${context?.componentId || ''}-menu-group-${menuItem.id}-${deep}`}>
                <StyledMenuGroupHeader key={`${context?.componentId || ''}-group-${menuItem.id}-${deep}-${itemIndex}`}>
                    {menuItem.label}
                </StyledMenuGroupHeader>
                {menuItem.groupItems.map((groupItem, index) =>
                    renderMenuItem(groupItem, index, contextRef, menuEvent, deep + 1),
                )}
            </MenuGroup>
        );
    }

    const isVisible =
        menuItem?.checkVisibility?.({
            contextRef,
            menuEvent,
            menuItemId: menuItem.id,
        }) ?? true;

    if (menuItem.renderer && isVisible) {
        return (
            <StyledMenuItem
                key={`${context?.componentId || ''}-custom-item-${menuItem.id}-${deep}-${itemIndex}`}
                style={menuItem.itemStyle}
            >
                {menuItem.renderer({
                    contextRef,
                    menuEvent,
                    menuItemId: menuItem.id,
                    label: menuItem.label,
                })}
            </StyledMenuItem>
        );
    }

    return (
        <StyledMenuItem
            key={`${context?.componentId || ''}-menu-item-${menuItem.id}-${deep}-${itemIndex}`}
            onClick={() => {
                menuItem?.onItemClick?.({
                    contextRef,
                    menuEvent,
                    menuItemId: menuItem.id,
                });

                context?.closeMenu?.();
            }}
        >
            {menuItem.label}
        </StyledMenuItem>
    );
};

export const getContextualMenuBody = <
    I extends string,
    R extends Identity<I>,
    E extends object = {},
    C extends MenuContextBase = MenuContextBase
>(
    menuConfig: MenuConfig<I, R>,
    contextRef: React.RefObject<C>,
    menuEvent: MouseEvent,
): React.ReactElement<MenuConfig<I, R>, typeof MenuList> => {
    return (
        <MenuList key={`${contextRef?.current?.componentId || ''}-menu-list`}>
            {menuConfig.map((menuItem, index) => (
                <Fragment key={`${contextRef?.current?.componentId || ''}-menu-list-item-fragment-${index}`}>
                    {renderMenuItem(menuItem, index, contextRef, menuEvent)}
                </Fragment>
            ))}
        </MenuList>
    );
};

// export const ContextMenu = <I extends string = string, R extends Identity<I> = Identity<I>>({
//     menuEvent,
//     contextRef,
//     menuConfig,
// }: // menuConfig,
// ContextMenuProps<I, R>) => {
//     if (!contextRef?.current) {
//         throw new Error('Table context is not available');
//     }

//     const contextMenuTargetRef = useRef<PositioningImperativeRef>(null);

//     const isOpened = useMemo(() => {
//         const virtualElement: PositioningVirtualElement = {
//             getBoundingClientRect: getContextMenuRect(menuEvent?.clientX, menuEvent?.clientY),
//         };
//         contextMenuTargetRef.current?.setTarget(virtualElement);

//         return menuEvent !== null;
//     }, [menuEvent]);

//     // TODO: join with menuConfig
//     const menuBody = isOpened ? getContextualMenuBody(menuConfig, contextRef, menuEvent!) : null;

//     return (
//         <ContextualMenu
//             key={`table-menu-${contextRef.current.componentId}`}
//             isContextualMenuOpen={isOpened}
//             closeContextualMenu={contextRef.current.closeMenu}
//             contextualMenuBody={menuBody!}
//             positioningRef={contextMenuTargetRef}
//         />
//     );
// };
