import React from 'react';
import { FluentProvider, webLightTheme, BrandVariants, createLightTheme } from '@fluentui/react-components';
import { ThemeProvider } from 'styled-components';

import { screenBreakpointsFromComputedStyle } from './definitions';
import { ThemeProps } from './interfaces';
import { prepareFluentThemeFabric, prepareStyledThemeFabric } from './utils';
import { useAssignVars } from './useAssignVars';
import { commonTheme } from './commonTheme';

export const Theme: React.FC<ThemeProps> = ({ children, isRtlLanguage }: ThemeProps) => {
    useAssignVars();
    const direction = isRtlLanguage ? 'rtl' : 'ltr';
    const theme = {
        ...screenBreakpointsFromComputedStyle,
        isRtlLanguage,
        direction,
    };

    const overridedColors: BrandVariants = {
        10: '#020205',
        20: '#141623',
        30: '#1D233D',
        40: '#242F54',
        50: '#2B3A6B',
        60: '#314784',
        70: '#38539D',
        80: '#3E60B7',
        90: '#506DC4',
        100: '#657BCA',
        110: '#7989D1',
        120: '#8B98D7',
        130: '#9DA7DD',
        140: '#AFB6E3',
        150: '#C0C5EA',
        160: '#D1D5F0',
    };

    const lightTheme = {
        ...createLightTheme(overridedColors),
        colorNeutralForeground1: commonTheme.colors.text,
        colorNeutralBackground1: commonTheme.colors.secondaryLight,

        colorStrokeFocus2: 'rgba(0, 0, 0, 0.00)', // to reset focus
        // "colorStrokeFocus2": "#000000",
        // "colorStrokeFocus1": "#ffffff",
        // "colorNeutralStroke1": "#d1d1d1",
    };

    // console.log(lightTheme);

    // const fluentFabric = prepareFluentThemeFabric(webLightTheme);

    const styledFabric = prepareStyledThemeFabric();

    const styledTheme = { ...theme, ...styledFabric, rtl: isRtlLanguage };

    return (
        <FluentProvider theme={lightTheme} dir={direction}>
            <ThemeProvider theme={styledTheme}>{children}</ThemeProvider>
        </FluentProvider>
    );
};
