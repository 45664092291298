import { useMemo } from 'react';

import { useGeneralSelector, useWidgetCurrentOptions } from 'src/hooks';

const DEFAULT_PERIOD = [{ text: undefined }, { text: undefined }];

// TODO implement period getter from mainPeriod or period (UniversalDatePicker)
export const useUniversalDateTimePickerPeriod = () => {
    const { currentModuleID } = useGeneralSelector();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const [{ text: dateFrom }, { text: dateTo }] = localCurrentOptions?.period || DEFAULT_PERIOD;
    // const periodFromTo = periodGetter
    const periodFromTo = useMemo(
        () => ({ dateFrom, dateTo }),
        [dateFrom, dateTo],
    );

    return periodFromTo
};