import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const InputWrapper = styled.div`
    width: 100%;
    margin-bottom: var(--space-xs);
`;

export const Footer = styled.div`
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const ColorBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: var(--space-xs);
`;

export const Txt = styled.div`
    margin-left: var(--space-xs);
`;

export const Box = styled.div<{ color: string }>`
    cursor: pointer;
    background-color: ${(p) => p.color};
    width: 30px;
    height: 30px;
    border-radius: 5px;
`;
