import React, { ReactElement } from 'react';
import { IRating } from '../../interfaces';
import { Performance_Ratings_RatingList_Widget_Reducer_Values } from '../../reducer';
import { RatingListContainer } from './style';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import usePeriods from '../../../../../../../hooks/common/usePeriods';

import RatingItem from './components/RatingItem/RatingItem';
import { IReportingObject } from '../../../../../../../General.interfaces';

const RatingList: React.FC = () => {
    const { ratings, idLoadRating } = useSelector(Performance_Ratings_RatingList_Widget_Reducer_Values);
    const {
        cfg: { reportingObjectsByType },
        mainAreaSize: { width },
        currentModuleID,
        allMetrics,
    } = useSelector(generalReducerValues);
    const { comparePeriods, mainPeriod } = usePeriods(currentModuleID);

    const ratingItems: IRating[] | null = ratings;
    let List: ReactElement[] = [];
    if (
        ratingItems &&
        reportingObjectsByType &&
        comparePeriods &&
        mainPeriod &&
        currentModuleID &&
        allMetrics &&
        ratingItems?.length !== 0
    ) {
        List = ratingItems.map((item: IRating) => {
            return (
                <RatingItem
                    rating={item}
                    key={item.id}
                    isLoad={idLoadRating[item.id] ? true : false}
                    reportingObjectIds={
                        reportingObjectsByType?.[item.dataobj_type ? item.dataobj_type : '']?.map(
                            (item: IReportingObject) => item.id,
                        ) || []
                    }
                    comparePeriods={comparePeriods.map((item) => item.period)}
                    mainPeriod={mainPeriod.period}
                    moduleId={currentModuleID}
                    metric={item.metric}
                    widgetId={'Rating with area by objects'}
                    allMetrics={allMetrics}
                ></RatingItem>
            );
        });
    }
    return <RatingListContainer>{List}</RatingListContainer>;
};
export default RatingList;
