import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    padding: 10px 0;
`;

export const OptionWrapper = styled.div`
    padding: var(--space-xxxxs) var(--space-xs);
    cursor: pointer;
    border: 1px solid transparent;

    :hover {
        background-color: var(--color-primary-lighter);
        color: white;
    }
`;
