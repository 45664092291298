import { theme } from 'src/theme';

const FULL_SENSOR_OPERATIONS = {
    fill: theme.colors.success,
    operation: 'full',
};

const PARTIAL_SENSOR_OPERATIONS = {
    fill: theme.colors.warning,
    operation: 'partial',
};

const NO_SENSOR_OPERATIONS = {
    fill: theme.colors.accent,
    operation: 'no',
};

export type PcSensorDescription = {
    fill: string;
    operation: string
}

export const describeSensor = (values: Array<{ v: number }>, maxMeasures: number): PcSensorDescription => {
    const averageMeasure =
        values.reduce((acc, val) => {
            return acc + val.v;
        }, 0) / maxMeasures;

    if (averageMeasure === 0) {
        return NO_SENSOR_OPERATIONS;
    }
    if (averageMeasure < 0.999999 && averageMeasure > 0) {
        return PARTIAL_SENSOR_OPERATIONS;
    }
    return FULL_SENSOR_OPERATIONS;
}