import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import { Title } from './styles';

const WidgetTitle: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { isRtlLanguage } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    return <Title isRtlLanguage={isRtlLanguage}>{children}</Title>;
};

WidgetTitle.displayName = 'WidgetTitle';
export default WidgetTitle;
