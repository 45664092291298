import { generalReducerValues } from '../../../General.reducer';
import { useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import Select from '../Select/Select';
import { IObject, ISelectedOption, TSelectedOptions } from '../Select/interfaces';
import useTranslation from '../../../hooks/useTranslation/useTranslation';

/**
 * Компонент универсального селетка
 */
const UniversalSelect: React.FC<IProps> = ({
    iconType = 'chevron-down',
    defaultSelected,
    mode = 'multi',
    dropdownAlign = 'left',
    labelText = ' ',
    dropdownWidth,
    outsideText,
    showFilter = false,
    isGlobal = false,
    options,
    text,
    minSelected,
    maxSelected,
    name,
    maxWidth,
    localCurrentOptions,
    disabled = false,
    fullWidth = false,
    changeOptions,
}) => {
    const { t } = useTranslation(['translation']);

    const { locations, selectedLocationId, currentModuleID, modulesConfig } = useSelector(generalReducerValues);

    /**
     * Если выбранный элемент не содержится в options, то происходит перезапись options
     */
    useEffect(() => {
        if (localCurrentOptions?.[name] && Array.isArray(localCurrentOptions?.[name]) && options?.length) {
            const availableOptionIds = options.map((item) => item.id);

            if (localCurrentOptions?.[name]?.some((item: ISelectedOption) => !availableOptionIds.includes(item?.id))) {
                changeOptions({
                    newOptions: {
                        [name]: [options[0]],
                    },
                });
            }
        }
    }, [JSON.stringify(localCurrentOptions?.[name]), JSON.stringify(options)]);

    useEffect(() => {
        if (
            localCurrentOptions?.currentModuleID === currentModuleID &&
            defaultSelected &&
            selectedLocationId &&
            !localCurrentOptions?.[name]
        ) {
            changeOptions({
                newOptions: {
                    [name]: localCurrentOptions?.[name] || defaultSelected,
                },

                isGlobal,
            });
        }
    }, [
        localCurrentOptions?.currentModuleID,
        selectedLocationId,
        currentModuleID,
        name,
        isGlobal,
        localCurrentOptions,
        JSON.stringify(defaultSelected),
        // changeOptions,
    ]);

    useEffect(() => {
        if (
            localCurrentOptions &&
            !localCurrentOptions?.[name] &&
            options?.[0] &&
            selectedLocationId &&
            mode === 'single' &&
            !defaultSelected &&
            localCurrentOptions?.currentModuleID === currentModuleID
        ) {
            changeOptions({
                newOptions: {
                    [name]: [options?.[0]],
                },

                isGlobal,
            });
        }
    }, [
        currentModuleID,
        JSON.stringify(defaultSelected),
        localCurrentOptions?.currentModuleID,
        mode,
        name,
        JSON.stringify(options),
        selectedLocationId,
        isGlobal,
        modulesConfig,
        // changeOptions,
        localCurrentOptions,
    ]);

    const handleSelect = (args: TSelectedOptions) => {
        const argsNoTranslate = args
            // .filter((element) => idsOfOptions.includes(element.id))
            .map((item) => {
                const initial = options?.find((obj) => obj.id === item.id);
                const text = initial?.text;
                const section = initial?.section;
                return { ...item, text, section };
            });

        selectedLocationId &&
            changeOptions({
                newOptions: {
                    [name]: argsNoTranslate,
                },
                isGlobal,
            });
    };

    const outsideSelected = useMemo(() => {
        if (localCurrentOptions?.[name] && Array.isArray(localCurrentOptions?.[name])) {
            const idsOfOptions = options?.map((element) => element.id);
            const selectedIds: (string | number | null)[] = localCurrentOptions?.[name]
                ?.map((element: ISelectedOption) => {
                    return element.id;
                })
                ?.filter((element: string | number | null) => idsOfOptions?.includes(element));
            return selectedIds?.map((id) => {
                const selectedOption = options.find((option) => option.id === id)!;
                const text = selectedOption?.text;
                return { ...selectedOption, text: t(text || '') };
            });
        }

        return null;
    }, [localCurrentOptions, name, options, t]);

    // const max = maxSelected ? ` (${t('max')}: ${maxSelected})` : '';

    return (
        <Wrapper>
            <Select
                testId={`${currentModuleID}_${name}`}
                outsideSelected={outsideSelected || []}
                disabled={locations.length === 0 || disabled}
                options={
                    options?.map((element) => ({
                        ...element,
                        text: t(element?.text || ''),
                    })) as IObject[]
                }
                dropdownWidth={dropdownWidth}
                handleSelect={handleSelect}
                maxSelected={maxSelected}
                minSelected={minSelected}
                labelText={t(labelText)}
                dropdownAlign={dropdownAlign}
                outsideText={outsideText}
                iconType={iconType}
                showFilter={showFilter}
                fullWidth={fullWidth}
                mode={mode}
                maxWidth={maxWidth}
                text={text ? t(text) : t('Selected')}
            />
        </Wrapper>
    );
};

export default UniversalSelect;
