/**
 * Функция для получение различия между переданными числами
 * @param mainValue Перове число
 * @param compareValue Второе число
 * @returns Объект с различием между переданными числами в процентах и числовое различие
 */

const getDifferenceBetweenNumbers = (mainValue: number | undefined, compareValue: number | undefined) => {
    // if (!mainValue || !compareValue) {
    if (compareValue === undefined || compareValue === null) {
        return {
            percentDifference: '-',
            difference: '-',
        };
    }
    const difference = compareValue === 0 && mainValue !== 0 ? 1 : (mainValue ?? 0) / compareValue - 1;

    // const difference =  mainValue / compareValue - 1;
    return {
        percentDifference:
            (mainValue ?? 0) * compareValue < 0 || compareValue === 0 ? '-' : (difference * 100).toFixed(2),
        difference: ((mainValue ?? 0) - compareValue).toFixed(2),
    };
};

export default getDifferenceBetweenNumbers;
