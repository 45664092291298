import ReactDataSheet from 'react-datasheet';
import { ICell } from '../interfaces';
import { MAX_COLS, MAX_ROWS } from '../constants/constants';

export const generateEmptyData = (cols: number = MAX_COLS, rows: number = MAX_ROWS): ICell[][] => {
    const emptyData: ICell[][] = [];
    for (let i = 0; i < rows; i++) {
        const row: { value: string; [key: string]: any }[] = [];
        for (let j = 0; j < cols; j++) {
            row.push({ value: '' });
        }
        emptyData.push(row);
    }
    return emptyData;
};
