import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { EventsManagementContext } from '../../../../context';
import { TColumnsKeys } from '../../constants/constants';
import { DataCell, HeaderCell } from '../../styles';
import EventControls from '../EventControls/EventControls';
import EventType from '../EventType/EventType';
import ReportingObjects from '../ReportingObjects/ReportingObjects';

import { FilterValue } from './styles';
/**
 * Компонент для отображения ячейки таблицы
 */
const Cell = (props: any) => {
    const context = useContext(EventsManagementContext);
    const { children, filtersByCellKey, ...rest } = props;
    const changedType = context?.changedData[props.cell.frontId]?.type;
    const { user } = useSelector(generalReducerValues);

    const dataCellContent = useMemo(() => {


        switch (props.cell.key as TColumnsKeys) {
            case 'controls':
                return user?.permissions.includes('app/client | modules/configuration/events | feature/edit') ? (
                    <EventControls data={props.cell} />
                ) : null;

            case 'event_type':
                return <EventType data={props.cell} />;

            case 'data_objects_ids':
                return <ReportingObjects data={props.cell} />;

            default:
                return children;
        }
    }, [props]);

    switch (props.cell.type) {
        case 'header':
            const filterParams = filtersByCellKey?.[props.cell.key]!;
            const filterText = (filterParams?.filter !== undefined) ? filterParams?.filter : '';
            return (
                <HeaderCell {...rest}>
                    {children}
                    <FilterValue>{filterText}</FilterValue>
                </HeaderCell>
            );
        default:
            return (
                <DataCell changedType={changedType} {...rest}>
                    {dataCellContent}
                </DataCell>
            );
    }
};

export default Cell;
