import { ITabListOptionsItem } from '../../../components/TabList/interfaces';
import { TabListItemIds } from '../enums';

import { DS } from 'src/constants';

export const DETAIL_SELECT_NAME = `detailSelect${DS}Sales:POS monitoring${DS}Structure-Dynamics`;
export const SD_VAT_SELECT = `useVAT${DS}Sales:POS monitoring${DS}Structure-Dynamics`;
export const CORRECTION_RECEIPT_SELECT = `correctionReceipts${DS}Sales:POS monitoring${DS}Structure-Dynamics`;

export const PAYMENT_ANALYSIS_TENANT_SELECT = 'paymentAnalysisTenantSelect';
export const VAT_SELECT = 'useVAT';

// export const salesMetricsNames = ['fsf_sales_', 'fsf_sales_return_'];
export const SALES_METRIC_NAMES = ['fsf_sum_'];
export const PAYMENT_TYPES = ['ecash', 'cash', 'credit', 'prepaid', 'provision'];
export const OPERATION_TYPES = ['income', 'income_return'];
export const PAYMENT_TYPE_TEXT_MAP = {
    ecash: 'E-Cash',
    cash: 'Cash',
    credit: 'Credit',
    prepaid: 'Prepaid',
    provision: 'Provision',
};

export const CORRECTION_MAP = {
    withoutCorrection: 'bill|bso',
    addCorrection: 'bill|bso|bill_correction|bso_correction',
    correctionOnly: 'bill_correction|bso_correction',
};

export const TAB_LIST_ITEMS: ITabListOptionsItem[] = [
    { id: TabListItemIds.PaymentType, text: 'Payment type' },
    { id: TabListItemIds.OperationType, text: 'Operation type' },
];


export const VAT_OPTIONS = [
    { id: 'vat', text: 'Use VAT' },
    { id: 'novat', text: 'No VAT' },
];

export const CORRECTION_OPTIONS = [
    { id: 'withoutCorrection', text: 'Sum without correction' },
    { id: 'addCorrection', text: 'Add correction' },
    { id: 'correctionOnly', text: 'Correction sum only' },
];