import styled from 'styled-components/macro';

export const THead = styled.thead`
    position: sticky;
    top: 0;
    z-index: 5;
`;

export const Th = styled.th`
    border-bottom: 1px solid #ebebeb;
    padding: 15px;
    text-align: center;
    width: 180px;
    background-color: white;
    border-right: 1px solid #ebebeb;
    :first-child {
        border-right: none;
        background: #f6f9ff;
    }
    :last-child {
        border-right: none;
    }
`;

export const HeaderCellWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const UnitsText = styled.div`
    color: rgba(0, 0, 0, 0.4);
`;
