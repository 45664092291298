import React from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import MetricsSelect from '../../../components/Selects/MetricsSelect/MetricsSelect';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import ObjectsSelect from '../../../components/Selects/ObjectsSelect/ObjectsSelect';
import Preset from '../../../components/Preset/Preset';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import UniversalSelect from '../../../components/Selects/UniversalSelect/UniversalSelect';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import { ISettings } from '../../../components/UserSettings/interfaces';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import { generalReducerValues } from '../../../General.reducer';
import { useAppDispatch } from '../../../hooks/useSettings';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../components/UserSettings/reducer';

import { YOY_TABLE_SETTINGS, YOY_YEARS_SETTINGS, yoyTableSettingsOptions } from './constants/constants';
// import YearOverYearWidget from './widgets/YearOverYear/YearOverYear';
import DynamicsByMonths from './widgets/DynamicsByMonths/DynamicsByMonths';

/**
 * Модуль Год к Году
 */
const YearOverYear: React.FC = () => {
    const { currentModuleID, locations, selectedLocationId } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const locationStartYear = locations.find((item) => item.id === selectedLocationId)?.date_from;

    const yearOptions = () => {
        const result = [];
        const currentYear = DateTime.now().year;
        for (let year = DateTime.fromISO(locationStartYear || '2020').year; year <= currentYear; year++) {
            result.push({ id: year, text: String(year) });
        }
        return result.reverse();
    };

    // const availableYears = generateAvailableYears();

    const { t } = useTranslation();

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <ObjectsSelect
                            maxSelected={5}
                            localCurrentOptions={localCurrentOptions}
                            dataObjectFilters={[
                                'data_objects_tenant',
                                'data_objects_floor',
                                'data_objects_passway_type',
                            ]}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <MetricsSelect
                            maxSelected={1}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <UniversalSelect
                            defaultSelected={yearOptions().length >= 2 ? yearOptions().slice(0, 2) : yearOptions()}
                            options={yearOptions()}
                            name={YOY_YEARS_SETTINGS}
                            labelText={t('Years')}
                            minSelected={1}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <UniversalSelect
                            options={yoyTableSettingsOptions}
                            labelText={t('Table settings')}
                            name={YOY_TABLE_SETTINGS}
                            iconType={'setting'}
                            dropdownWidth="md"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={
                                [
                                    'selectedReportingObjectsIds',
                                    YOY_YEARS_SETTINGS,
                                    YOY_TABLE_SETTINGS,
                                    'selectedMetrics',
                                ] as (keyof ISettings)[]
                            }
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <WrapperContainer>
                {/* <YearOverYearWidget /> */}
                {localCurrentOptions?.[YOY_YEARS_SETTINGS]?.length &&
                    localCurrentOptions?.selectedMetrics?.length &&
                    localCurrentOptions?.selectedReportingObjectsIds?.length && (
                        <DynamicsByMonths
                            reportingObjectsIds={localCurrentOptions.selectedReportingObjectsIds}
                            years={localCurrentOptions[YOY_YEARS_SETTINGS]}
                            metric={localCurrentOptions.selectedMetrics[0]}
                            viewSettings={{
                                showDifference: Boolean(localCurrentOptions?.[YOY_TABLE_SETTINGS]?.length),
                            }}
                            moduleId={currentModuleID}
                            widgetId={'DynamicsByMonths'}
                        />
                    )}
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default YearOverYear;
