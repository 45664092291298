// https://github.com/microsoft/fluentui/discussions/25794
// https://dianabirkelbach.wordpress.com/2024/01/13/standard-or-custom-theming-for-pcf-using-fluent-ui-v9/
// https://github.com/brasov2de/ToDosDataGridFluent9/blob/main/Todo/ColorGenerator.ts

import {
    // BaseSlots,v8
    // IThemeRules,v8
    // FabricSlots, vv8
    // ThemeGenerator, v8
    // createDarkTheme,
    // createTeamsDarkTheme,
    BrandVariants,
    createLightTheme, // themeRulesStandardCreator,
} from '@fluentui/react-components';
// } from '@fluentui/react/lib/ThemeGenerator';
// import { Button, createLightTheme, FluentProvider } from '@fluentui/react-components';
// import { createTheme } from '@fluentui/react/lib/Styling';

// import { isDark } from '@fluentui/react/lib/Color';
// import { getColorFromString } from '@fluentui/react/lib/Color';
import { Theme as FluentTheme } from '@fluentui/react-components';

import { commonTheme } from './commonTheme';
import { screenBreakpointsFromComputedStyle } from './definitions';

export const isTabletInboundWidth = (width: number) => {
    return width <= Number(screenBreakpointsFromComputedStyle.breakpoints.tablet.split('px')[0]);
};

export const isTabletOutboundWidth = (width: number) => {
    return width > Number(screenBreakpointsFromComputedStyle.breakpoints.tablet.split('px')[0]);
};

export const isMobileInboundWidth = (width: number) => {
    return width <= Number(screenBreakpointsFromComputedStyle.breakpoints.mobile.split('px')[0]);
};

export const isMobileOutboundWidth = (width: number) => {
    return width > Number(screenBreakpointsFromComputedStyle.breakpoints.mobile.split('px')[0]);
};

export const prepareFluentThemeFabric = (baseTheme?: FluentTheme) => {
    // const themeRules = themeRulesStandardCreator();
    // const colors = {
    //     primary: getColorFromString(commonTheme.colors.primary)!,
    //     secondary: getColorFromString(commonTheme.colors.info)!,

    //     green: getColorFromString(commonTheme.colors.success)!,
    //     text: getColorFromString(commonTheme.colors.text)!,
    //     background: getColorFromString(commonTheme.colors['secondary-light'])!,
    // };

    const colors = {
        primary: commonTheme.colors.primary!,
        secondary: commonTheme.colors.info!,

        green: commonTheme.colors.success!,
        text: commonTheme.colors.text!,
        background: commonTheme.colors['secondary-light']!,
    };

    // ThemeGenerator.insureSlots(themeRules, isDark(themeRules[BaseSlots[BaseSlots.backgroundColor]].color!));
    // ThemeGenerator.setSlot(themeRules[BaseSlots[BaseSlots.primaryColor]], colors.primary, undefined, false, false);

    // ThemeGenerator.setSlot(
    //     themeRules[FabricSlots[FabricSlots.themeSecondary]],
    //     colors.secondary,
    //     undefined,
    //     false,
    //     false,
    // );
    // ThemeGenerator.setSlot(
    //     themeRules[FabricSlots[FabricSlots.neutralSecondaryAlt]],
    //     colors.green,
    //     undefined,
    //     false,
    //     false,
    // );

    // ThemeGenerator.setSlot(themeRules[BaseSlots[BaseSlots.foregroundColor]], colors.text, undefined, false, false);
    // ThemeGenerator.setSlot(
    //     themeRules[BaseSlots[BaseSlots.backgroundColor]],
    //     colors.background,
    //     undefined,
    //     false,
    //     false,
    // );

    // const themeAsJson: {
    //     [key: string]: string;
    // } = ThemeGenerator.getThemeAsJson(themeRules);

    // const finalTheme = createTheme({ v8
    //     ...{ palette: themeAsJson },
    //     isInverted: isDark(themeRules[BaseSlots[BaseSlots.backgroundColor]].color!),
    // });

    const myNewTheme: BrandVariants = {
        10: '#040204',
        20: '#1C141F',
        30: '#2F1F35',
        40: '#3F2848',
        50: '#50315C',
        60: '#613A70',
        70: '#724385',
        80: '#854D9B',
        90: '#9757B1',
        100: '#A467BC',
        110: '#AF78C4',
        120: '#BA89CC',
        130: '#C59BD4',
        140: '#D0ACDC',
        150: '#DABEE4',
        160: '#E5D0EB',
    };

    const finalTheme = createLightTheme(myNewTheme);

    const themeFabric = {
        ...colors,
        theme: { ...baseTheme, ...finalTheme },
        // themeRules,
    };

    return themeFabric;
};

export const prepareStyledThemeFabric = () => {
    return commonTheme;
};
