import * as THREE from 'three';

/**
 * Вычисление координат финишной точки для стрелки.
 * @param point Координаты точки
 * @param i Индекс точки
 * @param arr Массив точек
 * @param planScale Масштаб плана
 * @param margin Ширина прохода
 * @returns Координаты финишной точки для стрелки.
 */
export const getArrowPoint = <T extends number>(point: T[], i: T, arr: Array<T[]>, planScale: T, margin: T, direction: string = 'end') => {
    const angle = direction === 'end' ? -Math.PI / 2 : Math.PI / 2;
    const center = [(point[0] + arr[i - 1][0]) / 2, (point[1] + arr[i - 1][1]) / 2];
    const normalV = new THREE.Vector3(0, 0, 1);
    const arrowV = new THREE.Vector3(point[0], point[1], 0)
        .sub(new THREE.Vector3(...center, 0))
        .normalize()
        .multiplyScalar(Math.max(planScale * margin, planScale * 0.4))
        .applyAxisAngle(normalV, angle)
        .add(new THREE.Vector3(...center, 0));

    return [arrowV.x, arrowV.y];
};
