import styled from 'styled-components/macro';
import { getColorFromPercent } from '../../../../../../../tools/getColorFromPercent';

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: separate;
    background: #fff;
    table-layout: fixed;
    position: relative;
`;

export const Th = styled.th`
    border-bottom: 1px solid #ebebeb;
    padding: 15px;
    text-align: center;
    width: 180px;
    background-color: white;
    border-right: 1px solid #ebebeb;
    :first-child {
        background: #f6f9ff;
        position: sticky;
        left: 0;
    }
    :last-child {
        border-right: none;
    }
`;

export const Td = styled.td`
    border-bottom: 1px solid #ebebeb;
    width: 180px;
    padding: 15px;
    border-right: 1px solid #ebebeb;
    :first-child {
        background: #f6f9ff;
        position: sticky;
        left: 0;
    }
    :last-child {
        border-right: none;
    }
`;

export const THead = styled.thead`
    position: sticky;
    top: 0;
    z-index: 5;
`;

export const Tr = styled.tr`
    :hover {
        td {
            background: #f6f9ff;
        }
    }
    :last-child {
        td {
            border-bottom: none;
        }
    }
`;

export const MetricValuesWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DefaultText = styled.div<{ value?: string }>`
    font-weight: 500;
    font-size: 14px;
    color: ${({ value }) => getColorFromPercent(value)};
`;

export const HeaderCellWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const IconsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const iconStyles = {
    root: {
        fontSize: '8px',
        height: '8px',
        lineHeight: '8px',
        cursor: 'pointer',
    },
};

export const NameWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DotsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 16px;
    position: relative;
`;

export const Dot = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: black;
    margin-left: 2px;
    :first-child {
        margin-right: 0px;
    }
`;
