import { IEvent, IEventType, IExtendedEvent } from '../../interfaces';

export enum ActionKind {
    StoreName = 'StoreName',
    ToggleNameError = 'ToggleNameError',
    StorePeriod = 'StorePeriod',
    TogglePeriodError = 'TogglePeriodError',
    StoreEventType = 'StoreEventType',
    ToggleEventTypeError = 'ToggleEventTypeError',
    StoreComment = 'StoreComment',
    ResetReducer = 'ResetReducer',
    StoreEventsToCreate = 'StoreEventsToCreate',
    FillDataFromEvent = 'FillDataFromEvent',
    StoreEventToUpdate = 'StoreEventToUpdate',
    StoreSelectedReportingObjectsIds = 'StoreSelectedReportingObjectsIds',
    ToggleSelectedReportingObjectsIdsError = 'ToggleSelectedReportingObjectsIdsError',
    StoreTimeFrom = 'StoreTimeFrom',
    StoreTimeTo = 'StoreTimeTo',
}

export type Action = {
    type: ActionKind;
    payload: any;
};

export interface IState {
    name: string;
    nameError: boolean;
    period: IPeriod;
    periodError: boolean;
    eventType: IEventType | null;
    eventTypeError: boolean;
    comment?: string;
    eventsToCreate: IEvent[];
    eventToUpdate: null | IExtendedEvent;
    selectedReportingObjectsIds: number[];
    timeFrom: string;
    timeTo: string;
    timeFromError: boolean;
    timeToError: boolean;
    selectedReportingObjectsIdsError: boolean;
}

export interface IPeriod {
    dateFrom: string;
    dateTo: string;
}
