import { TFunction } from 'i18next';
import { ZONES_WORD } from '../../constants/constants';
import { IReportingObject } from '../../General.interfaces';
import { objectTypesMap } from '../getReportingObjects';

export const getObjSectionName = ({ reportingObject, t }: { reportingObject: IReportingObject; t: TFunction }) => {
    let type = 'project_category';
    if (reportingObject?.block_type === 'reporting_object') {
        if (reportingObject.object_type.includes(ZONES_WORD)) {
            type = `${t(ZONES_WORD)} (${reportingObject.object_type.replace(ZONES_WORD + ': ', '')})`;
            if (reportingObject.object_type === 'Zones: Main') {
                type = `${t(ZONES_WORD)}`;
            }
        } else {
            type = t(objectTypesMap[reportingObject.object_type]);
        }
    } else if (reportingObject?.block_type === 'project_category') {
        type = 'project_category';
    } else {
        type = reportingObject?.object_type;
    }

    switch (type) {
        case 'project_category':
            return `${t('Category')}: ${t(reportingObject?.object_type)}`;

        default:
            return type;
    }
};
