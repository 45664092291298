import styled from 'styled-components/macro';

export const ModalWrapper = styled.div`
    width: 40vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Body = styled.div`
    width: 100%;
    overflow: hidden;
    display: flex;
    gap: 10px;
    padding-top: var(--space-xs);
    margin-bottom: var(--space-xs);
`;
export const Footer = styled.div`
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 10px;
    padding-top: var(--space-xs);
    margin-top: auto;
`;

export const SelectsWrapper = styled.div`
    display: flex;
    position: relative;
    align-items: flex-end;
    gap: var(--space-xxxs);
`;
