import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { generalReducerValues } from '../../../../../../General.reducer';
import { cabinetPreferencesValues } from '../../../../../../components/CabinetPreferences/reducer';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { TMetricResponse } from '../../../../../../General.interfaces';
import { IMetricParams, IRequestMetricsArgs, TTimeFreq } from '../../../../../../tools/API/interfaces';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../../../tools/API/dataAccessors/metricsDataAccessor';
import { DS } from '../../../../../../constants/constants';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';
import { Reports_Advanced_DetailedBills_Widget_Reducer_Values } from '../reducer';

const useFetchBills = () => {
    const { token, allMetrics, currentModuleID } = useSelector(generalReducerValues);
    const { keyWord } = useSelector(Reports_Advanced_DetailedBills_Widget_Reducer_Values);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const fetchData = useRequestMetrics();
    const { t } = useTranslation(['metrics', 'translation']);

    const [queryKey, queryFn, adapter] = useMemo(() => {
        const mainDatePeriod = localCurrentOptions?.[`mainDateRanges${DS}${keyWord}`]?.find(
            (item: { id: string }) => item.id === localCurrentOptions?.[`mainPeriod${DS}${keyWord}`]?.id,
        )?.period;

        const tenantIds = localCurrentOptions?.[`reportObject${DS}${keyWord}`];

        if (mainDatePeriod && token && tenantIds) {
            const requestArgs: IRequestMetricsArgs[] = [
                {
                    object_aggregation: false,
                    time_range: [mainDatePeriod.dateFrom, mainDatePeriod.dateTo],
                    time_freq: null,
                    obj_ids: tenantIds,
                    metric: 'fsf_bill_count',
                    alias: 'BillsExist',
                    token,
                },
            ];

            const args: IMetricsDAArgs = { requestArgs, fetchData };
            return [
                ['BillsExist', mainDatePeriod.dateFrom, mainDatePeriod.dateTo, tenantIds],
                () => metricsDataAccessor(args),
                (response: TMetricResponse[]): number | null => {
                    const values = response[0].reduce((acc, item) => {
                        const value = item.items[0].value;
                        return acc + (value || 0);
                    }, 0);

                    return values;
                },
            ];
        } else {
            return [[]];
        }
    }, [fetchData, keyWord, localCurrentOptions, token]);

    return useQuery({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn),
        staleTime: 6 * 3600 * 1000,
        select: adapter,
    });
};

export default useFetchBills;
