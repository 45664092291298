export const replaceRepeating = <T extends { value: string | null }>(args: Array<T[]>): T[] => {
    const flatArray: T[] = [];
    const valueCounts: { [key: string]: number } = {};

    args.forEach((innerArray) => {
        innerArray.forEach((item) => {
            let { value } = item;
            if (value !== null) {
                if (valueCounts[value] === undefined) {
                    // Первое вхождение значения
                    valueCounts[value] = 1;
                } else {
                    // Повторное вхождение значения
                    valueCounts[value] += 1;
                    value = `${value}_${valueCounts[value]}`;
                }
            }
            flatArray.push({ value } as T);
        });
    });

    return flatArray;
};
