import styled from 'styled-components/macro';

export const Wrapper = styled.div``;

export const Item = styled.div`
    padding: 5px 10px;
    cursor: pointer;
    :hover {
        background-color: rgba(69, 114, 230, 0.1);
    }
`;
