import { useEffect, useState } from 'react';
import { IArgs } from '../styles/styles.interfaces';
import _ from 'lodash';

import { ShadowSize } from './configurations/shadow-size.enum';
import { ShadowType } from './configurations/shadow-size.type';

const useShadow = ({
    ref,
    styles: { shadow },
    constants: { DEFAULT_CLASS_SHADOW_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX },
}: IArgs): string[] => {
    const [classes, setClasses] = useState<string[]>([]);

    /**
     * Проверка: есть ли такой размер ?
     * @param size - размер тени, например ('sm')
     */
    function isValidSize(size: ShadowType): boolean {
        return _.values(ShadowSize).includes(size as ShadowSize);
    }

    /**
     * Возвращает размер тени
     * @param size - размер тени, например ('sm')
     */
    function getShadowSizeClass(size: ShadowType): ShadowType {
        if (isValidSize(size)) {
            return size;
        } else {
            return ShadowSize.Default;
        }
    }

    useEffect(() => {
        const classes: string[] = [];
        if (shadow && isValidSize(shadow as ShadowType)) {
            const sizeClassModification: ShadowType = getShadowSizeClass(shadow as ShadowType);
            classes.push(`${DEFAULT_CLASS_SHADOW_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}${sizeClassModification}`);
        }
        setClasses(classes);
    }, [shadow]);

    return classes;
};

export default useShadow;
