import { useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
// import omit from 'lodash/omit';

import { snakeToCamelCase, transformFields, emptyArray, camelToSnakeCase } from 'src/tools';
import { useGeneralSelector } from 'src/hooks';

import { getAny } from '../../getAny';
import { generateUrl } from '../../helpers/generateUrl';

import { MapLayer } from './interfaces';

const transformResponse = (response: Array<object>): Array<MapLayer> => {
    if (!response.length) {
        return emptyArray;
    }

    return response.map((versionInfo) => transformFields<MapLayer>(versionInfo, snakeToCamelCase));
};

export const useFetchLayers = (mlId?: string | null, actualVersionId?: number) => {
    const { token, urlsByServices } = useGeneralSelector();

    const requestUrl = useMemo(() => {
        if (!actualVersionId || !token) {
            return null;
        }
        if (urlsByServices?.['core/map-service-back']) {
            const urlTemplate = `${urlsByServices['core/map-service-back'].LAYERS_URL}`;
            const query = transformFields({
                locationId: mlId,
                versionId: actualVersionId,
            }, camelToSnakeCase);
            return generateUrl(urlTemplate, { query });
        }
        return null;
    }, [token, actualVersionId, urlsByServices, mlId]);

    const isQueryEnabled = !!requestUrl && !!mlId && !!token;
    const result = useQuery({
        queryKey: [actualVersionId, mlId],
        queryFn: async () => {
            const response = await getAny(requestUrl!, token);
            if (!response || !response.data) {
                console.error(`Data is undefined or null for plans query from URL: ${requestUrl}`);
                return emptyArray; // возвращаем пустой массив, если данных нет
            }

            return transformResponse(response.data);
        },
        staleTime: Infinity,
        enabled: isQueryEnabled,
    });

    return result;
};
