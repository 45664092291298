import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
    Table,
    TableBody,
    TableCell,
    TableColumnDefinition,
    TableColumnId,
    TableHeader,
    TableHeaderCell,
    TableRow,
    createTableColumn,
    useTableFeatures,
    useTableSort,
    useTableColumnSizing_unstable,
    TableColumnSizingOptions,
} from '@fluentui/react-components';
import Button from '../../../../../../../components/Button/Button';

import { DS } from '../../../../../../../constants/constants';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../../../hooks/useWidgetCurrentOptions';
import { Reports_Advanced_DetailedBills_Widget_Reducer_Values } from '../../reducer';
import { Body, Footer, ModalWrapper } from './styles';
import { ISelectedOption } from 'src/components/Selects';
import useCreateBgReport from '../CreateBgReportButton/hooks/useCreateBgReport';
import { IPeriod, IPeriodObj } from 'src/General.interfaces';
import { stringDate } from 'src/tools/Strings/stringDate';
import { shortStringDate } from 'src/tools/Strings/shortStringDate';

interface IProps {
    onCloseModal: () => void;
    modalStatus: { show: boolean };
}

const OrderModal: React.FC<IProps> = ({ onCloseModal, modalStatus }) => {
    const { mutate, isPending } = useCreateBgReport();
    const { keyWord, bgReportType } = useSelector(Reports_Advanced_DetailedBills_Widget_Reducer_Values);
    const {
        selectedLocationId,
        currentModuleID,
        backgroundReportTypes,
        lang,
        appCoreData,
        currencyCode,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const rest = `${DS}${keyWord}`;

    const { t } = useTranslation();

    /** Основной период */
    const period: IPeriodObj | null = useMemo(() => {
        if (localCurrentOptions?.[`mainPeriod${rest}`] && localCurrentOptions?.[`mainDateRanges${rest}`]) {
            return (
                localCurrentOptions?.[`mainDateRanges${rest}`]?.find(
                    (dateRange: IPeriod) => dateRange.id === localCurrentOptions?.[`mainPeriod${rest}`]?.id,
                )?.period || null
            );
        }
        return null;
    }, [localCurrentOptions, rest]);

    /** ID типа отчета */
    const reportTypeId = useMemo(() => {
        if (backgroundReportTypes?.length) {
            return backgroundReportTypes.find((type) => type.name === bgReportType)?.id || null;
        }
        return null;
    }, [backgroundReportTypes, bgReportType]);

    const onCreate = useCallback(() => {
        if (selectedLocationId && period && reportTypeId && localCurrentOptions?.[`reportObject${rest}`]?.length) {
            const langCode = appCoreData?.languages?.find((item) => item.includes(lang)) || 'en-US';

            mutate({
                date_from: period.dateFrom,
                date_to: period.dateTo,
                pl_id: selectedLocationId,
                input_parameters: {
                    obj_ids: localCurrentOptions?.[`reportObject${rest}`],
                    additional_fields: localCurrentOptions?.[`additionalFields${rest}`]?.map(
                        (item: ISelectedOption) => item.id,
                    ),
                },
                report_type: reportTypeId,
                lang_code: langCode,
                currency_code: currencyCode,
            });
        }
        onCloseModal();
        return null;
    }, [selectedLocationId, period, reportTypeId, localCurrentOptions, rest, mutate]);

    const objNames = localCurrentOptions?.[`reportObject${rest}`]
        .map((id: string) => {
            const obj = reportingObjectsById?.[id];
            return obj ? obj.name : '';
        })
        .join(', ');

    const additionalColumns = localCurrentOptions?.[`additionalFields${rest}`]
        ?.map((item: ISelectedOption) => t(item.text))
        .join(', ');

    return (
        <ModalWrapper>
            <Body>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>{t('Period')}</TableCell>
                            <TableCell>
                                {period
                                    ? shortStringDate({ dateFrom: period?.dateFrom, dateTo: period?.dateTo }, lang)
                                    : ''}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('Tenants')}</TableCell>
                            <TableCell>{objNames}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('Additional columns')}</TableCell>
                            <TableCell>{additionalColumns}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Body>
            <Footer>
                <Button onClick={onCloseModal} text={t('Cancel')} />
                <Button appearance="primary" onClick={onCreate} text={'OK'} />
            </Footer>
        </ModalWrapper>
    );
};

export default OrderModal;
