import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isMobileInboundWidth } from 'src/theme';

import { IRequestMetricsArgs } from '../../../../../../tools/API/interfaces';
import { generalReducerValues } from '../../../../../../General.reducer';
import { Performance_Dashboard_ByArea_Widget_Reducer_Values, storeSelectedObjectId } from '../reducer';
import usePeriods from '../../../../../../hooks/common/usePeriods';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { DS } from '../../../../../../constants/constants';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../../../tools/API/dataAccessors/metricsDataAccessor';
import { ANALYSIS_BY_AREA_QUERY_KEY } from '../constants/constants';
import { TMetricResponse } from '../../../../../../General.interfaces';
import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';
import useObjectTranslation from '../../../../../../hooks/useObjectTranslation';
import { IDefaultDAResponse } from '../interfaces';

import defaultDataAdapter from './dataAdapters/defaultDataAdapter';

const useFetchData = () => {
    const {
        cfg: { reportingObjectsByType, reportingObjectsById },
        mainAreaSize: { width },
        currentModuleID,
        allMetrics,
        token,
    } = useSelector(generalReducerValues);

    const { mainPeriod, comparePeriods } = usePeriods(currentModuleID);

    const { objectsType, comparePeriod, areaMetric, colorMetric } = useSelector(
        Performance_Dashboard_ByArea_Widget_Reducer_Values,
    );
    const fetchData = useRequestMetrics();
    const dispatch = useDispatch();
    const { getObjName } = useObjectTranslation(['translation']);
    const { t } = useTranslation(['metrics', 'translation']);

    const isMobile = isMobileInboundWidth(width);

    const { queryKey, queryFn, dataAdapter } = useMemo(() => {
        const comparePeriodDateRange = comparePeriods?.find((item) => item.id === comparePeriod.id)?.period;
        if (token && mainPeriod && comparePeriodDateRange) {
            const objIds = reportingObjectsByType?.[objectsType]?.map((item) => item.id);
            dispatch(storeSelectedObjectId(null));
            const areaRequest: IRequestMetricsArgs = {
                time_range: [mainPeriod.period.dateFrom, mainPeriod.period.dateTo],
                alias: `main${DS}areaMetric`,
                object_aggregation: false,
                metric: areaMetric,
                time_freq: null,
                obj_ids: objIds,
                token,
            };
            const metricMainRequest: IRequestMetricsArgs = {
                ...areaRequest,
                metric: colorMetric,
                alias: `main${DS}colorMetric`,
            };
            const metricCompareRequest: IRequestMetricsArgs = {
                ...metricMainRequest,
                time_range: [comparePeriodDateRange.dateFrom, comparePeriodDateRange.dateTo],
                alias: `compare${DS}colorMetric`,
            };

            const requestArgs: IRequestMetricsArgs[] = [areaRequest, metricMainRequest, metricCompareRequest];

            const dataAccessorArgs: IMetricsDAArgs = {
                requestArgs,
                fetchData,
            };

            return {
                queryKey: [ANALYSIS_BY_AREA_QUERY_KEY, currentModuleID, requestArgs],
                queryFn: () => metricsDataAccessor(dataAccessorArgs),
                dataAdapter: (response: TMetricResponse[]) =>
                    defaultDataAdapter({
                        response,
                        isMobile,
                        dispatch,
                        t,
                        allMetrics,
                        reportingObjectsById,
                        getObjName,
                    }),
            };
        }
        return {};
    }, [
        comparePeriod,
        mainPeriod,
        comparePeriods,
        reportingObjectsByType,
        reportingObjectsById,
        currentModuleID,
        objectsType,
        colorMetric,
        areaMetric,
        allMetrics,
        isMobile,
        token,
        t,
    ]);

    return useQuery<TMetricResponse[], unknown, IDefaultDAResponse, any>({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(dataAdapter),
        select: dataAdapter,
        staleTime: 6 * 3600 * 1000,
    });
};

export default useFetchData;
