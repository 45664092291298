import React from 'react';
import ReactDataSheet from 'react-datasheet';
import { TFunction } from 'i18next';


import { MenuConfig, MenuContextArgs, MenuContextBase } from 'src/components/ContextualMenu';
import { ComponentIdentity, Identity } from 'src/types';
// import { Identity, PickPureFieldType, ExtractOne, SpliceOne, Unpacked } from 'src/types';

export type SortOrder = 'asc' | 'desc';

export type Cell<T extends Cell<any, I>, I extends string = string> = ReactDataSheet.Cell<T, I>;

export enum CellDataType {
    Boolean = 'b',
    Error = 'e',
    Number = 'n',
    Date = 'd',
    String = 's',
    Stub = 'z',
}

export type ColumnOptions<I extends string = string, R extends Identity<I> = Identity<I>> = {
    // stay first to overload title type
    label?: React.ReactNode;
    // type?: ColumnTypes;
    visible?: boolean;
    hasSort?: boolean;
    style?: React.CSSProperties;
    // Включение возможности отсутствия сортировки
    isCancellableSort?: boolean;
    hasFilter?: boolean;
    // Начальное значение сортировки
    sortOrder?: SortOrder;
    align?: 'left' | 'center' | 'right' | 'justify';
    cellRenderer?: (value: unknown, context: TableContext<I, R>) => React.ReactNode;
    valueFormatter?: (value: unknown, context: TableContext<I, R>) => unknown;
    dataType?: CellDataType;
    precision?: number;
} & Omit<Cell<Cell<any, I>, string>, 'width'> &
    Identity<I>; // Column<R> & // stay last to require 'id' always

// export type ColumnOptions<I extends string | number = string, R extends Identity<I, R> = Identity<I>> = ColumnOption<
//     I,
//     R
// >[];

export type ColumnOptionsProps<I extends string = string, R extends Identity<I> = Identity<I>> = {
    columnsOptions?: ColumnOptions<I, R>[];
};

export type TableMenuArgs<
    I extends string = string,
    R extends Identity<I> = Identity<I>
> = MenuContextArgs<I, R, CellEvent<I, R>, TableContext<I, R>>

export type MenuConfigProps<
    I extends string = string,
    R extends Identity<I> = Identity<I>
> = {
    menuConfig?: MenuConfig<I, R, CellEvent<I, R>, TableContext<I, R>>;
};

// export type ContextMenuData<D = unknown, O = unknown> = {
//     coords?: { x: number; y: number };
//     cellData?: D;
//     cellObject?: O;
//     cellCoords?: { row: number; col: number };
// };

// export type SelectionOptions = Unpacked<Parameters<PickPureFieldType<DataSheetGridProps, 'onSelectionChange'>>>;
// export type Selection = PickPureFieldType<SelectionOptions, 'selection'>;
type ReactDataSheetBaseProps = ReactDataSheet.DataSheetProps<any, string>;

export type Location = {
    row: number; //
    col: number;
};

export type TableRange = {
    start: Location;
    end: Location;
};

type OmitProps = 'data' | 'valueRenderer' | 'selected';

type NotSelectedRange = null;

export type FocusTableProps<
    // T extends Cell<any, I>,
    // I extends string = string,
    I extends string = string, // id type
    // Каждая строка на входе в компоненту должна иметь айдишник
    R extends Identity<I> = Identity<I>, // row data type
    // D extends {} = {}, // column data type
    // P // Paste data type
> = {
    tableTitle?: string;

    // onCellMouseUp?: (rowData: unknown[], ranges: CellRange[]) => void;
    // menuComponent: ComponentType<ContextMenuData>;
    value: R[];
    onSelectionChange?: (selection: TableRange | null, selectedRows: R[]) => void;
    selectedCells?: TableRange | NotSelectedRange; // null - Запрет выделения; undefined - использовать локал стейт таблицы react-datasheet

    cellStyle?: React.CSSProperties;
    // saveConfig;
    // LoadConfig;
} & Omit<ReactDataSheetBaseProps, OmitProps> &
    // ReactDataSheetBaseProps &
    // CellRenderer<> &
    MenuConfigProps<I, R> &
    ColumnOptionsProps<I> &
    ExternalTableContextProps &
    TableConfigProps;
// declare class ReactDataSheet<T extends ReactDataSheet.Cell<T, V>, V = string> extends Component<ReactDataSheet.DataSheetProps<T, V>, ReactDataSheet.DataSheetState> {
// }
// export interface HeaderRender<T extends ReactDataSheet.Cell<T, V>, V = string> {
//     headerRenderer?: ReactDataSheet.CellRenderer<T, V>;
// }

// export interface CellRender<T extends ReactDataSheet.Cell<T, V>, V = string> {
//     cellRenderer?: ReactDataSheet.CellRenderer<T, V>;
// }

export type TableConfig = {
    sorters?: ColumnSorter[];
    filters?: TableFilter[];
};

export type TableConfigChanges = TableConfig & ComponentIdentity;

export type ExternalTableContextProps = ComponentIdentity & {
    componentId: string
    nameForExports: string;
};

export type WrapperStyleProps = Omit<React.CSSProperties, 'translate'>;

export type TableConfigProps = {
    initialConfig?: TableConfig;
    onChangeConfig?: (changes: TableConfigChanges) => void;
};

export type CellRender<T extends ReactDataSheet.Cell<T, V>, V = string> = {
    cellRenderer?: ReactDataSheet.CellRenderer<T, V>;
};

export type ColumnSorter = {
    sortOrder?: SortOrder;
    columnId: string;
};
export type SorterUpdater = (prevSorters: ColumnSorter[]) => ColumnSorter[];

export type TableFilter = {
    columnId: string;
    filterId: string;
    filterValue: string | number | null | undefined;
    filterPredicate: (value: unknown) => boolean;
};

export type FilterUpdater = (prevFilters: TableFilter[]) => TableFilter[];

export type TableContext<
    I extends string = string, // id type
    R extends Identity<I> = Identity<I>,
> = MenuContextBase & {
    sorters: ColumnSorter[];
    changeSorter: (newValueOrUpdater: ColumnSorter[] | SorterUpdater) => void;

    filters: TableFilter[];
    changeFilter: (filterUpdaterOrFilters: FilterUpdater | TableFilter[]) => void;

    selection?: ReactDataSheet.Selection | null;
    changeSelection?: (selection: ReactDataSheet.Selection | null) => void;
    isSelection?: boolean;

    visibleColumns: ColumnOptions<I, R>[];
    closeMenu: (event?: MouseEvent) => void;
    data: R[];
    rawData: R[];

    t: TFunction;

} & ExternalTableContextProps & TableConfigProps;

export type CellEvent<
    I extends string = string,
    R extends Identity<I> = Identity<I>
> = {
    row?: number;
    col?: number;
    column: ColumnOptions<I, R>;
    // value: string | number | undefined | null | R[keyof R] ;
    value?: R[keyof R] | null;
};

// export type MenuContextArgs<
//     I extends string = string, // id type
//     R extends Identity<I> = Identity<I>,
// > = {
//     event: CellEvent<I, R>;
//     rowData: R;
//     context: TableContext<I, R>;
//     menuItemId: string;
//     label?: React.ReactNode;
// };
