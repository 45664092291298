import { FC } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../General.reducer';
import { IEvent } from '../../Chapters/Events/EventsMap/widgets/EventsMapWidget/interfaces';
import { EventName, Wrapper } from './styles';
import Stack from '../Stack/Stack';
import { shortStringDate } from '../../tools/Strings/shortStringDate';
import { rgba2hex } from 'src/tools/rgba2hex';

interface IProps {
    /** функция для записи id выбранного события */
    storeSelectedEventIdTrans: (id: number) => void;
    /** само событие */
    event: IEvent;
}

/**
 * Компонент для отображения карточки события
 */
const EventCard: FC<IProps> = ({ event, storeSelectedEventIdTrans }) => {
    const { lang } = useSelector(generalReducerValues);

    const onCardClick = () => {
        storeSelectedEventIdTrans(event.id);
    };

    const color = rgba2hex(event.event_type_color);

    return (
        <Wrapper color={color} onClick={onCardClick}>
            <Stack alignItems="center" direction={'row'} gap={10}>
                <EventName>{event.name}</EventName>
            </Stack>

            <div>{shortStringDate({ dateFrom: event.date_from, dateTo: event.date_to }, lang)}</div>
        </Wrapper>
    );
};

export default EventCard;
