import { IEventType } from '../../../interfaces';
import { IPeriod } from '../interfaces';

interface IArgs {
    selectedReportingObjectsIds: number[];
    eventType: IEventType | null;
    timeFrom: string;
    period: IPeriod;
    timeTo: string;
    name: string;
}

const validateFormValues = (args: IArgs): { hasError: boolean; result: { [key: string]: boolean } } => {
    const { selectedReportingObjectsIds, eventType, timeFrom, period, timeTo, name } = args;
    let hasError = false;
    const result = {
        selectedReportingObjectsIdsError: false,
        eventTypeError: false,
        timeFromError: false,
        periodError: false,
        timeToError: false,
        nameError: false,
    };

    if (
        !/([01]?[0-9]|2[0-3]):[0-5][0-9]/.test(timeFrom) ||
        !/([01]?[0-9]|2[0-3]):[0-5][0-9]/.test(timeTo) ||
        !selectedReportingObjectsIds.length ||
        !period.dateFrom ||
        !eventType?.id ||
        !name
    ) {
        hasError = true;
        if (!timeFrom || !/([01]?[0-9]|2[0-3]):[0-5][0-9]/.test(timeFrom)) result.timeFromError = true;
        if (!timeTo || !/([01]?[0-9]|2[0-3]):[0-5][0-9]/.test(timeTo)) result.timeToError = true;
        if (!selectedReportingObjectsIds.length) result.selectedReportingObjectsIdsError = true;
        if (!period.dateFrom) result.periodError = true;
        if (!eventType?.id) result.eventTypeError = true;
        if (!name) result.nameError = true;
    } else {
        return { hasError, result };
    }

    return { hasError, result };
};

export default validateFormValues;
