import { useAppDispatch } from '../../../../hooks/useSettings';
import useMutateCorrectionBills from '../api/useMutateCorrectionBills';
import { IMetricCell, IPostBillData, TOperationType } from '../interfaces';
import { toggleValidationModalStatus } from '../reducer';
import { replaceCommas } from '../../tools/replaceCommas';
import { useValidate } from './useValidate';
import {
    isBillFilledIncome,
    isBillFilledReturn,
    isIntegerBillCount,
    isNonZeroVatIncome,
    isNonZeroVatReturn,
    isNumbersInCell,
    // isSalesBillCountMoreThanOne,
    // isReturnsBillCountMoreThanOne,
    // isValidReturnsNoVatToVatRatio,
    // isValidSalesNoVatToVatRatio,
} from './validationFoos';

const useSaveTable = (tableKey: string) => {
    const { postMutation } = useMutateCorrectionBills();
    const { validate } = useValidate({
        validateFoos: [
            isNumbersInCell,
            isIntegerBillCount,
            // isSalesBillCountMoreThanOne,
            // isReturnsBillCountMoreThanOne,
            // isValidSalesNoVatToVatRatio,
            // isValidReturnsNoVatToVatRatio,
            isNonZeroVatIncome,
            isNonZeroVatReturn,
            isBillFilledIncome,
            isBillFilledReturn,
        ],
        tableKey,
    });
    const dispatch = useAppDispatch();

    const save = ({
        dataToSave,
        onSaveSuccess,
    }: {
        dataToSave: IMetricCell[][];
        onSaveSuccess?: (data: IPostBillData[]) => void;
    }) => {
        const changedRows = dataToSave.filter((row) => row.some((cell) => cell.changeType !== undefined));

        const getDataToPost = (changedRows: IMetricCell[][], operation_type: TOperationType): IPostBillData[] => {
            return changedRows.reduce((acc, row) => {
                if (
                    row.some(
                        (cell) => cell.changeType !== undefined && cell.billData?.operation_type === operation_type,
                    )
                ) {
                    const obj = row
                        .filter((cell) => cell.billData?.operation_type === operation_type)
                        .reduce((acc, cell) => {
                            return {
                                ...acc,
                                ...cell.billData,
                                [cell['metric']!]: cell.value ? Number(replaceCommas(cell.value)) : null,
                            };
                        }, {});
                    return [...acc, obj];
                } else return acc;
            }, []) as IPostBillData[];
        };

        const salesData = getDataToPost(changedRows, 'income');
        const returnsData = getDataToPost(changedRows, 'income_return');

        const validateResult = validate(changedRows);

        if (validateResult.isValid) {
            postMutation.mutateAsync([...salesData, ...returnsData]).then((data) => {
                onSaveSuccess && onSaveSuccess(data);
            });
        } else {
            dispatch(toggleValidationModalStatus({ show: true, data: validateResult.data }));
        }
    };
    return { save };
};

export default useSaveTable;
