import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICell, IReducerState, IValidateFooResult, IValidationModalStatus, TSelectedTab } from './interfaces';
import { RootState } from '../../../store';
import ReactDataSheet from 'react-datasheet';
import { cloneDeep, isString, result } from 'lodash';
import { generateEmptyData } from './tools/generateEmptyData';
import { IChangeOptionsArgs, IObject } from 'src/components/Selects';

const initialState: IReducerState = {
    selectedTab: 'matching',
    dataToEdit: { result: generateEmptyData(), source: generateEmptyData(), dict: generateEmptyData() },
    selectedFields: { source: undefined, dict: undefined },
    dataFromFile: [],
    dateRangeFromFile: null,
    objIdsFromFile: [],
    validationModalStatus: { show: false, data: [] },
};

const Configuration_Utilities_Module_Reducer = createSlice({
    name: 'Configuration_Utilities_Module_Reducer',
    initialState,
    reducers: {
        storeSelectedTab: (state, action: PayloadAction<TSelectedTab>) => {
            state.selectedTab = action.payload;
        },

        storeDataFromFile: (state, action: PayloadAction<ICell[][]>) => {
            state.dataFromFile = action.payload;
        },

        storeDateRangeFromFile: (state, action: PayloadAction<{ dateFrom: string; dateTo: string } | null>) => {
            state.dateRangeFromFile = action.payload;
        },

        storeObjIdsFromFile: (state, action: PayloadAction<number[]>) => {
            state.objIdsFromFile = action.payload;
        },

        toggleValidationModalStatus: (state, action: PayloadAction<IValidationModalStatus>) => {
            state.validationModalStatus = action.payload;
        },

        resetState: () => initialState,

        storeSelectedField: (
            state,
            action: PayloadAction<{ tableKey: string; selectedField: Pick<IChangeOptionsArgs, 'newOptions'> | undefined }>,
        ) => {
            state.selectedFields[action.payload.tableKey] = action.payload?.selectedField?.[
                action.payload.tableKey
            ] as unknown as IObject[] | undefined;
        },

        updateTableData: (
            state,
            action: PayloadAction<{ data: ReactDataSheet.CellsChangedArgs<ICell>; tableKey: string }>,
        ) => {
            const { data, tableKey } = action.payload;
            const dataToEdit = cloneDeep(state.dataToEdit[tableKey]) || generateEmptyData(10, 100);

            data.forEach((args) => {
                const { cell, row, col, value } = args;

                if (cell && !cell.readOnly && isString(value)) {
                    const prevValue = (dataToEdit?.[row]?.[col] as ICell) || { value: '' };
                    if (dataToEdit?.[row]?.[col] !== undefined) {
                        dataToEdit[row][col] = {
                            ...prevValue,
                            value,
                        };
                    }
                }
            });

            state.dataToEdit[tableKey] = dataToEdit;
        },

        fillTable: (state, action: PayloadAction<{ data: ICell[][]; tableKey: string }>) => {
            const { data, tableKey } = action.payload;
            state.dataToEdit[tableKey] = data;
        },
    },
});

export const {
    storeSelectedTab,
    updateTableData,
    storeDateRangeFromFile,
    storeDataFromFile,
    storeSelectedField,
    fillTable,
    resetState,
    toggleValidationModalStatus,
    storeObjIdsFromFile,
} = Configuration_Utilities_Module_Reducer.actions;

export const Configuration_Utilities_Module_Reducer_Values = (state: RootState) =>
    state.Configuration_Utilities_Module_Reducer;

export default Configuration_Utilities_Module_Reducer.reducer;
