import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { IDynamicsSettings } from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/interfaces';
import { useGeneralSelector } from 'src/hooks';
import { toString, emptyArray } from 'src/tools';

import {
    OPERATION_TYPES,
    // PAYMENT_TYPES,
    SALES_METRIC_NAMES,
} from '../../../../constants/constants';
import { useWidgetCurrentOptions } from '../../../../../../hooks/useWidgetCurrentOptions';
import { useRequestMetrics } from '../../../../../../tools/API/hooks/useRequestMetrics';
import { IPeriod } from '../../../../../../General.interfaces';
// import { TabListItemIds } from '../../../../enums';
import { DS } from '../../../../../../constants/constants';
import metricsDataAccessor, { IMetricsDAArgs } from '../../../../../../tools/API/dataAccessors/metricsDataAccessor';
import { IMetricParams, IRequestMetricsArgs, TTimeFreq } from '../../../../../../tools/API/interfaces';
// import useTranslation from '../../../../../../hooks/useTranslation/useTranslation';
import { METRICS_POS_ANALYTICS_SELECT } from '../constants';

type PosInfo = {
    id: string,
    registrationId: number,
};

type FetchStructureDynamicsProps = {
    tenantId: number;
    posesData: Array<PosInfo>;
    viewSettings?: IDynamicsSettings;
};

const useFetchData = ({ posesData, tenantId }: FetchStructureDynamicsProps) => {
    const { token, currentModuleID } = useGeneralSelector();

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const fetchData = useRequestMetrics();

    const selectedMetrics: Array<{ id: string }> = localCurrentOptions?.[METRICS_POS_ANALYTICS_SELECT] ?? emptyArray;

    const [queryKey, queryFn, adapter] = useMemo(() => {
        const correctionReceipts = true;
        const mainPeriod = localCurrentOptions?.mainDateRanges?.find(
            (item) => item.id === localCurrentOptions?.mainPeriod?.id,
        ) as IPeriod | undefined;

        if (!mainPeriod?.period || !tenantId || !token || !correctionReceipts) {
            return [[]];
        }

        const generateRequests = (poses: PosInfo[], metric: string) => {
            const result: IRequestMetricsArgs[] = [];

            poses.forEach((pos) => {
                const alias = `${toString(pos.registrationId)}${DS}${metric}`;

                result.push(
                    prepareArgs({
                        timeRange: [mainPeriod?.period?.dateFrom || '', mainPeriod?.period?.dateTo || ''],
                        alias,
                        objIds: [tenantId],
                        metric,
                        timeFreq: null,
                        token,
                        metricParams: {
                            operation_type: OPERATION_TYPES[0],
                            // bill_type: CORRECTION_MAP[undefined],
                            // bill_type: CORRECTION_MAP[correctionReceipts === true ? undefined : correctionReceipts],
                            // payment_sum: pType,
                            point_of_sale_ids: pos.id,
                        },
                    }),
                );
            });

            return result;

        };

        const metrics = selectedMetrics.map(({ id }) => (id));
        const requestArgs = metrics.reduce((acc: IRequestMetricsArgs[], metric: string) => {
            acc.push(...generateRequests(posesData, metric));
            return acc;
        }, []);

        const args: IMetricsDAArgs = { requestArgs, fetchData };

        return [
            [
                mainPeriod?.period,
                posesData,
                tenantId,
                correctionReceipts,
                metrics,
            ],
            () => metricsDataAccessor(args),
            (response: unknown) => response,
        ];
    }, [
        fetchData,
        localCurrentOptions,
        posesData,
        tenantId,
        selectedMetrics,
        token,
    ]);

    return useQuery({
        queryKey,
        queryFn,
        enabled: Boolean(queryKey) && Boolean(queryFn) && Boolean(adapter),
        staleTime: 6 * 3600 * 1000,
    });
};

export default useFetchData;

interface IPrepareArgs {
    timeRange: [string, string];
    alias: string;
    metric: string;
    objIds: number[];
    token: string;
    timeFreq: TTimeFreq;
    metricParams?: IMetricParams;
}

const prepareArgs = (args: IPrepareArgs): IRequestMetricsArgs => {
    const { timeRange, alias, metric, objIds, token, metricParams, timeFreq = null } = args;
    return {
        object_aggregation: false,
        time_range: timeRange,
        time_freq: timeFreq,
        obj_ids: objIds,
        metric,
        alias,
        token,
        metric_params: metricParams,
    };
};
