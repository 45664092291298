import { TFunction } from 'i18next';

export enum IdsOfREportingObjectTypes {
    Tenants = 'tenant',
    Zones = 'zone',
}

export const optionsOfReportingObjectType = [
    { id: IdsOfREportingObjectTypes.Tenants, text: 'Tenants' },
    { id: IdsOfREportingObjectTypes.Zones, text: 'Zones' },
];
