
import { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { DS } from 'src/constants/constants';
import { useGeneralSelector } from 'src/hooks';
import {
    DynamicsSettings as GenericDynamicsSettings,
    DynamicsSettingsByDefault,
} from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings';
import { Dynamics_Settings_Reducer_Values } from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings/reducer';
import { DynamicsSettings, IDynamicsSettings } from 'src/components/CommonWidgets/DynamicsPMO/components/DynamicsSettings';
import { TDynamicsSeries } from 'src/components/CommonWidgets/DynamicsPMO/components/Dynamics/interfaces';
import { emptyObject } from 'src/tools';

export const DynamicsSettingsWrapper = styled.div`
    position: absolute;
    top: 15px;
    right: 10px;
    background-color: white;
`;

type ChartDynamicsSettingsOptions = {
    disableWeekends?: boolean;
    disableWeather?: boolean;
    disableEvents?: boolean;
    disableAverage?: boolean;
    disableTrend?: boolean;
};

// type ViewSettings = {
//     seriesIdsForTrend?: string[];
//     seriesIdsForAverage?: string[];
//     showEvents?: boolean;
//     showWeather?: boolean;
//     showWWeekends?: boolean;
// };

export const useModuleChartsDynamicsSettings = <
    // S extends TDynamicsSeries = TDynamicsSeries,
    V extends object = {},
>(fullWidgetModuleNameOrShortWidgetID: string, options?: ChartDynamicsSettingsOptions & DynamicsSettingsByDefault) => {
    const { currentModuleID } = useGeneralSelector();

    const dynamicsSettingsId = fullWidgetModuleNameOrShortWidgetID.includes(DS)
        ? fullWidgetModuleNameOrShortWidgetID
        : `${currentModuleID}${DS}${fullWidgetModuleNameOrShortWidgetID}`;

    const { dynamicsSettingsById } = useSelector(Dynamics_Settings_Reducer_Values);
    const widgetSettings = dynamicsSettingsById[dynamicsSettingsId] as IDynamicsSettings | undefined;

    const {
        disableWeekends,
        disableEvents,
        disableWeather,
        disableTrend,
        disableAverage,
        ...defaultDynamicsSettings
    } = options || emptyObject as ChartDynamicsSettingsOptions & DynamicsSettingsByDefault;

    const [viewSettingsProps, dynamicsSettingsProps] = useMemo(
        () => [
            {
                ...!disableWeekends && { showWeekends: widgetSettings?.weekends.isActive },
                ...!disableEvents && { showEvents: widgetSettings?.events.isActive },
                ...!disableWeather && { showWeather: widgetSettings?.weather.isActive },
                ...!disableTrend && { seriesIdsForTrend: widgetSettings?.trendSeries.selectedSeriesIds },
                ...!disableAverage && { seriesIdsForAverage: widgetSettings?.averageSeries.selectedSeriesIds },
            },
            {
                ...disableWeekends && { weekendsProps: { visible: false } },
                ...disableEvents && { eventsProps: { visible: false } },
                ...disableWeather && { weatherProps: { visible: false } },
                ...disableTrend && { trendSeriesProps: { visible: false } },
                ...disableAverage && { averageSeriesProps: { visible: false } },
            },
        ],
        [
            widgetSettings,
            disableAverage,
            disableEvents,
            disableTrend,
            disableWeather,
            disableWeekends,
        ],
    );
    const [outsideSeries, setSeries] = useState<Array<TDynamicsSeries>>([]);

    const onOutsideSeriesChange = useCallback(
        (series: Array<TDynamicsSeries>) => {
            setSeries(series);
        },
        [setSeries],
    );

    const dynamicsSettings = (
        <DynamicsSettings
            id={dynamicsSettingsId}
            series={outsideSeries}
            {...dynamicsSettingsProps}
            {...defaultDynamicsSettings}
        />
    );

    const wrappedDynamicsSettings = (
        <DynamicsSettingsWrapper>
            {dynamicsSettings}
        </DynamicsSettingsWrapper>
    );

    return {
        dynamicsSettingsId,
        widgetSettings,
        dynamicsSettings,
        DynamicsSettings: GenericDynamicsSettings<TDynamicsSeries>,
        outsideSeries,
        onOutsideSeriesChange,
        DynamicsSettingsWrapper,
        wrappedDynamicsSettings,
        viewSettingsProps: viewSettingsProps as V,
    };
};