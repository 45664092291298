/**
 * Размеры иконок
 */
export enum Size {
    /**
     * Значение пусто
     */
    Empty = '',
    /**
     * Базовый размер
     */
    Base = 'xs',
    /**
     * Самый маленький размер
     */
    Xxxs = 'xxxs',
    /**
     * Очень очень маленький
     */
    Xxs = 'xxs',
    /**
     * Очень маленький
     */
    Xs = 'xs',
    /**
     * Маленький размер
     */
    Sm = 'sm',
    /**
     * Средний размер
     */
    Md = 'md',
    /**
     * Большоя размер
     */
    Lg = 'lg',
    /**
     * Очень большой размер
     */
    Xl = 'xl',
    /**
     * Огромный размер
     */
    Xxl = 'xxl'
}
