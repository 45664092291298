import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: flex-end;
    color: var(--color-contrast-higher);

    span {
        padding: 0px !important;
    }
`;
