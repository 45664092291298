import React from 'react';
import { useTranslation } from 'react-i18next';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import BasicReportWidget from './widgets/BasicReportWidget/BasicReportWidget';
import { OptionsWrapper } from './styles/styles';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import { useAppDispatch } from '../../../hooks/useSettings';
import TabList from '../../../components/TabList/TabList';
import { toggleTab, Reports_Analytical_Module_Reducer_Values } from './reducer';
import BasicOptions from './widgets/BasicReportWidget/BasicOptions';
import ComparisonReportWidget from './widgets/ComparisonReportWidget/ComparisonReportWidget';
import ComparisonOptions from './widgets/ComparisonReportWidget/ComparisonOptions';
import IntersectionOptions from './widgets/IntersectionsReportWidget/IntersectionOptions';
import IntersectionsReportWidget from './widgets/IntersectionsReportWidget/IntersectionsReportWidget';
import TenantsOptions from './widgets/TenantsReportWidget/TenantsOptions';
import TenantsReportWidget from './widgets/TenantsReportWidget/TenantsReportWidget';
import ModuleOptionsPanel from 'src/components/ModuleOptionsPanel/ModuleOptionsPanel';
import { theme } from 'src/theme';
import TenantsComparisonOptions from './widgets/TenantsComparisonReportWidget/TenantsComparisonOptions';
import TenantsComparisonReportWidget from './widgets/TenantsComparisonReportWidget/TenantsComparisonReportWidget';

const Analytical: React.FC = () => {
    const { user } = useSelector(generalReducerValues);
    const { tab } = useSelector(Reports_Analytical_Module_Reducer_Values);
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const tabListItems = [
        {
            id: 'basic',
            text: t('Basic report'),
            permissions: ['app/client | modules/reports/basic | feature/view'],
        },
        {
            id: 'comparison',
            text: t('Basic report with comparison'),
            permissions: ['app/client | modules/reports/with-comparison | feature/view'],
        },
        {
            id: 'byTenants',
            text: t('By tenants'),
            permissions: ['app/client | modules/reports/by-tenants | feature/view'],
        },
        {
            id: 'byTenantsComparison',
            text: t('By tenants with comparison'),
            permissions: ['app/client | modules/reports/by-tenants | feature/view'],
        },
        {
            id: 'intersections',
            text: t('Daily intersections'),
            permissions: ['app/client | modules/reports/daily-intersections | feature/view'],
        },
    ];

    const allowedTabItems = tabListItems.filter((val) => {
        return val.permissions.every((permission) => user?.permissions.includes(permission));
    });

    const tabListSelectHandler = (id: string) => {
        dispatch(toggleTab(id));
    };

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    {tab === 'basic' && <BasicOptions />}
                    {tab === 'comparison' && <ComparisonOptions />}
                    {tab === 'byTenants' && <TenantsOptions />}
                    {tab === 'byTenantsComparison' && <TenantsComparisonOptions />}
                    {tab === 'intersections' && <IntersectionOptions />}
                </OptionsWrapper>
            </ModuleOptionsPanel>

            <WrapperContainer style={{ maxWidth: 'none' }}>
                <div style={{ marginTop: -15 }}>
                    <TabList
                        widgetName=""
                        selectHandler={tabListSelectHandler}
                        selectedValue={tab}
                        options={allowedTabItems}
                    />
                </div>

                {tab === 'basic' && <BasicReportWidget />}
                {tab === 'comparison' && <ComparisonReportWidget />}
                {tab === 'byTenants' && <TenantsReportWidget />}
                {tab === 'byTenantsComparison' && <TenantsComparisonReportWidget />}
                {tab === 'intersections' && <IntersectionsReportWidget />}
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Analytical;
