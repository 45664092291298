import React, { FC } from 'react';
import { IProps } from './interfaces';
import { Th } from '../../styles';

import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import { CellHeader } from './components/CellHeader';
import { CellHeaderDelta } from './components/CellHeaderDelta';
import { CellHeaderDeviation } from './components/CellHeaderDeviation';

/**
 * Шапка таблицы
 */
const Header: FC<IProps> = ({ viewSettings, dataItemDeltas, allMetrics, lang }) => {
    const { t } = useTranslation(['metrics', 'translation']);

    const currentMetric = allMetrics?.find((x) => x.id === viewSettings?.metric);

    return (
        <thead>
            <tr>
                <Th>
                    {t(`${String(viewSettings?.dataObjType)[0].toUpperCase()}${viewSettings?.dataObjType?.slice(1)}`)}
                </Th>
                <CellHeader>
                    {t('Total')}, {t('%')}
                </CellHeader>

                {viewSettings?.mainColumn === 'absolute' && (
                    <CellHeader>
                        {currentMetric ? `${t(currentMetric.text)}, ${t(currentMetric.units)}` : ''}
                    </CellHeader>
                )}
                {viewSettings?.mainColumn === 'delta' &&
                    dataItemDeltas.map((item, i) => {
                        return (
                            <CellHeaderDelta
                                key={`itemDeltasMain${i}`}
                                s={t('Delta')}
                                s1={t(currentMetric?.units || '')}
                                length={dataItemDeltas.length}
                                item={item}
                                lang={lang}
                            />
                        );
                    })}
                {viewSettings?.mainColumn === 'deviation' &&
                    dataItemDeltas.map((item, i) => {
                        return (
                            <CellHeaderDeviation
                                key={`itemDeviationMain${i}`}
                                t={t('Deviation')}
                                length={dataItemDeltas.length}
                                item={item}
                                lang={lang}
                            />
                        );
                    })}
                {viewSettings?.mainColumn === 'area' && (
                    <CellHeader>
                        {t('Area')}, {t('m²')}
                    </CellHeader>
                )}
                {viewSettings?.additionalColumns?.find((x) => viewSettings?.mainColumn !== x.id && x.id === 'area')
                    ?.checked && (
                    <CellHeader>
                        {t('Area')}, {t('m²')}
                    </CellHeader>
                )}
                {viewSettings?.additionalColumns?.find((x) => viewSettings?.mainColumn !== x.id && x.id === 'delta')
                    ?.checked &&
                    dataItemDeltas.map((item, i) => {
                        return (
                            <CellHeaderDelta
                                key={`itemDeltasMain${i}`}
                                s={t('Delta')}
                                s1={t(currentMetric?.units || '')}
                                length={dataItemDeltas.length}
                                item={item}
                                lang={lang}
                            />
                        );
                    })}
                {viewSettings?.additionalColumns?.find((x) => viewSettings?.mainColumn !== x.id && x.id === 'deviation')
                    ?.checked &&
                    dataItemDeltas.map((item, i) => {
                        return (
                            <CellHeaderDeviation
                                key={`itemDeviationMain${i}`}
                                t={t('Deviation')}
                                length={dataItemDeltas.length}
                                item={item}
                                lang={lang}
                            />
                        );
                    })}
                {viewSettings?.additionalColumns?.find((x) => viewSettings?.mainColumn !== x.id && x.id === 'absolute')
                    ?.checked && (
                    <CellHeader>
                        {currentMetric ? `${t(currentMetric.text)}, ${t(currentMetric.units)}` : ''}
                    </CellHeader>
                )}
            </tr>
        </thead>
    );
};

export default Header;
